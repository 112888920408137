import {
	BaseTextFieldProps,
	Box,
	TextField,
	TextFieldProps,
	Typography,
	useTheme,
} from '@mui/material';
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { FC, ReactNode } from 'react';

//#region Slot Overrides

type DateTextFieldProps = TextFieldProps & {
	extraEndAdornments?: ReactNode;
};

const DateTextField: FC<DateTextFieldProps> = observer(
	({ InputProps, extraEndAdornments, ...rest }) => {
		return (
			<TextField
				{...rest}
				InputProps={{
					...InputProps,
					endAdornment: (
						<>
							{InputProps?.endAdornment}
							{extraEndAdornments && extraEndAdornments}
						</>
					),
				}}
			/>
		);
	},
);

//#endregion

export interface T4DateFieldProps<TDateType>
	extends Pick<
		DatePickerProps<TDateType>,
		| 'disabled'
		| 'loading'
		| 'value'
		| 'readOnly'
		| 'slots'
		| 'slotProps'
		| 'maxDate'
		| 'sx'
	> {
	/**
	 * The id of the date field.
	 */
	id?: string;

	/**
	 * @see {@link BaseTextFieldProps.label}
	 */
	label: BaseTextFieldProps['label'];

	/**
	 * @see {@link BaseTextFieldProps.required}
	 */
	required?: BaseTextFieldProps['required'];

	/**
	 * @see {@link DatePickerProps.onAccept}
	 */
	onAccept?: DatePickerProps<TDateType>['onAccept'];

	/**
	 * @see {@link DatePickerProps.onChange}
	 */
	onChange?: DatePickerProps<TDateType>['onChange'];

	/**
	 * Function which is called when input is blurred
	 */
	onBlur?: () => void;
}

// eslint-disable-next-line mobx/missing-observer
export function T4DateField<TDateType extends Moment>({
	id,
	required = false,
	onAccept = () => {},
	onChange = () => {},
	onBlur = () => {},
	slots,
	slotProps,
	...props
}: T4DateFieldProps<TDateType>) {
	const theme = useTheme();

	return (
		<DesktopDatePicker<TDateType>
			className={id}
			data-testid={id}
			onChange={(date, _) => onChange?.(date, _)}
			onAccept={(date) => onAccept?.(date)}
			format="YYYY/MM/DD"
			slots={{
				...slots,
				textField: DateTextField,
			}}
			slotProps={{
				...slotProps,
				textField: {
					...slotProps?.textField,
					id: id,
					required,
					onBlur,
					error: false,
					size: 'small',
					fullWidth: true,
					inputProps: {
						...(slotProps?.textField as any)?.inputProps,
						style: {
							...(slotProps?.textField as any)?.inputProps?.style,
							color: props.value
								? theme.palette.text.primary
								: theme.palette.text.disabled,
							textTransform: 'uppercase',
							padding: '8.5px 16px',
							paddingLeft: (slotProps?.textField as any)?.InputProps
								?.startAdornment
								? '0px'
								: undefined,
						},
					},
					InputLabelProps: {
						...(slotProps?.textField as any)?.InputLabelProps,
						shrink: true,
					},
					...({
						extraEndAdornments: (slotProps?.textField as any)?.InputProps
							?.endAdornment,
					} as any),
					InputProps: {
						...(slotProps?.textField as any)?.InputProps,
						...((slotProps?.textField as any)?.variant === 'standard'
							? {
									disableUnderline: true,
							  }
							: {}),

						sx: {
							'& .MuiInput-inputAdornedStart': {
								paddingLeft: '0px',
							},
						},
					},
					sx: {
						...(slotProps?.textField as any)?.sx,
						margin: 0,
						marginTop: '8px',
						...(props.readOnly || props.disabled
							? {
									'& .MuiFormLabel-root': {
										'&.Mui-focused': {
											color: theme.palette.text.primary,
										},
									},
							  }
							: {}),
					},
				},
				openPickerButton: {
					...slotProps?.openPickerButton,
					sx: {
						...(slotProps?.openPickerButton as any)?.sx,
						margin: '0px',
					},
				},
			}}
			defaultValue={null}
			{...props}
		/>
	);
}

export interface T4DateFieldSideLabelProps<TDateType>
	extends Pick<
		DatePickerProps<TDateType>,
		| 'disabled'
		| 'loading'
		| 'value'
		| 'readOnly'
		| 'slots'
		| 'slotProps'
		| 'maxDate'
		| 'disableFuture'
		| 'disablePast'
		| 'sx'
	> {
	/**
	 * The id of the date field.
	 */
	id?: string;

	/**
	 * @see {@link BaseTextFieldProps.label}
	 */
	label: BaseTextFieldProps['label'];

	/**
	 * @see {@link BaseTextFieldProps.required}
	 */
	required?: BaseTextFieldProps['required'];

	/**
	 * @see {@link DatePickerProps.onAccept}
	 */
	onAccept?: DatePickerProps<TDateType>['onAccept'];

	/**
	 * @see {@link DatePickerProps.onChange}
	 */
	onChange?: DatePickerProps<TDateType>['onChange'];

	/**
	 * Function which is called when input is blurred
	 */
	onBlur?: () => void;

	/**
	 * Props to pass through to underlying text field component
	 */
	textFieldProps?: TextFieldProps;

	/**
	 * Adornment placed at the start of the input
	 */
	startAdornment?: React.ReactElement;

	/**
	 * Color to set the border and label text
	 */
	color?: string;
}

// eslint-disable-next-line mobx/missing-observer
export function T4DateFieldSideLabel<TDateType extends Moment>({
	id,
	required = false,
	onAccept = () => {},
	onChange = () => {},
	onBlur = () => {},
	textFieldProps,
	startAdornment,
	slots,
	slotProps,
	label,
	color = '#000000',
	...props
}: T4DateFieldSideLabelProps<TDateType>) {
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				height: '100%',
				...props.sx,
			}}
		>
			<DesktopDatePicker<TDateType>
				className={id}
				data-testid={id}
				onChange={(date, _) => onChange?.(date, _)}
				onAccept={(date) => onAccept?.(date)}
				format="YYYY/MM/DD"
				slots={{
					...slots,
				}}
				slotProps={{
					field: {
						InputProps: {
							startAdornment: (
								<Typography
									variant="body1"
									sx={{
										paddingLeft: '8px',
										paddingRight: '20px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',

										color: color,
									}}
									noWrap={true}
								>
									{label}
								</Typography>
							),
						},
					},
					textField: {
						required,
						onBlur,
						error: false,
						size: 'small',
						fullWidth: true,
						InputLabelProps: {
							shrink: true,
						},
						sx: {
							'& input': {
								paddingLeft: '8px',
								borderLeft: '1px solid',
								borderColor: color,
								textTransform: 'uppercase',
							},
							'& .MuiFormControl-root-MuiTextField-root': {
								color: theme.palette.text.primary,
							},
							'& .MuiFormLabel-root.Mui-disabled': {
								color: theme.palette.text.primary,
							},
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: color,
								borderWidth: '1px',
							},
							'& .MuiOutlinedInput-root': {
								'&.Mui-focused fieldset': {
									borderColor: color,
									borderWidth: '2px',
								},
								'&:hover fieldset': {
									borderColor: color,
									borderWidth: '2px',
								},
							},
							...(props.readOnly && {
								...(props.readOnly && {
									'& label.Mui-focused': {
										color: '#414042',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: '#C0C0C0',
											borderWidth: '1px',
										},
										'&:hover fieldset': {
											borderColor: '#C0C0C0',
											borderWidth: '1px',
										},
									},
								}),
							}),
						},
						...textFieldProps,
					},
					shortcuts: {
						...slotProps?.shortcuts,
					},
					...slotProps,
				}}
				defaultValue={null}
				{...props}
			/>
		</Box>
	);
}
