/* eslint-disable mobx/missing-observer */
import { Add, Delete } from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	Collapse,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Tooltip,
	Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import deepEql from 'deep-eql';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { User } from 'modules/clients/apiGateway/payments4';
import {
	ApprovalRule,
	ApprovalTier,
} from 'modules/clients/apiGateway/payments4/approvalRules';
import {
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { T4MultiSelectAutocompleteVertical } from 'shared/components/autocompletes/t4MultiselectAutocompleteVertical';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { FormModal } from 'shared/components/formModal';
import { T4CurrencyInput } from 'shared/components/t4CurrencyInput';
import { T4Toggle } from 'shared/components/t4Toggle';
import { convertToOrdinalNumber } from 'utilities/stringUtils';
import { ValidationError } from 'yup';
import { PaymentApprovalRuleQueryKeys } from './hooks/useGetAllPaymentApprovalRules';
import { useGetPaymentApproverUsers } from './hooks/useGetPaymentApproverUsers';
import { useEditPaymentTierFunctions } from './useEditPaymentTierFunctions';
import {
	isAnotherTierAutoApprove,
	isAnotherTierUnlimitedAmount,
	isLowestTierAmount,
} from './utilities';
import { getApprovalTierAmountValidator } from './validators';

export interface EditApprovalTierDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	tier: ApprovalTier | null;
	rule: ApprovalRule | null;
	ruleProperties: ReactNode;
}

type EditApprovalLevelForm = {
	id: string;
	level: number;
	users: User[];
};

export const EditApprovalTierDrawer: FC<EditApprovalTierDrawerProps> = ({
	isOpen,
	onClose,
	tier,
	rule,
	ruleProperties,
}) => {
	const queryClient = useQueryClient();
	const [currentTier, setCurrentTier] = useState<ApprovalTier | null>(tier);

	const {
		data: approverUsers,
		isLoading: isUsersLoading,
		isFetching: isUsersFetching,
	} = useGetPaymentApproverUsers();

	const sortedUsers = useMemo(
		() =>
			approverUsers?.sort((a, b) => {
				if (a.email !== null && b.email !== null) {
					if (a.email < b.email) return -1;
					if (a.email > b.email) return 1;
					return 0;
				} else {
					if (a.userId < b.userId) return -1;
					if (a.userId > b.userId) return 1;
					return 0;
				}
			}) ?? [],
		[approverUsers],
	);

	const {
		isHandleSetAmountLoading,
		handleSetAmountErrors,
		handleSetAmountErrorsRef,
		handleSetAmount,

		levelStateErrorsRef,

		isHandleSetIsAutoApproveLoading,
		handleSetIsAutoApproveErrors,
		handleSetIsAutoApprove,

		isHandleAddLevelLoading,
		handleAddLevelErrors,
		handleAddLevel,

		isHandleUpdateLevelUsersLoading,
		handleUpdateLevelUsers,

		isHandleRemoveLevelLoading,
		handleRemoveLevel,
	} = useEditPaymentTierFunctions();

	const levelErrors = useMemo(
		() => [...handleSetIsAutoApproveErrors, ...handleAddLevelErrors],
		[handleSetIsAutoApproveErrors, handleAddLevelErrors],
	);

	const [amount, setAmount] = useState<number | null>(
		currentTier?.amount ?? null,
	);
	const [amountValidationError, setAmountValidationError] = useState<
		string | undefined
	>(undefined);
	const [isUnlimitedAmount, setIsUnlimitedAmount] = useState<boolean>(
		currentTier?.isUnlimitedAmount ?? false,
	);
	const [isAutoApprove, setIsAutoApprove] = useState<boolean>(
		currentTier?.isAutoApprove ?? false,
	);
	const [approvalLevelsDictionary, setApprovalLevelsDictionary] = useState<{
		[key: string]: EditApprovalLevelForm;
	}>({});
	useEffect(() => {
		if (currentTier && !isUsersLoading && !isUsersFetching) {
			let levelDict: { [key: string]: EditApprovalLevelForm } = {};
			currentTier?.approvalLevels.forEach((level) => {
				levelDict[level.id!] = {
					id: level.id!,
					level: level.level,
					users: level.users ?? [],
				};
			});
			setApprovalLevelsDictionary(levelDict);
		}
	}, [currentTier, isUsersLoading, isUsersFetching]);
	const approvalLevels = useMemo(
		() =>
			Object.values(approvalLevelsDictionary).sort((a, b) => a.level - b.level),
		[approvalLevelsDictionary],
	);

	const [levelIdToDelete, setLevelIdToDelete] = useState<string | null>(null);

	// when drawer is opened, set the current tier and form fields with data from tier prop
	if (currentTier?.id !== tier?.id) {
		setCurrentTier(tier);
		setAmount(tier?.amount ?? 0);
		setIsUnlimitedAmount(tier?.isUnlimitedAmount ?? false);
		setIsAutoApprove(tier?.isAutoApprove ?? false);
		setApprovalLevelsDictionary({});
		setAmountValidationError(undefined);
	}

	const isFormDirty = useMemo(
		() => !deepEql(currentTier, tier),
		[currentTier, tier],
	);

	const isCurrentAmountLowest = useCallback(
		(amount: number | null) =>
			amount !== null && rule && currentTier
				? isLowestTierAmount(amount, rule, currentTier)
				: true,
		[rule, currentTier],
	);

	const isOtherTierUnlimitedAmount = useMemo(
		() =>
			rule && currentTier
				? isAnotherTierUnlimitedAmount(rule, currentTier)
				: false,
		[rule, currentTier],
	);
	const isOtherTierAutoApprove = useMemo(
		() =>
			rule && currentTier ? isAnotherTierAutoApprove(rule, currentTier) : false,
		[rule, currentTier],
	);

	// #region Close Drawer State + Functions

	const isAnyFieldLoading = useMemo(
		() =>
			isHandleSetAmountLoading ||
			isHandleSetIsAutoApproveLoading ||
			isHandleAddLevelLoading ||
			isHandleUpdateLevelUsersLoading ||
			isHandleRemoveLevelLoading,
		[
			isHandleSetAmountLoading,
			isHandleSetIsAutoApproveLoading,
			isHandleAddLevelLoading,
			isHandleUpdateLevelUsersLoading,
			isHandleRemoveLevelLoading,
		],
	);
	const [isLevelLoading, setIsLevelLoading] = useState<{
		[key: string]: boolean;
	}>({});

	const resetDrawer = useCallback(() => {
		setCurrentTier(null);
		setAmount(null);
		setIsUnlimitedAmount(false);
		setIsAutoApprove(false);
		setApprovalLevelsDictionary({});
		setIsLevelLoading({});
		setAmountValidationError(undefined);
	}, []);

	const closeDrawer = useCallback(() => {
		if (!isAnyFieldLoading) {
			if (isFormDirty)
				queryClient.invalidateQueries(PaymentApprovalRuleQueryKeys.base);

			resetDrawer();
			onClose();
		}
	}, [isAnyFieldLoading, isFormDirty, queryClient, resetDrawer, onClose]);

	// #endregion

	// #region Amount & IsUnlimitedAmount Functions

	const amountOnChange = useCallback(
		async (amount: number | null) => {
			setAmount(amount);
			// only validate when in correct state
			if (rule?.id && currentTier?.id) {
				try {
					const validator = getApprovalTierAmountValidator(
						isUnlimitedAmount,
						isAutoApprove,
						currentTier?.id ?? null,
						rule,
					);
					validator.validateSync(amount);
					setAmountValidationError(undefined);
				} catch (error: any) {
					if (error instanceof ValidationError)
						setAmountValidationError(
							error.errors.length ? error.errors[0] : 'Invalid amount',
						);
				}
			}
		},
		[isUnlimitedAmount, isAutoApprove, currentTier, rule],
	);
	const amountOnBlur = useCallback(async () => {
		if (
			rule?.id &&
			currentTier?.id &&
			amount !== null &&
			!amountValidationError &&
			amount !== currentTier.amount
		) {
			const amountResponse = await handleSetAmount(
				rule.id,
				currentTier.id,
				amount,
				isUnlimitedAmount,
			);
			// if successfull then update form
			// otherwise reset to previous amount
			if (amountResponse !== undefined) {
				setCurrentTier(
					(prev) =>
						({ ...prev, amount: amountResponse.amount }) as ApprovalTier,
				);
			} else setAmount(currentTier.amount);
		}
	}, [
		rule,
		currentTier,
		amountValidationError,
		amount,
		isUnlimitedAmount,
		handleSetAmount,
	]);

	const isUnlimitedAmountOnChange = useCallback(
		async (_, checked: boolean) => {
			if (rule?.id && currentTier?.id) {
				const newAmount = checked
					? -1
					: (rule.approvalTiers
							.filter((x) => x.id !== currentTier.id)
							.sort((a, b) => b.amount - a.amount)[0]?.amount ?? 0) + 1;

				const amountResponse = await handleSetAmount(
					rule.id,
					currentTier.id,
					newAmount,
					checked,
				);

				if (amountResponse !== undefined) {
					setIsUnlimitedAmount(amountResponse.isUnlimitedAmount);
					setAmount(amountResponse.amount);
					setCurrentTier(
						(prev) =>
							({
								...prev,
								amount: amountResponse.amount,
								isUnlimitedAmount: amountResponse.isUnlimitedAmount,
							}) as ApprovalTier,
					);
				}
			}
		},
		[rule, currentTier, handleSetAmount],
	);

	// #endregion

	// #region Approval Level Functions

	const isAutoApproveOnChange = useCallback(
		async (_, checked: boolean) => {
			if (rule?.id && currentTier?.id) {
				const response = await handleSetIsAutoApprove(
					rule.id,
					currentTier.id,
					checked,
				);
				if (response !== undefined) {
					setIsAutoApprove(response);
					setCurrentTier(
						(prev) =>
							({
								...prev,
								isAutoApprove: response,
							}) as ApprovalTier,
					);
					// if isAutoApprove was set to true then remove all levels
					if (response === true) {
						setApprovalLevelsDictionary({});
						setCurrentTier(
							(prev) =>
								({
									...prev,
									approvalLevels: [],
								}) as ApprovalTier,
						);
					}
				}
			}
		},
		[rule, currentTier, handleSetIsAutoApprove],
	);

	// create level with empty users array at the end of the level list
	const addLevelOnClick = useCallback(async () => {
		if (rule?.id && currentTier?.id) {
			const response = await handleAddLevel(rule.id, currentTier.id, []);
			if (response !== undefined) {
				setApprovalLevelsDictionary((prev) => ({
					...prev,
					[response.id]: {
						...response,
						users: [],
					},
				}));
				setCurrentTier(
					(prev) =>
						({
							...prev,
							approvalLevels: [
								...prev!.approvalLevels,
								{ ...response, users: [] },
							],
						}) as ApprovalTier,
				);
			}
		}
	}, [rule, currentTier, handleAddLevel]);

	const removeLevelOnClick = useCallback(
		async (levelId: string) => {
			if (rule?.id && currentTier?.id && levelId) {
				let successfulDelete = false;
				setIsLevelLoading((prev) => ({ ...prev, [levelId]: true }));
				if (
					(await handleRemoveLevel(rule!.id, currentTier!.id, levelId)) !==
					undefined
				) {
					successfulDelete = true;
					setApprovalLevelsDictionary((prev) => {
						const newDict = { ...prev };
						delete newDict[levelId];
						return newDict;
					});
					setCurrentTier(
						(prev) =>
							({
								...prev,
								approvalLevels: prev!.approvalLevels.filter(
									(level) => level.id !== levelId,
								),
							}) as ApprovalTier,
					);
				}
				successfulDelete
					? setIsLevelLoading((prev) => {
							const newDict = { ...prev };
							delete newDict[levelId];
							return newDict;
					  })
					: setIsLevelLoading((prev) => ({ ...prev, [levelId]: false }));
			}
		},
		[rule, currentTier, handleRemoveLevel],
	);

	const levelUsersOnChange = useCallback(
		(level: EditApprovalLevelForm, users: User[]) => {
			const updatedLevel = { ...level };
			updatedLevel.users = users;
			setApprovalLevelsDictionary((prev) => ({
				...prev,
				[level.id]: updatedLevel,
			}));
		},
		[],
	);

	const levelUsersOnBlur = useCallback(
		async (level: EditApprovalLevelForm) => {
			if (
				rule?.id &&
				currentTier?.id &&
				level.id &&
				!deepEql(
					level.users.sort(),
					currentTier.approvalLevels
						.find((x) => x.id === level.id)
						?.users?.sort(),
				)
			) {
				setIsLevelLoading((prev) => ({ ...prev, [level.id]: true }));
				const usersResponse = await handleUpdateLevelUsers(
					rule.id,
					currentTier.id,
					level.id,
					level.users.map((user) => user.userId),
				);

				// if successful then update form
				// otherwise reset to previous users
				if (usersResponse !== undefined) {
					setCurrentTier(
						(prev) =>
							({
								...prev,
								approvalLevels: prev!.approvalLevels.map((lvl) =>
									lvl.id === level.id
										? {
												...lvl,
												users: usersResponse.map((userId) =>
													sortedUsers.find((x) => x.userId === userId),
												),
										  }
										: lvl,
								),
							}) as ApprovalTier,
					);
				} else {
					const previousUserList =
						currentTier.approvalLevels.find((lvl) => lvl.id === level.id)
							?.users ?? [];
					setApprovalLevelsDictionary((prev) => ({
						...prev,
						[level.id]: {
							id: level.id,
							level: level.level,
							users: previousUserList,
						},
					}));
				}
				setIsLevelLoading((prev) => ({ ...prev, [level.id]: false }));
			}
		},
		[rule, currentTier, sortedUsers, handleUpdateLevelUsers],
	);

	// #endregion

	const ApprovalLevelCollapse = useMemo(() => {
		return (
			<Collapse in={!isAutoApprove}>
				{isUsersFetching || isUsersLoading ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress size={25} />
					</Box>
				) : (
					<Grid container item xs={12} sx={{ gap: 2 }}>
						{approvalLevels.map((level) => (
							<Grid key={level.id} container item xs={12}>
								<Grid item xs={12}>
									<Typography
										variant="body1"
										fontWeight={500}
									>{`${convertToOrdinalNumber(
										level.level,
									)} Approval Level`}</Typography>
								</Grid>
								<Grid
									container
									item
									xs={12}
									columnSpacing={1}
									sx={{ alignItems: 'center' }}
								>
									<Grid item xs={11}>
										<T4MultiSelectAutocompleteVertical<User>
											options={sortedUsers}
											value={level.users}
											onChange={(_, value) => levelUsersOnChange(level, value)}
											onBlur={async () => await levelUsersOnBlur(level)}
											getOptionKey={(option) => option.userId}
											isOptionEqualToValue={(option, value) =>
												option.userId === value.userId
											}
											getOptionLabel={(option) => option.email ?? option.userId}
											disabled={isLevelLoading[level.id] ?? false}
											loading={
												isHandleUpdateLevelUsersLoading &&
												(isLevelLoading[level.id] ?? false)
											}
										/>
									</Grid>
									<Grid item xs={1}>
										<IconButton
											onClick={() => setLevelIdToDelete(level.id)}
											sx={{ marginTop: '8px', height: 'fit-content' }}
											disabled={isLevelLoading[level.id] ?? false}
										>
											{isHandleRemoveLevelLoading &&
											(isLevelLoading[level.id] ?? false) ? (
												<CircularProgress size={20} />
											) : (
												<Delete aria-label="Remove Approval Level" />
											)}
										</IconButton>
									</Grid>
								</Grid>

								<Grid item xs={12} sx={{ marginTop: '1rem' }}>
									<Divider />
								</Grid>
							</Grid>
						))}
						<Grid item xs={12}>
							<Tooltip
								title={
									approvalLevels.length >= 2
										? 'Only two levels can be created for each tier'
										: ''
								}
							>
								<span>
									<T4Button
										variant="outlined"
										startIcon={
											isHandleAddLevelLoading ? (
												<CircularProgress size={20} />
											) : (
												<Add />
											)
										}
										onClick={addLevelOnClick}
										disabled={
											approvalLevels.length >= 2 ||
											isAutoApprove ||
											isHandleAddLevelLoading
										}
									>
										Add Approval Level
									</T4Button>
								</span>
							</Tooltip>
						</Grid>
					</Grid>
				)}
			</Collapse>
		);
	}, [
		isAutoApprove,
		approvalLevels,
		sortedUsers,
		isUsersLoading,
		isUsersFetching,
		isHandleAddLevelLoading,
		isHandleUpdateLevelUsersLoading,
		isHandleRemoveLevelLoading,
		isLevelLoading,
		addLevelOnClick,
		levelUsersOnChange,
		levelUsersOnBlur,
	]);

	const DeleteTierModalComponent = useMemo(
		() => (
			<FormModal
				title="Delete approval level?"
				description="This approval level will be permanently deleted from the system. This action cannot be undone."
				open={!!levelIdToDelete}
				onClose={() => setLevelIdToDelete(null)}
				onSubmit={async () => {
					if (rule !== null && levelIdToDelete !== null) {
						await removeLevelOnClick(levelIdToDelete);
						setLevelIdToDelete(null);
					}
				}}
				loading={isHandleRemoveLevelLoading}
				submitButtonLabel="Delete"
				submitButtonColor="error"
				submitDisabled={isHandleRemoveLevelLoading}
			/>
		),
		[levelIdToDelete, rule, removeLevelOnClick, isHandleRemoveLevelLoading],
	);

	return (
		<T4DrawerBase
			open={isOpen}
			onClose={closeDrawer}
			title="Edit Tier"
			actions={[
				<DrawerCancelButton
					stonlyId="done"
					label="Done"
					onCancel={closeDrawer}
				/>,
			]}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 3 }}>
				<Grid item xs={12}>
					{ruleProperties}
				</Grid>
				<Grid container item columnSpacing={2} xs={12}>
					<Grid item xs={6}>
						{isUnlimitedAmount ? (
							<T4TextFieldV2
								label={`"Up To" Limit ${rule?.currencyCode}`}
								value="Unlimited"
								disabled={true}
								required
							/>
						) : (
							<T4CurrencyInput
								label={`"Up To" Limit ${rule?.currencyCode}`}
								value={amount}
								onValueChange={({ floatValue }) => {
									amountOnChange(floatValue ?? null);
								}}
								onBlur={amountOnBlur}
								locale="en-US"
								currencyCode={rule?.currencyCode ?? undefined}
								decimalScale={0}
								allowNegative={false}
								InputProps={{
									startAdornment: isHandleSetAmountLoading && (
										<InputAdornment position="start">
											<CircularProgress size={20} />
										</InputAdornment>
									),
								}}
								error={!!amountValidationError}
								helperText={amountValidationError}
								disabled={isUnlimitedAmount || isHandleSetAmountLoading}
								required
							/>
						)}
					</Grid>
					<Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
						<Tooltip
							title={
								isOtherTierUnlimitedAmount
									? 'Only one tier can be set with an unlimited amount'
									: isAutoApprove
									? 'A tier with no approvals cannot have an unlimited amount'
									: ''
							}
						>
							<span>
								<T4Toggle
									label="Unlimited amount"
									checked={isUnlimitedAmount}
									onChange={isUnlimitedAmountOnChange}
									disabled={
										isOtherTierUnlimitedAmount ||
										isAutoApprove ||
										isHandleSetAmountLoading
									}
									loading={isHandleSetAmountLoading}
								/>
							</span>
						</Tooltip>
					</Grid>
					{handleSetAmountErrors.length > 0 && (
						<Grid item xs={12} ref={handleSetAmountErrorsRef}>
							<T4AlertStack errors={handleSetAmountErrors} />
						</Grid>
					)}
				</Grid>
				<Grid container item columnSpacing={2} xs={12}>
					<Grid item xs={6}>
						<Typography variant="h4">Approval Levels</Typography>
					</Grid>
					<Grid item xs={6} sx={{ justifyContent: 'flex-end' }}>
						<Tooltip
							title={
								isOtherTierAutoApprove
									? 'Only one tier can be set to have no approvals required'
									: isUnlimitedAmount
									? 'A tier with an unlimited amount must have levels'
									: !isCurrentAmountLowest(amount)
									? 'Only the tier with the lowest amount can be set to have no approvals required'
									: ''
							}
						>
							<span>
								<T4Toggle
									label="No approvals required"
									checked={isAutoApprove}
									onChange={isAutoApproveOnChange}
									disabled={
										isOtherTierAutoApprove ||
										!isCurrentAmountLowest(amount) ||
										isUnlimitedAmount ||
										isHandleSetIsAutoApproveLoading
									}
									loading={isHandleSetIsAutoApproveLoading}
								/>
							</span>
						</Tooltip>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					{ApprovalLevelCollapse}
				</Grid>
				{Object.values(levelErrors).length > 0 && (
					<Grid item xs={12} ref={levelStateErrorsRef}>
						<T4AlertStack errors={levelErrors} />
					</Grid>
				)}
			</Grid>

			{DeleteTierModalComponent}
		</T4DrawerBase>
	);
};
