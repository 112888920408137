import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	IconButton,
	InputAdornment,
	InputBaseProps,
	TextField,
	TextFieldProps,
	useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ForwardedRef, forwardRef, useState } from 'react';

export interface T4TextFieldV2Props
	extends Pick<
		TextFieldProps,
		| 'id'
		| 'label'
		| 'value'
		| 'helperText'
		| 'placeholder'
		| 'required'
		| 'color'
		| 'disabled'
		| 'error'
		| 'InputLabelProps'
		| 'inputProps'
		| 'InputProps'
		| 'inputRef'
		| 'onBlur'
		| 'sx'
		| 'multiline'
		| 'minRows'
		| 'maxRows'
		| 'autoFocus'
		| 'select'
		| 'SelectProps'
	> {
	isSecret?: boolean;
	/**
	 * Called whenever the textbox value changes.
	 */
	onChange?: (value: any) => void;

	onChangeActual?: TextFieldProps['onChange'];

	/**
	 * @see {@link InputBaseProps.startAdornment}
	 */
	startAdornment?: InputBaseProps['startAdornment'];

	variant?: TextFieldProps['variant'];

	innerRef?: ForwardedRef<any>;
}

export const T4TextFieldV2: FC<T4TextFieldV2Props> = observer(
	({
		id,
		InputLabelProps,
		InputProps,
		onChange,
		onChangeActual,
		startAdornment,
		inputProps,
		isSecret,
		variant = 'outlined',
		innerRef,
		...props
	}) => {
		const theme = useTheme();

		const [showSecret, setShowSecret] = useState(false);
		const handleClickShowSecret = () => setShowSecret((show) => !show);

		return (
			<TextField
				ref={innerRef}
				{...props}
				id={`t4-outlined-textfield${id ? `-${id}` : ''}`}
				data-testid={'t4-textfield'}
				onChange={
					onChangeActual
						? onChangeActual
						: (event) => {
								onChange?.(event.target.value);
						  }
				}
				variant={variant}
				margin="dense"
				size="small"
				InputLabelProps={{
					...InputLabelProps,
					shrink: InputLabelProps?.shrink === false ? undefined : true,
				}}
				InputProps={{
					...InputProps,
					startAdornment: (
						<>
							{startAdornment}
							{InputProps?.startAdornment}
						</>
					),
					endAdornment: isSecret ? (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowSecret}
								onMouseDown={(e) => e.preventDefault()}
								edge="end"
							>
								{showSecret ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					) : (
						InputProps?.endAdornment
					),
					sx: {
						...InputProps?.sx,
						paddingLeft: '0.75rem',
					},
				}}
				inputProps={{
					'data-testid': inputProps?.['data-testid'] ?? 't4-text-field-input',
					...inputProps,
					style: {
						...inputProps?.style,
						padding: '8.5px 16px',
						paddingLeft: '0px',
					},
				}}
				fullWidth
				sx={{
					margin: 0,
					marginTop: '8px',
					'& .MuiInputBase-input': {
						color: theme.palette.text.primary,
					},
					'& .MuiFormControlLabel-label': {
						color: theme.palette.text.primary,
					},
					...(InputProps?.readOnly && {
						'& label.Mui-focused': {
							color: '#414042',
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#C0C0C0',
								borderWidth: '1px',
							},
							'&:hover fieldset': {
								borderColor: '#C0C0C0',
								borderWidth: '1px',
							},
						},
					}),
					...props.sx,
				}}
				type={isSecret && !showSecret ? 'password' : 'text'}
			>
				{props.children}
			</TextField>
		);
	},
);

export const WrappedT4TextFieldV2 = forwardRef(
	// eslint-disable-next-line mobx/missing-observer
	function WrappedMyComponent(props: T4TextFieldV2Props, ref) {
		return <T4TextFieldV2 {...props} innerRef={ref} />;
	},
);
