import { useQuery } from '@tanstack/react-query';
import { PaymentTemplate } from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { T4DataResponse2 } from 'modules/clients/types';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { Payments4QueryKeyBase } from './constants';

export const PaymentTemplateQueryKeys = {
	base: [...Payments4QueryKeyBase, 'payment-template'] as const,
	getAll: () => [...PaymentTemplateQueryKeys.base, 'get-all'] as const,
	get: (id: string | null | undefined) =>
		[...PaymentTemplateQueryKeys.base, 'get', id] as const,
	getDraft: (id: string | null | undefined) =>
		[...PaymentTemplateQueryKeys.get(id), 'draft'] as const,
};

export const useGetPaymentTemplate = (
	templateId: string | null,
	version: number | null = null,
) => {
	const { applicationApiClient } = useClients();

	const load = useCallback(async () => {
		try {
			if (templateId == null) return;
			const response =
				await applicationApiClient.payments4.paymentTemplates.get({
					id: templateId,
					version,
				});
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<PaymentTemplate>).data;
			else if (response.status === 404)
				throw new Error('Payment template not found.');
			else throw new Error();
		} catch (error: any) {
			throw new Error(
				!isStringUndefinedOrNullOrWhitespace(error?.message)
					? error.message
					: 'Unable to load payment template. Please try again later.',
			);
		}
	}, [templateId, version, applicationApiClient]);

	return useQuery<PaymentTemplate | undefined, Error>(
		PaymentTemplateQueryKeys.get(templateId),
		load,
		{
			refetchOnWindowFocus: false,
			enabled: templateId !== null,
		},
	);
};

export const useGetPaymentTemplateDraft = (templateId: string | null) => {
	const { applicationApiClient } = useClients();

	const load = useCallback(async () => {
		try {
			if (templateId == null) return;
			const response =
				await applicationApiClient.payments4.paymentTemplates.getDraft(
					templateId,
				);
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<PaymentTemplate>).data;
			else if (response.status === 204 || response.status === 404)
				throw new Error('Payment template draft not found.');
			else throw new Error();
		} catch (error: any) {
			throw new Error(
				!isStringUndefinedOrNullOrWhitespace(error?.message)
					? error.message
					: 'Unable to load payment template draft. Please try again later.',
			);
		}
	}, [templateId, applicationApiClient]);

	return useQuery<PaymentTemplate | undefined, Error>(
		PaymentTemplateQueryKeys.getDraft(templateId),
		load,
		{
			refetchOnWindowFocus: false,
			enabled: templateId !== null,
		},
	);
};

export const useGetAllPaymentTemplates = (isEnabled?: boolean) => {
	const { applicationApiClient } = useClients();

	const load = useCallback(async () => {
		try {
			const response =
				await applicationApiClient.payments4.paymentTemplates.getAll();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<PaymentTemplate[]>).data;
			else throw new Error();
		} catch {
			throw new Error(
				'Unable to load payment templates. Please try again later.',
			);
		}
	}, [applicationApiClient]);

	return useQuery<PaymentTemplate[], Error>(
		PaymentTemplateQueryKeys.getAll(),
		load,
		{
			refetchOnWindowFocus: false,
			enabled: isEnabled,
		},
	);
};
