/* eslint-disable mobx/missing-observer */
import { Add, SettingsInputComponent } from '@mui/icons-material';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { FormModal } from 'shared/components/formModal';
import { PageHeader } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { BankConnectionsPageTab } from './bankConnectionsPageTab';
import { CreateBankConnectionDrawer } from './createBankConnectionDrawer';
import { EditBankConnectionDrawer } from './editBankConnectionDrawer';
import { useGetAllBankConnections } from './hooks/useGetBankConnections';
import { useGetFinancialInstitutions } from './hooks/useGetFinancialInstitutions';
import { BankConnectionDetailsDrawer } from './viewBankConnectionDrawer';
import { useMutation } from '@tanstack/react-query';

export const BankConnectionsPageRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={`${paths.administration.payments4.paymentConnectionManagement.href}/:bankConnectionId`.concat(
					validIdRegex,
				)}
				exact
			>
				<BankConnectionsPage />
			</Route>
			<Route
				path={
					`${paths.administration.payments4.paymentConnectionManagement.href}/:bankConnectionId`.concat(
						validIdRegex,
					) + '/edit'
				}
				exact
			>
				<BankConnectionsPage />
			</Route>
			<Route
				path={paths.administration.payments4.paymentConnectionManagement.href}
				exact
			>
				<BankConnectionsPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};

export const BankConnectionsPage: FC = () => {
	const { bankConnectionId } = useParams<{
		bankConnectionId: string | undefined;
	}>();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { applicationApiClient } = useClients();

	const {
		isLoading: areBankConnectionsLoading,
		isFetching: areBankConnectionsFetching,
		data: connections,
		error: loadingBankConnectionsError,
		refetch,
	} = useGetAllBankConnections();
	useEffect(() => {
		if (loadingBankConnectionsError) {
			enqueueSnackbar(loadingBankConnectionsError.message, {
				key: 'get-all-payment-connections-failure',
				variant: 'error',
				preventDuplicate: true,
			});
		}
	}, [loadingBankConnectionsError, enqueueSnackbar]);

	const {
		isLoading: areFinancialInstitutionsLoading,
		isFetching: areFinancialInstitutionsFetching,
		data: financialInstitutions,
		error: loadingFinancialInstitutionsError,
	} = useGetFinancialInstitutions();
	useEffect(() => {
		if (loadingFinancialInstitutionsError) {
			enqueueSnackbar(
				'Unable to load financial institutions. Please try again later.',
				{
					key: 'load-financial-institutions-failure',
					variant: 'error',
					preventDuplicate: true,
				},
			);
		}
	}, [loadingFinancialInstitutionsError, enqueueSnackbar]);

	const isLoading = useMemo(
		() =>
			(areBankConnectionsLoading && areBankConnectionsFetching) ||
			(areFinancialInstitutionsLoading && areFinancialInstitutionsFetching),
		[
			areBankConnectionsLoading,
			areBankConnectionsFetching,
			areFinancialInstitutionsLoading,
			areFinancialInstitutionsFetching,
		],
	);

	const [bankTabList, setBankTabList] = useState<
		{ name: string; bankCode: string }[]
	>([]);
	useEffect(() => {
		if (!isLoading && (connections ?? []).length) {
			const uniqueBankCodes = [
				...new Set((connections ?? []).map((x) => x.bankCode)),
			];
			setBankTabList(
				uniqueBankCodes
					.map((code) => {
						const match = (financialInstitutions ?? []).find(
							(x) => x.bankCode === code,
						);
						return {
							name: match?.displayName ?? code,
							bankCode: code,
						};
					})
					.sort((a, b) => a.name.localeCompare(b.name)),
			);
		}
	}, [isLoading, connections, financialInstitutions]);

	const [viewConnectionId, setViewConnectionId] = useState<string | null>(null);
	const [editConnectionId, setEditConnectionId] = useState<string | null>(null);
	useEffect(() => {
		if (bankConnectionId !== undefined) {
			if ((history.location?.pathname as string | undefined)?.endsWith('edit'))
				setEditConnectionId(bankConnectionId);
			else setViewConnectionId(bankConnectionId);
		}
	}, [bankConnectionId, history]);
	const [deleteConnectionId, setDeleteConnectionId] = useState<string | null>(
		null,
	);

	const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState<boolean>(false);

	const [tabIndex, setTabIndex] = useState<number>(0);
	useEffect(() => {
		if (tabIndex >= bankTabList.length) setTabIndex(0);
	}, [tabIndex, bankTabList]);

	const setTabAfterCreate = useCallback(
		(bankCode: string) => {
			const bankTabIndex = bankTabList.findIndex(
				(x) => x.bankCode === bankCode,
			);
			if (bankTabIndex !== -1) setTabIndex(bankTabIndex);
			else {
				// if the tab does not exist yet, create one in the interim before the next reload completes
				const match = (financialInstitutions ?? []).find(
					(x) => x.bankCode === bankCode,
				);
				const newBankTabList = [
					...bankTabList,
					{
						name: match?.displayName ?? bankCode,
						bankCode: bankCode,
					},
				].sort((a, b) => a.name.localeCompare(b.name));
				setBankTabList(newBankTabList);
				setTabIndex(newBankTabList.findIndex((x) => x.bankCode === bankCode));
			}
		},
		[bankTabList, financialInstitutions],
	);

	const createConnectionButton = useMemo(
		() => (
			<Button
				variant="outlined"
				startIcon={<Add />}
				disabled={isLoading}
				onClick={() => setIsCreateDrawerOpen(true)}
			>
				Connection
			</Button>
		),
		[isLoading],
	);

	const tabContainer = useMemo(
		() => (
			<Tabs
				indicatorColor="primary"
				value={tabIndex}
				onChange={(_, index) => setTabIndex(index)}
				sx={{ marginBottom: '1rem' }}
				variant="scrollable"
				scrollButtons="auto"
			>
				{bankTabList.map((bankTab, index) => (
					<Tab key={bankTab.bankCode} label={bankTab.name} tabIndex={index} />
				))}
			</Tabs>
		),
		[tabIndex, bankTabList],
	);

	const activeTab = useMemo(() => {
		const active = bankTabList.at(tabIndex);
		if (active)
			return (
				<BankConnectionsPageTab
					bankCode={active.bankCode}
					isLoading={isLoading}
					areBankConnectionsFetching={areBankConnectionsFetching}
					connections={(connections ?? []).filter(
						(x) => x.bankCode === active.bankCode,
					)}
					setViewConnectionId={setViewConnectionId}
					setEditConnectionId={setEditConnectionId}
					setDeleteConnectionId={setDeleteConnectionId}
					createConnectionButton={createConnectionButton}
				/>
			);
		else return null;
	}, [
		tabIndex,
		bankTabList,
		isLoading,
		areBankConnectionsFetching,
		connections,
		createConnectionButton,
	]);

	const createDrawer = useMemo(
		() => (
			<CreateBankConnectionDrawer
				isOpen={isCreateDrawerOpen}
				setIsOpen={setIsCreateDrawerOpen}
				setTabAfterCreate={setTabAfterCreate}
				bankConnections={connections ?? []}
			/>
		),
		[isCreateDrawerOpen, setTabAfterCreate, connections],
	);

	const editDrawer = useMemo(
		() => (
			<EditBankConnectionDrawer
				bankConnectionId={editConnectionId}
				bankConnections={connections ?? []}
				onClose={() => {
					setEditConnectionId(null);
					history.push(
						paths.administration.payments4.paymentConnectionManagement.href,
					);
				}}
			/>
		),
		[editConnectionId, connections, history],
	);

	const viewDrawer = useMemo(
		() => (
			<BankConnectionDetailsDrawer
				bankConnectionId={viewConnectionId}
				onClose={() => {
					setViewConnectionId(null);
					history.push(
						paths.administration.payments4.paymentConnectionManagement.href,
					);
				}}
				setEditConnectionId={setEditConnectionId}
				setDeleteConnectionId={setDeleteConnectionId}
			/>
		),
		[viewConnectionId, history],
	);

	const deleteConnectionMutationFn = useCallback(
		async (connectionId: string) => {
			var response =
				await applicationApiClient.payments4.bankConnections.delete(
					connectionId,
				);

			if (response.status === 200) return true;
			else throw new Error();
		},
		[applicationApiClient],
	);
	const { isLoading: isDeleteConnectionLoading, mutate: deleteConnection } =
		useMutation<boolean, Error, string>({
			mutationFn: deleteConnectionMutationFn,
			onSuccess: () => {
				enqueueSnackbar('Successfully deleted payment connection.', {
					key: 'delete-payment-connection-success',
					variant: 'success',
					preventDuplicate: true,
				});
				refetch();
				setViewConnectionId(null);
				setEditConnectionId(null);
				history.push(
					paths.administration.payments4.paymentConnectionManagement.href,
				);
			},
			onError: () => {
				enqueueSnackbar(
					'Unable to delete payment connection. Please try again later.',
					{
						key: 'delete-payment-connection-failure',
						variant: 'error',
						preventDuplicate: true,
					},
				);
			},
			onSettled: () => {
				setDeleteConnectionId(null);
			},
		});

	const deleteModal = useMemo(
		() => (
			<FormModal
				title="Delete payment connection?"
				description="This payment connection will be permanently deleted from the system. This action cannot be undone."
				open={!!deleteConnectionId}
				onClose={() => setDeleteConnectionId(null)}
				onSubmit={() => {
					if (deleteConnectionId !== null) deleteConnection(deleteConnectionId);
				}}
				loading={isDeleteConnectionLoading}
				submitButtonLabel="Delete"
				submitButtonColor="error"
				submitDisabled={isDeleteConnectionLoading}
			/>
		),
		[deleteConnectionId, deleteConnection, isDeleteConnectionLoading],
	);

	return (
		<T4View
			header={
				<PageHeader
					id="payment-connection-management"
					title="Payment Connection Management"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
			loading={isLoading}
		>
			{connections && connections.length === 0 ? (
				<Grid
					container
					direction="column"
					sx={{
						height: '100%',
						gap: 2,
						flexWrap: 'nowrap',
					}}
				>
					<Grid
						item
						xs="auto"
						sx={{ display: 'flex', justifyContent: 'flex-end' }}
					>
						{createConnectionButton}
					</Grid>
					<Grid
						item
						xs
						sx={(theme) => ({
							height: '100%',
							border: `1px solid ${theme.palette.charcoal[50]}`,
							borderRadius: '0.5rem',
						})}
					>
						<NoRowsOverlay
							icon={SettingsInputComponent}
							heading={
								<Typography variant="h3">No Connections Exist</Typography>
							}
							body={
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										gap: 1,
									}}
								>
									<Typography variant="body1">
										You're almost ready to start making payments! Set up your
										first global connection to get started.
									</Typography>
									<Button
										variant="outlined"
										startIcon={<Add />}
										onClick={() => setIsCreateDrawerOpen(true)}
									>
										Global Connection
									</Button>
								</Box>
							}
						/>
					</Grid>
				</Grid>
			) : (
				<Grid
					container
					direction="column"
					sx={{
						height: '100%',
						gap: 2,
						flexWrap: 'nowrap',
					}}
				>
					{tabContainer}
					{activeTab}
				</Grid>
			)}

			{createDrawer}
			{editDrawer}
			{viewDrawer}
			{deleteModal}
		</T4View>
	);
};
