import { Add, DashboardCustomize } from '@mui/icons-material';
import { Button, Divider, Grid, MenuItem } from '@mui/material';
import { GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro';
import { CustomModal } from 'features/entity4/entities/components/customModal';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import {
	AccountGroup,
	AccountGroupCollection,
} from 'modules/clients/customer-api/src/api/administration/accountGroupCollections';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { getOptionsMenuColDef } from 'shared/utilities/dataGrid/dataGridUtils';
import { AccountGroupCollectionDrawer } from './components/accountGroupCollectionDrawer';
import { AccountGroupDrawer } from './components/accountGroupDrawer';
import { useAccountGroupCollectionsQuery } from './hooks/useAccountGroupCollectionsQuery';

type AccountGroupRowModel = {
	path: string[];
	id: string;
	name: string;
	description?: string;
	accounts: number;
	createdBy: string;
	createdOn: Moment;
};

export const AccountGroupsPage = observer(() => {
	const { customerApiClient } = useClients();
	const { initializing, loading, data, refetch } =
		useAccountGroupCollectionsQuery();

	const [selectedCollection, setSelectedCollection] =
		useState<AccountGroupCollection>();
	const [accountCollectionDrawerOpen, setAccountCollectionDrawerOpen] =
		useState(false);
	const [deleteCollectionModalOpen, setDeleteCollectionModalOpen] =
		useState(false);

	const [selectedGroup, setSelectedGroup] = useState<AccountGroup>();
	const [accountGroupDrawerOpen, setAccountGroupDrawerOpen] = useState(false);
	const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);

	useEffect(() => {
		return () => {
			setSelectedCollection(undefined);
			setSelectedGroup(undefined);
			setAccountCollectionDrawerOpen(false);
			setDeleteCollectionModalOpen(false);
		};
	}, []);

	const getCollection = useCallback(
		(row: AccountGroupRowModel) => {
			return data
				?.filter((x) => x.name !== 'General Collection')
				?.find((x) => x.id === row.id);
		},
		[data],
	);

	const getGroup = useCallback(
		(row: AccountGroupRowModel) => {
			return data?.flatMap((x) => x.groups ?? [])?.find((x) => x.id === row.id);
		},
		[data],
	);

	const deleteRowHandler = useCallback(
		(row: AccountGroupRowModel) => {
			if (row.path.length > 1) {
				const group = getGroup(row);
				if (group) {
					setSelectedGroup(group);
					setDeleteGroupModalOpen(true);
				}
			} else {
				const collection = getCollection(row);
				if (collection) {
					setSelectedCollection(collection);
					setDeleteCollectionModalOpen(true);
				}
			}
		},
		[getCollection, getGroup],
	);

	const editRowHandler = useCallback(
		(row: AccountGroupRowModel) => {
			if (row.path.length > 1) {
				const group = getGroup(row);
				if (group) {
					setSelectedGroup(group);
					setAccountGroupDrawerOpen(true);
				}
			} else {
				const collection = getCollection(row);
				if (collection) {
					setSelectedCollection(collection);
					setAccountCollectionDrawerOpen(true);
				}
			}
		},
		[getCollection, getGroup],
	);

	const deleteCollectionHandler = useCallback(async () => {
		if (selectedCollection) {
			const response =
				await customerApiClient.api.administration.accountGroupCollections.delete(
					selectedCollection.id,
				);

			if (response.status === 200) {
				setDeleteCollectionModalOpen(false);
				setSelectedCollection(undefined);
				refetch();

				enqueueSnackbar('Collection deleted successfully.', {
					variant: 'success',
				});
			} else {
				let message = 'An error occurred while deleting the collection.';
				if (typeof response?.data === 'string') {
					message = response?.data;
				}

				enqueueSnackbar(message, {
					variant: 'error',
				});
			}
		}
	}, [
		customerApiClient.api.administration.accountGroupCollections,
		refetch,
		selectedCollection,
	]);

	const deleteGroupHandler = useCallback(async () => {
		if (selectedGroup) {
			const response =
				await customerApiClient.api.administration.accountGroups.delete(
					selectedGroup.id,
				);

			if (response.status === 200) {
				setDeleteGroupModalOpen(false);
				setSelectedGroup(undefined);
				refetch();

				enqueueSnackbar('Group deleted successfully.', {
					variant: 'success',
				});
			} else {
				let message = 'An error occurred while deleting the group.';
				if (typeof response?.data === 'string') {
					message = response?.data;
				}

				enqueueSnackbar(message, {
					variant: 'error',
				});
			}
		}
	}, [
		customerApiClient.api.administration.accountGroups,
		refetch,
		selectedGroup,
	]);

	const rows = useMemo<AccountGroupRowModel[]>(() => {
		return (
			data?.flatMap<AccountGroupRowModel>((collection) => {
				return [
					{
						path: [collection.name],
						id: collection.id,
						name: collection.name,
						description: collection.description,
						accounts:
							collection.groups?.reduce(
								(acc, group) => acc + (group.accounts?.length ?? 0),
								0,
							) ?? 0,
						createdBy: collection.createdBy,
						createdOn: moment(collection.createdOn),
					},
					...(collection.groups?.map((group) => {
						return {
							path: [collection.name, group.name],
							id: group.id,
							name: group.name,
							description: group.description,
							accounts: group.accounts?.length ?? 0,
							createdBy: group.createdBy,
							createdOn: moment(group.createdOn),
						};
					}) ?? []),
				];
			}) ?? []
		);
	}, [data]);

	return (
		<T4View
			loading={initializing}
			header={
				<PageHeader
					id="account-groups-header"
					title="Account Groups"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
		>
			<Grid
				container
				item
				direction="column"
				sx={{ width: '100%', height: '100%', gap: 2 }}
			>
				<Grid
					container
					item
					xs="auto"
					sx={{
						justifyContent: 'flex-end',
						gap: 2,
					}}
				>
					<Grid item>
						<Button
							variant="outlined"
							startIcon={<Add />}
							onClick={() => setAccountGroupDrawerOpen(true)}
						>
							Create Account Group
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="outlined"
							startIcon={<DashboardCustomize />}
							onClick={() => setAccountCollectionDrawerOpen(true)}
						>
							Create Collection
						</Button>
					</Grid>
				</Grid>
				<Grid item xs={true}>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid<AccountGroupRowModel>
							stonlyId={'administration-account-group-collections'}
							tableKey={'administration-account-group-collections'}
							loading={loading}
							showToolbar
							showCustomViewButton
							treeData
							getTreeDataPath={(row) => row.path}
							rows={rows}
							onRowDoubleClick={(params) => editRowHandler(params.row)}
							initialState={{
								sorting: {
									sortModel: [
										{
											field: GRID_TREE_DATA_GROUPING_FIELD,
											sort: 'asc',
										},
									],
								},
							}}
							defaultGroupingExpansionDepth={1}
							groupingColDef={{
								headerName: 'Name',
								flex: 1,
								disableColumnMenu: false,
								filterable: true,
								sortable: true,
								valueGetter: (params) => params.row.name,
							}}
							columns={[
								{
									...getOptionsMenuColDef<AccountGroupRowModel>((params) => {
										if (params.row.name !== 'General Collection') {
											return (
												<OverflowMenu id="account-group-row-menu">
													<MenuItem onClick={() => editRowHandler(params.row)}>
														{'Edit'}
													</MenuItem>
													<Divider />
													<DeleteMenuItem
														onClick={() => deleteRowHandler(params.row)}
													/>
												</OverflowMenu>
											);
										}
									}),
								},
								{
									field: 'description',
									headerName: 'Description',
									flex: 1,
								},
								{
									field: 'accounts',
									headerName: 'Accounts',
									flex: 1,
								},
								{
									field: 'createdBy',
									headerName: 'Created By',
									flex: 1,
								},
								{
									...getDateColumnDefinition('DD-MMM-YYYY h:mm:ss a'),
									field: 'createdOn',
									headerName: 'Creation Date',
									flex: 1,
								},
							]}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>

			<AccountGroupCollectionDrawer
				open={accountCollectionDrawerOpen}
				onClose={() => {
					setAccountCollectionDrawerOpen(false);
					setSelectedCollection(undefined);
				}}
				onSuccess={() => refetch()}
				collections={data}
				collection={selectedCollection}
			/>
			<CustomModal
				title={'Delete collection'}
				open={deleteCollectionModalOpen}
				actions={[
					<Button
						color="secondary"
						onClick={() => {
							setDeleteCollectionModalOpen(false);
							setSelectedCollection(undefined);
						}}
					>
						Cancel
					</Button>,
					<Button
						variant="contained"
						color="error"
						onClick={deleteCollectionHandler}
					>
						Delete
					</Button>,
				]}
			>
				{`Are you sure you want to delete the collection "${selectedCollection?.name}"?`}
			</CustomModal>

			<AccountGroupDrawer
				open={accountGroupDrawerOpen}
				onClose={() => {
					setAccountGroupDrawerOpen(false);
					setSelectedGroup(undefined);
				}}
				onSuccess={() => refetch()}
				collections={data}
				group={selectedGroup}
			/>
			<CustomModal
				title={'Delete group'}
				open={deleteGroupModalOpen}
				actions={[
					<Button
						color="secondary"
						onClick={() => {
							setDeleteGroupModalOpen(false);
							setSelectedGroup(undefined);
						}}
					>
						Cancel
					</Button>,
					<Button
						variant="contained"
						color="error"
						onClick={deleteGroupHandler}
					>
						Delete
					</Button>,
				]}
			>
				{`Are you sure you want to delete the group "${selectedGroup?.name}"?`}
			</CustomModal>
		</T4View>
	);
});
