/* eslint-disable mobx/missing-observer */
import { ChevronLeft, ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	List,
	ListItem,
	Stack,
	Typography,
	createFilterOptions,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { GLCode, TransactionRuleRequest } from 'features/cash4/rules/models';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { brandColors } from 'shared/theme/brandColors';
import { TransactionListItem } from '../../models';
import { useEditRule } from '../../providers/useEditRule';
import { useManualCategorization } from '../../providers/useManualCategorization';
import {
	fetchCategoriesForRules,
	fetchGlCodes,
	saveManualCategorization,
} from '../../services';
import { TransactionDetails } from './TransactionDetails';

interface ManualCategorizationProps {
	transaction: TransactionListItem;
	onBack: () => void;
}

const ManualCategorization: React.FC<ManualCategorizationProps> = ({
	transaction,
	onBack,
}) => {
	const queryClient = useQueryClient();
	const { setIsManualCategorization } = useEditRule();
	const { enqueueSnackbar } = useSnackbar();
	const {
		thisClass,
		thisType,
		thisSubtype,
		thisGlCode,
		handleCategoriesChange,
		handleGlCodesChange,
	} = useManualCategorization();

	const [openCancel, setOpenCancel] = useState(false);

	const handleCancel = useCallback(() => {
		setOpenCancel(false);
		onBack();
	}, [onBack]);

	const handleOpenCancel = useCallback(() => {
		setOpenCancel(true);
	}, []);

	const handleCloseCancel = useCallback(() => {
		setOpenCancel(false);
	}, []);

	const handleSave = () => {
		mutation.mutate();
	};

	const { data: categories, isLoading: isLoadingCategories } = useQuery(
		['categories'],
		fetchCategoriesForRules,
	);

	const { data: glCodes, isLoading: isLoadingGlCodes } = useQuery(
		['glCodes'],
		fetchGlCodes,
	);

	const mutation = useMutation(
		() =>
			saveManualCategorization({
				transactionId: transaction.id,
				name: transaction.id,
				priority: 0,
				isManualCategorization: true,
				classId: thisClass?.id!,
				typeId: thisType?.id!,
				subtypeId: thisSubtype?.id!,
				glCode: thisGlCode!,
			} as TransactionRuleRequest),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([cash4QueryKeys.reportedTransactions]);
				queryClient.invalidateQueries(['transaction', transaction.id]);
				enqueueSnackbar('Transaction categorized successfully', {
					variant: 'success',
				});
				setIsManualCategorization(true);
				onBack();
			},
			onError: () => {
				enqueueSnackbar('Failed to categorize transaction', {
					variant: 'error',
				});
			},
		},
	);

	const canSave: boolean = !!thisClass && !!thisType;

	useEffect(() => {
		if (categories) {
			handleCategoriesChange(
				categories,
				transaction.cfc,
				transaction.cft,
				transaction.cfst,
			);
		}
	}, [categories, handleCategoriesChange, transaction]);

	useEffect(() => {
		if (glCodes) {
			handleGlCodesChange(glCodes, transaction.glNumber);
		}
	}, [glCodes, handleGlCodesChange, transaction.glNumber]);

	return (
		<Stack>
			<Box
				sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}
			>
				<IconButton onClick={onBack} sx={{ color: brandColors.denim[500] }}>
					<ChevronLeft />
					<Typography variant="button" sx={{ color: brandColors.denim[500] }}>
						BACK
					</Typography>
				</IconButton>
			</Box>
			<Box>
				<Typography variant="h6" sx={{ pt: 2, pb: 1 }}>
					Manual Categorization
				</Typography>
				<Typography variant="body2" sx={{ pb: 2 }}>
					Select a category for this transaction. Any current or future rules
					will not modify your manual categorization.
				</Typography>
				<Accordion defaultExpanded={false} elevation={0}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography
							sx={{
								color: brandColors.denim[500],
								textTransform: 'uppercase',
								fontWeight: '500',
							}}
						>
							Reported Transaction Details
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TransactionDetails transaction={transaction} />
					</AccordionDetails>
				</Accordion>
			</Box>
			<Divider />
			<ManualCategorizationForm
				isLoadingCategories={isLoadingCategories}
				isLoadingGlCodes={isLoadingGlCodes}
			/>
			<CancelPrompt
				open={openCancel}
				onDismissCancel={handleCloseCancel}
				onCancel={handleCancel}
			/>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					width: '100%',
					pt: 2,
					pb: 2,
				}}
			>
				<Button onClick={handleOpenCancel} variant="text" color="secondary">
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					disabled={!canSave || mutation.isLoading}
					variant="contained"
					color="primary"
					sx={{ ml: 2, position: 'relative' }}
				>
					{mutation.isLoading ? (
						<>
							Save
							<CircularProgress
								size={24}
								sx={{
									color: 'primary',
									position: 'absolute',
									top: '50%',
									left: '50%',
									marginTop: '-12px',
									marginLeft: '-12px',
								}}
							/>
						</>
					) : (
						'Save'
					)}
				</Button>
			</Box>
		</Stack>
	);
};

export default ManualCategorization;

type CancelPromptProps = {
	open: boolean;
	onCancel: () => void;
	onDismissCancel: () => void;
};

const CancelPrompt: FC<CancelPromptProps> = ({
	open,
	onCancel,
	onDismissCancel,
}) => {
	return (
		<Dialog open={open} onClose={onDismissCancel}>
			<DialogTitle>Cancel Manual Categorization</DialogTitle>
			<DialogContent>
				<Typography variant="body2">
					Are you sure you want to cancel?
				</Typography>
			</DialogContent>
			<DialogActions
				sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}
			>
				<Button variant="text" onClick={onDismissCancel}>
					No
				</Button>
				<Button
					variant="contained"
					onClick={onCancel}
					color="primary"
					sx={{ ml: 2 }}
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

type ManualCategorizationFormProps = {
	isLoadingCategories: boolean;
	isLoadingGlCodes: boolean;
};

const ManualCategorizationForm: FC<ManualCategorizationFormProps> = ({
	isLoadingCategories,
	isLoadingGlCodes,
}) => {
	const {
		classes,
		thisClass,
		setThisClass,
		types,
		thisType,
		setThisType,
		subtypes,
		thisSubtype,
		setThisSubtype,
		glCodes,
		thisGlCode,
		setThisGlCode,
	} = useManualCategorization();

	const handleSelectGLCode = useCallback(
		(value: GLCode | string | null) => {
			if (typeof value === 'string') {
				const newGLCode: GLCode = glCodes.filter(
					(x) => x.code === value,
				)[0] ?? {
					id: null,
					code: value,
				};
				setThisGlCode(newGLCode);
			} else {
				setThisGlCode(value);
			}
		},
		[glCodes, setThisGlCode],
	);

	const handleClassChange = useCallback(
		(value: any) => {
			if (value !== null) {
				setThisClass(value);
				setThisType(null);
				setThisSubtype(null);
			} else {
				setThisClass(null);
				setThisType(null);
				setThisSubtype(null);
			}
		},
		[setThisClass, setThisSubtype, setThisType],
	);

	const handleTypeChange = useCallback(
		(value: any) => {
			if (value !== null) {
				setThisType(value);
				setThisSubtype(null);
			} else {
				setThisType(null);
				setThisSubtype(null);
			}
		},
		[setThisSubtype, setThisType],
	);

	return (
		<List>
			<ListItem>
				<T4Autocomplete
					label="Cash Flow Class (CFC)"
					options={classes}
					getOptionLabel={(option) =>
						option.name.concat(' (', option.code, ')')
					}
					isOptionEqualToValue={(option, value) => option.code === value.code}
					value={thisClass}
					onSelect={handleClassChange}
					required
					loading={isLoadingCategories}
				/>
			</ListItem>
			<ListItem>
				<T4Autocomplete
					label="Cash Flow Type (CFT)"
					options={thisClass?.id ? types(thisClass.id) : []}
					getOptionLabel={(option) =>
						option.name.concat(' (', option.code, ')')
					}
					isOptionEqualToValue={(option, value) => option.code === value.code}
					value={thisType}
					onSelect={handleTypeChange}
					disabled={thisClass?.id ? false : true}
					required
					loading={isLoadingCategories}
				/>
			</ListItem>
			<ListItem>
				<T4Autocomplete
					label="Cash Flow Subtype (CFST)"
					options={thisType?.id ? subtypes(thisType.id) : []}
					getOptionLabel={(option) =>
						option.name.concat(' (', option.code, ')')
					}
					isOptionEqualToValue={(option, value) => option.code === value.code}
					value={thisSubtype}
					onSelect={(value) => setThisSubtype(value)}
					disabled={thisType?.id ? false : true}
					loading={isLoadingCategories}
				/>
			</ListItem>
			<ListItem>
				<T4Autocomplete<GLCode, false, false, true>
					data-testid="gl-code"
					label="GL Code"
					options={glCodes}
					getOptionLabel={(option) =>
						typeof option === 'string' ? option : option.code!
					}
					value={thisGlCode}
					onChange={(_: any, value: string | GLCode | null) =>
						handleSelectGLCode(value)
					}
					onInputChange={(_: any, value: string | GLCode | null) =>
						handleSelectGLCode(value)
					}
					renderOption={(props, option) => (
						<li {...props}>
							{option.addCodeTitle ? option.addCodeTitle : option.code}
						</li>
					)}
					filterOptions={(options, params) => {
						const filter = createFilterOptions<GLCode>();
						const filtered = filter(options, params);

						const { inputValue } = params;
						const isExisting = options.some(
							(option) => inputValue === option.code,
						);
						if (inputValue !== '' && !isExisting) {
							filtered.push({
								addCodeTitle: `Add "${inputValue}"`,
								id: null,
								code: inputValue,
							});
						}

						return filtered;
					}}
					freeSolo
					autoHighlight={false}
					loading={isLoadingGlCodes}
				/>
			</ListItem>
		</List>
	);
};
