import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import { BofaConnectionSettings } from './bofa/contants';
import { WfbiConnectionSettings } from './wfbi/constants';
import { ChasConnectionSettings } from './chas/constants';
import {
	Account,
	LegalEntity,
} from 'modules/clients/apiGateway/payments4/bankConnections';
import { UsbkConnectionSettings } from './usbk/constants';

export const BankConnectionTypes = ['Global', 'Entity', 'Account'] as const;
export type BankConnectionType = (typeof BankConnectionTypes)[number];

export type BankConnectionSettingsType =
	| BofaConnectionSettings
	| WfbiConnectionSettings
	| ChasConnectionSettings
	| UsbkConnectionSettings;

export type BankConnectionForm = {
	id: string | null;
	name: string;
	bank: FinancialInstitution | null;
	connectionType: BankConnectionType;
	connectionSettings: BankConnectionSettingsType | null;
	e4Entities: LegalEntity[];
	e4Accounts: Account[];
	notes: string | null;
};

export const defaultBankConnectionForm: BankConnectionForm = {
	id: null,
	name: '',
	bank: null,
	connectionType: 'Global',
	connectionSettings: null,
	e4Entities: [],
	e4Accounts: [],
	notes: null,
};

export type BankConnectionSettingsFieldDefinition = {
	key: string;
	name: string;
	type: 'string' | 'certificate';
	isSecret?: boolean;
};
