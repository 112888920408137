import {
	Autocomplete,
	AutocompleteFreeSoloValueMapping,
	AutocompleteProps,
	Box,
	Checkbox,
	Chip,
	CircularProgress,
	TextField,
	TextFieldProps,
	Typography,
	useTheme,
} from '@mui/material';
import {
	ForwardedRef,
	forwardRef,
	HTMLAttributes,
	useImperativeHandle,
	useRef,
} from 'react';

/* eslint-disable mobx/missing-observer */
interface T4MultiSelectAutocompleteVerticalProps<
	TOption,
	TDisableClearable extends boolean = false,
	TFreeSolo extends boolean = false,
> extends Omit<
		AutocompleteProps<TOption, true, TDisableClearable, TFreeSolo>,
		'renderInput'
	> {
	/**
	 * @see {@link AutoCompleteProps.getOptionLabel}
	 */
	getOptionLabel: (
		option: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>,
	) => string;

	/**
	 * @see {@link AutoCompleteProps.isOptionEqualToValue}
	 */
	isOptionEqualToValue: (
		option: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>,
		value: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>,
	) => boolean;

	/**
	 * Get key for each item in list rendering
	 */
	getOptionKey: (
		option: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>,
	) => string;

	/**
	 * Helper text displayed at bottom of open list
	 */
	listHelperText?: string;

	textFieldProps?: Partial<TextFieldProps>;

	maxHeight?: string;
}

export const T4MultiSelectAutocompleteVerticalInner =
	function T4MultiSelectAutocompleteVerticalInnerFunc<
		TOption,
		TDisableClearable extends boolean = false,
		TFreeSolo extends boolean = false,
	>(
		{
			getOptionKey,
			getOptionLabel,
			isOptionEqualToValue,
			listHelperText,
			textFieldProps,
			maxHeight,
			readOnly,
			openOnFocus = true,
			filterSelectedOptions = false,
			disableCloseOnSelect = true,
			blurOnSelect = false,
			fullWidth = true,
			size = 'small',
			autoHighlight = true,
			...rest
		}: T4MultiSelectAutocompleteVerticalProps<
			TOption,
			TDisableClearable,
			TFreeSolo
		>,
		ref?: ForwardedRef<any>,
	) {
		// take forwared ref and combine it with input ref needed for text field
		const inputRef = useRef<HTMLInputElement | null>(null);
		useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, []);

		return (
			<Autocomplete<TOption, true, TDisableClearable, TFreeSolo>
				{...rest}
				multiple
				openOnFocus={openOnFocus}
				filterSelectedOptions={filterSelectedOptions}
				disableCloseOnSelect={disableCloseOnSelect}
				blurOnSelect={blurOnSelect}
				size={size}
				autoHighlight={autoHighlight}
				readOnly={readOnly}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={isOptionEqualToValue}
				ListboxComponent={ListBox}
				ListboxProps={{
					defaultValue: listHelperText,
				}}
				renderInput={(params) => {
					const {
						sx,
						InputProps,
						margin = 'dense',
						size = 'small',
						...textFieldPropsRest
					} = textFieldProps ?? {};
					return (
						<TextField
							{...params}
							{...textFieldPropsRest}
							inputRef={inputRef}
							InputProps={{
								...params.InputProps,
								...InputProps,
								readOnly: readOnly,
								startAdornment: (
									<>
										{InputProps?.startAdornment}
										{params.InputProps.startAdornment}
									</>
								),
								endAdornment: (
									<>
										{rest.loading ? (
											<CircularProgress color="inherit" size={20} />
										) : null}
										{params?.InputProps?.endAdornment &&
											params.InputProps.endAdornment}
										{textFieldProps?.InputProps?.endAdornment &&
											textFieldProps.InputProps.endAdornment}
									</>
								),
							}}
							InputLabelProps={{ shrink: true }}
							margin={margin}
							size={size}
							sx={{
								...(readOnly && {
									'& label.Mui-focused': {
										color: '#414042',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: '#C0C0C0',
											borderWidth: '1px',
										},
										'&:hover fieldset': {
											borderColor: '#C0C0C0',
											borderWidth: '1px',
										},
									},
								}),
								'& .MuiInputBase-root': {
									paddingLeft:
										InputProps?.startAdornment !== undefined
											? '3rem!important'
											: undefined,
								},
								'& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
									height: 'fit-content',
									display: 'block',
									position: 'absolute',
									left: '12px',
									top: '50%',
									transform: 'translate(0, -50%)',
								},
								...sx,
							}}
						/>
					);
				}}
				renderTags={(value, getTagProps, _) => (
					<Box
						sx={{
							maxHeight: maxHeight ?? '7rem',
							display: 'flex',
							flexWrap: 'wrap',
							overflowY: 'auto',
							scrollbarWidth: 'thin',
						}}
					>
						{value.map((option, index) => {
							const tagProps = getTagProps({ index });
							return (
								<Chip
									{...tagProps}
									size="small"
									key={index}
									label={getOptionLabel(option)}
									onDelete={
										rest.disabled || readOnly
											? undefined
											: (event) => {
													inputRef?.current?.focus();
													tagProps.onDelete(event);
											  }
									}
								/>
							);
						})}
					</Box>
				)}
				renderOption={(props, option) => (
					<li
						{...props}
						key={getOptionKey(option)}
						style={{
							padding: '0.5rem',
							paddingTop: '0.1rem',
							paddingBottom: '0.1rem',
						}}
					>
						<Checkbox
							checked={rest.value?.some((value) =>
								isOptionEqualToValue(value, option),
							)}
							size="small"
						/>
						<Typography>{getOptionLabel(option)}</Typography>
					</li>
				)}
			/>
		);
	};

const ListBox = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(
	function ListBox({ children, defaultValue, ...rest }, ref) {
		const theme = useTheme();
		return (
			<ul {...rest} ref={ref} role="listbox">
				{children}
				{defaultValue && (
					<li
						style={{
							paddingTop: '8px',
							paddingBottom: '4px',
							paddingLeft: '16px',
							paddingRight: '16px',
							borderTop: '1px solid',
							borderColor: theme.palette.charcoal[50],
						}}
						key="helperText"
					>
						<Typography variant="caption" sx={{ fontStyle: 'italic' }}>
							{defaultValue}
						</Typography>
					</li>
				)}
			</ul>
		);
	},
);

export const T4MultiSelectAutocompleteVertical = forwardRef(
	T4MultiSelectAutocompleteVerticalInner,
) as <
	TOption,
	TDisableClearable extends boolean = false,
	TFreeSolo extends boolean = false,
>(
	props: T4MultiSelectAutocompleteVerticalProps<
		TOption,
		TDisableClearable,
		TFreeSolo
	> & { ref?: ForwardedRef<any> },
) => ReturnType<typeof T4MultiSelectAutocompleteVerticalInner>;
