import {
	useMutation,
	UseMutationResult,
	useQueryClient,
} from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { Balance } from './useBalancesQuery';

export type UseUpdateBalanceNoteVariables = {
	id: string;
	note: string | undefined;
};

export type UseUpdateBalanceNoteMutationProps = UseMutationResult<
	boolean,
	Error,
	UseUpdateBalanceNoteVariables
>;

export function useUpdateBalanceNoteMutation(): UseUpdateBalanceNoteMutationProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const updateBalanceNote = useCallback(
		async ({ id, note }: UseUpdateBalanceNoteVariables) => {
			const balance = queryClient.getQueryData<Balance>([
				cash4QueryKeys.balance,
				id,
			]);

			if (note !== balance?.note) {
				const response = await customerApiClient.api.v1.balances.upsertNote(
					id,
					note,
				);

				if (response?.data) {
					const balance = queryClient.getQueryData<Balance>([
						cash4QueryKeys.balance,
						id,
					]);
					if (balance) {
						const newBalance = {
							...balance,
							note: response.data.data.content ?? null,
						};

						queryClient.setQueryData<Balance>(
							[cash4QueryKeys.balance, id],
							newBalance,
						);
						queryClient.setQueryData<Balance[]>(
							[cash4QueryKeys.balances],
							(current) => {
								if (current) {
									return [...current.filter((x) => x.id !== id), newBalance];
								}
							},
						);
					}

					return true;
				}

				throw new Error('Failed to save note');
			}

			return false;
		},
		[customerApiClient.api.v1.balances, queryClient],
	);

	return useMutation<boolean, Error, UseUpdateBalanceNoteVariables, unknown>({
		mutationFn: updateBalanceNote,
		onError: () => {
			enqueueSnackbar('Unable to save note.', {
				key: 'cash4-balance-note-update-error',
				preventDuplicate: true,
				variant: 'error',
			});
		},
		onSuccess: (success: boolean) => {
			if (success) {
				enqueueSnackbar('Note saved.', {
					key: 'cash4-balance-note-update-success',
					preventDuplicate: true,
					variant: 'success',
				});
			}
		},
	});
}
