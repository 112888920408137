import { BalanceListItem } from 'modules/clients/customer-api/src/api/v1/cash4/balances';
import { Balance } from '../_hooks/useBalancesQuery';

export function mapBalance({
	balanceId,
	...balance
}: BalanceListItem): Balance {
	return {
		id: balanceId,
		statementDate: balance.statementDate,
		balanceImportedDate: balance.balanceImportedDate,
		lastUpdatedDate: balance.lastUpdatedDate,
		e4AccountName: balance.e4AccountName,
		e4AccountId: balance.e4AccountId,
		e4AccountNumber: balance.e4AccountNumber,
		e4AccountType: balance.e4AccountType,
		c4AccountNumber: balance.c4AccountNumber,
		bankCode: balance.bankCode,
		bankName: balance.bankName,
		openingLedgerBalance: {
			value: balance.openingLedgerBalance,
			reportingValue: balance.openingLedgerBalance_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		openingAvailableBalance: {
			value: balance.openingAvailableBalance,
			reportingValue: balance.openingAvailableBalance_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		currentLedgerBalance: {
			value: balance.currentLedgerBalance,
			reportingValue: balance.currentLedgerBalance_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		currentAvailableBalance: {
			value: balance.currentAvailableBalance,
			reportingValue: balance.currentAvailableBalance_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		totalCreditTransactions: {
			value: balance.totalCreditTransactions,
			reportingValue: balance.totalCreditTransactions_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		totalDebitTransactions: {
			value: balance.totalDebitTransactions,
			reportingValue: balance.totalDebitTransactions_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		closingLedgerBalance: {
			value: balance.closingLedgerBalance,
			reportingValue: balance.closingLedgerBalance_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		closingAvailableBalance: {
			value: balance.closingAvailableBalance,
			reportingValue: balance.closingAvailableBalance_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		forwardAvailableBalance0Day: {
			value: balance.forwardAvailableBalance0Day,
			reportingValue: balance.forwardAvailableBalance0Day_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		forwardAvailableBalance1Day: {
			value: balance.forwardAvailableBalance1Day,
			reportingValue: balance.forwardAvailableBalance1Day_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		forwardAvailableBalance2Day: {
			value: balance.forwardAvailableBalance2Day,
			reportingValue: balance.forwardAvailableBalance2Day_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		forwardAvailableBalance3Day: {
			value: balance.forwardAvailableBalance3Day,
			reportingValue: balance.forwardAvailableBalance3Day_ReportingCurrency,
			valueCurrency: balance.currency,
		},
		transactionCount: balance.transactionCount,
		debitTransactionsCount: balance.debitTransactionsCount,
		creditTransactionsCount: balance.creditTransactionsCount,
		currency: balance.currency,
		foreignExchangeRate: balance.foreignExchangeRate,
		foreignExchangeDate: balance.foreignExchangeDate,
		note: balance.note,
	};
}
