import { DateRange } from '@mui/x-date-pickers-pro';
import { useQuery } from '@tanstack/react-query';
import { Payment } from 'modules/clients/apiGateway/payments4/payments';
import { T4DataResponse2 } from 'modules/clients/types';
import moment, { Moment } from 'moment';
import { useCallback, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { dateWriteFormat } from 'shared/utilities/dateUtilities';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { Payments4QueryKeyBase } from './constants';

export const PaymentQueryKeys = {
	base: [...Payments4QueryKeyBase, 'payment'] as const,
	getAll: () => [...PaymentQueryKeys.base, 'get-all'] as const,
	get: (id: string | null | undefined) =>
		[...PaymentQueryKeys.base, 'get', id] as const,
};

export const dateRangeDefault: DateRange<Moment> = [
	moment().subtract(2, 'days').startOf('day'),
	moment().add(5, 'days').startOf('day'),
];

export const useGetAllPayments = () => {
	const { applicationApiClient } = useClients();

	const [dateRange, setDateRange] =
		useState<DateRange<Moment>>(dateRangeDefault);

	const loadPayments = useCallback(async () => {
		try {
			const response = await applicationApiClient.payments4.payments.getAll({
				startDate: dateRange[0]?.format(dateWriteFormat),
				endDate: dateRange[1]?.format(dateWriteFormat),
			});
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<Payment[]>).data;
			else throw new Error();
		} catch {
			throw new Error('Unable to load payments. Please try again later.');
		}
	}, [applicationApiClient, dateRange]);

	const query = useQuery<Payment[], Error>(
		[...PaymentQueryKeys.getAll(), dateRange[0], dateRange[1]],
		loadPayments,
		{
			refetchOnWindowFocus: false,
		},
	);

	return {
		dateRange,
		setDateRange,
		...query,
	};
};

export const useGetPayment = (id: string | null) => {
	const { applicationApiClient } = useClients();

	const loadPayment = useCallback(async () => {
		try {
			if (id === null) return null;
			const response = await applicationApiClient.payments4.payments.get(id);
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<Payment>).data;
			else if (response.status === 404) throw new Error('Payment not found.');
			else throw new Error();
		} catch (error: any) {
			throw new Error(
				!isStringUndefinedOrNullOrWhitespace(error?.message)
					? error.message
					: 'Unable to load payment. Please try again later.',
			);
		}
	}, [id, applicationApiClient]);

	return useQuery<Payment | null, Error>(
		PaymentQueryKeys.get(id),
		loadPayment,
		{
			refetchOnWindowFocus: false,
			enabled: id !== null,
		},
	);
};
