/* eslint-disable mobx/missing-observer */
import { Box, Paper, Stack, SxProps, Theme, Typography } from '@mui/material';
import React, { FC } from 'react';
import { brandColors } from 'shared/theme/brandColors';

type LabelValuePair = {
	type: 'bold' | 'normal';
	label: string;
	value: string | null;
	shouldDisplay: boolean;
	sx?: SxProps<Theme>;
	lineBreak?: boolean;
};

type SingleLabel = {
	type: 'single';
	label: string;
	sx?: SxProps<Theme>;
};

type DetailItem = LabelValuePair | SingleLabel | JSX.Element;

export type T4DetailBoxProps = {
	details: DetailItem[];
};

const T4DetailBox: FC<T4DetailBoxProps> = ({ details }) => {
	return (
		<Paper
			sx={{
				bgcolor: brandColors.cornflower[50],
				mt: 2,
				p: 2,
				maxWidth: 'inherit',
			}}
			elevation={0}
		>
			<Stack>
				{details.map((detail, index) => {
					if (isLabelValuePair(detail)) {
						// Render label-value pair
						return <TabulatedRow key={index} detail={detail} />;
					} else if (isSingleLabel(detail)) {
						// Render single label (bold)
						return (
							<Typography
								key={index}
								component="div"
								sx={{ fontWeight: 'bold', mt: 2, ...detail.sx }}
							>
								{detail.label}
							</Typography>
						);
					} else if (React.isValidElement(detail)) {
						// Render JSX.Element directly
						return <React.Fragment key={index}>{detail}</React.Fragment>;
					} else {
						// Fallback or error handling if needed
						return null;
					}
				})}
			</Stack>
		</Paper>
	);
};

export default T4DetailBox;

// Type guard for LabelValuePair
const isLabelValuePair = (item: DetailItem): item is LabelValuePair =>
	typeof item === 'object' && 'label' in item && 'value' in item;

// Type guard for SingleLabel
const isSingleLabel = (item: DetailItem): item is SingleLabel =>
	typeof item === 'object' && 'label' in item && !('value' in item);

interface TabulatedRowProps {
	detail: LabelValuePair;
}

const TabulatedRow: React.FC<TabulatedRowProps> = ({ detail }) => {
	const fontWeight = detail.type === 'bold' ? 'bold' : 'normal';
	const direction = detail.lineBreak ? 'column' : 'row';
	const textAlign = detail.lineBreak ? 'left' : 'right';

	return (
		<>
			<Stack
				direction={direction}
				sx={{
					justifyContent: 'space-between',
					width: '100%',
					alignItems: 'baseline',
					...detail.sx,
				}}
			>
				<Box sx={{ textAlign: 'left', flex: '1 0 auto', minWidth: '150px' }}>
					<Typography sx={{ fontWeight, lineHeight: '2rem' }}>
						{detail.label}
					</Typography>
				</Box>
				<Box
					sx={{
						textAlign: textAlign,
						flex: '3 1 auto',
						position: 'relative',
					}}
				>
					{detail.shouldDisplay && (
						<Typography
							sx={{
								display: '-webkit-box',
								WebkitBoxOrient: 'vertical',
								textOverflow: 'ellipsis',
								wordBreak: 'break-all',
							}}
						>
							{detail.value}
						</Typography>
					)}
				</Box>
			</Stack>
		</>
	);
};
