/* eslint-disable mobx/missing-observer */
import {
	CalendarIcon,
	DateRange,
	DesktopDateRangePicker,
	PickersShortcutsItem,
} from '@mui/x-date-pickers-pro';
import { DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker';
import moment, { Moment } from 'moment';
import { FC, useMemo } from 'react';

export type T4DateRangePickerProps = Omit<
	DateRangePickerProps<Moment | null>,
	'renderInput'
> & {
	id?: string;
	fromLabel?: string;
	toLabel?: string;
	showShortcuts?: boolean;
	shortcutResetDefaults?: DateRange<Moment>;
};

const T4DateRangePicker: FC<T4DateRangePickerProps> = ({
	id,
	fromLabel,
	toLabel,
	showShortcuts: showPresets = false,
	shortcutResetDefaults: resetShortcutDefaults = [null, null],
	...props
}) => {
	const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = useMemo(
		() => [
			{
				label: 'This Week',
				getValue: () => {
					const today = moment();
					return [
						today.clone().startOf('week'),
						today.clone().subtract(1, 'day'),
					];
				},
			},
			{
				label: 'Last Week',
				getValue: () => {
					const today = moment();
					const prevWeek = today.subtract(7, 'day');
					return [
						prevWeek.clone().startOf('week'),
						prevWeek.clone().endOf('week'),
					];
				},
			},
			{
				label: 'Current Month',
				getValue: () => {
					const today = moment();
					return [
						today.clone().startOf('month'),
						today.clone().subtract(1, 'day'),
					];
				},
			},
			{
				label: 'Previous Month',
				getValue: () => {
					const today = moment();
					const startOfLastMonth = today
						.clone()
						.startOf('month')
						.subtract(1, 'day')
						.startOf('month');
					return [startOfLastMonth, startOfLastMonth.clone().endOf('month')];
				},
			},
			{
				label: 'Reset',
				getValue: () => [resetShortcutDefaults[0], resetShortcutDefaults[1]],
			},
		],
		[resetShortcutDefaults],
	);

	return (
		<DesktopDateRangePicker
			{...props}
			format="YYYY/MM/DD"
			localeText={{ start: fromLabel, end: toLabel }}
			slotProps={{
				shortcuts: {
					items: showPresets ? shortcutsItems : undefined,
				},
				textField: {
					size: 'small',
					sx: {},
					inputProps: {
						'data-stonlyid': `${id}-date-range-picker`,
					},
					InputProps: {
						endAdornment: <CalendarIcon />,
					},
				},
				...props.slotProps,
			}}
			sx={{
				marginTop: '8px',
				marginBottom: '4px',
				maxWidth: '340px',
				...props.sx,
			}}
		/>
	);
};

export default T4DateRangePicker;
