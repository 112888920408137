import { BofaConnectionSettings } from './contants';
import * as yup from 'yup';

export const BofaConnectionSettingsValidator: yup.ObjectSchema<BofaConnectionSettings> =
	yup.object({
		applicationId: yup.string().trim().label('Application Id').required(),
		clientId: yup.string().trim().label('Client Id').required(),
		clientSecret: yup.string().trim().label('Client Secret').required(),
		cashProId: yup.string().trim().label('CashPro Id').required(),
		senderId: yup.string().trim().label('Sender Id').required(),
	});
