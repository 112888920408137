import {
	useMutation,
	UseMutationResult,
	useQueryClient,
} from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { Balance } from './useBalancesQuery';

export type UseDeleteBalanceMutationProps = UseMutationResult<
	string,
	Error,
	string
>;

export function useDeleteBalanceMutation(): UseDeleteBalanceMutationProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const deleteBalance = useCallback(
		async (id: string) => {
			const response = await customerApiClient.api.v1.balances.delete(id);

			if (response?.data?.data) {
				queryClient.setQueryData<Balance[]>(
					[cash4QueryKeys.balances],
					(oldData) => oldData?.filter((x) => x.id !== id) ?? [],
				);

				return response.data.data;
			} else {
				throw new Error('Unable to delete balance.');
			}
		},
		[customerApiClient.api.v1.balances, queryClient],
	);

	return useMutation<string, Error, string>({
		mutationFn: deleteBalance,
		onError: () => {
			enqueueSnackbar('Unable to delete balance.', {
				key: 'cash4-balance-delete-failure',
				preventDuplicate: true,
				variant: 'error',
			});
		},
		onSuccess: () => {
			enqueueSnackbar('Balance deleted.', {
				key: 'cash4-balance-delete-success',
				preventDuplicate: true,
				variant: 'success',
			});
		},
	});
}
