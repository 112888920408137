import { OpenInNewOutlined } from '@mui/icons-material';
import { Divider, ListItemIcon, MenuItem } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	getDateColumnDefinition,
	getSingleSelectGridColDef,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { OverflowMenu } from '../../../../shared/components/overflowMenu';
import { T4Link } from '../../../../shared/components/t4Link';
import { paths } from '../../../../shared/constants/paths';
import { useReferenceDataFetcher } from '../../../../shared/hooks/useReferenceDataFetcher';
import { useUser } from '../../../../shared/hooks/useUser';
import {
	USER_PREFERENCES_FIELD_OPTIONS,
	getOptionsMenuColDef,
} from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { Document } from '../../documents/models/document';
import {
	GlobalDocumentListItem,
	GlobalDocumentsRepository,
} from '../shared/globalDocumentsRepository';
import DocumentGlobalListViewModel from './models/globalDocumentListViewModel';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';

export const GlobalDocumentsPage: FC = observer(() => {
	const history = useHistory();
	const { isAuthor } = useUser();
	const { fetch } = useReferenceDataFetcher();

	const globalDocumentsRepository = useMemo(
		() => new GlobalDocumentsRepository(),
		[],
	);
	const [viewModel] = useState<DocumentGlobalListViewModel>(
		new DocumentGlobalListViewModel(globalDocumentsRepository, fetch, history),
	);

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	const columns = useMemo(
		() => [
			{
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<GlobalDocumentListItem, Document>) => {
						if (!params) {
							return null;
						}

						return (
							<OverflowMenu id={`document-overflow-menu-${params.row.id}`}>
								<MenuItem
									onClick={() => {
										viewModel.navigateToDocument(params.row.id);
									}}
								>
									View
								</MenuItem>
								{isAuthor && (
									<MenuItem
										onClick={() => {
											viewModel.navigateToDocument(params.row.id, 'edit');
										}}
									>
										Edit
									</MenuItem>
								)}
								{isAuthor && <Divider />}
								{isAuthor && (
									<MenuItem
										onClick={async () => {
											await viewModel.openDocumentInNewTabOrDownload(
												params.row.id,
												new Document({
													documentId: params.row.id,
													name: params.row.recordName,
													externalUrl: params.row.externalUrl,
													contentType: params.row.contentType,
													uploadedById: params.row.uploadedById,
													uploadedByName: params.row.uploadedByName,
												}),
											);
										}}
									>
										<ListItemIcon>
											<OpenInNewOutlined />
										</ListItemIcon>{' '}
										View File
									</MenuItem>
								)}
							</OverflowMenu>
						);
					},
				),
			},
			{
				field: 'documentName',
				headerName: 'Document Name',
				flex: 20,
				minWidth: 150,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(paths.entity4.documents.document.href, {
						documentId: params.id,
					});

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'area',
				headerName: 'Record Area',
				flex: 1,
				minWidth: 150,
			},
			{
				field: 'recordName',
				headerName: 'Record Name',
				flex: 20,
				minWidth: 150,
			},
			{
				...getSingleSelectGridColDef(viewModel.types),
				field: 'type',
				headerName: 'Type',
				flex: 5,
				minWidth: 150,
			},
			{
				...getSingleSelectGridColDef(viewModel.statuses),
				field: 'status',
				headerName: 'Status',
				flex: 1,
				minWidth: 100,
			},
			{
				...getDateColumnDefinition(),
				field: 'expirationDate',
				headerName: 'Expiration Date',
				flex: 1,
				minWidth: 150,
			},
			{
				field: 'uploadedByEmail',
				headerName: 'Uploaded By',
				flex: 2,
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'uploadedDate',
				headerName: 'Uploaded Date',
				flex: 1,
				minWidth: 150,
			},
		],
		[isAuthor, viewModel],
	);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.globalDocumentListView}
					title="Documents"
				/>
			}
		>
			<ActuallyPrettyGoodDataGridWrapper>
				<UserPreferencesDataGrid
					tableKey="globalDocumentListView"
					stonlyId="global-documents-list"
					columns={columns}
					rows={viewModel.getDocuments()}
					loading={viewModel.isLoading()}
					pagination
					hideFooter={false}
					errorMessage={viewModel.getError()}
					emptyMessage="No Documents found"
					showToolbar
					showCustomViewButton
					initialState={{
						pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
					}}
				/>
			</ActuallyPrettyGoodDataGridWrapper>
		</T4View>
	);
});
