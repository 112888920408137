/* eslint-disable mobx/missing-observer */
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { BankConnectionsPageRoutes } from './bankConnections/bankConnectionsPage';
import { PaymentApprovalsPageRoutes } from './paymentApprovals/paymentApprovalsPage';

export const Payments4AdministrationRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={paths.administration.payments4.paymentApprovalsManagement.href}
			>
				<PaymentApprovalsPageRoutes />
			</Route>
			<Route
				path={paths.administration.payments4.paymentConnectionManagement.href}
			>
				<BankConnectionsPageRoutes />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};
