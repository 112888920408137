/* eslint-disable mobx/missing-observer */
import { Grid, ListItemText, MenuItem, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { ApprovalRule } from 'modules/clients/apiGateway/payments4/approvalRules';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { getBooleanColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { ApprovalRuleDetailDrawer } from './approvalRuleDetailDrawer';
import { useGetAllPaymentApprovalRules } from './hooks/useGetAllPaymentApprovalRules';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';

const stonlyIds = {
	grid: 'payment-approval-rules',
	optionsMenu: 'payment-approval-rule-option-menu',
	ruleDetail: 'option-menu-rule-detail',
};

export const PaymentApprovalsPageRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={`${paths.administration.payments4.paymentApprovalsManagement.href}/:approvalRuleId`.concat(
					validIdRegex,
				)}
				exact
			>
				<PaymentApprovalsPage />
			</Route>
			<Route
				path={paths.administration.payments4.paymentApprovalsManagement.href}
				exact
			>
				<PaymentApprovalsPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};

export const PaymentApprovalsPage: FC = () => {
	// #region State

	const history = useHistory();
	const { approvalRuleId: approvalRuleIdQueryParam } = useParams<{
		approvalRuleId: string | undefined;
	}>();

	const { enqueueSnackbar } = useSnackbar();

	const { data, isLoading, isFetching, error } =
		useGetAllPaymentApprovalRules();
	useEffect(() => {
		if (!isLoading && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	}, [isLoading, error, enqueueSnackbar]);
	const rules = useMemo(() => data ?? [], [data]);

	const [selectedRuleId, setSelectedRuleId] = useState<string | null>(null);
	useEffect(() => {
		setSelectedRuleId(approvalRuleIdQueryParam ?? null);
	}, [approvalRuleIdQueryParam]);

	// #endregion

	// #region Memoized Values

	const columns: GridColDef[] = useMemo(
		() => [
			{
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<ApprovalRule>) => (
						<OverflowMenu
							id={stonlyIds.optionsMenu}
							iconButtonProps={{
								...stonlyData({ id: stonlyIds.optionsMenu }),
							}}
						>
							<MenuItem
								onClick={() => {
									setSelectedRuleId(params.row.id);
									history.push(
										`${paths.administration.payments4.paymentApprovalsManagement.href}/${params.row.id}`,
									);
								}}
								{...stonlyData({ id: stonlyIds.ruleDetail })}
							>
								<ListItemText>Manage Rule Properties</ListItemText>
							</MenuItem>
						</OverflowMenu>
					),
				),
			},
			{
				field: 'name',
				headerName: 'Rule Name',
				description: 'Rule Name',
				type: 'string',
				minWidth: DataGridColumnWidths.medium,
				flex: 1,
			},
			{
				...getBooleanColumnDefinition('string'),
				field: 'canApproveOwnPayments',
				headerName: 'Approve Own',
				description: 'Approve Own',
				minWidth: DataGridColumnWidths.medium,
				flex: 0.75,
			},
			{
				...getBooleanColumnDefinition('string'),
				field: 'requiresUniqueApprovers',
				headerName: 'Unique Approvers',
				description: 'Unique Approvers',
				minWidth: DataGridColumnWidths.medium,
				flex: 0.75,
			},
			{
				...getBooleanColumnDefinition('string'),
				field: 'hasSequentialApprovers',
				headerName: 'Sequential Approvers',
				description: 'Sequential Approvers',
				minWidth: DataGridColumnWidths.medium,
				flex: 0.75,
			},
		],
		[history],
	);

	const DetailDrawer = useMemo(
		() => (
			<ApprovalRuleDetailDrawer
				ruleId={selectedRuleId}
				onClose={() => {
					setSelectedRuleId(null);
					history.push(
						paths.administration.payments4.paymentApprovalsManagement.href,
					);
				}}
			/>
		),
		[selectedRuleId, history],
	);

	// #endregion

	return (
		<T4View
			header={
				<PageHeader
					id={
						pageHeaderStonlyIds.administration.payments4
							.paymentApprovalsManagementPage
					}
					title="Payment Approvals Management"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
			loading={isLoading && isFetching}
		>
			<Grid
				container
				item
				direction="column"
				sx={{ gap: 2, height: '100%', flexWrap: 'nowrap' }}
			>
				<Grid item xs="auto">
					<Typography variant="h2" sx={{ paddingBottom: '0.75rem' }}>
						Payment Approval Rules
					</Typography>
					<Typography variant="body1">
						Use approval rules with tiered limits and approval levels to ensure
						efficient and controlled payment authorization.
					</Typography>
				</Grid>
				<Grid item xs>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid<ApprovalRule>
							stonlyId={stonlyIds.grid}
							tableKey={stonlyIds.grid}
							columns={columns}
							rows={rules ?? []}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>

			{DetailDrawer}
		</T4View>
	);
};
