/* eslint-disable mobx/missing-observer */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, CircularProgress, Divider, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { E4EntityLink } from 'features/cash4/shared/components/T4SideDrawer/E4EntityLink';
import T4BlueHeader from 'features/cash4/shared/components/T4SideDrawer/T4BlueHeader';
import T4DetailBox from 'features/cash4/shared/components/T4SideDrawer/T4DetailBox';
import T4TopBar from 'features/cash4/shared/components/T4SideDrawer/T4TopBar';
import { T4Checkbox } from 'features/entity4/shared/components/atoms/t4Checkbox';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { FC, useMemo, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import ModalBase from 'shared/components/modalBase';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
} from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import { convertDate, formatReadDate } from 'shared/utilities/dateUtilities';
import { formatCurrency } from 'utilities/currencyUtils';
import { Action, Actions } from '../../../shared/components/T4ActionMenu';
import { getForecastedTransactionCopyContentValue } from '../../utilities';
import { ReconciliationSummaryView } from '../ReconciliationSummaryView';

export type ForecastedTransactionViewDrawerProps = {
	forecastedTransactionId?: string;
	disableLink?: boolean;
	forecastedTransactionActions?: Action[];
};

export const ForecastedTransactionViewDrawer: FC<
	ForecastedTransactionViewDrawerProps
> = ({
	forecastedTransactionId,
	disableLink = false,
	forecastedTransactionActions,
}) => {
	const { cash4 } = useUser();
	const { customerApiClient } = useClients();
	const { data: forecastedTransaction, isLoading } = useQuery(
		['forecastedTransaction', forecastedTransactionId],
		() =>
			customerApiClient.api.cash4
				.getForecastedTransaction(forecastedTransactionId!)
				.then((res) => res?.data.data),
		{
			refetchOnWindowFocus: false,
			enabled: !!forecastedTransactionId,
		},
	);

	const [
		isForecastModelExcludedModalOpen,
		setIsForecastModelExcludedModalOpen,
	] = useState<boolean>(false);

	const transactionActions = useMemo(() => {
		return (
			<Actions
				objecttype="Transaction"
				stonlyId={'cash4-forecasted-transactions-view-drawer-context-menu'}
				actions={forecastedTransactionActions!}
				id="forecastedTransactionId-more-menu"
			/>
		);
	}, [forecastedTransactionActions]);

	if (!cash4.isViewer) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	} else if (isLoading) {
		return (
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					padding: '1rem',
					justifyContent: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	} else if (!forecastedTransaction) {
		return (
			<CannotDisplay
				headingText="Not found"
				bodyText="Forecasted transaction doesn't exist."
				imageSrc={NOT_FOUND_MESSAGING.IMAGE}
			/>
		);
	}

	return (
		<>
			<T4TopBar
				title="Forecasted Transaction Details"
				copyText={getForecastedTransactionCopyContentValue(
					forecastedTransaction,
				)}
				stonlyPrefix="forecasted-item-details"
				copyUrl={`${window.location.origin}${paths.cash4.forecastedTransactions.href}/${forecastedTransaction.id}?tab=forecasted`}
				disableLink={disableLink}
				customActionDropdown={
					forecastedTransactionActions &&
					forecastedTransactionActions!.length > 0
						? transactionActions
						: undefined
				}
			/>
			<T4BlueHeader
				bigNumber={forecastedTransaction.amount.accountCurrencyAmount}
				currency={forecastedTransaction.amount.accountCurrencyCode}
				subheadlines={[
					<E4EntityLink
						e4EntityId={forecastedTransaction?.primaryParty.object?.id}
						e4EntityName={forecastedTransaction?.primaryParty.object?.name}
						stonlyPrefix="forecasted-item-details"
					/>,
					formatReadDate(
						convertDate(
							forecastedTransaction?.expectedValueDate ||
								forecastedTransaction.dueDate,
						),
					) ?? null,
				]}
			/>
			<T4DetailBox
				details={[
					{
						type: 'bold',
						label: 'Entity',
						value: forecastedTransaction?.primaryParty.object?.name ?? null,
						shouldDisplay: !!forecastedTransaction.primaryParty.object?.name,
					},
					{
						type: 'bold',
						label: 'Account',
						value: forecastedTransaction.primaryParty.account?.name ?? null,
						shouldDisplay: !!forecastedTransaction.primaryParty.account?.name,
					},
					{
						type: 'bold',
						label: 'Counterparty Name',
						value: forecastedTransaction.bankInformation?.name ?? null,
						shouldDisplay: !!forecastedTransaction.bankInformation?.name,
					},
					{
						type: 'bold',
						label: 'Counterparty Code',
						value: forecastedTransaction.bankInformation?.code ?? null,
						shouldDisplay: !!forecastedTransaction.bankInformation?.code,
					},
					<Divider sx={{ mt: 2, mb: 2 }} />,
					{
						type: 'bold',
						label: 'Secondary Party Type',
						value: forecastedTransaction.secondaryParty?.type ?? null,
						shouldDisplay: !!forecastedTransaction.secondaryParty?.type,
					},
					{
						type: 'bold',
						label: 'Secondary Entity',
						value: forecastedTransaction.secondaryParty?.object?.name ?? null,
						shouldDisplay: !!forecastedTransaction.secondaryParty?.object?.name,
					},
					{
						type: 'bold',
						label: 'Secondary Account',
						value: forecastedTransaction.secondaryParty?.account?.name ?? null,
						shouldDisplay:
							!!forecastedTransaction.secondaryParty?.account?.name,
					},
					<Divider sx={{ mt: 2, mb: 2 }} />,
					{
						type: 'bold',
						label: 'Label',
						value: forecastedTransaction.label ?? null,
						shouldDisplay: !!forecastedTransaction.label,
					},
					{
						type: 'bold',
						label: 'Transaction Amount',
						value: `${formatCurrency(
							forecastedTransaction.amount.accountCurrencyAmount,
							{
								currency: forecastedTransaction.amount.accountCurrencyCode,
							},
						)} ${forecastedTransaction.amount.accountCurrencyCode.toUpperCase()}`,
						shouldDisplay: !!forecastedTransaction.amount.accountCurrencyAmount,
					},
					{
						type: 'bold',
						label: 'Reporting Amount',
						value: `${formatCurrency(
							forecastedTransaction.amount.reportingCurrencyAmount,
							{
								currency: forecastedTransaction.amount.reportingCurrencyCode,
							},
						)} ${
							forecastedTransaction.amount.reportingCurrencyCode?.toUpperCase() ||
							'USD'
						}`,
						shouldDisplay:
							!!forecastedTransaction.amount.reportingCurrencyAmount,
					},
					{
						type: 'bold',
						label: 'Expected Value Date',
						value:
							formatReadDate(
								convertDate(forecastedTransaction?.expectedValueDate),
							) ?? null,
						shouldDisplay: !!forecastedTransaction.expectedValueDate,
					},
					{
						type: 'bold',
						label: 'As of Date',
						value:
							formatReadDate(convertDate(forecastedTransaction?.asOfDate)) ??
							null,
						shouldDisplay: !!forecastedTransaction.asOfDate,
					},
					{
						type: 'bold',
						label: 'Due Date',
						value:
							formatReadDate(convertDate(forecastedTransaction?.dueDate)) ??
							null,
						shouldDisplay: !!forecastedTransaction.dueDate,
					},
					{
						type: 'bold',
						label: 'Expiration Date',
						value:
							formatReadDate(
								convertDate(forecastedTransaction?.expirationDate),
							) ?? null,
						shouldDisplay: !!forecastedTransaction.expirationDate,
					},
					{
						type: 'bold',
						label: 'Source',
						value: forecastedTransaction.source?.sourceType ?? null,
						shouldDisplay: !!forecastedTransaction.source?.sourceType,
					},
					<Divider sx={{ mt: 2, mb: 2 }} />,
					{
						type: 'bold',
						label: 'Cash Flow Class',
						value: `${forecastedTransaction.categorization?.class?.name} (${forecastedTransaction.categorization?.class?.code})`,
						shouldDisplay: !!forecastedTransaction.categorization?.class,
					},
					{
						type: 'bold',
						label: 'Cash Flow Type',
						value: `${forecastedTransaction.categorization?.type?.name} (${forecastedTransaction.categorization?.type?.code})`,
						shouldDisplay: !!forecastedTransaction.categorization?.type,
					},
					{
						type: 'bold',
						label: 'Cash Flow Subtype',
						value: `${forecastedTransaction.categorization?.subtype?.name} (${forecastedTransaction.categorization?.subtype?.code})`,
						shouldDisplay: !!forecastedTransaction.categorization?.subtype,
					},
					{
						type: 'bold',
						label: 'GL Code',
						value: forecastedTransaction.categorization?.glCode?.code || '',
						shouldDisplay: !!forecastedTransaction.categorization?.glCode,
					},
				]}
			/>

			<ReconciliationSummaryView
				summary={forecastedTransaction.reconciliationSummary}
				noReconciliationText="Unreconciled"
			/>

			<Box>
				<IconButton
					onClick={() => {
						setIsForecastModelExcludedModalOpen(true);
					}}
				>
					<InfoOutlinedIcon />
				</IconButton>
				<T4Checkbox
					label="Exclude from forecast reports"
					checked={forecastedTransaction.isForecastModelExcluded}
					value={forecastedTransaction.isForecastModelExcluded}
					disabled={true}
				/>
				<ModalBase
					title="Exclude from forecast reports"
					open={isForecastModelExcludedModalOpen}
					onClose={() => {
						setIsForecastModelExcludedModalOpen(false);
					}}
				>
					Select to exclude the transaction from forecast model calculations.
					This is useful for removing one-time or non-recurring transactions
					that may skew future projections.
				</ModalBase>
			</Box>

			<Box>
				<T4TextFieldV2
					sx={{ mt: 2, mb: 2 }}
					multiline
					minRows={5}
					maxRows={5}
					label="Notes"
					value={forecastedTransaction.noteContent}
					disabled
					helperText={`${forecastedTransaction.noteContent?.length ?? 0}/2048`}
				/>
			</Box>
		</>
	);
};
