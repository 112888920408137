import { Add } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EntityType } from 'features/entity4/entity4Constants';
import { observer } from 'mobx-react-lite';
import { FC, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { useUser } from 'shared/hooks/useUser';
import { OptionList } from 'shared/types/referenceDataTypes';
import {
	getDateColumnDefinition,
	getMultiSelectGridColDef,
	getSingleSelectGridColDef,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { stonlyData } from 'stonly/functions';
import { T4Link } from '../../../../../../shared/components/t4Link';
import { paths } from '../../../../../../shared/constants/paths';
import {
	USER_PREFERENCES_FIELD_OPTIONS,
	getOptionsMenuColDef,
	primaryIconRenderCell,
} from '../../../../../../shared/utilities/dataGrid/dataGridUtils';
import { useProfileView } from '../../../../entityProfile/providers/entityProfileContextProvider';
import { FieldViews } from '../../../../shared/fieldSets/fieldViews/fieldViewTypes';
import { CreateTaxIdDialog } from '../components/createTaxIdDialog';
import { DeleteTaxIdDialog } from '../components/deleteTaxIdDialog';
import { TaxIdOverflowMenu } from '../components/taxIdOverflowMenu';
import { NewTaxIdForm } from '../models/newTaxIdForm';
import { TaxIdListItemData, TaxIds } from '../models/taxIds';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';

export const stonlyIds = {
	addTaxId: 'add-tax-id-button',
};

export const testIds = {
	addTaxId: 'add-button',
};

type TaxIdListItem = {
	id: string;
	options: TaxIdListItemData;
	purposes?: string[];
	taxIdNumber?: string;
	location: string;
	issuedDate: string;
	status?: string;
};

export interface TaxIdsViewProps {
	taxIds: TaxIds;
	entityType: EntityType;
	purposes: OptionList | undefined;
	statuses: OptionList | undefined;
}

export const TaxIdsView: FC<TaxIdsViewProps> = observer(
	({ taxIds, entityType, purposes, statuses }) => {
		const { setViewType } = useProfileView();
		const { isAuthor } = useUser();

		const history = useHistory();

		const headerRef = useRef<HTMLDivElement>(null);
		const [taxIdToDelete, setTaxIdToDelete] = useState<string | null>(null);
		const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
		const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

		const navigateToTaxId = (
			taxIdId: string | undefined,
			viewType: FieldViews,
		) => {
			setViewType(viewType);
			if (taxIdId) {
				history.push(
					generatePath(paths.entity4.objects.object.taxIds.taxId.href, {
						objectType: taxIds.entityType,
						objectId: taxIds.entityId,
						taxIdId: taxIdId,
					}),
				);
			}
		};

		const columns: GridColDef[] = [
			getOptionsMenuColDef<TaxIdListItem, TaxIdListItemData>((params) => (
				<TaxIdOverflowMenu
					taxIdId={params.value?.taxIdId}
					viewTaxId={() =>
						navigateToTaxId(params.value?.taxIdId, FieldViews.default)
					}
					editTaxId={() =>
						navigateToTaxId(params.value?.taxIdId, FieldViews.edit)
					}
					deleteTaxId={() => {
						setTaxIdToDelete(params.value?.taxIdId ?? null);
						setIsDeleteModalOpen(true);
					}}
				/>
			)),
			{
				field: 'taxIdNumber',
				headerName: 'Tax ID',
				flex: 2,
				minWidth: 150,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.taxIds.taxId.href,
						{
							objectType: taxIds.entityType,
							objectId: taxIds.entityId,
							taxIdId: params.id,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{primaryIconRenderCell(
								params.row.options.isPrimaryFederalIncomeTaxId,
								params.row.taxIdNumber,
								'secondary',
							)}
						</T4Link>
					);
				},
			},
			{
				...getMultiSelectGridColDef(
					purposes?.options.map((x) => x.displayName),
				),
				field: 'purposes',
				headerName: 'Purpose',
				flex: 5,
				minWidth: 150,
				valueFormatter: ({ value }) => value.join(', '),
			},
			{
				field: 'location',
				headerName: 'Location',
				flex: 4,
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'issuedDate',
				headerName: 'Issued Date',
				flex: 1,
				minWidth: 150,
			},
			{
				...getSingleSelectGridColDef(
					statuses?.options.map((x) => x.displayName),
				),
				field: 'status',
				headerName: 'Status',
				flex: 0.8,
				minWidth: 150,
			},
		];

		const rows =
			taxIds.taxIds.map(
				(taxId) =>
					({
						id: taxId.taxIdId,
						taxIdNumber: taxId.taxIdNumber,
						options: taxId,
						purposes: taxId.purposes,
						location: taxId.location,
						issuedDate: taxId.issuedDate,
						status: taxId.status?.status,
					}) as TaxIdListItem,
			) ?? [];

		const deleteTaxId = (taxIdId: string) => {
			return taxIds.deleteTaxId(taxIdId);
		};

		if (taxIds.loading || !purposes || !statuses) {
			return (
				<Box width="100%" my={5} py={2} display="flex" justifyContent="center">
					<CircularProgress />
				</Box>
			);
		}

		return (
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{
					paddingX: '2rem',
					paddingY: '1.25rem',
					gap: '16px',
					height: '100%',
				}}
			>
				<Grid
					ref={headerRef}
					container
					item
					xs="auto"
					sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
				>
					<Grid item xs="auto">
						<Typography variant="h3">Tax IDs</Typography>
					</Grid>
					{isAuthor && (
						<Grid item xs="auto">
							<Button
								data-testid={testIds.addTaxId}
								{...stonlyData({ id: stonlyIds.addTaxId })}
								variant="outlined"
								startIcon={<Add />}
								onClick={() => setIsCreateModalOpen(true)}
							>
								Add Tax ID
							</Button>
						</Grid>
					)}
				</Grid>
				<Grid
					item
					xs
					sx={{
						maxHeight: `calc(100% - ${
							(headerRef?.current?.clientHeight ?? 0) + 56
						}px)`,
					}}
				>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid
							tableKey={entityType.toString() + 'TaxIdsView'}
							stonlyId={entityType.toString() + '-taxids'}
							columns={columns}
							rows={rows}
							loading={taxIds.loading}
							pagination
							hideFooter={false}
							showToolbar
							showCustomViewButton
							initialState={{
								sorting: {
									sortModel: [
										{
											field: 'location',
											sort: 'asc',
										},
									],
								},
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							errorMessage={taxIds.error}
							emptyMessage="No Tax IDs found"
						/>
					</ActuallyPrettyGoodDataGridWrapper>

					<CreateTaxIdDialog
						open={isCreateModalOpen}
						onClose={() => setIsCreateModalOpen(false)}
						newTaxIdForm={new NewTaxIdForm(taxIds.entityId, taxIds.entityType)}
					/>
					<DeleteTaxIdDialog
						taxIdId={taxIdToDelete}
						open={isDeleteModalOpen}
						onClose={() => {
							setIsDeleteModalOpen(false);
							setTaxIdToDelete(null);
						}}
						onDelete={deleteTaxId}
						isDeletingTaxId={taxIds.isDeletingTaxId}
					/>
				</Grid>
			</Grid>
		);
	},
);
