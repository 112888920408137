/* eslint-disable mobx/missing-observer */
import { ExpandMore, Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	ListItemText,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import {
	Account,
	BankConnection,
	LegalEntity,
} from 'modules/clients/apiGateway/payments4/bankConnections';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ShareLinkIconButton } from 'shared/components/copyToClipboardIconButton';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { paths } from 'shared/constants/paths';
import { BankConnectionSettingsFieldDefinition } from './constants';
import { useGetBankConnection } from './hooks/useGetBankConnections';
import {
	useGetBankConnectionAccounts,
	useGetBankConnectionLegalEntities,
} from './hooks/useGetE4Objects';
import { useGetFinancialInstitutions } from './hooks/useGetFinancialInstitutions';
import { getBankConnectionSpecification } from './utilities';

type BankConnectionDetailsDrawerProps = {
	bankConnectionId: string | null;
	onClose: () => void;
	setEditConnectionId: (value: string | null) => void;
	setDeleteConnectionId: (value: string | null) => void;
};
export const BankConnectionDetailsDrawer: FC<
	BankConnectionDetailsDrawerProps
> = ({
	bankConnectionId,
	onClose,
	setEditConnectionId,
	setDeleteConnectionId,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const {
		isLoading,
		isFetching,
		data: bankConnection,
		error,
	} = useGetBankConnection(bankConnectionId);
	useEffect(() => {
		if (!isLoading && error?.message) {
			if (bankConnection === undefined) onClose();
			enqueueSnackbar(error?.message, {
				key: 'get-bank-connection-failure',

				variant: 'error',
				preventDuplicate: true,
			});
		}
	}, [isLoading, bankConnection, error, onClose, enqueueSnackbar]);

	const {
		isLoading: areFinancialInstitutionsLoading,
		isFetching: areFinancialInstitutionsFetching,
		data: financialInstitutions,
		error: loadingFinancialInstitutionsError,
	} = useGetFinancialInstitutions();
	useEffect(() => {
		if (loadingFinancialInstitutionsError) {
			enqueueSnackbar(
				'Unable to load financial institutions. Please try again later.',
				{
					key: 'load-financial-institutions-failure',
					variant: 'error',
					preventDuplicate: true,
				},
			);
		}
	}, [loadingFinancialInstitutionsError, enqueueSnackbar]);

	const bankConnectionFinancialInstitution = useMemo(
		() =>
			(financialInstitutions ?? []).find(
				(x) => x.bankCode === bankConnection?.bankCode,
			),
		[financialInstitutions, bankConnection],
	);

	const bankConnectionType = useMemo(() => {
		if (bankConnection === undefined) return undefined;
		if (bankConnection?.isGlobalConnection) return 'Global';
		else if (bankConnection?.e4EntityIds.length) return 'Entity';
		else if (bankConnection?.e4AccountIds.length) return 'Account';
	}, [bankConnection]);

	const {
		isFetching: areLegalEntitiesFetching,
		data: legalEntities,
		error: loadingLegalEntitiesError,
	} = useGetBankConnectionLegalEntities(bankConnectionType === 'Entity');

	const {
		isFetching: areAccountsFetching,
		data: accounts,
		error: loadingAccountsError,
	} = useGetBankConnectionAccounts(bankConnectionType === 'Account');

	const connectionTypeAlert = useMemo(() => {
		let messaging = '';
		switch (bankConnectionType) {
			case 'Global':
				messaging =
					'These credentials will be used for all entities and accounts associated with this bank.';
				break;
			case 'Entity':
				messaging =
					'Entity-level credentials override any global connection and apply to all accounts within the selected entities for the specified bank.';
				break;
			case 'Account':
				messaging =
					'Account-level credentials will take precedence over both global and entity-level connections for the selected accounts.';
				break;
			default:
				return null;
		}

		return (
			<Grid item xs={12}>
				<Alert
					severity="info"
					sx={{ '&.MuiPaper-root': { margin: 0, height: '100%' } }}
				>
					{messaging}
				</Alert>
			</Grid>
		);
	}, [bankConnectionType]);

	const relatedObjectCollapse = useMemo(
		() =>
			bankConnectionType !== undefined && bankConnectionType !== 'Global' ? (
				<Grid container item xs={12}>
					<RelatedBankObjectCollapse
						bankConnection={bankConnection}
						bankConnectionType={bankConnectionType}
						isLoading={areLegalEntitiesFetching || areAccountsFetching}
						hasLoadingError={
							loadingLegalEntitiesError !== null ||
							loadingAccountsError !== null
						}
						legalEntities={legalEntities ?? []}
						accounts={accounts ?? []}
					/>
				</Grid>
			) : null,
		[
			bankConnectionType,
			loadingLegalEntitiesError,
			loadingAccountsError,
			areLegalEntitiesFetching,
			areAccountsFetching,
			bankConnection,
			legalEntities,
			accounts,
		],
	);

	const connectionSettingsFields = useMemo(
		() =>
			getBankConnectionSpecification(bankConnection?.bankCode)
				?.fieldDefinitions ?? [],
		[bankConnection],
	);

	return (
		<T4DrawerBase
			title="Connection Details"
			open={!!bankConnectionId}
			onClose={onClose}
			initializing={
				isLoading ||
				isFetching ||
				(areFinancialInstitutionsLoading && areFinancialInstitutionsFetching)
			}
			utilityActions={[
				<ShareLinkIconButton value={window.location.href} />,
				<OverflowMenu id="payment-bank-connection-options-menu">
					<MenuItem
						onClick={() => {
							onClose();
							setEditConnectionId(bankConnectionId);
							history.push(
								`${paths.administration.payments4.paymentConnectionManagement.href}/${bankConnectionId}/edit`,
							);
						}}
					>
						<ListItemText>Edit Details</ListItemText>
					</MenuItem>
					<DeleteMenuItem
						onClick={() => setDeleteConnectionId(bankConnectionId)}
					/>
				</OverflowMenu>,
			]}
			actions={[<DrawerCancelButton label="Close" onCancel={onClose} />]}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 2 }}>
				<Grid container item xs={12} sx={{ gap: 2 }}>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Bank
							</Typography>
						</Grid>
						<Grid item xs sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{bankConnectionFinancialInstitution?.displayName ??
									bankConnection?.bankCode}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Connection Type
							</Typography>
						</Grid>
						<Grid item xs sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{bankConnectionType}</Typography>
						</Grid>
					</Grid>

					{connectionTypeAlert}

					{relatedObjectCollapse}

					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Connection Name
							</Typography>
						</Grid>
						<Grid item xs sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{bankConnection?.name}</Typography>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					{connectionSettingsFields.map((field) =>
						field.isSecret ? (
							<SecretFieldDisplay
								field={field}
								value={bankConnection?.connectionSettings[field.key]}
								key={field.key}
							/>
						) : (
							<Grid container item xs={12} key={field.key}>
								<Grid item xs={5}>
									<Typography variant="body1" sx={{ fontWeight: 500 }}>
										{field.name}
									</Typography>
								</Grid>
								<Grid item xs sx={{ textAlign: 'right' }}>
									<Typography variant="body1">
										{bankConnection?.connectionSettings[field.key]}
									</Typography>
								</Grid>
							</Grid>
						),
					)}
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<T4TextFieldV2
						id="notes"
						label="Notes"
						value={bankConnection?.notes ?? ''}
						minRows={4}
						maxRows={4}
						multiline
						InputProps={{ readOnly: true }}
						helperText={`${bankConnection?.notes?.length ?? 0}/140`}
					/>
				</Grid>
			</Grid>
		</T4DrawerBase>
	);
};

const RelatedBankObjectCollapse: FC<{
	bankConnection: BankConnection | undefined;
	bankConnectionType: 'Entity' | 'Account';
	isLoading: boolean;
	hasLoadingError: boolean;
	legalEntities: LegalEntity[];
	accounts: Account[];
}> = ({
	bankConnection,
	bankConnectionType,
	isLoading,
	hasLoadingError,
	legalEntities,
	accounts,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const names = useMemo(() => {
		let arr: string[] = [];
		if (bankConnectionType === 'Entity') {
			bankConnection?.e4EntityIds.forEach((id) => {
				const match =
					legalEntities.find((x) => x.id === id)?.displayName ?? null;
				if (match !== null) arr.push(match);
			});
		} else if (bankConnectionType === 'Account') {
			bankConnection?.e4AccountIds.forEach((id) => {
				const match = accounts.find((x) => x.id === id)?.displayName ?? null;
				if (match !== null) arr.push(match);
			});
		}

		return arr;
	}, [bankConnectionType, bankConnection, legalEntities, accounts]);

	if (hasLoadingError) {
		return (
			<Alert
				severity="warning"
				sx={{ '&.MuiPaper-root': { margin: 0, height: '100%' }, width: '100%' }}
			>
				Unable to retrieve{' '}
				{bankConnectionType === 'Entity' ? 'entities' : 'accounts'} at this
				time. Please try again later.
			</Alert>
		);
	}

	return (
		<Accordion
			expanded={isOpen}
			defaultExpanded={false}
			onChange={(_, expanded) => {
				if (!isLoading) setIsOpen(expanded);
			}}
			sx={(theme) => ({
				'&.MuiPaper-root': {
					margin: 0,
					height: '100%',
				},
				width: '100%',
				backgroundColor: theme.palette.cornflower[50],
			})}
		>
			<AccordionSummary
				expandIcon={
					isLoading ? <CircularProgress size="20px" /> : <ExpandMore />
				}
				id={`${bankConnectionType.toLowerCase()}-objects`}
			>
				<Typography>{`View ${
					bankConnectionType === 'Entity' ? 'entities' : 'accounts'
				} included`}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{ maxHeight: '12rem', overflowY: 'auto' }}>
				<Grid
					container
					sx={{ flexDirection: 'column', gap: 2, flexWrap: 'nowrap' }}
				>
					{names.map((name, index) => (
						<Grid item xs="auto" key={index}>
							<Typography variant="body2" fontWeight={500}>
								{name}
							</Typography>
						</Grid>
					))}
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

const SecretFieldDisplay: FC<{
	field: BankConnectionSettingsFieldDefinition;
	value: string | undefined;
}> = ({ field, value }) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const maskedValue = useMemo(
		() => (value !== undefined ? '*'.repeat(value.length) : null),
		[value],
	);

	return (
		<Grid container item xs={12} sx={{ alignItems: 'center' }}>
			<Grid
				item
				xs={5}
				sx={{
					display: 'flex',
					flexWrap: 'nowrap',
					alignItems: 'center',
					gap: 1,
				}}
			>
				<Typography variant="body1" sx={{ fontWeight: 500 }}>
					{field.name}
				</Typography>
				<Tooltip title={isVisible ? 'Hide' : 'Reveal'} placement="right">
					<IconButton
						aria-label="toggle secret visibility"
						onClick={() => {
							setIsVisible(!isVisible);
						}}
						onMouseDown={(e) => e.preventDefault()}
						edge="end"
					>
						{isVisible ? (
							<VisibilityOff fontSize="small" />
						) : (
							<Visibility fontSize="small" />
						)}
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid item xs sx={{ textAlign: 'right' }}>
				<Typography variant="body1">
					{isVisible ? value : maskedValue}
				</Typography>
			</Grid>
		</Grid>
	);
};
