import { Grid, TextField, Typography } from '@mui/material';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { PageHeader } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useDataRefreshInterval } from './hooks/useDataRefreshInterval';

export const AnalyticStudioConfigurationsPage: FC = observer(() => {
	const {
		query: { initializing, data: interval },
		updateInterval,
	} = useDataRefreshInterval();

	const [_interval, setInterval] = useState(interval);

	const [invalidReason, setInvalidReason] = useState<string>();
	const isIntervalValid = useMemo(() => {
		if (_interval !== undefined) {
			if (_interval < 0 || _interval > 600) {
				setInvalidReason('Interval must be between 0 and 600 seconds');
				return false;
			}
		} else {
			return false;
		}

		setInvalidReason(undefined);
		return true;
	}, [_interval]);

	useEffect(() => {
		setInterval(interval);
	}, [interval]);

	return (
		<T4View
			loading={initializing}
			header={
				<PageHeader
					id="analytic-sudio-configurations-header"
					title="Analytic Studio Configurations"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
		>
			<Grid
				container
				item
				direction="column"
				sx={{ width: '100%', height: '100%', gap: 2 }}
			>
				<Grid
					container
					item
					sx={{
						gap: 2,
					}}
				>
					<Grid item xs={12}>
						<Typography variant="h3">Data Refresh Interval</Typography>
					</Grid>
					<Grid
						container
						item
						xs={12}
						sx={{
							justifyContent: 'space-between',
						}}
					>
						<Grid item>
							<Typography>
								How frequently (in seconds) this organization can manually
								refresh their data in the Analytic Studio.
							</Typography>
						</Grid>
						<Grid item>
							<TextField
								label="Interval (seconds)"
								size="small"
								type="number"
								inputProps={{
									inputMode: 'numeric',
								}}
								defaultValue={_interval}
								onChange={(event) => {
									const newInterval = parseInt(event.target.value, 10);
									if (newInterval !== interval) {
										setInterval(newInterval);
									}
								}}
								onBlur={() => {
									if (
										_interval !== undefined &&
										isIntervalValid &&
										interval !== _interval
									) {
										updateInterval(_interval);
									}
								}}
								error={!isIntervalValid}
								helperText={invalidReason}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</T4View>
	);
});
