import { BankConnectionSettingsFieldDefinition } from '../constants';

export type BofaConnectionSettings = {
	applicationId: string;
	clientId: string;
	clientSecret: string;
	cashProId: string;
	senderId: string;
};

export const bofaConnectionSettingsForm: BofaConnectionSettings = {
	applicationId: '',
	clientId: '',
	clientSecret: '',
	cashProId: '',
	senderId: '',
};

export const bofaConnectionSettingsFieldDefinitions: BankConnectionSettingsFieldDefinition[] =
	[
		{
			key: 'applicationId',
			name: 'Application Id',
			type: 'string',
		},
		{
			key: 'clientId',
			name: 'Client Id',
			type: 'string',
		},
		{
			key: 'clientSecret',
			name: 'Client Secret',
			type: 'string',
			isSecret: true,
		},
		{
			key: 'cashProId',
			name: 'Cash Pro Id',
			type: 'string',
		},
		{
			key: 'senderId',
			name: 'Sender Id',
			type: 'string',
		},
	];
