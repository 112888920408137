import { AxiosInstance } from 'axios';
import { Entity4Endpoints, entity4 } from './entity4';
import {
	FinancialInstitutionEndpoints,
	financialInstitutions,
} from './financialInstitutions';
import { Payments4Endpoints, payments4 } from './payments4';

export type T4ApplicationApiClient = {
	payments4: Payments4Endpoints;
	entity4: Entity4Endpoints;
	financialInstitutions: FinancialInstitutionEndpoints;
};

export function getApplicationApiClient(
	axiosInstance: AxiosInstance,
): T4ApplicationApiClient {
	return {
		payments4: payments4(axiosInstance),
		entity4: entity4(axiosInstance),
		financialInstitutions: financialInstitutions(axiosInstance),
	};
}
