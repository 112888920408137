import { AxiosInstance, AxiosResponse } from 'axios';
import { T4DataResponse2 } from 'modules/clients/types';

// #region Read Models

export type AccountGroupCollection = {
	id: string;
	name: string;
	description?: string;
	isDefault: boolean;
	createdOn: string;
	createdBy: string;
	updatedOn?: string;
	updatedBy?: string;
	groups?: AccountGroup[];
};

export type AccountGroup = {
	id: string;
	name: string;
	description?: string;
	createdOn: string;
	createdBy: string;
	updatedOn?: string;
	updatedBy?: string;
	accounts?: Account[];
};

export type Account = {
	id: string;
	name: string;
};

// #endregion

// #region Write Models

export type AccountGroupCollectionReq = {
	name: string;
	description?: string;
	groupIds?: string[];
};

export type AccountGroupReq = {
	collectionId?: string;
	name: string;
	description?: string;
	accountIds?: string[];
};

// #endregion

export type AccountGroupingEndpoints = {
	accounts: () => Promise<AxiosResponse<T4DataResponse2<Account[]>>>;
	accountGroupCollections: {
		getAll: () => Promise<
			AxiosResponse<T4DataResponse2<AccountGroupCollection[]>>
		>;
		create: (
			req: AccountGroupCollectionReq,
		) => Promise<AxiosResponse<T4DataResponse2<string>>>;
		update: (
			id: string,
			req: AccountGroupCollectionReq,
		) => Promise<AxiosResponse<T4DataResponse2<string>>>;
		delete(id: string): Promise<AxiosResponse<T4DataResponse2<string>>>;
	};
	accountGroups: {
		getAll: () => Promise<AxiosResponse<T4DataResponse2<AccountGroup[]>>>;
		create: (
			req: AccountGroupReq,
		) => Promise<AxiosResponse<T4DataResponse2<string>>>;
		update: (
			id: string,
			req: AccountGroupReq,
		) => Promise<AxiosResponse<T4DataResponse2<string>>>;
		delete: (
			id: string,
		) => Promise<AxiosResponse<T4DataResponse2<AccountGroup>>>;
	};
};

export function accountGroupCollections(
	axiosInstance: AxiosInstance,
): AccountGroupingEndpoints {
	return {
		accounts: async () =>
			await axiosInstance.get<T4DataResponse2<Account[]>>(
				'/api/administration/accounts',
			),
		accountGroupCollections: {
			getAll: async () =>
				await axiosInstance.get<T4DataResponse2<AccountGroupCollection[]>>(
					'/api/administration/account-group-collections',
				),
			create: async (req) =>
				await axiosInstance.post<T4DataResponse2<string>>(
					'/api/administration/account-group-collections',
					req,
				),
			update: async (id, req) =>
				await axiosInstance.put<T4DataResponse2<string>>(
					`/api/administration/account-group-collections/${id}`,
					req,
				),
			delete: async (id) =>
				await axiosInstance.delete<T4DataResponse2<string>>(
					`/api/administration/account-group-collections/${id}`,
				),
		},
		accountGroups: {
			getAll: async () =>
				await axiosInstance.get<T4DataResponse2<AccountGroup[]>>(
					'/api/administration/account-groups',
				),
			create: async (req) =>
				await axiosInstance.post<T4DataResponse2<string>>(
					'/api/administration/account-groups',
					req,
				),
			update: async (id, req) =>
				await axiosInstance.put<T4DataResponse2<string>>(
					`/api/administration/account-groups/${id}`,
					req,
				),
			delete: async (id) =>
				await axiosInstance.delete<T4DataResponse2<AccountGroup>>(
					`/api/administration/account-groups/${id}`,
				),
		},
	};
}
