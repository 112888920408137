import {
	useT4Query,
	UseT4QueryProps,
} from 'features/cash4/_shared/_utilities/useT4Query';
import { AccountGroupCollection } from 'modules/clients/customer-api/src/api/administration/accountGroupCollections';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseAccountGroupCollectionsQuery = UseT4QueryProps<
	AccountGroupCollection[]
>;

export function useAccountGroupCollectionsQuery(): UseAccountGroupCollectionsQuery {
	const { customerApiClient } = useClients();

	const getAccountGroupCollections = useCallback(async () => {
		const response =
			await customerApiClient.api.administration.accountGroupCollections.getAll();

		return response?.data?.data ?? [];
	}, [customerApiClient.api.administration.accountGroupCollections]);
	const result = useT4Query(getAccountGroupCollections, {
		errorMessage: 'Unable to get account group collections.',
	});

	return result;
}
