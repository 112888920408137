import { ObjectSchema } from 'yup';
import {
	bofaConnectionSettingsFieldDefinitions,
	bofaConnectionSettingsForm,
} from './bofa/contants';
import { BofaConnectionSettingsValidator } from './bofa/validators';
import {
	chasConnectionSettingsFieldDefinitions,
	chasConnectionSettingsForm,
} from './chas/constants';
import { ChasConnectionSettingsValidator } from './chas/validators';
import {
	BankConnectionSettingsFieldDefinition,
	BankConnectionSettingsType,
} from './constants';
import {
	wfbiConnectionSettingsFieldDefinitions,
	wfbiConnectionSettingsForm,
} from './wfbi/constants';
import { WfbiConnectionSettingsValidator } from './wfbi/validators';
import {
	usbkConnectionSettingsFieldDefinitions,
	usbkConnectionSettingsForm,
} from './usbk/constants';
import { UsbkConnectionSettingsValidator } from './usbk/validators';

export type BankConnectionSpecification = {
	defaultForm: BankConnectionSettingsType;
	fieldDefinitions: BankConnectionSettingsFieldDefinition[];
	validator: ObjectSchema<BankConnectionSettingsType>;
};

export const getBankConnectionSpecification = (
	bankCode: string | null | undefined,
): BankConnectionSpecification | null => {
	switch (bankCode?.toLowerCase()) {
		case 'bofa':
			return {
				defaultForm: bofaConnectionSettingsForm,
				fieldDefinitions: bofaConnectionSettingsFieldDefinitions,
				validator: BofaConnectionSettingsValidator,
			};
		case 'chas':
			return {
				defaultForm: chasConnectionSettingsForm,
				fieldDefinitions: chasConnectionSettingsFieldDefinitions,
				validator: ChasConnectionSettingsValidator,
			};
		case 'wfbi':
			return {
				defaultForm: wfbiConnectionSettingsForm,
				fieldDefinitions: wfbiConnectionSettingsFieldDefinitions,
				validator: WfbiConnectionSettingsValidator,
			};
		case 'usbk':
			return {
				defaultForm: usbkConnectionSettingsForm,
				fieldDefinitions: usbkConnectionSettingsFieldDefinitions,
				validator: UsbkConnectionSettingsValidator,
			};
		default:
			return null;
	}
};
