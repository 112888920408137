import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import {
	ConfirmationDialog,
	ConfirmationDialogProps,
} from 'shared/components/confirmationDialog';
import { useDeleteBalanceMutation } from '../_hooks/useDeleteBalanceMutation';

export const stonlyIds = {
	balancesDeleteConfirmationModal: 'balances-delete-confirmation-modal',
	balancesDeleteConfirmationCancelButton: 'balances-delete-confirmation-cancel',
	balancesDeleteConfirmationDeleteButton: 'balances-delete-confirmation-delete',
};

export type DeleteBalanceConfirmationDialogProps = {
	id?: string;
	onClose?: ConfirmationDialogProps['onClose'];
};

export const DeleteBalanceConfirmationDialog: FC<DeleteBalanceConfirmationDialogProps> =
	observer(({ id, onClose }) => {
		const { isLoading: isDeletingBalance, mutate: deleteBalance } =
			useDeleteBalanceMutation();

		const handleDelete = useCallback(() => {
			if (id) {
				deleteBalance(id, {
					onSuccess: () => {
						onClose?.();
					},
				});
			}
		}, [deleteBalance, id, onClose]);

		return (
			<ConfirmationDialog
				loading={isDeletingBalance}
				open={id !== undefined}
				onClose={onClose}
				title={'Delete balance record?'}
				text={
					'This balance record will be permanently deleted from the system. This action cannot be undone.'
				}
				primaryButtonText={'DELETE'}
				onPrimaryButtonClick={handleDelete}
				secondaryButtonText={'CANCEL'}
				onSecondaryButtonClick={onClose}
				stonlyIds={{
					confirmationModal: stonlyIds.balancesDeleteConfirmationModal,
					secondaryButton: stonlyIds.balancesDeleteConfirmationCancelButton,
					primaryButton: stonlyIds.balancesDeleteConfirmationDeleteButton,
				}}
			/>
		);
	});
