import { AxiosInstance } from 'axios';
import { FrontendEndpoints, frontend } from './frontend';
import { BalanceEndpoints, balances } from './cash4/balances';

export type V1Endpoints = {
	frontend: FrontendEndpoints;
	balances: BalanceEndpoints;
};

export function v1(axiosInstance: AxiosInstance): V1Endpoints {
	return {
		frontend: frontend(axiosInstance),
		balances: balances(axiosInstance),
	};
}
