import { DeleteOutlined } from '@mui/icons-material';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Route,
	Switch,
	generatePath,
	useHistory,
	useLocation,
} from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { PageHeader, PageHeaderProps } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { useApplicationConfiguration } from 'shared/hooks/useApplicationConfigurations';
import { useUser } from 'shared/hooks/useUser';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { paths } from '../../../../shared/constants/paths';
import {
	EntityType,
	aspectUrlIds,
	objectTypeToObjectTypeName,
} from '../../entity4Constants';
import { AddAspectButton } from '../../entityProfile/AddAspectButton';
import { usePageHeader } from '../../entityProfile/hooks/usePageHeader';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { ObjectRelationshipsPage } from '../../relationships/objectRelationshipsPage';
import { usePowerOfAttorneys } from '../../relationships/providers/powerOfAttorneysProvider';
import { DeleteObjectModal } from '../../shared/components/deleteObjectModal';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { ObjectInformationPage } from '../pages/objectInformationPage';
import { ObjectDocumentsRoutes } from './documents/objectDocumentsRoutes';
import { ObjectLegalPage } from './legal/objectLegalPage';
import { ObjectRegisteredAgentJurisdictionsRoutes } from './registeredAgentJurisdictions/objectregisteredAgentJurisdictionsRoutes';
import { ObjectRegistrationsRoutes } from './registrations/objectRegistrationsRoutes';
import { ObjectSubaccountRoutes } from './subaccounts/objectSubaccountsRoutes';
import { ObjectTaxIdsRoutes } from './taxIds/objectTaxIdsRoutes';
import { AccountGroupsProvider } from 'shared/providers/accountGroupsProvider';

interface AspectInterface {
	label: string;
	link: string;
}

const testId = {
	headerReviewChangesButton: 'header-review-changes-button',
	headerEditButton: 'header-edit-button',
	headerDeleteButton: 'header-review-changes-button',
};

const stonlyIds = {
	headerReviewChangesButton: 'header-review-changes-button',
	headerEditButton: 'header-edit-button',
	headerDeleteButton: 'header-review-changes-button',
};

const subNavLabels = {
	info: 'Information',
	relationships: 'Relationships',
	registrations: 'Registrations',
	documents: 'Documents',
	taxIds: 'Tax',
	legal: 'Legal',
};

export const ObjectRoutes: FC = observer(() => {
	const location = useLocation();
	const history = useHistory();
	const { isInitializing } = usePowerOfAttorneys();
	const {
		name,
		entityObjectTags,
		addTag,
		removeTag,
		objectId: entityId,
		entityType,
	} = usePageHeader();

	const { approvalConfiguration } = useApplicationConfiguration();
	const { isLoading, viewType, setViewType, aspectManager } = useProfileView();
	const { isAuthor, isApprover } = useUser();
	const { lastAutoSave, breadcrumbs } = useHeader();

	const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			await aspectManager.loadAspects();
		})();
	}, [entityType, entityId, aspectManager]);

	const aspects = useMemo<AspectInterface[]>(() => {
		let aspects = [
			{
				label: subNavLabels.info,
				link: generatePath(paths.entity4.objects.object.information.href, {
					objectType: entityType,
					objectId: entityId,
				}),
			},
			{
				label: subNavLabels.relationships,
				link: generatePath(paths.entity4.objects.object.relationships.href, {
					objectType: entityType,
					objectId: entityId,
				}),
			},
			{
				label: subNavLabels.documents,
				link: generatePath(paths.entity4.objects.object.documents.href, {
					objectType: entityType,
					objectId: entityId,
				}),
			},
		];

		// add specific KNOWN aspects based on object type
		switch (entityType) {
			case EntityType.Entity:
				aspects.push({
					label: subNavLabels.registrations,
					link: generatePath(paths.entity4.objects.object.registrations.href, {
						objectType: entityType,
						objectId: entityId,
					}),
				});

				aspects.push({
					label: subNavLabels.taxIds,
					link: generatePath(paths.entity4.objects.object.taxIds.href, {
						objectType: entityType,
						objectId: entityId,
					}),
				});

				aspects.push({
					label: subNavLabels.legal,
					link: generatePath(paths.entity4.objects.object.legal.href, {
						objectType: entityType,
						objectId: entityId,
					}),
				});
				break;
			case EntityType.Partner:
				aspects.push({
					label: subNavLabels.taxIds,
					link: generatePath(paths.entity4.objects.object.taxIds.href, {
						objectType: entityType,
						objectId: entityId,
					}),
				});

				aspects.push({
					label: subNavLabels.legal,
					link: generatePath(paths.entity4.objects.object.legal.href, {
						objectType: entityType,
						objectId: entityId,
					}),
				});

				break;
		}

		// add dynamic aspects (aspects you can add/remove from an object): 'Registered Agent Jurisdictions, Subaccounts, etc.'
		aspectManager.aspects.forEach((aspect) => {
			const path =
				aspect.urlId === aspectUrlIds.subAccounts
					? paths.entity4.objects.object.subaccounts.href
					: aspect.urlId === aspectUrlIds.registeredAgentDetails
					? paths.entity4.objects.object.registeredAgentDetails.href
					: paths.entity4.objects.object.information.href;

			aspects.push({
				label: aspect.name,
				link: generatePath(path, {
					objectType: entityType,
					objectId: entityId,
				}),
			});
		});

		return aspects;
	}, [entityType, entityId, aspectManager.aspects]);

	const actions = useMemo<PageHeaderProps['actions']>(() => {
		let actions: PageHeaderProps['actions'] = [];

		const entityName = objectTypeToObjectTypeName.get(entityType);

		let shouldIncludeHeaderActionButtons = true;

		if (
			location.pathname.includes(`/${aspectUrlIds.taxIds}/}`) ||
			location.pathname.includes(
				`/${aspectUrlIds.registrations}/` ||
					location.pathname.includes(`/${aspectUrlIds.subAccounts}/`),
			)
		) {
			shouldIncludeHeaderActionButtons = true;
		} else {
			[
				aspectUrlIds.relationships,
				aspectUrlIds.registrations,
				aspectUrlIds.registeredAgentDetails,
				aspectUrlIds.taxIds,
				aspectUrlIds.documents,
				aspectUrlIds.subAccounts,
			].forEach((aspectId) => {
				if (location.pathname.endsWith(aspectId)) {
					shouldIncludeHeaderActionButtons = false;
				}
			});
		}

		if (shouldIncludeHeaderActionButtons) {
			if (viewType !== FieldViews.default) {
				actions.push({
					label: 'View',
					stonlyId: 'header-view-button',
					testId: 'header-view-button',
					action: () => setViewType(FieldViews.default),
				});
			}

			if (isAuthor && viewType !== FieldViews.edit) {
				actions.push({
					label: 'Edit',
					stonlyId: stonlyIds.headerEditButton,
					testId: testId.headerEditButton,
					action: () => setViewType(FieldViews.edit),
				});
			}

			if (
				isApprover &&
				approvalConfiguration.isActive &&
				viewType !== FieldViews.review
			) {
				actions.push({
					label: 'Review Changes',
					stonlyId: stonlyIds.headerReviewChangesButton,
					testId: testId.headerReviewChangesButton,
					action: () => setViewType(FieldViews.review),
				});
			}

			if (isAuthor && location.pathname.includes('/information')) {
				actions.push({
					label: `Delete${entityName ? ` ${entityName}` : ''}`,
					stonlyId: stonlyIds.headerDeleteButton,
					testId: testId.headerDeleteButton,
					startIcon: <DeleteOutlined />,
					color: 'error',
					action: () => setDeleteModalOpen(true),
				});
			}
		}

		return actions;
	}, [
		entityType,
		viewType,
		location.pathname,
		isAuthor,
		isApprover,
		approvalConfiguration.isActive,
		setViewType,
	]);

	const shouldIncludeTags = useCallback((): boolean => {
		return !location.pathname.includes(aspectUrlIds.subAccounts);
	}, [location.pathname]);

	return (
		<T4View
			loading={aspectManager.loadingAspects || isInitializing || isLoading}
			disablePadding
			header={
				<PageHeader
					id={name ?? 'entityprofilepage'}
					isEditMode={viewType === FieldViews.edit}
					title={name}
					showDoneButton={viewType !== FieldViews.default}
					actions={actions}
					aspects={aspects}
					lastAutoSave={moment(lastAutoSave)}
					breadcrumbs={<BreadcrumbList breadcrumbs={breadcrumbs} />}
					addAspectAction={
						viewType === FieldViews.edit &&
						isAuthor &&
						aspectManager.availableAspects.length > 0 ? (
							<AddAspectButton />
						) : null
					}
					isAuthor={isAuthor}
					entityObjectTags={entityObjectTags}
					addTag={addTag}
					removeTag={removeTag}
					shouldIncludeTagsComponent={shouldIncludeTags()}
				/>
			}
		>
			<Switch>
				<Route exact path={paths.entity4.objects.object.information.href}>
					<ObjectInformationPage />
				</Route>
				<Route exact path={paths.entity4.objects.object.relationships.href}>
					<ObjectRelationshipsPage />
				</Route>
				<Route path={paths.entity4.objects.object.documents.href}>
					<ObjectDocumentsRoutes />
				</Route>
				<Route path={paths.entity4.objects.object.registrations.href}>
					<ObjectRegistrationsRoutes />
				</Route>
				<Route path={paths.entity4.objects.object.registeredAgentDetails.href}>
					<ObjectRegisteredAgentJurisdictionsRoutes />
				</Route>
				<Route path={paths.entity4.objects.object.taxIds.href}>
					<ObjectTaxIdsRoutes />
				</Route>
				<Route exact path={paths.entity4.objects.object.legal.href}>
					<ObjectLegalPage />
				</Route>
				<Route
					path={`${paths.entity4.objects.object.subaccounts.href.replace(
						':objectType',
						'accounts',
					)}`}
				>
					<AccountGroupsProvider>
						<ObjectSubaccountRoutes />
					</AccountGroupsProvider>
				</Route>

				<Route>
					<CannotDisplay
						headingText={NOT_FOUND_MESSAGING.HEADING}
						bodyText={NOT_FOUND_MESSAGING.BODY}
						imageSrc={NOT_FOUND_MESSAGING.IMAGE}
						buttonText={RETURN_TO_HOME}
						buttonHref={paths.root.href}
					/>
				</Route>
			</Switch>
			<DeleteObjectModal
				isOpen={deleteModalOpen}
				objectType={entityType}
				objectCode={name ?? ''}
				objectId={entityId ?? ''}
				closeModal={() => setDeleteModalOpen(false)}
				afterDelete={() =>
					history.push(
						generatePath(paths.entity4.objects.href, {
							objectType: entityType,
						}),
					)
				}
			/>
		</T4View>
	);
});
