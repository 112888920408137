/* eslint-disable mobx/missing-observer */
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import {
	convertToPercentage,
	normalizeReconciliationStatus,
} from 'features/cash4/reconciliations/_hooks/useReconciliationCalculations';
import { ReconciliationSummary } from 'modules/clients/customer-api/src/api/cash4';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { paths } from 'shared/constants/paths';
import { formatCurrency } from 'utilities/currencyUtils';
import { TabulatedRow } from './TransactionDrawer/TabulatedRow';

export type ReconciliationSummaryViewProps = {
	summary: ReconciliationSummary | undefined;
	noReconciliationText: string;
};

export const ReconciliationSummaryView: FC<ReconciliationSummaryViewProps> = ({
	summary,
	noReconciliationText,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();
	const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

	const handleOpen = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		},
		[],
	);

	const handleClose = useCallback(() => {
		setAnchorEl(undefined);
	}, []);

	const handleViewClick = useCallback(() => {
		if (summary) {
			handleClose();
			window.open(
				`${paths.cash4.reconciliations.href}/${summary.reconciliationRecordId}`,
				'_blank',
			);
		}
	}, [handleClose, summary]);

	return (
		<Grid
			container
			sx={{
				borderRadius: '5px',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: 'black',
				padding: '1rem',
				mt: 2, // move to parent component
			}}
		>
			<Grid container>
				<Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
					<Grid
						container
						item
						xs="auto"
						sx={{ gap: 1, alignContent: 'center' }}
					>
						<Grid item>
							<Typography sx={{ fontWeight: '600' }}>Reconciliation</Typography>
						</Grid>
						<Grid item>
							<Typography
								sx={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									color: 'secondary.main',
									fontWeight: '600',
								}}
							>
								{`${normalizeReconciliationStatus(
									summary?.reconciliationStatus ?? noReconciliationText,
								)}`}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						item
						xs="auto"
						sx={{ gap: 1, alignContent: 'center' }}
					>
						<IconButton onClick={handleOpen} data-testid={'menu'}>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="reconciliation-summary-menu"
							anchorEl={anchorEl}
							keepMounted
							open={open}
							onClose={handleClose}
						>
							<MenuItem onClick={handleViewClick} key={1}>
								<span>View</span>&nbsp;&nbsp;
								<OpenInNewIcon fontSize="small" />
							</MenuItem>
						</Menu>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Divider sx={{ mt: 1, mb: 1 }} />
				</Grid>
			</Grid>
			<TabulatedRow
				label="Variance"
				value={summary ? convertToPercentage(summary.variancePercentage) : '-'}
			/>
			<TabulatedRow
				label="Reconciled Amount"
				value={
					summary
						? formatCurrency(summary.reconciledAmount, {
								currency: summary.currencyCode,
						  }) ?? new Intl.NumberFormat().format(summary.reconciledAmount)
						: '-'
				}
			/>
			<TabulatedRow
				label="Unreconciled Amount"
				value={
					summary
						? formatCurrency(summary.unreconciledAmount, {
								currency: summary.currencyCode,
						  }) ?? new Intl.NumberFormat().format(summary.unreconciledAmount)
						: '-'
				}
			/>
			<TabulatedRow
				label="Reconciled With"
				value={
					summary
						? `${summary.projectedCount + summary.reportedCount} transactions`
						: '-'
				}
			/>
		</Grid>
	);
};
