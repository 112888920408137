import {
	GRID_CHECKBOX_SELECTION_FIELD,
	GridColDef,
	GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import T4Drawer from 'features/cash4/shared/components/T4SideDrawer/T4DrawerShell';
import { ProjectionViewDrawer } from 'features/cash4/transactions/components/ProjectedTransactions/ProjectionViewDrawer';
import { observer } from 'mobx-react-lite';
import { ProjectedTransaction } from 'modules/clients/customer-api/src/api/cash4';
import { FC, useCallback, useMemo, useState } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import {
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';
import {
	Actions,
	ActionsEnum,
} from 'features/cash4/shared/components/T4ActionMenu';
import { useUser } from 'shared/hooks/useUser';
import {
	getProjectedTransactionsColumnDefs,
	projectedTransactionsColumnVisibility,
} from 'features/cash4/transactions/utilities';
import { getCurrencyColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import AmountCurrencyTooltipGridCell from 'features/cash4/_shared/_components/AmountCurrencyTooltipGridCell';
import { CurrencySource, defaultCurrencyCode } from 'utilities/currencyUtils';

export type ProjectedTransactionsGridProps = {
	stonlyId: string;
	tableKey: string;
	loading?: boolean;
	projectedTransactions: ProjectedTransaction[];
	selectedProjectedIds?: string[];
	setSelectedProjectedIds?: (selectedProjectedIds: string[]) => void;
};

export const ProjectedTransactionsGrid: FC<ProjectedTransactionsGridProps> =
	observer(
		({
			stonlyId,
			tableKey,
			loading = false,
			selectedProjectedIds,
			setSelectedProjectedIds,
			projectedTransactions,
		}) => {
			const {
				setSelectedProjectedTransaction,
				setProjectedTransactionDrawerOpen,
			} = useReconciliationsContext();

			const useCheckboxSelection = useMemo(
				() => selectedProjectedIds !== undefined,
				[selectedProjectedIds],
			);
			const [projection, setProjection] = useState<ProjectedTransaction>();
			const [openProjectionDrawer, setOpenProjectionDrawer] = useState(false);

			const onRowSelectionModelChangeHandler = useMemo(() => {
				if (useCheckboxSelection && setSelectedProjectedIds) {
					return (projectedIds: GridRowSelectionModel) => {
						setSelectedProjectedIds(projectedIds.map((x) => x.toString()));
					};
				}
			}, [setSelectedProjectedIds, useCheckboxSelection]);

			const handleOpenProjectionDrawer = useCallback(() => {
				setOpenProjectionDrawer(true);
			}, []);

			const handleCloseProjectionDrawer = useCallback(() => {
				setOpenProjectionDrawer(false);
			}, []);

			const handleProjectedViewClick = useCallback(
				(projection: ProjectedTransaction) => {
					setProjection(projection);
					handleOpenProjectionDrawer();
				},
				[handleOpenProjectionDrawer],
			);

			const handleProjectedEditClick = useCallback(
				(projection: ProjectedTransaction) => {
					setSelectedProjectedTransaction(projection);
					setProjectedTransactionDrawerOpen(true);
				},
				[setProjectedTransactionDrawerOpen, setSelectedProjectedTransaction],
			);

			const user = useUser();

			const columns = useMemo<GridColDef[]>(() => {
				return getProjectedTransactionsColumnDefs(
					{
						...getCurrencyColumnDefinition(),
						field: 'amount',
						headerName: 'Amount',
						valueGetter: (params) => params.row.amount.accountCurrencyAmount,
						renderCell: (params) => {
							return AmountCurrencyTooltipGridCell({
								accountCurrencyAmount: params.row.amount.accountCurrencyAmount,
								accountCurrencyCode: params.row.amount.accountCurrencyCode,
								reportingCurrencyAmount:
									params.row.amount.reportingCurrencyAmount || null,
								reportingCurrencyCode:
									params.row.amount.reportingCurrencyCode ||
									defaultCurrencyCode,
								displayedCurrencyCode: CurrencySource.Account,
							});
						},
					},
					{
						field: 'currencyCode',
						headerName: 'Currency Code',
						valueGetter: (params) => {
							return params.row.amount.accountCurrencyCode;
						},
					},
					useCheckboxSelection
						? undefined
						: {
								...getOptionsMenuColDef((params) => {
									return (
										<Actions
											objecttype="Transaction"
											stonlyId="c4-create-reconciliation-projected-item"
											actions={[
												{
													action: ActionsEnum.view,
													callback: () => handleProjectedViewClick(params.row),
												},
												...(user.cash4.isAuthor
													? [
															{
																action: ActionsEnum.edit,
																callback: () =>
																	handleProjectedEditClick(params.row),
															},
													  ]
													: []),
											]}
											id="projectedTransactionId-more-menu"
										/>
									);
								}),
						  },
					undefined,
				);
			}, [
				handleProjectedViewClick,
				handleProjectedEditClick,
				useCheckboxSelection,
				user.cash4.isAuthor,
			]);

			return (
				<>
					<UserPreferencesDataGrid
						stonlyId={stonlyId}
						tableKey={tableKey}
						loading={loading}
						rows={projectedTransactions}
						hideFooter={false}
						showToolbar={true}
						showCustomViewButton
						pagination
						autoPageSize
						checkboxSelection={useCheckboxSelection}
						disableRowSelectionOnClick={useCheckboxSelection}
						rowSelectionModel={selectedProjectedIds}
						onRowSelectionModelChange={onRowSelectionModelChangeHandler}
						columns={columns}
						columnVisibilityModel={projectedTransactionsColumnVisibility}
						initialState={{
							sorting: {
								sortModel: [{ field: 'expectedValueDate', sort: 'asc' }],
							},
							pinnedColumns: {
								left: [
									GRID_CHECKBOX_SELECTION_FIELD,
									USER_PREFERENCES_FIELD_OPTIONS,
								],
							},
						}}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
								hideExport: true,
								quickFilterProps: {
									variant: 'outlined',
									size: 'small',
								},
							},
						}}
					/>
					<T4Drawer
						open={openProjectionDrawer}
						onClose={handleCloseProjectionDrawer}
						stonlyPrefix="projected-transaction-details"
					>
						{projection && (
							<ProjectionViewDrawer projectionId={projection.id} />
						)}
					</T4Drawer>
				</>
			);
		},
	);
