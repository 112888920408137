import { Add } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ExpirationDateRegistrationCell } from 'features/entity4/components/expirationDateRegistrationCell';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import {
	getDateColumnDefinition,
	getSingleSelectGridColDef,
} from 'shared/utilities/dataGrid/columnDefinitions';
import {
	USER_PREFERENCES_FIELD_OPTIONS,
	getOptionsMenuColDef,
	primaryIconRenderCell,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { T4Link } from '../../../../../../shared/components/t4Link';
import {
	ObjectPathParams,
	paths,
} from '../../../../../../shared/constants/paths';
import { useUser } from '../../../../../../shared/hooks/useUser';
import {
	FrontendRegistrationRepository,
	RegistrationListItem,
} from '../../../../registrations/frontendRegistrationRepository';
import { CreateRegistrationModal } from '../../../../registrations/views/components/createRegistrationModal';
import { DeleteRegistrationModal } from '../../../../registrations/views/components/deleteRegistrationModal';
import { RegistrationOptionsCell } from '../../../../registrations/views/components/registrationOptionCell';
import { RegistrationListViewModel } from '../../../../registrations/views/models/registrationListViewModel';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';

export const stonlyIds = {
	addRegistration: 'registration-add-button',
};

export const testIds = {
	addRegistration: 'registration-add-button',
};

export const ObjectRegistrationsPage: FC = observer(() => {
	const { objectId, objectType } = useParams<ObjectPathParams>();
	const { setViewType } = useProfileView();
	const { fetch } = useReferenceDataFetcher();
	const { isAuthor } = useUser();
	const handleError = useErrorHandler();

	const headerRef = useRef<HTMLDivElement>(null);
	const [viewModel] = useState(
		new RegistrationListViewModel(
			objectId,
			new FrontendRegistrationRepository(),
			fetch,
		),
	);

	var columns = [
		{
			...getOptionsMenuColDef<RegistrationListItem>((params) => (
				<RegistrationOptionsCell
					{...params}
					deleteRegistrationViewModel={viewModel.deleteRegistrationModal}
				/>
			)),
		},
		{
			field: 'registrationNumber',
			headerName: 'Registration Number',
			minWidth: 150,
			renderCell: (params: GridRenderCellParams) => {
				const path = generatePath(
					paths.entity4.objects.object.registrations.registration.href,
					{
						objectType: objectType,
						objectId: viewModel.entityId,
						registrationId: params.id,
					},
				);

				return (
					<T4Link to={path} color="secondary">
						{primaryIconRenderCell(
							params.row.isHomeRegistration,
							params.row.registrationNumber,
							'secondary',
						)}
					</T4Link>
				);
			},
		},
		{
			...getSingleSelectGridColDef(viewModel.entityLegalFormCodes),
			field: 'entityLegalFormCode',
			headerName: 'Legal Form',
			minWidth: 150,
			sortable: true,
		},
		{
			...getSingleSelectGridColDef(viewModel.countries),
			field: 'country',
			headerName: 'Country',
			minWidth: 150,
			sortable: true,
		},
		{
			...getSingleSelectGridColDef(viewModel.stateProvinces),
			field: 'state',
			headerName: 'State/Province',
			minWidth: 150,
			sortable: true,
		},
		{
			field: 'county',
			headerName: 'County',
			minWidth: 150,
			sortable: true,
		},
		{
			field: 'city',
			headerName: 'City',
			minWidth: 150,
			sortable: true,
		},
		{
			...getSingleSelectGridColDef(viewModel.statuses),
			field: 'status',
			headerName: 'Status',
			minWidth: 150,
		},
		{
			...getDateColumnDefinition(),
			field: 'expirationDate',
			headerName: 'Expiration Date',
			minWidth: 150,
			sortable: true,
			renderCell: ExpirationDateRegistrationCell,
		},
	] as GridColDef[];

	useEffect(() => {
		setViewType(FieldViews.default);
		viewModel.load().catch((error) => handleError(error));
	}, [handleError, viewModel]);

	return (
		<Grid
			container
			direction="column"
			wrap="nowrap"
			sx={{
				paddingX: '2rem',
				paddingY: '1.25rem',
				gap: '16px',
				height: '100%',
			}}
		>
			<Grid
				ref={headerRef}
				container
				item
				xs="auto"
				sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
			>
				<Grid item xs="auto">
					<Typography variant="h3" display="flex" alignItems="center">
						Registrations
					</Typography>
				</Grid>
				{isAuthor && (
					<Grid item xs="auto">
						<Button
							data-testid={testIds.addRegistration}
							{...stonlyData({ id: stonlyIds.addRegistration })}
							onClick={() => viewModel.onAddRegistrationClick(isAuthor)}
							startIcon={<Add />}
							variant="outlined"
							color="primary"
						>
							Add Registration
						</Button>
					</Grid>
				)}
			</Grid>
			<Grid
				item
				xs
				sx={{
					maxHeight: `calc(100% - ${
						(headerRef?.current?.clientHeight ?? 0) + 16
					}px)`,
				}}
			>
				<ActuallyPrettyGoodDataGridWrapper>
					<UserPreferencesDataGrid
						tableKey={objectType.toString() + 'RegistrationsView'}
						stonlyId={objectType.toString() + '-registrations'}
						columns={columns}
						rows={viewModel.registrations}
						calculateColumnWidths
						pagination
						hideFooter={false}
						showToolbar
						showCustomViewButton
						initialState={{
							sorting: {
								sortModel: [
									{
										field: 'locationDisplayName',
										sort: 'asc',
									},
								],
							},
							pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
						}}
						loading={viewModel.loading}
						emptyMessage="No Registrations found"
					/>
				</ActuallyPrettyGoodDataGridWrapper>
			</Grid>
			<CreateRegistrationModal viewModel={viewModel.createRegistrationModal} />
			<DeleteRegistrationModal
				registrationsListViewModel={viewModel}
				viewModel={viewModel.deleteRegistrationModal}
			/>
		</Grid>
	);
});
