import {
	Cached,
	CancelOutlined,
	CheckCircleOutline,
	ErrorOutline,
	OpenInNew,
} from '@mui/icons-material';
import {
	Alert,
	Box,
	Button,
	Chip,
	CircularProgress,
	Divider,
	Grid,
	Tab,
	Tabs,
	Typography,
	useTheme,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { User } from 'modules/clients/apiGateway/payments4';
import {
	Payment,
	PaymentApprovalLevelStatusTypes,
	PaymentApprovalState,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { T4ProblemDetails } from 'modules/clients/types';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import ModalBase from 'shared/components/modalBase';
import { T4Link } from 'shared/components/t4Link';
import { paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import {
	convertDate,
	dateTimeReadFormat,
	formatDate,
	formatReadDate,
} from 'shared/utilities/dateUtilities';
import { formatCurrency } from 'utilities/currencyUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { PaymentQueryKeys } from '../hooks/usePayments';
import { getPaymentApprovalStatusText } from '../utilities';

export const PaymentApprovalsTab: FC<{
	tabIndex: number;
	payment: Payment | null | undefined;
}> = ({ tabIndex, payment }) => {
	interface PaymentLevelState {
		date: Moment | null;
		status: string | null;
		user: User | null;
		reason: string | null;
		level: number;
	}

	const theme = useTheme();
	const { applicationApiClient } = useClients();
	const queryClient = useQueryClient();
	const { payments4 } = useUser();
	const { enqueueSnackbar } = useSnackbar();

	const approvalList = useMemo(() => {
		if (payment?.approvalState === null)
			return (
				<Alert
					severity="warning"
					sx={{
						'&.MuiPaper-root': {
							marginTop: '0',
							height: '100%',
							width: '100%',
						},
					}}
				>
					Contact your payment admin to setup a new approval tier.
				</Alert>
			);

		if (
			payment?.approvalState.isCompleted &&
			payment.approvalState.isAutoApprove
		) {
			const approvalStatus = payment.statusHistory.find(
				(status) =>
					status.paymentStatusType ===
					PaymentStatusTypes[PaymentStatusTypes.Approved],
			);
			if (approvalStatus !== undefined)
				return (
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							flexWrap: 'nowrap',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 1,
							}}
						>
							<Typography variant="body1">
								{formatDate(
									convertDate(approvalStatus?.createdOn),
									dateTimeReadFormat,
								)?.toUpperCase()}
							</Typography>
							<Typography variant="body1">
								{approvalStatus?.createdBy?.email ??
									approvalStatus?.createdBy?.userId}
							</Typography>
							<Chip
								label={approvalStatus?.paymentStatusType}
								color={'success'}
								sx={{
									width: '6.5rem',
									justifyContent: 'center',
								}}
							/>
						</Box>
						<CheckCircleOutline color="success" />
					</Grid>
				);
		}

		if (payment?.approvalState.paymentApprovalLevelStates.length === 0)
			return (
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						flexWrap: 'nowrap',
						justifyContent: 'space-between',
					}}
				>
					<Typography variant="body1">No approvals to display.</Typography>
					<ErrorOutline color="warning" />
				</Grid>
			);

		const levelState: PaymentLevelState[] =
			payment?.approvalState?.paymentApprovalLevelStates
				.filter(
					(history) =>
						history.status !==
						PaymentApprovalLevelStatusTypes[
							PaymentApprovalLevelStatusTypes.Pending
						],
				)
				.map((history) => {
					const historyInfo =
						history.status ===
						PaymentApprovalLevelStatusTypes[
							PaymentApprovalLevelStatusTypes.Approved
						]
							? { date: history.approvedOn, user: history.approvedBy }
							: { date: history.rejectedOn, user: history.rejectedBy };

					return {
						date: moment(historyInfo.date),
						status: history.status,
						user: historyInfo.user,
						reason: history.rejectedReason,
						level: history.level,
					};
				}) ?? [];

		return levelState
			.sort((a, b) => b.level - a.level)
			.map((value, index) => {
				const isApproved =
					value.status ===
					PaymentApprovalLevelStatusTypes[
						PaymentApprovalLevelStatusTypes.Approved
					];
				return (
					<Grid
						key={value.level}
						container
						item
						xs={12}
						sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
					>
						<Grid
							item
							xs="auto"
							sx={{
								display: 'flex',
								flexWrap: 'nowrap',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 1,
								}}
							>
								<Typography variant="body1">
									{formatDate(value.date, dateTimeReadFormat)?.toUpperCase()}
								</Typography>
								<Typography variant="body1">
									{value.user?.email ?? value.user?.userId}
								</Typography>
								<Chip
									icon={
										<div
											style={{
												height: '1.5rem',
												width: '1.5rem',
												color: theme.palette.primary.contrastText,
												backgroundColor: isApproved
													? theme.palette.success.dark
													: theme.palette.error.dark,
												borderRadius: '50%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											{value.level}
										</div>
									}
									label={value.status}
									color={isApproved ? 'success' : 'error'}
									sx={{
										width: '6.5rem',
										justifyContent: 'space-between',
									}}
								/>
							</Box>
							{isApproved ? (
								<CheckCircleOutline color="success" />
							) : (
								<CancelOutlined color="error" />
							)}
						</Grid>
						{!isApproved && (
							<Grid
								item
								xs="auto"
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 1,
								}}
							>
								{value.reason !== null && (
									<>
										<Typography variant="body1" fontWeight={500}>
											Rejection Reason
										</Typography>
										<Typography variant="body1">{value.reason}</Typography>
									</>
								)}
								<Alert
									severity="warning"
									sx={{
										'&.MuiPaper-root': {
											marginTop: '0',
											height: '100%',
											width: '100%',
										},
									}}
								>
									<T4Link
										to={{
											pathname: paths.payments4.paymentStatus.href,
											state: {
												openSubmitPaymentDrawer: true,
											},
										}}
										color="inherit"
									>
										Create a new payment
									</T4Link>{' '}
									from the Payment page to change payment information and
									restart the approval process.
								</Alert>
							</Grid>
						)}
						{index !== levelState.length - 1 && (
							<Divider sx={{ paddingTop: '0.5rem' }} />
						)}
					</Grid>
				);
			});
	}, [payment, theme]);

	// #region Resubmit Payment

	const canResubmitPayment = useMemo(() => {
		if (
			!!payment &&
			payment.approvalState === null &&
			payment.currentStatus ===
				PaymentStatusTypes[PaymentStatusTypes.Created] &&
			(payments4.isPaymentInitiator || payments4.isPaymentApprover)
		)
			return true;
		return false;
	}, [payment, payments4]);

	const resubmitPaymentMutationFn = useCallback(async () => {
		const response = await applicationApiClient.payments4.payments.resubmit(
			payment!.id,
		);
		if (response.status === 200) return true;
		else if (response.status === 400 && response.data)
			throw new Error((response.data as T4ProblemDetails).detail ?? undefined);
		else throw new Error();
	}, [applicationApiClient, payment]);

	const { isLoading: isResubmitLoading, mutate: resubmitPayment } = useMutation<
		boolean,
		Error
	>({
		mutationFn: resubmitPaymentMutationFn,
		onSuccess: () => {
			enqueueSnackbar('Successfully resubmitted payment.', {
				key: 'resubmit-payment-success',
				variant: 'success',
				preventDuplicate: true,
			});
			queryClient.invalidateQueries(PaymentQueryKeys.base);
		},
		onError: (error) => {
			enqueueSnackbar(
				!isStringUndefinedOrNullOrWhitespace(error?.message)
					? error.message
					: 'An unexpected error occured and we were unable to resubmit the payment. Please try again later.',
				{
					key: 'resubmit-payment-failure',
					variant: 'error',
					preventDuplicate: true,
				},
			);
			return Promise.resolve();
		},
	});

	const ResubmitButton = useMemo(
		() => (
			<Button
				startIcon={
					isResubmitLoading ? (
						<CircularProgress
							size="1rem"
							sx={{ color: theme.palette.primary.contrastText }}
						/>
					) : (
						<Cached />
					)
				}
				color="primary"
				variant="contained"
				onClick={() => {
					if (canResubmitPayment) resubmitPayment();
				}}
				disabled={isResubmitLoading}
			>
				Resubmit
			</Button>
		),
		[isResubmitLoading, theme, canResubmitPayment, resubmitPayment],
	);

	// #endregion

	const [isApproversModalOpen, setIsApproversModalOpen] =
		useState<boolean>(false);
	const approversModal = useMemo(() => {
		if (
			!payment ||
			payment.approvalState === null ||
			payment.approvalState.isCompleted === true
		)
			return null;

		return (
			<ViewLevelApproversModal
				isOpen={isApproversModalOpen}
				onClose={() => setIsApproversModalOpen(false)}
				paymentApprovalState={payment.approvalState!}
			/>
		);
	}, [isApproversModalOpen, payment]);
	useEffect(() => {
		if (payment === undefined) setIsApproversModalOpen(false);
	}, [payment]);

	return tabIndex === 1 ? (
		<div role="tabpanel">
			<Grid container sx={{ gap: 2 }}>
				<Grid container item xs={12} sx={{ gap: 2 }}>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Value Date
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{formatReadDate(moment(payment?.valueDate))}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Amount
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{formatCurrency(payment?.instructedAmount.value, {
									currency: payment?.instructedAmount.currencyCode ?? undefined,
								})}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Currency
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{payment?.instructedAmount.currencyCode}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Current Approval Status
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{getPaymentApprovalStatusText(payment)}
							</Typography>
						</Grid>
					</Grid>
					{payment?.approvalState?.isCompleted === false && (
						<Grid
							item
							xs={12}
							sx={{ display: 'flex', justifyContent: 'flex-end' }}
						>
							<Button
								onClick={() => setIsApproversModalOpen(true)}
								endIcon={<OpenInNew />}
								variant="text"
								color="secondary"
							>
								View Approver List
							</Button>
						</Grid>
					)}
					{canResubmitPayment && (
						<Grid
							item
							xs={12}
							sx={{ display: 'flex', justifyContent: 'flex-end' }}
						>
							{ResubmitButton}
						</Grid>
					)}
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid container item xs={12} sx={{ gap: 2 }}>
					{approvalList}
				</Grid>
			</Grid>

			{approversModal}
		</div>
	) : null;
};

const ViewLevelApproversModal: FC<{
	isOpen: boolean;
	paymentApprovalState: PaymentApprovalState;
	onClose: () => void;
}> = ({ isOpen, onClose, paymentApprovalState }) => {
	const [tabIndex, setTabIndex] = useState<number>(0);

	const closeModal = useCallback(() => {
		setTabIndex(0);
		onClose();
	}, [onClose]);

	const activeLevels = paymentApprovalState.paymentApprovalLevelStates.filter(
		(x) =>
			x.status ===
			PaymentApprovalLevelStatusTypes[PaymentApprovalLevelStatusTypes.Pending],
	);

	const columns: GridColDef[] = useMemo(
		() => [
			{
				field: 'email',
				headerName: 'Email',
				description: 'User Email',
				type: 'string',
				flex: 1,
			},
			{
				field: 'name',
				headerName: 'Name',
				description: 'User Name',
				type: 'string',
				flex: 1,
			},
		],
		[],
	);

	const DisplayTab = useMemo(() => {
		const currentTab = activeLevels[tabIndex];
		return (
			<Box role="tabpanel" sx={{ height: '250px' }}>
				<ActuallyPrettyGoodDataGridWrapper>
					<DataGridPro<User>
						density="compact"
						columns={columns}
						rows={currentTab.approvers ?? []}
						getRowId={(user) => user.userId}
						hideFooter={true}
						initialState={{
							sorting: {
								sortModel: [{ field: 'email', sort: 'asc' }],
							},
						}}
					/>
				</ActuallyPrettyGoodDataGridWrapper>
			</Box>
		);
	}, [activeLevels, tabIndex, columns]);

	return (
		<ModalBase
			title={`Payment Approvers by Level`}
			open={isOpen}
			onClose={closeModal}
			fullWidth
		>
			<Tabs
				indicatorColor="primary"
				value={tabIndex}
				onChange={(_, index) => setTabIndex(index)}
				variant="scrollable"
				scrollButtons="auto"
			>
				{activeLevels.map((level) => (
					<Tab key={level.id} label={`Level ${level.level} Approvers`} />
				))}
			</Tabs>

			{DisplayTab}
		</ModalBase>
	);
};
