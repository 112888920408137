/* eslint-disable mobx/missing-observer */
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Divider, Grid, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import {
	Account,
	BankConnection,
	CreateBankConnectionRequest,
	LegalEntity,
} from 'modules/clients/apiGateway/payments4/bankConnections';
import { T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { T4MultiSelectAutocompleteVertical } from 'shared/components/autocompletes/t4MultiselectAutocompleteVertical';
import { CancellationModal } from 'shared/components/CancellationModal';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { TooltipAdornment } from 'shared/components/tooltipAdornment';
import { useClients } from 'shared/hooks/useClients';
import { flattenProblemDetails, MultiError } from 'utilities/errors/errorUtils';
import { trimStringsInObject } from 'utilities/objectUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { ConnectionSettingsForm } from './connectionSettingsForm';
import {
	BankConnectionForm,
	BankConnectionTypes,
	defaultBankConnectionForm,
} from './constants';
import { BankConnectionQueryKeys } from './hooks/useGetBankConnections';
import { useGetCertificateNames } from './hooks/useGetCertificateNames';
import {
	useGetBankConnectionAccounts,
	useGetBankConnectionLegalEntities,
} from './hooks/useGetE4Objects';
import { useGetFinancialInstitutions } from './hooks/useGetFinancialInstitutions';
import { GetBankConnectionFormValidator } from './validators';
import { getBankConnectionSpecification } from './utilities';

export const CreateBankConnectionDrawer: FC<{
	isOpen: boolean;
	bankConnections: BankConnection[];
	setIsOpen: (value: boolean) => void;
	setTabAfterCreate: (bankCode: string) => void;
}> = ({ isOpen, bankConnections, setIsOpen, setTabAfterCreate }) => {
	const { applicationApiClient } = useClients();
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();

	const formMethods = useForm<BankConnectionForm>({
		defaultValues: defaultBankConnectionForm,
		resolver: yupResolver(GetBankConnectionFormValidator(bankConnections)),
	});
	const { control, watch, setValue, reset, handleSubmit, formState } =
		formMethods;

	const closeDrawer = useCallback(() => {
		setIsOpen(false);
		setIsCancellationModalOpen(false);
		reset(defaultBankConnectionForm);
		setErrors([]);
	}, [setIsOpen, reset]);

	const [isCancellationModalOpen, setIsCancellationModalOpen] =
		useState<boolean>(false);

	const {
		isLoading: areFinancialInstitutionsLoading,
		isFetching: areFinancialInstitutionsFetching,
		data: financialInstitutions,
		error: loadingFinancialInstitutionsError,
	} = useGetFinancialInstitutions(isOpen);
	const {
		isLoading: areCertificateNamesLoading,
		isFetching: areCertificateNamesFetching,
		data: certificateNames,
		error: loadingCertificateNamesError,
	} = useGetCertificateNames(isOpen);
	useEffect(() => {
		if (loadingFinancialInstitutionsError || loadingCertificateNamesError) {
			setIsOpen(false);
			reset(defaultBankConnectionForm);
			enqueueSnackbar(
				'Unable to load supporting data to enable bank connection creation. Please try again later.',
				{
					key: 'load-bank-connection-data-failure',
					variant: 'error',
					preventDuplicate: true,
				},
			);
		}
	}, [
		loadingFinancialInstitutionsError,
		loadingCertificateNamesError,
		setIsOpen,
		reset,
		enqueueSnackbar,
	]);

	const isInitializing = useMemo(
		() =>
			(areFinancialInstitutionsFetching && areFinancialInstitutionsLoading) ||
			(areCertificateNamesFetching && areCertificateNamesLoading),
		[
			areFinancialInstitutionsFetching,
			areFinancialInstitutionsLoading,
			areCertificateNamesFetching,
			areCertificateNamesLoading,
		],
	);

	const [errors, setErrors] = useState<string[]>([]);
	const errorsRef = useRef<HTMLDivElement>(null);

	const createMutationFn = useCallback(
		async (data: BankConnectionForm) => {
			const request = trimStringsInObject({
				name: data.name,
				bankCode: data.bank!.bankCode!,
				isGlobalConnection: data.connectionType === 'Global',
				connectionSettings: data.connectionSettings! as {
					[key: string]: string;
				},
				e4EntityIds: data.e4Entities.map((x) => x.id),
				e4AccountIds: data.e4Accounts.map((x) => x.id),
				notes: data.notes,
			}) as CreateBankConnectionRequest;

			const response =
				await applicationApiClient.payments4.bankConnections.create(request);
			if (response.status === 200 && response.data) return true;
			else if (response.status === 400 && response.data)
				throw new MultiError(
					flattenProblemDetails(response.data as T4ProblemDetails),
				);
			else throw new Error();
		},
		[applicationApiClient],
	);

	const { isLoading, mutate: onCreate } = useMutation<
		boolean,
		Error,
		BankConnectionForm
	>({
		mutationFn: createMutationFn,
		onMutate: () => {
			setErrors([]);
		},
		onSuccess: (_, request) => {
			closeDrawer();
			setTabAfterCreate(request.bank!.bankCode!);
			enqueueSnackbar('Payment connection created successfully.', {
				key: 'create-bank-connection-success',
				variant: 'success',
				preventDuplicate: true,
			});
			queryClient.invalidateQueries(BankConnectionQueryKeys.getAll());
		},
		onError: (error) => {
			if (error instanceof MultiError) {
				setErrors(error.messages);
				errorsRef?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			} else
				enqueueSnackbar(
					'Unable to create payment connection. Please try again later.',
					{
						key: 'create-bank-connection-failure',
						variant: 'error',
						preventDuplicate: true,
					},
				);
		},
	});

	const selectedBank = watch('bank');
	const bankAlreadyHasGlobalConnection = useMemo(
		() =>
			bankConnections.some(
				(x) => x.bankCode === selectedBank?.bankCode && x.isGlobalConnection,
			),
		[selectedBank, bankConnections],
	);
	const selectedConnectionType = watch('connectionType');
	useEffect(() => {
		if (bankAlreadyHasGlobalConnection && selectedConnectionType === 'Global')
			setValue('connectionType', 'Entity');
	}, [
		selectedBank,
		selectedConnectionType,
		bankAlreadyHasGlobalConnection,
		setValue,
	]);

	const {
		isLoading: areLegalEntitiesLoading,
		isFetching: areLegalEntitiesFetching,
		data: legalEntities,
		error: loadingLegalEntitiesError,
	} = useGetBankConnectionLegalEntities(
		isOpen && selectedConnectionType === 'Entity',
	);

	const {
		isLoading: areAccountsLoading,
		isFetching: areAccountsFetching,
		data: accounts,
		error: loadingAccountsError,
	} = useGetBankConnectionAccounts(
		isOpen && selectedConnectionType === 'Account',
	);

	const connectionTypeAlert = useMemo(() => {
		let messaging = '';
		switch (selectedConnectionType) {
			case 'Global':
				messaging =
					'These credentials will be used for all entities and accounts associated with this bank.';
				break;
			case 'Entity':
				messaging =
					'Entity-level credentials override any global connection and apply to all accounts within the selected entities for the specified bank.';
				break;
			case 'Account':
				messaging =
					'Account-level credentials will take precedence over both global and entity-level connections for the selected accounts.';
		}

		return (
			<Grid item xs={12}>
				<Alert
					severity="info"
					sx={{ '&.MuiPaper-root': { margin: 0, height: '100%' } }}
				>
					{messaging}
				</Alert>
			</Grid>
		);
	}, [selectedConnectionType]);

	const relatedObjectSelector = useMemo(() => {
		if (selectedConnectionType === 'Entity')
			return (
				<Grid item xs={12}>
					{loadingLegalEntitiesError ? (
						<Alert
							severity="warning"
							sx={{ '&.MuiPaper-root': { marginTop: 0, height: '100%' } }}
						>
							Unable to load Legal Entities. Please try again later.
						</Alert>
					) : (
						<Controller
							name={'e4Entities'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4MultiSelectAutocompleteVertical<LegalEntity>
									id="e4-entities"
									value={value}
									options={legalEntities ?? []}
									onChange={(_, value) => onChange(value)}
									getOptionKey={(option) => option.id}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									textFieldProps={{
										label: 'Entities',
										error: !!error,
										helperText: error && error.message,
										required: true,
										InputProps: {
											startAdornment: (
												<TooltipAdornment
													adornmentType="info"
													text="If you don't see an expected entity in the dropdown, check the account relationships in Entity4."
												/>
											),
										},
									}}
									loading={areLegalEntitiesFetching && areLegalEntitiesLoading}
									readOnly={areLegalEntitiesFetching && areLegalEntitiesLoading}
								/>
							)}
						/>
					)}
				</Grid>
			);
		else if (selectedConnectionType === 'Account')
			return (
				<Grid item xs={12}>
					{loadingAccountsError ? (
						<Alert
							severity="warning"
							sx={{ '&.MuiPaper-root': { marginTop: 0, height: '100%' } }}
						>
							Unable to load Accounts. Please try again later.
						</Alert>
					) : (
						<Controller
							name={'e4Accounts'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4MultiSelectAutocompleteVertical<Account>
									id="e4-accounts"
									value={value}
									options={accounts ?? []}
									onChange={(_, value) => onChange(value)}
									getOptionKey={(option) => option.id}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									textFieldProps={{
										label: 'Accounts',
										error: !!error,
										helperText: error && error.message,
										required: true,
										InputProps: {
											startAdornment: (
												<TooltipAdornment
													adornmentType="info"
													text="If you don't see an expected account in the dropdown, check the account relationships in Entity4."
												/>
											),
										},
									}}
									loading={areAccountsFetching && areAccountsLoading}
									readOnly={areAccountsFetching && areAccountsLoading}
								/>
							)}
						/>
					)}
				</Grid>
			);
		else return null;
	}, [
		control,
		selectedConnectionType,
		legalEntities,
		accounts,
		areLegalEntitiesFetching,
		areLegalEntitiesLoading,
		loadingLegalEntitiesError,
		areAccountsFetching,
		areAccountsLoading,
		loadingAccountsError,
	]);

	return (
		<T4DrawerBase
			title="Create Connection"
			open={isOpen}
			onClose={() => {
				if (formState.isDirty) setIsCancellationModalOpen(true);
				else closeDrawer();
			}}
			initializing={isInitializing}
			loading={isLoading}
			actions={[
				<DrawerCancelButton
					onCancel={() => {
						if (formState.isDirty) setIsCancellationModalOpen(true);
						else closeDrawer();
					}}
					disabled={isLoading}
				/>,
				<DrawerSubmitButton
					onSubmit={handleSubmit((data) => onCreate(data))}
					label="Create"
					disabled={!formState.isDirty || isLoading || isInitializing}
				/>,
			]}
			disableNavigationCollapse
		>
			<FormProvider {...formMethods}>
				<Grid container sx={{ gap: 2 }}>
					<Grid item xs={12}>
						<Controller
							name={'bank'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => {
								return (
									<T4Autocomplete<FinancialInstitution>
										id="bank"
										label="Bank"
										value={value}
										options={financialInstitutions ?? []}
										onChange={(
											_: any,
											newValue: FinancialInstitution | null,
										) => {
											if (
												newValue?.financialInstitutionId !==
												value?.financialInstitutionId
											) {
												onChange(newValue);

												if (newValue === null)
													setValue('connectionSettings', null);
												else
													setValue(
														'connectionSettings',
														getBankConnectionSpecification(newValue.bankCode)
															?.defaultForm ?? null,
													);
											}
										}}
										isOptionEqualToValue={(option, value) =>
											option.bankCode === value.bankCode
										}
										getOptionLabel={(option) =>
											option.displayName ??
											option.bankCode ??
											option.financialInstitutionId
										}
										getOptionDisabled={(option) =>
											!option.isWirePaymentsEnabled
										}
										renderOption={(props, option, _, ownerState) => {
											const labelBox = (
												<Box
													component="li"
													{...props}
													key={props.id ?? option.financialInstitutionId}
												>
													{ownerState.getOptionLabel(option)}
												</Box>
											);
											if (!option.isWirePaymentsEnabled) {
												return (
													<Box key={option.financialInstitutionId}>
														<Tooltip
															title="This counterparty is not enabled for wire payments yet. Please contact your administrator to enable wire payments for this counterparty."
															placement="left"
															arrow
														>
															<span>{labelBox}</span>
														</Tooltip>
													</Box>
												);
											}

											return labelBox;
										}}
										error={!!error}
										helperText={error && error.message}
										required
									/>
								);
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name={'connectionType'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => {
								return (
									<T4Autocomplete<string, false, true>
										id="connectionType"
										label="Connection Type"
										value={value}
										options={BankConnectionTypes}
										onChange={(_: any, newValue: string) => {
											if (newValue !== value) {
												onChange(newValue);
												setValue('e4Entities', []);
												setValue('e4Accounts', []);
											}
										}}
										getOptionDisabled={(option) =>
											option === 'Global' && bankAlreadyHasGlobalConnection
										}
										renderOption={(props, option, _, ownerState) => {
											const labelBox = (
												<Box component="li" {...props} key={option}>
													{ownerState.getOptionLabel(option)}
												</Box>
											);
											if (ownerState.getOptionDisabled?.(option)) {
												return (
													<Box key={option}>
														<Tooltip
															title={`${selectedBank?.displayName} already has a global connection.`}
															placement="left"
															arrow
														>
															<span>{labelBox}</span>
														</Tooltip>
													</Box>
												);
											}

											return labelBox;
										}}
										error={!!error}
										helperText={error && error.message}
										disableClearable
										required
									/>
								);
							}}
						/>
					</Grid>

					{connectionTypeAlert}

					{relatedObjectSelector}

					<Grid item xs={12}>
						<Controller
							name={'name'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => {
								return (
									<T4TextFieldV2
										id="connection-name"
										label="Connection Name"
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={error && error.message}
										required
										InputProps={{
											startAdornment: (
												<TooltipAdornment
													adornmentType="info"
													text="Choose a unique and descriptive name for this connection to help you easily identify it later."
												/>
											),
										}}
									/>
								);
							}}
						/>
					</Grid>

					{!!selectedBank && (
						<Grid item xs={12}>
							<Divider />
						</Grid>
					)}

					<ConnectionSettingsForm certificateNames={certificateNames ?? []} />

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Controller
							name="notes"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4TextFieldV2
									id="notes"
									label="Notes"
									value={value ?? ''}
									onChange={(value: string) => {
										if (isStringUndefinedOrNullOrWhitespace(value))
											onChange(null);
										else onChange(value);
									}}
									minRows={4}
									maxRows={4}
									multiline
									error={!!error}
									helperText={`${value?.length ?? 0}/140${
										error?.message ? ' ' + error.message : ''
									}`}
									inputProps={{
										maxLength: 140,
									}}
								/>
							)}
						/>
					</Grid>

					{Object.values(errors).length > 0 && (
						<Grid item xs={12} ref={errorsRef}>
							<T4AlertStack errors={errors} />
						</Grid>
					)}
				</Grid>
			</FormProvider>

			<CancellationModal
				variant="create"
				resourceType="payment connection"
				isOpen={isCancellationModalOpen}
				onClose={() => setIsCancellationModalOpen(false)}
				onSubmit={closeDrawer}
			/>
		</T4DrawerBase>
	);
};
