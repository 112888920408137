/* eslint-disable mobx/missing-observer */
import { Grid, Paper, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { brandColors } from 'shared/theme/brandColors';
import { formatCurrency } from 'utilities/currencyUtils';

export type T4BlueHeaderProps = {
	bigNumber?: number | null;
	currency: string;
	subheadlines?: (string | JSX.Element | null)[];
};

const T4BlueHeader: FC<T4BlueHeaderProps> = ({
	bigNumber,
	currency,
	subheadlines,
}) => {
	// Filter out null values and elements that should not be rendered
	const filteredSubheadlines = subheadlines?.filter((item) => {
		if (item === null) return false;
		return true;
	});

	return (
		<Paper
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				backgroundColor: brandColors.denim[500],
				color: 'white',
				p: 2,
			}}
			elevation={0}
		>
			{bigNumber != null && (
				<Typography variant="h2" color="inherit">
					{`${formatCurrency(bigNumber, {
						currency: currency,
					})} ${currency.toUpperCase()}`}
				</Typography>
			)}
			{subheadlines && <PipeDelimitedContent items={filteredSubheadlines} />}
		</Paper>
	);
};

export default T4BlueHeader;

type PipeDelimitedContentProps = {
	items?: (string | JSX.Element | null)[];
};

const PipeDelimitedContent: FC<PipeDelimitedContentProps> = ({ items }) => {
	items =
		items?.reduce<(string | JSX.Element | null)[]>((acc, current, index) => {
			if (index === 0) {
				return [current];
			} else {
				return [...acc, '|', current];
			}
		}, []) ?? [];

	return (
		<Grid container sx={{ gap: 0.5, justifyContent: 'center' }}>
			{items.map((item, index) => (
				<Grid item key={`subheadline-${index}`}>
					<Tooltip title={index === 0 ? item : undefined}>
						<Typography
							key={index}
							variant="body1"
							noWrap
							sx={{
								maxWidth: index === 0 ? '15.5rem' : undefined,
								color: 'inherit',
							}}
						>
							{item}
						</Typography>
					</Tooltip>
				</Grid>
			))}
		</Grid>
	);
};
