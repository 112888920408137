import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Collapse,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	useTheme,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { usePartiesQuery } from 'features/cash4/_queries/usePartiesQuery';
import { useT4Query } from 'features/cash4/_shared/_utilities/useT4Query';
import { useDataContext } from 'features/cash4/rules/createRuleModal/providers/DataProvider';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4DateField } from 'features/entity4/shared/components/atoms/t4DateField';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import {
	CashFlowDetail,
	GeneralLedgerDetail,
	Party,
	PartyAccount,
	ForecastedTransaction,
	ForecastedTransactionReq,
} from 'modules/clients/customer-api/src/api/cash4';
import { T4DataResponse } from 'modules/clients/types';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { CancellationModal } from 'shared/components/CancellationModal';
import { T4NumberInput } from 'shared/components/t4NumberInput';
import { useClients } from 'shared/hooks/useClients';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { stonlyData } from 'stonly/functions';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TransactionAmountInfoModal } from '../shared/TransactionAmountInfoModal';
import ModalBase from 'shared/components/modalBase';
import { T4Checkbox } from 'features/entity4/shared/components/atoms/t4Checkbox';
import { useUser } from 'shared/hooks/useUser';
import { convertDate } from 'shared/utilities/dateUtilities';
import { Add, ExpandMore, DeleteOutline } from '@mui/icons-material';

const stonlyIds = {
	submitButton: 'create-forecasted-transaction-submit-button',
	cancelButton: 'create-forecasted-transaction-cancel-button',
	detailsSection: 'create-forecasted-transaction-details-section',
	primaryPartySection: 'create-forecasted-transaction-primaryParty-section',
	secondaryPartySection: 'create-forecasted-transaction-secondaryParty-section',
	categorizationSection: 'create-forecasted-transaction-categorization-section',
	flowDirectionBtnGroup: 'create-forecasted-transaction-flow-direction',
	secondaryFlowDirectionBtnGroup:
		'create-forecasted-transaction-secondary-flow-direction',
	addSecondaryPartyButton:
		'create-forecasted-transaction-add-secondary-party-button',
	removeSecondaryPartyButton:
		'create-forecasted-transaction-remove-secondary-party-button',
};

export type ForecastedTransactionForm = {
	//accounts
	primaryPartyType: string | null;
	primaryPartyObject: Party | null;
	primaryPartyAccount: PartyAccount | null;
	secondaryPartyType: string | null;
	secondaryPartyObject: Party | null;
	secondaryPartyAccount: PartyAccount | null;
	//dates
	expectedValueDate: Date | null;
	asOfDate: Date | null;
	dueDate: Date | null;
	expirationDate: Date | null;

	//categorization
	class: CashFlowDetail | null;
	type: CashFlowDetail | null;
	subtype: CashFlowDetail | null;
	glDetail: GeneralLedgerDetail | null;
	glCode: string | null;

	//other
	flowDirection: string;
	secondaryFlowDirection: string;
	isForecastModelExcluded: boolean;
	label: string | null;
	noteContent: string | null;
	currencyCode: string | null;
	amount: number | null;
};

export type ForecastedTransactionDrawerProps = {
	isOpen: boolean;
	forecastedTransaction?: ForecastedTransaction;
	onClose: () => void;
	onSubmit?: (id: string | undefined) => void;
};

export const ForecastedTransactionDrawer: FC<ForecastedTransactionDrawerProps> =
	observer(
		({ isOpen, forecastedTransaction, onClose, onSubmit: onDrawerSubmit }) => {
			const { customerApiClient } = useClients();
			const { fetch } = useReferenceDataFetcher();
			const { enqueueSnackbar } = useSnackbar();
			const {
				categories,
				types,
				subtypes,
				glCodes,
				isLoadingGlCodes,
				refetchGlCodes,
			} = useDataContext();

			const theme = useTheme();
			const { cash4 } = useUser();

			//#region Queries

			const { loading: loadingParties, data: parties } = usePartiesQuery();

			const getCurrencyCodes = useCallback(async () => {
				const response = await fetch({
					referenceListNames: ['FunctionalCurrency'],
				});

				return (
					response?.referenceLists['FunctionalCurrency'].map((x) => x.value) ??
					[]
				);
			}, [fetch]);
			const { loading: loadingCurrencyCodes, data: currencyCodes } =
				useT4Query(getCurrencyCodes);

			//#endregion

			//#region State

			const [showSecondaryParties, setShowSecondaryParties] =
				useState<boolean>(false);

			const getDefaultValues = useCallback(
				(forecastedTransaction?: ForecastedTransaction) => {
					let values: ForecastedTransactionForm = {
						primaryPartyType: 'Entity',
						primaryPartyObject: null,
						primaryPartyAccount: null,
						secondaryPartyType: null,
						secondaryPartyObject: null,
						secondaryPartyAccount: null,

						expectedValueDate: null,
						asOfDate: null,
						dueDate: null,
						expirationDate: null,

						class: null,
						type: null,
						subtype: null,
						glDetail: null,
						glCode: null,

						flowDirection: 'Inflow',
						secondaryFlowDirection: 'Outflow',
						isForecastModelExcluded: false,
						label: null,
						noteContent: null,
						currencyCode: 'USD',
						amount: null,
					};

					if (forecastedTransaction) {
						values.primaryPartyType = forecastedTransaction.primaryParty.type;
						values.primaryPartyObject = forecastedTransaction.primaryParty
							.object
							? {
									id: forecastedTransaction.primaryParty.object.id,
									type: forecastedTransaction.primaryParty.type,
									name: forecastedTransaction.primaryParty.object.name,
									accounts: parties.filter(
										(x) =>
											x.id === forecastedTransaction.primaryParty?.object?.id,
									)[0].accounts,
							  }
							: null;
						values.primaryPartyAccount = forecastedTransaction.primaryParty
							.account
							? {
									id: forecastedTransaction.primaryParty.account.id,
									number: forecastedTransaction.primaryParty.account.number,
									name: forecastedTransaction.primaryParty.account.name,
							  }
							: null;
						values.secondaryPartyType =
							forecastedTransaction.secondaryParty?.type ?? null;
						if (values.secondaryPartyType) {
							setShowSecondaryParties(true);
						}
						values.secondaryPartyObject = forecastedTransaction.secondaryParty
							?.object
							? {
									id: forecastedTransaction.secondaryParty.object.id,
									type: forecastedTransaction.secondaryParty.type,
									name: forecastedTransaction.secondaryParty.object.name,
									accounts: parties.filter(
										(x) =>
											x.id === forecastedTransaction.secondaryParty?.object?.id,
									)[0].accounts,
							  }
							: null;
						values.secondaryPartyAccount = forecastedTransaction.secondaryParty
							?.account
							? {
									id: forecastedTransaction.secondaryParty.account.id,
									number: forecastedTransaction.secondaryParty.account.number,
									name: forecastedTransaction.secondaryParty.account.name,
							  }
							: null;

						values.expectedValueDate = forecastedTransaction.expectedValueDate
							? convertDate(forecastedTransaction.expectedValueDate)!.toDate()
							: null;
						values.asOfDate = forecastedTransaction.asOfDate
							? convertDate(forecastedTransaction.asOfDate)!.toDate()
							: null;
						values.dueDate = forecastedTransaction.dueDate
							? convertDate(forecastedTransaction.dueDate)!.toDate()
							: null;
						values.expirationDate = forecastedTransaction.expirationDate
							? convertDate(forecastedTransaction.expirationDate)!.toDate()
							: null;

						values.class = forecastedTransaction.categorization?.class
							? {
									id: forecastedTransaction.categorization.class.id,
									code: forecastedTransaction.categorization.class.code,
									name: forecastedTransaction.categorization.class.name,
							  }
							: null;
						values.type = forecastedTransaction.categorization?.type
							? {
									id: forecastedTransaction.categorization.type.id,
									code: forecastedTransaction.categorization.type.code,
									name: forecastedTransaction.categorization.type.name,
							  }
							: null;
						values.subtype = forecastedTransaction.categorization?.subtype
							? {
									id: forecastedTransaction.categorization.subtype.id,
									code: forecastedTransaction.categorization.subtype.code,
									name: forecastedTransaction.categorization.subtype.name,
							  }
							: null;
						values.glDetail = forecastedTransaction.categorization?.glCode
							? {
									id: forecastedTransaction.categorization.glCode.id,
									code: forecastedTransaction.categorization.glCode.code,
							  }
							: null;
						values.glCode =
							forecastedTransaction.categorization?.glCode?.code ?? null;

						values.flowDirection = forecastedTransaction.flowDirection;
						values.isForecastModelExcluded =
							forecastedTransaction.isForecastModelExcluded;
						values.label = forecastedTransaction.label ?? null;
						values.noteContent = forecastedTransaction.noteContent ?? null;
						values.currencyCode =
							forecastedTransaction.amount.accountCurrencyCode;
						values.amount = Math.abs(
							forecastedTransaction.amount.accountCurrencyAmount,
						);
					}

					return values;
				},
				[parties],
			);

			const defaultValues = useMemo<ForecastedTransactionForm>(() => {
				return getDefaultValues(forecastedTransaction);
			}, [getDefaultValues, forecastedTransaction]);

			const { handleSubmit, control, reset, formState, watch, setValue } =
				useForm<ForecastedTransactionForm>({
					defaultValues: defaultValues,
				});

			useEffect(() => {
				reset(defaultValues);
			}, [defaultValues, reset]);

			const primaryPartyType = watch('primaryPartyType');
			const primaryPartyObject = watch('primaryPartyObject');
			const primaryPartyAccount = watch('primaryPartyAccount');
			const secondaryPartyType = watch('secondaryPartyType');
			const secondaryPartyObject = watch('secondaryPartyObject');
			const secondaryPartyAccount = watch('secondaryPartyAccount');

			const expectedValueDate = watch('expectedValueDate');
			const dueDate = watch('dueDate');

			const classValue = watch('class');
			const typeValue = watch('type');
			const subtypeValue = watch('subtype');
			const glDetail = watch('glDetail');
			const glCode = watch('glCode');

			const flowDirection = watch('flowDirection');
			const secondaryFlowDirection = watch('secondaryFlowDirection');
			const isForecastModelExcluded = watch('isForecastModelExcluded');
			const amount = watch('amount');
			const currencyCode = watch('currencyCode');

			const [isLoading, setIsLoading] = useState<boolean>(false);
			const [isCancellationModalOpen, setIsCancellationModalOpen] =
				useState<boolean>(false);
			const [isAmountInfoModalOpen, setIsAmountInfoModalOpen] =
				useState<boolean>(false);
			const [
				isForecastModelExcludedModalOpen,
				setIsForecastModelExcludedModalOpen,
			] = useState<boolean>(false);

			const cashFlowClasses = useMemo<CashFlowDetail[]>(() => {
				return categories.map((x) => ({
					id: x.id,
					code: x.code,
					name: x.name,
				}));
			}, [categories]);

			const cashFlowTypes = useMemo<CashFlowDetail[]>(() => {
				if (classValue) {
					return types(classValue.id)?.map((x) => ({
						id: x.id,
						code: x.code,
						name: x.name,
					}));
				}

				return [];
			}, [classValue, types]);

			const cashFlowSubtypes = useMemo(() => {
				if (typeValue) {
					return subtypes(typeValue.id)?.map((x) => ({
						id: x.id,
						code: x.code,
						name: x.name,
					}));
				}

				return [];
			}, [typeValue, subtypes]);

			const mappedGlCodes = useMemo<GeneralLedgerDetail[]>(() => {
				return glCodes.map((x) => ({
					id: x.id!,
					code: x.code!,
				}));
			}, [glCodes]);

			const canSave = useMemo(
				() =>
					primaryPartyType &&
					(primaryPartyAccount === null ||
						primaryPartyAccount?.id !== secondaryPartyAccount?.id) &&
					primaryPartyObject !== null &&
					(secondaryPartyType !== 'Entity' || secondaryPartyObject !== null) &&
					amount !== null &&
					amount >= 0 &&
					currencyCode !== null &&
					((classValue !== null && typeValue !== null) ||
						(classValue === null && typeValue === null)) &&
					flowDirection !== null &&
					(dueDate !== null || expectedValueDate !== null) &&
					(moment(expectedValueDate).isValid() || moment(dueDate).isValid()) &&
					formState.isDirty,
				[
					primaryPartyType,
					primaryPartyAccount,
					secondaryPartyAccount,
					primaryPartyObject,
					secondaryPartyType,
					secondaryPartyObject,
					amount,
					currencyCode,
					expectedValueDate,
					classValue,
					typeValue,
					flowDirection,
					dueDate,
					formState.isDirty,
				],
			);

			const actionType = useMemo(() => {
				return forecastedTransaction ? 'update' : 'create';
			}, [forecastedTransaction]);

			const handleDrawerClose = useCallback(() => {
				reset(getDefaultValues());
				onClose();
				setShowSecondaryParties(false);
				setIsLoading(false);
			}, [getDefaultValues, onClose, reset]);

			const onSubmit = useCallback(
				async (data: ForecastedTransactionForm) => {
					if (canSave) {
						let id: string | undefined;

						try {
							setIsLoading(true);

							const requestData: ForecastedTransactionReq = {
								primaryParty: {
									partyType: 'Entity',
									entityId: data.primaryPartyObject?.id,
									accountId: data.primaryPartyAccount?.id,
								},
								secondaryParty: !!data.secondaryPartyType
									? {
											partyType: data.secondaryPartyType ?? '',
											entityId: data.secondaryPartyObject?.id,
											accountId: data.secondaryPartyAccount?.id,
									  }
									: undefined,
								expectedValueDate: data.expectedValueDate
									?.toISOString()
									.split('T')?.[0],
								asOfDate: data.asOfDate?.toISOString().split('T')?.[0],
								dueDate: data.dueDate?.toISOString().split('T')?.[0],
								expirationDate: data.expirationDate
									?.toISOString()
									.split('T')?.[0],
								categorization:
									data.class && data.type
										? {
												classId: data.class?.id,
												typeId: data.type?.id,
												subtypeId: data.subtype?.id,
												glCode: data.glDetail
													? data.glDetail
													: {
															id: undefined,
															code: data.glCode ?? undefined,
													  },
										  }
										: undefined,
								flowDirection: data.flowDirection,
								isForecastModelExcluded: data.isForecastModelExcluded,
								label: data.label ?? undefined,
								noteContent: data.noteContent ?? undefined,
								currencyCode: data.currencyCode!,
								amount: data.amount ?? 0,
							};

							let response:
								| AxiosResponse<
										T4DataResponse<string>,
										ForecastedTransactionReq
								  >
								| undefined;
							if (forecastedTransaction) {
								response = await customerApiClient.api.cash4.updateForecasted(
									forecastedTransaction?.id,
									requestData,
								);
							} else {
								response = await customerApiClient.api.cash4.createForecasted(
									requestData,
								);

								id = response?.data as any as string; // todo c4: api is messed up
							}

							if (
								response.data.success !== undefined &&
								!response.data.success
							) {
								throw new Error('Failed response.');
							} else {
								if (!forecastedTransaction) {
									reset(defaultValues);
								}
							}

							handleDrawerClose();
							onDrawerSubmit?.(id);
							enqueueSnackbar(
								`Forecasted Transaction ${actionType}d successfully!`,
								{
									variant: 'success',
								},
							);
						} catch (error) {
							enqueueSnackbar(
								`An unexpected error occurred and we were unable to ${actionType} Forecasted Transaction. Please try again later.`,
								{
									variant: 'error',
								},
							);
						} finally {
							refetchGlCodes();
							setIsLoading(false);
						}
					}
				},
				[
					canSave,
					forecastedTransaction,
					handleDrawerClose,
					onDrawerSubmit,
					enqueueSnackbar,
					actionType,
					customerApiClient.api.cash4,
					reset,
					defaultValues,
					refetchGlCodes,
				],
			);

			const handleAddSecondaryPartyButtonClick = useCallback(() => {
				if (!showSecondaryParties) {
					defaultValues.secondaryPartyType = 'Entity';
				} else {
					defaultValues.secondaryPartyType = null;
				}
				defaultValues.secondaryPartyObject = null;
				defaultValues.secondaryPartyAccount = null;
				setValue('secondaryPartyType', defaultValues.secondaryPartyType, {
					shouldDirty: true,
				});
				setValue('secondaryPartyObject', defaultValues.secondaryPartyObject, {
					shouldDirty: true,
				});
				setValue('secondaryPartyAccount', defaultValues.secondaryPartyAccount, {
					shouldDirty: true,
				});

				setShowSecondaryParties(!showSecondaryParties);
				setShowSecondaryParties(!showSecondaryParties);
			}, [defaultValues, setValue, showSecondaryParties]);

			// #endregion

			return (
				<T4DrawerBase
					open={isOpen}
					initializing={loadingParties || loadingCurrencyCodes}
					loading={isLoading}
					title={`${
						forecastedTransaction ? 'Edit' : 'Create'
					} Forecasted Transaction`}
					onClose={() => {
						if (formState.isDirty) {
							setIsCancellationModalOpen(true);
						} else {
							handleDrawerClose();
						}
					}}
					actions={[
						<DrawerCancelButton
							stonlyId={stonlyIds.cancelButton}
							onCancel={() => {
								if (formState.isDirty) {
									setIsCancellationModalOpen(true);
								} else {
									handleDrawerClose();
								}
							}}
						/>,
						<DrawerSubmitButton
							stonlyId={stonlyIds.submitButton}
							label={forecastedTransaction ? 'Save' : 'Create'}
							onSubmit={handleSubmit(onSubmit)}
							disabled={!canSave || isLoading}
						/>,
					]}
					disableNavigationCollapse
				>
					<Grid container item xs={12} spacing={1}>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							{...stonlyData({ id: stonlyIds.primaryPartySection })}
						>
							{/* Primary Party */}
							<Grid container item xs={12}>
								<Accordion
									defaultExpanded
									sx={{
										height: 'fit-content !important',
										width: '100%',
										borderRadius: '4px',
										marginTop: '0px !important',
									}}
								>
									<AccordionSummary expandIcon={<ExpandMore />}>
										<Typography variant="h4">Account Information</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid spacing={1} container>
											<Grid item xs={12}>
												<Controller
													name="flowDirection"
													control={control}
													render={({ field: { onChange, value } }) => (
														<ToggleButtonGroup
															color="primary"
															value={flowDirection}
															exclusive
															onChange={(_, newValue) => {
																onChange(newValue);
																if (newValue !== value) {
																	setValue('flowDirection', newValue);
																	setValue(
																		'secondaryFlowDirection',
																		newValue === 'Inflow'
																			? 'Outflow'
																			: 'Inflow',
																	);
																}
															}}
															size="small"
															aria-label="Flow Direction"
															style={{ alignItems: 'center' }}
															{...stonlyData({
																id: stonlyIds.flowDirectionBtnGroup,
															})}
														>
															<ToggleButton
																value="Inflow"
																sx={{
																	'&.Mui-selected': {
																		backgroundColor: theme.palette.primary.main,
																		color: theme.palette.primary.contrastText,
																	},
																	'&.Mui-selected:hover': {
																		backgroundColor:
																			theme.palette.primary.light,
																		color: theme.palette.primary.contrastText,
																	},
																}}
															>
																Inflow
															</ToggleButton>
															<ToggleButton
																value="Outflow"
																sx={{
																	'&.Mui-selected': {
																		backgroundColor: theme.palette.primary.main,
																		color: theme.palette.primary.contrastText,
																	},
																	'&.Mui-selected:hover': {
																		backgroundColor:
																			theme.palette.primary.light,
																		color: theme.palette.primary.contrastText,
																	},
																}}
															>
																Outflow
															</ToggleButton>
														</ToggleButtonGroup>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="primaryPartyObject"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error },
													}) => (
														<T4Autocomplete<Party>
															id="primaryParty"
															loading={loadingParties}
															label="Entity"
															options={
																parties
																	?.filter(
																		(x) =>
																			x.type === primaryPartyType &&
																			(x.accounts?.length ?? 0) > 0,
																	)
																	?.sort((a, b) =>
																		(a.name ?? '').localeCompare(b.name ?? ''),
																	) ?? []
															}
															value={primaryPartyObject ?? null}
															onChange={(_, newValue) => {
																onChange(newValue ?? null);
																if (value?.id !== newValue?.id) {
																	setValue('primaryPartyAccount', null);
																}
															}}
															isOptionEqualToValue={(option, value) =>
																option?.id === value?.id
															}
															getOptionLabel={(option) => option.name}
															error={!!error}
															helperText={error && error.message}
															required={true}
															readOnly={!primaryPartyType}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="primaryPartyAccount"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error },
													}) => (
														<T4Autocomplete<PartyAccount>
															id="primaryParty-account"
															loading={loadingParties}
															label="Account"
															options={
																primaryPartyObject?.accounts?.filter(
																	(x) => x.id !== secondaryPartyAccount?.id,
																) ?? []
															}
															value={primaryPartyAccount ?? null}
															onChange={(_, newValue) => {
																onChange(newValue);
																if (value?.id !== newValue?.id) {
																	setValue(
																		'currencyCode',
																		newValue?.currencyCode ?? 'USD',
																	);
																}
															}}
															isOptionEqualToValue={(option, value) =>
																option?.id === value?.id
															}
															getOptionLabel={(option) => option.name}
															error={!!error}
															helperText={error && error.message}
															readOnly={!primaryPartyObject}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h4">Categorization</Typography>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="class"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error },
													}) => (
														<T4Autocomplete<CashFlowDetail>
															id="cash-flow-class"
															label="Cash Flow Class (CFC)"
															options={cashFlowClasses}
															value={classValue ?? null}
															onChange={(_, newValue) => {
																if (value?.id !== newValue?.id) {
																	onChange(newValue ?? null);
																	setValue('type', null);
																	setValue('subtype', null);
																}

																if (
																	newValue === null ||
																	newValue === undefined
																) {
																	setValue('glDetail', null);
																	setValue('glCode', null);
																}
															}}
															isOptionEqualToValue={(option, value) =>
																option.id === value.id
															}
															getOptionLabel={(option) => option.name}
															error={!!error}
															helperText={error && error.message}
															required={!!classValue}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="type"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error },
													}) => (
														<T4Autocomplete<CashFlowDetail>
															id="cash-flow-type"
															label="Cash Flow Type (CFT)"
															options={cashFlowTypes}
															value={typeValue ?? null}
															onChange={(_, newValue) => {
																if (newValue?.id !== value?.id) {
																	onChange(newValue ?? null);
																	setValue('subtype', null);
																}

																if (newValue === null || newValue === null) {
																	setValue('glDetail', null);
																	setValue('glCode', null);
																}
															}}
															isOptionEqualToValue={(option, value) =>
																option.id === value.id
															}
															getOptionLabel={(option) => option.name}
															error={!!error}
															helperText={error && error.message}
															readOnly={!classValue}
															required={!!classValue}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="subtype"
													control={control}
													render={({
														field: { onChange },
														fieldState: { error },
													}) => (
														<T4Autocomplete<CashFlowDetail>
															id="cash-flow-subtype"
															label="Cash Flow Subtype (CFST)"
															options={cashFlowSubtypes}
															value={subtypeValue ?? null}
															onChange={(_, newValue) =>
																onChange(newValue ?? null)
															}
															isOptionEqualToValue={(option, value) =>
																option.id === value.id
															}
															getOptionLabel={(option) => option.name}
															error={!!error}
															helperText={error && error.message}
															readOnly={!typeValue}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="glCode"
													control={control}
													render={({ field: { onChange } }) => (
														<T4Autocomplete<
															GeneralLedgerDetail,
															false,
															false,
															true
														>
															loading={isLoadingGlCodes}
															label="GL Code"
															options={mappedGlCodes}
															isOptionEqualToValue={(option, value) =>
																option?.id === value?.id
															}
															getOptionLabel={(option) =>
																typeof option === 'string'
																	? option
																	: option.code!
															}
															value={glDetail ?? glCode ?? null}
															inputValue={glCode ?? ''}
															onInputChange={(_, value) => {
																const foundGlCode = mappedGlCodes?.find(
																	(x) => x.code === value,
																);
																if (foundGlCode) {
																	onChange(foundGlCode.code ?? null);
																	setValue('glDetail', foundGlCode ?? null);
																} else {
																	onChange(value ?? null);
																	setValue('glDetail', null);
																}
															}}
															onChange={(_, value) => {
																if (typeof value !== 'string') {
																	onChange(null);
																	setValue('glDetail', value ?? null);
																}
															}}
															readOnly={!typeValue}
															freeSolo
															autoSelect
															autoHighlight={false}
														/>
													)}
												/>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							{/* Details */}
							<Grid
								container
								item
								xs={12}
								{...stonlyData({ id: stonlyIds.secondaryPartySection })}
							>
								<Paper
									variant="outlined"
									sx={{
										padding: '8px 16px',
										marginTop: '0px !important',
										height: '100% !important',
									}}
								>
									<Grid
										container
										item
										xs={12}
										spacing={1}
										{...stonlyData({ id: stonlyIds.detailsSection })}
									>
										<Grid item xs={12}>
											<Typography variant="h4">Details</Typography>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="label"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<T4TextFieldV2
														id="forecasted-transaction-label"
														label="Label"
														value={value ?? ''}
														onChange={(value: string) => {
															if (isStringUndefinedOrNullOrWhitespace(value)) {
																onChange(null);
															} else {
																onChange(value);
															}
														}}
														error={!!error}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={6}>
											<Controller
												name="amount"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<T4NumberInput
														id="forecasted-transaction-amount"
														label="Transaction Amount"
														min={0}
														value={value === null ? '' : Math.abs(value)}
														onChange={(event) =>
															onChange(
																event.target.value?.length > 0
																	? event.target.value.replaceAll(',', '')
																	: null,
															)
														}
														decimalScale={4}
														error={!!error}
														required
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<IconButton
																		onClick={() => {
																			setIsAmountInfoModalOpen(true);
																		}}
																	>
																		<InfoOutlinedIcon />
																	</IconButton>
																</InputAdornment>
															),
														}}
													></T4NumberInput>
												)}
											/>
										</Grid>
										<Grid item xs={6}>
											<Controller
												name="currencyCode"
												control={control}
												render={({
													field: { onChange },
													fieldState: { error },
												}) => (
													<T4Autocomplete<string, false, true>
														id="currency-code"
														loading={loadingCurrencyCodes}
														label="Transaction Currency"
														options={currencyCodes ?? []}
														value={currencyCode ?? ''}
														onChange={(_, value) => onChange(value ?? null)}
														error={!!error}
														helperText={error && error.message}
														disableClearable
														required
													/>
												)}
											/>
										</Grid>

										<Grid item xs={12}>
											<Divider sx={{ mt: 2, mb: 2 }} />
											<Controller
												name="expectedValueDate"
												control={control}
												render={({ field: { onChange, value } }) => (
													<T4DateField
														id="forecasted-transaction-expected-value-date"
														label="Expected Value Date"
														value={value ? moment(value) : null}
														onChange={(value) =>
															onChange(value?.toDate() ?? null)
														}
														required={dueDate === null}
													/>
												)}
											/>
										</Grid>

										<Grid item xs={12}>
											<Controller
												name="asOfDate"
												control={control}
												render={({ field: { onChange, value } }) => (
													<T4DateField
														id="forecasted-transaction-as-of-date"
														label="As of Date"
														value={value ? moment(value) : null}
														onChange={(value) =>
															onChange(value?.toDate() ?? null)
														}
													/>
												)}
											/>
										</Grid>

										<Grid item xs={12}>
											<Controller
												name="dueDate"
												control={control}
												render={({ field: { onChange, value } }) => (
													<T4DateField
														id="forecasted-transaction-due-date"
														label="Due Date"
														value={value ? moment(value) : null}
														onChange={(value) =>
															onChange(value?.toDate() ?? null)
														}
														required={expectedValueDate === null}
													/>
												)}
											/>
										</Grid>

										<Grid item xs={12}>
											<Controller
												name="expirationDate"
												control={control}
												render={({ field: { onChange, value } }) => (
													<T4DateField
														id="forecasted-transaction-expiration-date"
														label="Expiration Date"
														value={value ? moment(value) : null}
														onChange={(value) =>
															onChange(value?.toDate() ?? null)
														}
													/>
												)}
											/>
										</Grid>

										<Grid item xs={12}>
											<Divider sx={{ mt: 2, mb: 2 }} />
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="noteContent"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<T4TextFieldV2
														id="notes"
														label="Notes"
														value={value ?? ''}
														onChange={(value: string) => {
															if (isStringUndefinedOrNullOrWhitespace(value)) {
																onChange(null);
															} else {
																onChange(value);
															}
														}}
														minRows={4}
														maxRows={4}
														multiline
														error={!!error}
														helperText={`${value?.length ?? 0}/2048${
															error?.message ? ' ' + error.message : ''
														}`}
														inputProps={{
															maxLength: 2048,
														}}
													/>
												)}
											/>
										</Grid>

										{cash4.isAuthor && (
											<Grid item xs={12}>
												<Controller
													name="isForecastModelExcluded"
													control={control}
													render={({ field: { onChange, value } }) => (
														<>
															<IconButton
																onClick={() => {
																	setIsForecastModelExcludedModalOpen(true);
																}}
															>
																<InfoOutlinedIcon />
															</IconButton>
															<T4Checkbox
																label="Exclude from forecast reports"
																checked={isForecastModelExcluded}
																value={isForecastModelExcluded}
																onChange={(_, newValue) => {
																	onChange(newValue);
																	if (newValue !== value) {
																		setValue(
																			'isForecastModelExcluded',
																			newValue,
																		);
																	}
																}}
															/>
															<ModalBase
																title="Exclude from forecast reports"
																open={isForecastModelExcludedModalOpen}
																onClose={() => {
																	setIsForecastModelExcludedModalOpen(false);
																}}
															>
																Select to exclude the transaction from forecast
																model calculations. This is useful for removing
																one-time or non-recurring transactions that may
																skew future projections.
															</ModalBase>
														</>
													)}
												/>
											</Grid>
										)}
									</Grid>
								</Paper>
							</Grid>
							{/* Secondary Party */}
							<Grid item xs={12}>
								<Collapse orientation="vertical" in={!showSecondaryParties}>
									<Button
										startIcon={<Add />}
										type="button"
										variant="outlined"
										color="primary"
										{...stonlyData({
											id: stonlyIds.addSecondaryPartyButton,
										})}
										onClick={handleAddSecondaryPartyButtonClick}
									>
										Secondary Party Information
									</Button>
								</Collapse>
								<Collapse orientation="vertical" in={showSecondaryParties}>
									<Accordion
										defaultExpanded
										sx={{
											height: 'fit-content !important',
											width: '100%',
											borderRadius: '4px',
											marginTop: '0px !important',
										}}
									>
										<AccordionSummary expandIcon={<ExpandMore />}>
											<Typography variant="h4">
												Secondary Party Information
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid spacing={1} container>
												<Grid item xs={12}>
													<ToggleButtonGroup
														color="primary"
														value={secondaryFlowDirection}
														exclusive
														disabled
														size="small"
														aria-label="Secondary Flow Direction"
														style={{ alignItems: 'center' }}
														{...stonlyData({
															id: stonlyIds.secondaryFlowDirectionBtnGroup,
														})}
													>
														<ToggleButton
															value="Inflow"
															sx={{
																'&.Mui-selected': {
																	backgroundColor: theme.palette.primary.main,
																	color: theme.palette.primary.contrastText,
																},
																'&.Mui-selected:hover': {
																	backgroundColor: theme.palette.primary.light,
																	color: theme.palette.primary.contrastText,
																},
															}}
														>
															Inflow
														</ToggleButton>
														<ToggleButton
															value="Outflow"
															sx={{
																'&.Mui-selected': {
																	backgroundColor: theme.palette.primary.main,
																	color: theme.palette.primary.contrastText,
																},
																'&.Mui-selected:hover': {
																	backgroundColor: theme.palette.primary.light,
																	color: theme.palette.primary.contrastText,
																},
															}}
														>
															Outflow
														</ToggleButton>
													</ToggleButtonGroup>
												</Grid>
												<Grid item xs={12}>
													<Controller
														name="secondaryPartyType"
														control={control}
														render={({
															field: { onChange, value },
															fieldState: { error },
														}) => (
															<T4Autocomplete<string>
																id="secondaryPartyType"
																label="Secondary Party Type"
																options={['Entity', 'Partner', 'Counterparty']}
																value={secondaryPartyType ?? null}
																onChange={(_, newValue) => {
																	if (value !== newValue) {
																		onChange(newValue ?? null);
																		setValue('secondaryPartyObject', null);
																		setValue('secondaryPartyAccount', null);
																	}
																}}
																error={!!error}
																helperText={error && error.message}
																required={secondaryPartyType === 'Entity'}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Controller
														name="secondaryPartyObject"
														control={control}
														render={({
															field: { onChange, value },
															fieldState: { error },
														}) => (
															<T4Autocomplete<Party>
																id="secondaryParty"
																loading={loadingParties}
																label="Entity"
																options={
																	parties
																		?.filter(
																			(x) =>
																				x.type === secondaryPartyType &&
																				(x.type !== 'Entity' ||
																					(x.accounts?.length ?? 0) > 0),
																		)
																		?.sort((a, b) =>
																			(a.name ?? '').localeCompare(
																				b.name ?? '',
																			),
																		) ?? []
																}
																value={secondaryPartyObject ?? null}
																onChange={(_, newValue) => {
																	onChange(newValue);
																	if (value?.id !== newValue?.id) {
																		setValue('secondaryPartyAccount', null);
																	}
																}}
																isOptionEqualToValue={(option, value) =>
																	option?.id === value?.id
																}
																getOptionLabel={(option) => option.name}
																error={!!error}
																helperText={error && error.message}
																required={secondaryPartyType === 'Entity'}
																readOnly={!secondaryPartyType}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Controller
														name="secondaryPartyAccount"
														control={control}
														render={({
															field: { onChange },
															fieldState: { error },
														}) => (
															<T4Autocomplete<PartyAccount>
																id="secondaryParty-account"
																loading={loadingParties}
																label="Account"
																options={
																	secondaryPartyObject?.accounts?.filter(
																		(x) => x.id !== primaryPartyAccount?.id,
																	) ?? []
																}
																value={secondaryPartyAccount ?? null}
																onChange={(_, newValue) => {
																	onChange(newValue);
																}}
																isOptionEqualToValue={(option, value) =>
																	option?.id === value?.id
																}
																getOptionLabel={(option) => option.name}
																error={!!error}
																helperText={error && error.message}
																readOnly={!secondaryPartyObject}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Button
														startIcon={<DeleteOutline />}
														type="button"
														variant="outlined"
														color="error"
														onClick={handleAddSecondaryPartyButtonClick}
														{...stonlyData({
															id: stonlyIds.removeSecondaryPartyButton,
														})}
													>
														Remove Secondary Party
													</Button>{' '}
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Collapse>
							</Grid>
						</Grid>
					</Grid>
					<CancellationModal
						isOpen={isCancellationModalOpen}
						resourceType="Forecasted Transaction"
						variant={forecastedTransaction ? 'edit' : 'create'}
						onClose={() => setIsCancellationModalOpen(false)}
						onSubmit={() => handleDrawerClose()}
					/>

					<TransactionAmountInfoModal
						isOpen={isAmountInfoModalOpen}
						onCancel={() => {
							setIsAmountInfoModalOpen(false);
						}}
					/>
				</T4DrawerBase>
			);
		},
	);
