import { Read, T4ApiResponse } from 'modules/clients/types';
import { E4ObjectBase } from '..';
import { Counterparty } from '../counterparties';
import { AxiosInstance } from 'axios';

export type AccountEndpoints = {
	get: Read<T4ApiResponse<Account>, string>;
	getAll: Read<T4ApiResponse<Account[]>>;
};

// #region Models

export type Account = E4ObjectBase & {
	shortName: string | null;
	currencyCode: string | null;
	accountNumbers: AccountNumbers;
	heldWithCounterparty: Counterparty | null;
};

export type AccountNumbers = {
	naturalAccountNumber: string | null;
	pain001: string | null;
};

// #endregion

export function accounts(axiosInstance: AxiosInstance): AccountEndpoints {
	return {
		get: async (id, config = {}) => {
			return await axiosInstance.get(`entity4/accounts/${id}`, {
				...config,
			});
		},
		getAll: async (_, config = {}) => {
			return await axiosInstance.get('entity4/accounts', {
				...config,
			});
		},
	};
}
