import {
	useT4Query,
	UseT4QueryProps,
} from 'features/cash4/_shared/_utilities/useT4Query';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseDataRefreshIntervalProps = {
	query: UseT4QueryProps<number>;
	updateInterval: (interval: number) => Promise<void>;
};

export function useDataRefreshInterval(): UseDataRefreshIntervalProps {
	const { customerApiClient } = useClients();

	const getInterval = useCallback(async () => {
		const response =
			await customerApiClient.api.administration.analyticStudioConfigurations.getInterval();

		if (response.status === 200 && response?.data?.data !== undefined) {
			return response.data.data;
		}

		throw new Error('Failed to get data refresh interval.');
	}, [customerApiClient.api.administration.analyticStudioConfigurations]);
	const intervalQuery = useT4Query(getInterval, {
		errorMessage: 'Failed to get data refresh interval.',
	});

	const updateInterval = useCallback(
		async (interval: number) => {
			const response =
				await customerApiClient.api.administration.analyticStudioConfigurations.updateInterval(
					interval,
				);

			if (response.status === 200 && response?.data?.data !== undefined) {
				intervalQuery.refetch();
			} else {
				throw new Error('Failed to update data refresh interval.');
			}
		},
		[
			customerApiClient.api.administration.analyticStudioConfigurations,
			intervalQuery,
		],
	);

	return {
		query: intervalQuery,
		updateInterval: updateInterval,
	};
}
