/* eslint-disable mobx/missing-observer */
import {
	CheckCircleOutline,
	HighlightOff,
	InfoOutlined,
} from '@mui/icons-material';
import {
	CircularProgress,
	ClickAwayListener,
	IconButton,
	IconButtonProps,
	InputAdornment,
	Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';

export type AdornmentType = 'loading' | 'success' | 'info' | 'error';

export const TooltipAdornment: FC<{
	adornmentType?: AdornmentType;
	text: string;
	iconButtonProps?: Partial<IconButtonProps>;
	tooltipPlacement?:
		| 'bottom'
		| 'left'
		| 'right'
		| 'top'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start';
	hover?: boolean;
}> = ({
	adornmentType = 'info',
	text,
	iconButtonProps,
	tooltipPlacement,
	hover,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<InputAdornment position="start">
			<ClickAwayListener onClickAway={() => setIsOpen(false)}>
				<Tooltip
					open={isOpen}
					title={text}
					placement={tooltipPlacement}
					disableFocusListener={!hover}
					disableHoverListener={!hover}
					disableTouchListener
					onClose={hover ? () => setIsOpen(false) : undefined}
					onOpen={hover ? () => setIsOpen(true) : undefined}
					arrow
				>
					<IconButton
						size="small"
						{...iconButtonProps}
						onClick={
							!hover
								? () => (isOpen ? setIsOpen(false) : setIsOpen(true))
								: undefined
						}
					>
						{adornmentType === 'loading' ? (
							<CircularProgress size={20} />
						) : adornmentType === 'success' ? (
							<CheckCircleOutline color="primary" />
						) : adornmentType === 'error' ? (
							<HighlightOff color="error" />
						) : (
							<InfoOutlined />
						)}
					</IconButton>
				</Tooltip>
			</ClickAwayListener>
		</InputAdornment>
	);
};
