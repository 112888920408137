import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { CannotDisplay } from '../../../shared/components/cannotDisplay';
import { ACCESS_DENIED_MESSAGING } from '../../../shared/constants/cannotDisplayMessaging';
import { UserEntitlementsListViewModel } from './userEntitlementsListViewModel';

export const UserEntitlementsPage: FC = observer(() => {
	const [viewModel] = useState(new UserEntitlementsListViewModel());
	const { isAdmin } = useUser();

	if (!isAdmin) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}

	return <InnerUserEntitlementsListPage viewModel={viewModel} />;
});

interface IUserEntitlementsListPageProps {
	viewModel: UserEntitlementsListViewModel;
}

const InnerUserEntitlementsListPage: FC<IUserEntitlementsListPageProps> =
	observer(({ viewModel }) => {
		useEffect(() => {
			viewModel.loadUserEntitlements();
		}, [viewModel]);

		return (
			<T4View
				header={
					<PageHeader
						id={pageHeaderStonlyIds.userEntitlementsList}
						title="User Entitlements"
						breadcrumbs={
							<BreadcrumbList
								breadcrumbs={[
									{
										label: 'Administration',
										href: paths.administration.href,
									},
								]}
							/>
						}
					/>
				}
			>
				<ActuallyPrettyGoodDataGridWrapper>
					<UserPreferencesDataGrid
						tableKey="userEntitlementsList"
						stonlyId="user-entitlements"
						columns={viewModel.getColumns()}
						rows={viewModel.getRows()}
						loading={viewModel.getLoading()}
						errorMessage={viewModel.getError()}
						density="compact"
						pagination
						showToolbar
						showCustomViewButton
						initialState={{
							sorting: {
								sortModel: [{ field: 'id', sort: 'asc' }],
							},
						}}
					/>
				</ActuallyPrettyGoodDataGridWrapper>
			</T4View>
		);
	});
