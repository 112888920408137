import { useQuery } from '@tanstack/react-query';
import {
	CashFlowClass,
	CashFlowSubtype,
	CashFlowType,
} from 'features/cash4/categories/categoriesViewModel';
import { observer } from 'mobx-react-lite';
import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import {
	GLCode,
	OperatorMatchOperations,
	RulePredicate,
	TransactionRuleListItem,
} from '../../models';
import {
	fetchCategoriesForRules,
	fetchGlCodes,
	fetchOperatorMatchOperations,
	fetchRulePredicates,
} from '../../services';

import { ProjectedTransactionSourceDefinitions } from 'modules/clients/customer-api/src/api/cash4';
import { useClients } from 'shared/hooks/useClients';

//#region Context

type DataContextProps = {
	rule: TransactionRuleListItem | null | undefined;
	setRule: (rule: TransactionRuleListItem | null | undefined) => void;
	categoriesError: boolean;
	categories: CashFlowClass[];
	types: (classId: string) => CashFlowType[];
	subtypes: (typeId: string) => CashFlowSubtype[];
	isLoadingCategories: () => boolean;
	glCodes: GLCode[];
	isLoadingGlCodes: boolean;
	rulePredicates: RulePredicate[];
	isLoadingRulePredicates: boolean;
	isLoadingOperatorMatchOperations: boolean;
	fields: string[];
	refetchGlCodes: () => void;
	getOperatorOperations(operator: string): string[];
	operators: (field: string) => string[];
	findCashFlowClass: (classId: string) => CashFlowClass;
	findCashFlowType: (classId: string, typeId: string) => CashFlowType;
	findCashFlowSubtype: (typeId: string, subtypeId: string) => CashFlowSubtype;
	projectedTransactionSourceDefinitions:
		| ProjectedTransactionSourceDefinitions
		| undefined;
};

const DataContext = createContext<DataContextProps | null>(null);

//#endregion

//#region Provider

export type DataProviderProps = {
	children: React.ReactNode;
};

export const DataProvider: FC<DataProviderProps> = observer(({ children }) => {
	const { customerApiClient } = useClients();

	const { data: categories, isError: categoriesError } = useQuery(
		['categories'],
		fetchCategoriesForRules,
		{
			initialData: [],
			refetchOnWindowFocus: false,
		},
	);

	const {
		data: glCodes,
		isLoading: isLoadingGlCodes,
		refetch: refetchGlCodes,
	} = useQuery(['glCodes'], fetchGlCodes, {
		initialData: [],
		refetchOnWindowFocus: false,
	});

	const { data: rulePredicates, isLoading: isLoadingRulePredicates } = useQuery(
		['rulePredicates'],
		fetchRulePredicates,
		{
			initialData: [],
			refetchOnWindowFocus: false,
		},
	);

	const {
		data: operatorMatchOperations,
		isLoading: isLoadingOperatorMatchOperations,
	} = useQuery(['operatorMatchOperations'], fetchOperatorMatchOperations, {
		initialData: [],
		refetchOnWindowFocus: false,
	});

	const [rule, setRule] = useState<TransactionRuleListItem | null | undefined>(
		null,
	);

	const types = useCallback(
		(classId: string) => categories?.find((x) => x.id === classId)?.types ?? [],
		[categories],
	);

	const subtypes = useCallback(
		(typeId: string) =>
			categories?.flatMap((x) => x.types).find((x) => x.id === typeId)
				?.subtypes ?? [],
		[categories],
	);

	const fields = useMemo(
		() => rulePredicates?.map((x: RulePredicate) => x.field).sort() ?? [],
		[rulePredicates],
	);

	const operators = useCallback(
		(field: string) =>
			rulePredicates?.find((x: RulePredicate) => x.field === field)
				?.operators ?? [],
		[rulePredicates],
	);

	const getOperatorOperations = useCallback(
		(operator: string) => {
			return (
				operatorMatchOperations?.find(
					(x: OperatorMatchOperations) => x.operator === operator,
				)?.operations ?? []
			);
		},
		[operatorMatchOperations],
	);

	const findCashFlowClass = useCallback(
		(classId: string): CashFlowClass => {
			const foundClass = categories.find((x) => x.id === classId);
			if (!foundClass) {
				throw new Error(`CashFlowClass not found for classId: ${classId}`);
			}
			return foundClass;
		},
		[categories],
	);

	const findCashFlowType = useCallback(
		(classId: string, typeId: string): CashFlowType => {
			const foundType = types(classId).find((x) => x.id === typeId);
			if (!foundType) {
				throw new Error(
					`CashFlowType not found for typeId: ${typeId} in classId: ${classId}`,
				);
			}
			return foundType;
		},
		[types],
	);

	const findCashFlowSubtype = useCallback(
		(typeId: string, subtypeId: string): CashFlowSubtype => {
			const foundSubtype = subtypes(typeId).find((x) => x.id === subtypeId);
			if (!foundSubtype) {
				throw new Error(
					`CashFlowSubtype not found for subtypeId: ${subtypeId} in typeId: ${typeId}`,
				);
			}
			return foundSubtype;
		},
		[subtypes],
	);

	const isLoadingCategories = useCallback((): boolean => {
		return !categories.length;
	}, [categories.length]);

	const { data: projectedTransactionSourceDefinitions } = useQuery(
		['projectedTransactionSourceDefinitions'],
		customerApiClient.api.cash4.getProjectedTransactionSourceDefinitions,
		{
			initialData: undefined,
			refetchOnWindowFocus: false,
		},
	);

	return (
		<DataContext.Provider
			value={{
				rule: rule,
				setRule: setRule,
				categories: categories,
				categoriesError: categoriesError,
				types: types,
				subtypes: subtypes,
				isLoadingCategories: isLoadingCategories,
				glCodes: glCodes,
				isLoadingGlCodes: isLoadingGlCodes,
				rulePredicates: rulePredicates,
				isLoadingRulePredicates: isLoadingRulePredicates,
				isLoadingOperatorMatchOperations: isLoadingOperatorMatchOperations,
				fields: fields,
				refetchGlCodes: refetchGlCodes,
				operators: operators,
				getOperatorOperations: getOperatorOperations,
				findCashFlowClass: findCashFlowClass,
				findCashFlowType: findCashFlowType,
				findCashFlowSubtype: findCashFlowSubtype,
				projectedTransactionSourceDefinitions:
					projectedTransactionSourceDefinitions,
			}}
		>
			{children}
		</DataContext.Provider>
	);
});

//#endregion

//#region Hook

export type UseDataContextProps = DataContextProps;

export function useDataContext(): UseDataContextProps {
	const context = useContext(DataContext);
	if (context === null) {
		throw new Error('useDataContext must be used within a DataProvider');
	}
	return context;
}

//#endregion
