import { useQuery } from '@tanstack/react-query';
import {
	Account,
	LegalEntity,
} from 'modules/clients/apiGateway/payments4/bankConnections';
import { T4DataResponse2 } from 'modules/clients/types';
import { useClients } from 'shared/hooks/useClients';
import { BankConnectionQueryKeyBase } from './constants';

export const BankConnectionObjectQueryKeys = {
	getLegalEntities: () =>
		[...BankConnectionQueryKeyBase, 'legal-entities', 'get-all'] as const,
	getAccounts: () =>
		[...BankConnectionQueryKeyBase, 'accounts', 'get-all'] as const,
};

export const useGetBankConnectionLegalEntities = (
	isEnabled: boolean = true,
) => {
	const { applicationApiClient } = useClients();

	return useQuery<LegalEntity[], Error>(
		BankConnectionObjectQueryKeys.getLegalEntities(),
		async () => {
			const response =
				await applicationApiClient.payments4.bankConnections.getAllLegalEntities();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<LegalEntity[]>).data.sort(
					(a, b) => a.displayName.localeCompare(b.displayName),
				);
			else throw new Error('Unable to load legal entities.');
		},
		{
			refetchOnWindowFocus: false,
			enabled: isEnabled,
		},
	);
};

export const useGetBankConnectionAccounts = (isEnabled: boolean = true) => {
	const { applicationApiClient } = useClients();

	return useQuery<Account[], Error>(
		BankConnectionObjectQueryKeys.getAccounts(),
		async () => {
			const response =
				await applicationApiClient.payments4.bankConnections.getAllAccounts();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<Account[]>).data.sort((a, b) =>
					a.displayName.localeCompare(b.displayName),
				);
			else throw new Error('Unable to load accounts.');
		},
		{
			refetchOnWindowFocus: false,
			enabled: isEnabled,
		},
	);
};
