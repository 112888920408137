/* eslint-disable mobx/missing-observer */
import { Divider } from '@mui/material';
import { E4AccountLink } from 'features/cash4/shared/components/T4SideDrawer/E4AccountLink';
import T4BlueHeader from 'features/cash4/shared/components/T4SideDrawer/T4BlueHeader';
import T4DetailBox, {
	T4DetailBoxProps,
} from 'features/cash4/shared/components/T4SideDrawer/T4DetailBox';
import T4TopBar from 'features/cash4/shared/components/T4SideDrawer/T4TopBar';
import { FC, useMemo } from 'react';
import { paths } from 'shared/constants/paths';
import {
	convertDate,
	formatDateSimpleUpperCase,
	formatReadDate,
} from 'shared/utilities/dateUtilities';
import { formatCurrency } from 'utilities/currencyUtils';
import { Action, Actions } from '../../../shared/components/T4ActionMenu';
import { TransactionListItem } from '../../models';
import {
	formattedExchangeRate,
	getTransactionCopyContentValue,
} from '../../utilities';

export type TransactionDetailsProps = {
	transaction: TransactionListItem;
	disableLink?: boolean;
	transactionActions?: Action[];
};

export const TransactionDetails: FC<TransactionDetailsProps> = ({
	transaction,
	disableLink,
	transactionActions,
}) => {
	const formattedFxDate = useMemo(
		() =>
			transaction.number.reportingCurrencyEffectiveDate
				? formatDateSimpleUpperCase(
						transaction.number.reportingCurrencyEffectiveDate,
				  )
				: null,
		[transaction.number.reportingCurrencyEffectiveDate],
	);

	const actions = useMemo(() => {
		return (
			<Actions
				objecttype="Transaction"
				stonlyId={'cash4-transaction-details-context-menu'}
				actions={transactionActions!}
				id="transactionId-more-menu"
			/>
		);
	}, [transactionActions]);

	const details = useMemo<T4DetailBoxProps['details']>(() => {
		const values = [
			{
				type: 'bold',
				label: 'Account',
				value: transaction.e4AccountNumber,
				shouldDisplay: !!transaction.e4AccountNumber,
			},
			{
				type: 'bold',
				label: 'Cash4 Account',
				value: transaction.c4AccountNumber,
				shouldDisplay: !!transaction.c4AccountNumber,
			},
			{
				type: 'bold',
				label: 'Counterparty Name',
				value: transaction.bankName,
				shouldDisplay: !!transaction.bankName,
			},
			{
				type: 'bold',
				label: 'Counterparty Code',
				value: transaction.bankCode,
				shouldDisplay: !!transaction.bankCode,
			},
			{
				type: 'bold',
				label: 'Post Date',
				value: formatReadDate(convertDate(transaction.date)) ?? null,
				shouldDisplay: !!transaction.date,
			},
			<Divider sx={{ mt: 2, mb: 2 }} />,
			{
				type: 'bold',
				label: 'Transaction Code',
				value: transaction.transactionCode,
				shouldDisplay: !!transaction.transactionCode,
			},
			{
				type: 'bold',
				label: 'Customer Reference',
				value: transaction.customerReference,
				shouldDisplay: !!transaction.customerReference,
			},
			{
				type: 'bold',
				label: 'Bank Reference',
				value: transaction.bankReference,
				shouldDisplay: !!transaction.bankReference,
			},
			{
				type: 'bold',
				label: 'Check Number',
				value: transaction.checkNumber,
				shouldDisplay: !!transaction.checkNumber,
			},
			{
				type: 'bold',
				label: 'Transaction Detail',
				value: transaction.detail,
				shouldDisplay: !!transaction.detail,
				lineBreak: true,
			},
			<Divider sx={{ mt: 2, mb: 2 }} />,
			{
				type: 'bold',
				label: 'Amount',
				value: `${formatCurrency(transaction.number.accountCurrencyAmount, {
					currency: transaction.number.accountCurrencyCode,
				})}  ${transaction.number.accountCurrencyCode.toUpperCase()}`,
				shouldDisplay: !!transaction.number.accountCurrencyAmount,
			},
			{
				type: 'bold',
				label: 'Reporting Ccy Amount',
				value: `${formatCurrency(transaction.number.reportingCurrencyAmount, {
					currency: transaction.number.reportingCurrencyCode,
				})} ${transaction.number.reportingCurrencyCode.toUpperCase()}`,
				shouldDisplay: !!transaction.number.reportingCurrencyAmount,
			},
			{
				type: 'bold',
				label: 'Foreign Exchange (FX) Rate',
				value: formattedExchangeRate(transaction.number.reportingCurrencyRate),
				shouldDisplay: !!transaction.number.reportingCurrencyRate,
			},
			{
				type: 'bold',
				label: 'Foreign Exchange (FX) Date',
				value: formattedFxDate,
				shouldDisplay: !!transaction.number.reportingCurrencyEffectiveDate,
			},
		];

		return values as T4DetailBoxProps['details'];
	}, [formattedFxDate, transaction]);

	return (
		<>
			<T4TopBar
				title="Reported Transaction Details"
				copyText={getTransactionCopyContentValue(transaction)}
				copyUrl={`${window.location.origin}${paths.cash4.transactions.href}/${transaction.id}?tab=reported`}
				stonlyPrefix="transaction-details"
				disableLink={disableLink}
				customActionDropdown={actions}
			/>
			<T4BlueHeader
				bigNumber={transaction.number.accountCurrencyAmount}
				currency={transaction.number.accountCurrencyCode}
				subheadlines={[
					transaction?.e4AccountType === 'Subaccount' ? (
						transaction?.e4AccountName
					) : (
						<E4AccountLink
							e4AccountId={transaction?.e4AccountId}
							e4AccountName={transaction?.e4AccountName}
							stonlyPrefix="transaction-details"
						/>
					),
					transaction.bankCode,
					formatReadDate(convertDate(transaction.date)) ?? null,
				]}
			/>
			<T4DetailBox details={details} />
		</>
	);
};
