import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { EntityProfileProvider } from '../entityProfile/providers/entityProfileContextProvider';
import { ObjectsPage } from '../listPage/components/objectsPage';
import { PowerOfAttorneyProvider } from '../relationships/providers/powerOfAttorneysProvider';
import { ObjectRoutes } from './object/objectRoutes';
import { AccountGroupsProvider } from 'shared/providers/accountGroupsProvider';

export const ObjectsRoutes: FC = observer(() => (
	<Switch>
		<Route exact path={paths.entity4.objects.href}>
			<AccountGroupsProvider>
				<ObjectsPage />
			</AccountGroupsProvider>
		</Route>
		<Route path={paths.entity4.objects.object.href.concat(validIdRegex)}>
			<EntityProfileProvider>
				<PowerOfAttorneyProvider>
					<ObjectRoutes />
				</PowerOfAttorneyProvider>
			</EntityProfileProvider>
		</Route>

		<Route>
			<CannotDisplay
				headingText={NOT_FOUND_MESSAGING.HEADING}
				bodyText={NOT_FOUND_MESSAGING.BODY}
				imageSrc={NOT_FOUND_MESSAGING.IMAGE}
				buttonText={RETURN_TO_HOME}
				buttonHref={paths.root.href}
			/>
		</Route>
	</Switch>
));
