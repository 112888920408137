/* eslint-disable mobx/missing-observer */
import { Alert, Collapse, Grid, Typography } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getBankConnectionSpecification } from './utilities';

export const ConnectionSettingsForm: FC<{
	certificateNames: string[];
}> = ({ certificateNames }) => {
	const { control, watch } = useFormContext();

	const bank = watch('bank');

	const fieldDefinitions = useMemo(
		() =>
			getBankConnectionSpecification(bank?.bankCode)?.fieldDefinitions ?? [],
		[bank],
	);

	if (bank !== null && bank !== undefined && fieldDefinitions.length === 0)
		return (
			<Alert sx={{ '&.MuiPaper-root': { margin: 0 } }} severity="warning">
				No connection settings have been set up for this bank integration yet.
				Contact your administrator for more information.
			</Alert>
		);

	return (
		<Collapse in={!!bank} sx={{ width: '100%' }}>
			<Grid
				container
				item
				xs={12}
				sx={(theme) => ({
					gap: 1,
					padding: '0.75rem',
					border: 'solid',
					borderWidth: '1px',
					borderRadius: '4px',
					borderColor: theme.palette.charcoal[50],
				})}
			>
				<Grid item xs={12}>
					<Typography variant="h4">Connection Settings</Typography>
				</Grid>
				<Grid item container xs={12} sx={{ gap: 2 }}>
					{fieldDefinitions.map((field, index) => {
						if (field.type === 'certificate')
							return (
								<Grid item xs={12} key={field.key}>
									<Controller
										name={`connectionSettings.${field.key}`}
										control={control}
										render={({
											field: { onChange, value },
											fieldState: { error },
										}) => (
											<T4Autocomplete<string>
												id={field.key}
												label={field.name}
												value={value ?? null}
												options={certificateNames}
												onChange={(_, value) => onChange(value)}
												error={!!error}
												helperText={error && error.message}
												required
											/>
										)}
									/>
								</Grid>
							);

						return (
							<Grid item xs={12} key={field.key}>
								<Controller
									name={`connectionSettings.${field.key}`}
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4TextFieldV2
											id={field.key}
											label={field.name}
											value={value ?? ''}
											onChange={onChange}
											error={!!error}
											helperText={error && error.message}
											isSecret={field.isSecret}
											required
										/>
									)}
								/>
							</Grid>
						);
					})}
				</Grid>
			</Grid>
		</Collapse>
	);
};
