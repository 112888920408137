import {
	DashboardCustomize,
	SettingsInputComponent,
} from '@mui/icons-material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import GroupsIcon from '@mui/icons-material/Groups';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import TuneIcon from '@mui/icons-material/Tune';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import {
	PageHeader,
	pageHeaderStonlyIds,
} from '../../../shared/components/pageHeader';
import { AdministrationCard } from '../_shared/_components/administrationCard';

export const AdministrationPage: FC = observer(() => {
	const theme = useTheme();
	const { isAdmin, cash4, payments4 } = useUser();
	const { cash4Enabled, payments4Module, customerSuccess } =
		useT4FeatureFlags();
	const history = useHistory();

	const adminCards = useMemo(() => {
		const globalSettings = [
			{
				id: 'legal-entity-groups',
				icon: (
					<DashboardCustomize
						sx={{
							fontSize: '64px',
							color: theme.palette.secondaryHeader?.main,
						}}
					/>
				),
				title: 'Entity Groups',
				description:
					'Create and manage entity groups that can be filtered and reported on based on entity relationships.',
				buttonText: 'View Entity Groups',
				link: () => {
					if (isAdmin) {
						history.push(paths.administration.legalEntityGroups.href);
					}
				},
				isDisabled: !isAdmin,
			},
			{
				id: 'account-groups',
				icon: (
					<DashboardCustomize
						sx={{
							fontSize: '64px',
							color: theme.palette.secondaryHeader?.main,
						}}
					/>
				),
				title: 'Account Groups',
				description:
					'Create and manage account groups that can be filtered and reported on.',
				buttonText: 'View Account Groups',
				link: () => {
					if (isAdmin) {
						history.push(paths.administration.accountGroups.href);
					}
				},
				isDisabled: !isAdmin,
			},
			{
				id: 'security-groups',
				icon: (
					<GroupsIcon
						sx={{
							fontSize: '64px',
							color: theme.palette.secondaryHeader?.main,
						}}
					/>
				),
				title: 'Security Groups',
				description:
					'Limit access to entities within the system by creating groups and associating users.',
				buttonText: 'View Security Groups',
				link: () => {
					if (isAdmin) {
						history.push(paths.administration.groups.href);
					}
				},
				isDisabled: !isAdmin,
			},
			{
				id: 'manage configurations',
				icon: (
					<TuneIcon
						sx={{
							fontSize: '64px',
							color: theme.palette.secondaryHeader?.main,
						}}
					/>
				),
				title: 'Configurations',
				description: 'Set organizational configuration settings.',
				buttonText: 'Manage Configurations',
				link: () => {
					if (isAdmin) {
						history.push(paths.administration.securityGroupApprovals.href);
					}
				},
				isDisabled: !isAdmin,
			},
		];

		if (customerSuccess) {
			globalSettings.push({
				id: 'analytic-studio-configurations',
				icon: (
					<TuneIcon
						sx={{
							fontSize: '64px',
							color: theme.palette.secondaryHeader?.main,
						}}
					/>
				),
				title: 'Analytic Studio Configurations',
				description: 'Analytic studio configuration settings.',
				buttonText: 'Manage Configurations',
				link: () => {
					if (isAdmin) {
						history.push(
							paths.administration.analyticStudioConfigurations.href,
						);
					}
				},
				isDisabled: !isAdmin,
			});
		}

		globalSettings.push(
			...[
				{
					id: 'user-entitlement-report',
					icon: (
						<VerifiedUserIcon
							sx={{
								fontSize: '64px',
								color: theme.palette.secondaryHeader?.main,
							}}
						/>
					),
					title: 'User Entitlement Report',
					description:
						'View user entitlements for roles and security groups access.',
					buttonText: 'View Report',
					link: () => {
						if (isAdmin) {
							history.push(paths.administration.userEntitlements.href);
						}
					},
					isDisabled: !isAdmin,
				},
			],
		);

		let cards = [
			{
				title: 'Global Settings',
				components: globalSettings,
			},
			{
				title: 'Entity4 Settings',
				components: [
					{
						id: 'audit-log',
						icon: (
							<ViewListOutlinedIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						),
						title: 'Audit Log',
						description:
							'Field level changes, along with user and date information, presented in an easy to read format that can be filtered and exported.',
						buttonText: 'View Audit Log',
						link: () => {
							if (isAdmin) {
								history.push(paths.administration.auditLog.href);
							}
						},
						isDisabled: !isAdmin,
					},
					{
						id: 'approvals',
						icon: (
							<RuleFolderIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						),
						title: 'Approvals',
						description:
							'Update organizational configurations for approvals and workflows.',
						buttonText: 'Manage Approvals',
						link: () => {
							if (isAdmin) {
								history.push(paths.administration.entity4Configurations.href);
							}
						},
						isDisabled: !isAdmin,
					},
				],
			},
		];

		if (cash4Enabled) {
			cards.push({
				title: 'Cash4 Settings',
				components: [
					{
						id: 'cash4-configurations',
						icon: (
							<TuneIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						),
						title: 'Configurations',
						description: 'Set Cash4 module configuration settings.',
						buttonText: 'Manage Configurations',
						link: () => {
							if (isAdmin && cash4.isAuthor) {
								history.push(paths.administration.cash4Configuration.href);
							}
						},
						isDisabled: !isAdmin || !cash4.isAuthor,
					},
				],
			});
		}

		if (payments4Module) {
			cards.push({
				title: 'Payments4 Settings',
				components: [
					{
						id: 'payments-approvals-management',
						icon: (
							<AltRouteIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						),
						title: 'Payment Approvals Management',
						description:
							'Create approval rules with tiered limits and approval levels to ensure efficient and controlled payment authorization.',
						buttonText: 'Manage Payment Approvals',
						link: () => {
							if (payments4.isAdmin) {
								history.push(
									paths.administration.payments4.paymentApprovalsManagement
										.href,
								);
							}
						},
						isDisabled: !payments4.isAdmin,
					},
					{
						id: 'payments-connection-management',
						icon: (
							<SettingsInputComponent
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						),
						title: 'Payment Connection Management',
						description:
							'Configure and manage payment connections, including certifications and credentials for secure bank integrations and payment processing.',
						buttonText: 'Manage Connections',
						link: () => {
							if (payments4.isAdmin) {
								history.push(
									paths.administration.payments4.paymentConnectionManagement
										.href,
								);
							}
						},
						isDisabled: !payments4.isAdmin,
					},
				],
			});
		}

		return cards;
	}, [
		theme.palette.secondaryHeader?.main,
		isAdmin,
		customerSuccess,
		cash4Enabled,
		payments4Module,
		history,
		cash4.isAuthor,
		payments4.isAdmin,
	]);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.administrationView}
					title="Administration"
				/>
			}
		>
			<Container>
				<Grid container sx={{ gap: 4, paddingBottom: '2rem' }}>
					{adminCards.map((x) => {
						const key = x.title.toLowerCase().replace(' ', '-');
						return (
							<Grid key={key} container item xs={12} spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h2">{x.title}</Typography>
								</Grid>
								{x.components
									.sort((a, b) => a.title.localeCompare(b.title))
									.map((component, index) => (
										<Grid
											key={`${key}-${index}`}
											item
											xs={12}
											sm={12}
											md={6}
											lg={4}
										>
											<AdministrationCard
												cardId={component.id}
												cardIcon={component.icon}
												cardTitle={component.title}
												cardDescription={component.description}
												cardButtonText={component.buttonText}
												cardLink={component.link}
												disabled={component.isDisabled}
											/>
										</Grid>
									))}
							</Grid>
						);
					})}
				</Grid>
			</Container>
		</T4View>
	);
});
