import {
	useT4Query,
	UseT4QueryProps,
} from 'features/cash4/_shared/_utilities/useT4Query';
import { Account } from 'modules/clients/customer-api/src/api/administration/accountGroupCollections';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseAccountsQueryProps = UseT4QueryProps<Account[]>;

export function useAccountsQuery() {
	const { customerApiClient } = useClients();

	const getAccounts = useCallback(async () => {
		const response = await customerApiClient.api.administration.accounts();

		return response?.data?.data ?? [];
	}, [customerApiClient.api.administration]);

	return useT4Query(getAccounts, {
		errorMessage: 'Unable to get accounts.',
	});
}
