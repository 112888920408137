import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { Layout } from 'shared/components/layout';
import { Navbar } from 'shared/components/navbar';
import { NavigationItemProps } from 'shared/components/navigation/navigationItem';
import { NavigationMenu } from 'shared/components/navigation/navigationMenu';
import { NavigationSectionProps } from 'shared/components/navigation/navigationSection';
import SolutionPicker from 'shared/components/solutionPicker/solutionPicker';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { T4Solution } from 'shared/t4Solutions';
import { AccountGroupsPage } from './accountGroups/accountGroupsPage';
import { AnalyticStudioConfigurationsPage } from './analyticStudioConfigurations/analyticStudioConfigurationsPage';
import { ApprovalsPage } from './approvals/approvalsPage';
import { AuditPage } from './auditLog/auditPage';
import { Cash4ConfigurationsPage } from './cash4Configurations/cash4ConfigurationsPage';
import { ConfigurationPage } from './configurations/configurationPage';
import { LegalEntityGroupsPage } from './legalEntityGroups/components/legalEntityGroupsPage';
import { AdministrationPage } from './pages/administrationPage';
import { Payments4AdministrationRoutes } from './payments4/payments4AdministrationRoutes';
import { AdminProvider } from './providers/adminViewModeProvider';
import { GroupsRoute } from './securityGroups/groupList/groupList';
import { UserEntitlementsPage } from './userEntitlementReport/userEntitlementsList';

export const AdministrationRoutes: FC = observer(() => {
	const location = useLocation();
	const { isAdmin, cash4, payments4 } = useUser();
	const { cash4Enabled, payments4Module, customerSuccess } =
		useT4FeatureFlags();

	const navigationSections = useMemo(() => {
		const globalSettings: NavigationItemProps[] = [
			{
				id: 'legal-entity-groups',
				to: paths.administration.legalEntityGroups.href,
				label: 'Entity Groups',
				disabled: !isAdmin,
				selected: location.pathname.includes(
					paths.administration.legalEntityGroups.href,
				),
			},
			{
				id: 'account-groups',
				to: paths.administration.accountGroups.href,
				label: 'Account Groups',
				disabled: !isAdmin,
				selected: location.pathname.includes(
					paths.administration.accountGroups.href,
				),
			},
			{
				id: 'security-groups',
				to: paths.administration.groups.href,
				label: 'Security Groups',
				disabled: !isAdmin,
				selected: location.pathname.includes(paths.administration.groups.href),
			},
			{
				id: 'configurations',
				to: paths.administration.securityGroupApprovals.href,
				label: 'Configurations',
				disabled: !isAdmin,
				selected: location.pathname.includes(
					paths.administration.securityGroupApprovals.href,
				),
			},
			{
				id: 'userEntitlements',
				to: paths.administration.userEntitlements.href,
				label: 'User Entitlement Report',
				disabled: !isAdmin,
				selected: location.pathname.includes(
					paths.administration.userEntitlements.href,
				),
			},
		];

		if (customerSuccess) {
			globalSettings.push({
				id: 'customer-success',
				to: paths.administration.analyticStudioConfigurations.href,
				label: 'Analytic Studio Configurations',
				disabled: !isAdmin,
				selected: location.pathname.includes(
					paths.administration.analyticStudioConfigurations.href,
				),
			});
		}

		const navigations: NavigationSectionProps[] = [
			{
				title: 'Global Settings',
				navigationItems: globalSettings.sort((a, b) =>
					a.label.localeCompare(b.label),
				),
			},
			{
				title: 'Entity4 Settings',
				navigationItems: [
					{
						id: 'audit-log',
						to: paths.administration.auditLog.href,
						label: 'Audit Log',
						disabled: !isAdmin,
						selected: location.pathname.includes(
							paths.administration.auditLog.href,
						),
					},
					{
						id: 'approvals',
						to: paths.administration.entity4Configurations.href,
						label: 'Approvals',
						disabled: !isAdmin,
						selected: location.pathname.includes(
							paths.administration.entity4Configurations.href,
						),
					},
				].sort((a, b) => a.label.localeCompare(b.label)),
			},
		];

		if (cash4Enabled) {
			navigations.push({
				title: 'Cash4 Settings',
				navigationItems: [
					{
						id: 'cash4-configurations',
						to: paths.administration.cash4Configuration.href,
						label: 'Configurations',
						disabled: !isAdmin || !cash4.isAuthor,
						selected: location.pathname.includes(
							paths.administration.cash4Configuration.href,
						),
					},
				].sort((a, b) => a.label.localeCompare(b.label)),
			});
		}

		if (payments4Module) {
			navigations.push({
				title: 'Payments4 Settings',
				navigationItems: [
					{
						id: 'payments4-payment-approvals',
						to: paths.administration.payments4.paymentApprovalsManagement.href,
						label: 'Payment Approvals Management',
						disabled: !payments4.isAdmin,
						selected: location.pathname.includes(
							paths.administration.payments4.paymentApprovalsManagement.href,
						),
					},
					{
						id: 'payments4-payment-connection-management',
						to: paths.administration.payments4.paymentConnectionManagement.href,
						label: 'Payment Connection Management',
						disabled: !payments4.isAdmin,
						selected: location.pathname.includes(
							paths.administration.payments4.paymentConnectionManagement.href,
						),
					},
				].sort((a, b) => a.label.localeCompare(b.label)),
			});
		}

		return navigations;
	}, [
		isAdmin,
		location.pathname,
		customerSuccess,
		cash4Enabled,
		payments4Module,
		cash4.isAuthor,
		payments4.isAdmin,
	]);

	return (
		<AdminProvider>
			<Layout
				appBar={
					<Navbar
						logo={<SolutionPicker activeSolution={T4Solution.Administration} />}
					/>
				}
				navElements={<NavigationMenu sections={navigationSections} />}
				toggleable={false}
			>
				<Switch>
					<Route exact path={paths.administration.href}>
						<AdministrationPage />
					</Route>
					<Route exact path={paths.administration.legalEntityGroups.href}>
						<LegalEntityGroupsPage />
					</Route>
					<Route exact path={paths.administration.accountGroups.href}>
						<AccountGroupsPage />
					</Route>
					<Route path={paths.administration.groups.href}>
						<GroupsRoute />
					</Route>
					<Route exact path={paths.administration.securityGroupApprovals.href}>
						<ConfigurationPage />
					</Route>
					<Route exact path={paths.administration.userEntitlements.href}>
						<UserEntitlementsPage />
					</Route>

					<Route exact path={paths.administration.auditLog.href}>
						<AuditPage />
					</Route>
					<Route exact path={paths.administration.entity4Configurations.href}>
						<ApprovalsPage />
					</Route>
					<Route exact path={paths.administration.cash4Configuration.href}>
						<Cash4ConfigurationsPage />
					</Route>
					{customerSuccess && (
						<Route
							exact
							path={paths.administration.analyticStudioConfigurations.href}
						>
							<AnalyticStudioConfigurationsPage />
						</Route>
					)}
					<Route path={paths.administration.payments4.href}>
						{payments4.isAdmin && payments4Module ? (
							<Payments4AdministrationRoutes />
						) : (
							<CannotDisplay
								headingText={ACCESS_DENIED_MESSAGING.HEADING}
								bodyText={ACCESS_DENIED_MESSAGING.BODY}
								imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
							/>
						)}
					</Route>

					<Route>
						<CannotDisplay
							headingText={NOT_FOUND_MESSAGING.HEADING}
							bodyText={NOT_FOUND_MESSAGING.BODY}
							imageSrc={NOT_FOUND_MESSAGING.IMAGE}
							buttonText={RETURN_TO_HOME}
							buttonHref={paths.root.href}
						/>
					</Route>
				</Switch>
			</Layout>
		</AdminProvider>
	);
});
