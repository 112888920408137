import { Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Balance } from 'features/cash4/balances/_hooks/useBalancesQuery';
import {
	convertToPercentage,
	normalizeReconciliationStatus,
} from 'features/cash4/reconciliations/_hooks/useReconciliationCalculations';
import {
	ForecastedTransaction,
	ProjectedTransaction,
} from 'modules/clients/customer-api/src/api/cash4';
import { Transaction } from 'modules/clients/customer-api/src/transactions';
import {
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { DataGridColumnWidths } from 'shared/utilities/dataGrid/dataGridUtils';
import {
	convertDate,
	formatDateSimpleUpperCase,
	formatReadDate,
} from 'shared/utilities/dateUtilities';
import { defaultCurrencyCode, formatCurrency } from 'utilities/currencyUtils';
import { TransactionListItem } from '../models';

function isNullOrUndefined(value: any): boolean {
	return value === null || value === undefined;
}

export const formattedExchangeRate = (number: number | null | undefined) => {
	if (number === null) return null;
	if (number === undefined) return null;
	return number.toFixed(8);
};

export const indentSubsequentLines = (
	indent: string,
	content: string | undefined,
): string => {
	if (!content) return '';
	return content?.replace(/^(?!\s*$)/gm, (match, index) => {
		return `${index > 0 ? indent : ''}${match}`;
	});
};

export const replaceEmpty = (content: string | null | undefined): string => {
	if (!content || content.trim() === '') {
		return '-';
	} else {
		return content;
	}
};

const DETAILS_DIVIDER = 'DETAILS_DIVIDER';
const DETAILS_SPACING = 3; // WARNING: If you modify this value, then you will need to update the spacing for Balances or update the Balances code to format the new way.

export const getLongestKeyLength = (
	detailsMap: Map<string, string | null | undefined>,
): number => {
	var maxKeyLength = 0;
	detailsMap.forEach((value, key) => {
		maxKeyLength = key.length > maxKeyLength ? key.length : maxKeyLength;
	});
	return maxKeyLength;
};

/**
 * A function to format property/value pairs for the clipboard.
 *
 * @param headerName A description name for the copied contents
 * @param detailsMap A map of all the items to copy where the key is the property name and value is the value of the property
 * @returns This code formats the details map like the following
 *
 * [headerName]
 * [A blank new line is inserted when the value is DETAILS_DIVIDER]
 * [Key name][colon][padded to longest key name][DETAILS_SPACING][value or a dash, if undefined]
 *
 * For example...
 * Forecasted Transaction Details
 *
 * Entity:                      Primary-Entity-Name
 * Account:                     Primary-Account-Name
 * Loooooooong Property Name:   Something cool here
 * Property Name:               -    <-- a dash when the value is null or undefined
 *
 * Notes:
 * Some cool note
 */
export const getCopyDetailsFromTransactionDetailsMap = (
	headerName: string,
	detailsMap: Map<string, string | null | undefined>,
): string => {
	/**
	 *
	 *
	 */

	// Get the length of the longest key name
	var keyPaddingLength = getLongestKeyLength(detailsMap);

	// Create a list of details
	var detailsList = [headerName];

	// Push formatted details from transaction details map
	detailsMap.forEach((value, key) => {
		var keyName = `${key}:`;
		if (value === DETAILS_DIVIDER) {
			detailsList.push('');
		} else {
			// plus 1 for colon
			detailsList.push(
				`${keyName.padEnd(
					keyPaddingLength + 1 + DETAILS_SPACING,
				)}${replaceEmpty(value)}`,
			);
		}
	});

	// Return a string by joining the list of transaction details by a new line character
	return detailsList.join('\n');
};

export const getTransactionCopyContentValue = (
	transaction: TransactionListItem,
) => {
	const detailsMap = new Map([
		['div1', DETAILS_DIVIDER],
		['Account', transaction.e4AccountNumber],
		['Cash4 Account', transaction.c4AccountNumber],
		['Counterparty Name', transaction.bankName],
		['Counterparty Code', transaction.bankCode],
		['Post Date', formatReadDate(convertDate(transaction.date))],
		['div2', DETAILS_DIVIDER],
		['Transaction Code', transaction.transactionCode],
		['Customer Reference', transaction.customerReference],
		['Bank Reference', transaction.bankReference],
		['Check Number', transaction.checkNumber],
		['Transaction Detail', transaction.detail],
		['div3', DETAILS_DIVIDER],
		[
			'Amount',
			`${formatCurrency(transaction.number.accountCurrencyAmount, {
				currency: transaction.number.accountCurrencyCode,
			})} ${transaction.number.accountCurrencyCode.toUpperCase()}`,
		],
		[
			'Reporting Ccy Amount',
			!isNullOrUndefined(transaction.number.reportingCurrencyAmount)
				? `${formatCurrency(transaction.number.reportingCurrencyAmount, {
						currency: transaction.number.reportingCurrencyCode,
				  })} ${
						transaction.number.reportingCurrencyCode.toUpperCase() ||
						defaultCurrencyCode
				  }`
				: '-',
		],
		[
			'Foreign Exchange (FX) Rate',
			formattedExchangeRate(transaction.number.reportingCurrencyRate),
		],
		[
			'Foreign Exchange (FX) Date',
			formatDateSimpleUpperCase(
				transaction.number.reportingCurrencyEffectiveDate,
			),
		],
		['div4', DETAILS_DIVIDER],
		['Rule', transaction.transactionRuleName || 'No Rule Matched'],
		['Cash Flow Class', transaction.cfcName],
		['Cash Flow Type', transaction.cftName],
		['Cash Flow Subtype', transaction.cfstName],
		['GL Code', transaction.glNumber],
		['div5', DETAILS_DIVIDER],
		[
			'Reconciliation',
			transaction.reconciliationSummary?.reconciliationStatus
				? normalizeReconciliationStatus(
						transaction.reconciliationSummary?.reconciliationStatus,
				  )
				: 'No Reconciliation',
		],
		[
			'Variance',
			transaction.reconciliationSummary?.variancePercentage
				? convertToPercentage(
						transaction.reconciliationSummary?.variancePercentage,
				  )
				: '-',
		],
		[
			'Reconciled Amount',
			transaction.reconciliationSummary?.reconciledAmount
				? `${formatCurrency(
						transaction.reconciliationSummary.reconciledAmount,
						{
							currency: transaction.reconciliationSummary.currencyCode,
						},
				  )} ${transaction.reconciliationSummary.currencyCode.toUpperCase()}`
				: '-',
		],
		[
			'Unreconciled Amount',
			transaction.reconciliationSummary?.unreconciledAmount
				? `${formatCurrency(
						transaction.reconciliationSummary.unreconciledAmount,
						{
							currency: transaction.reconciliationSummary.currencyCode,
						},
				  )} ${transaction.reconciliationSummary.currencyCode.toUpperCase()}`
				: '-',
		],
		[
			'Reconciled With',
			transaction.reconciliationSummary
				? `${
						(transaction.reconciliationSummary.reportedCount ?? 0) +
						(transaction.reconciliationSummary.projectedCount ?? 0)
				  } transactions`
				: '-',
		],
		['div6', DETAILS_DIVIDER],
		[
			'Excluded from forecast reports',
			transaction.isForecastModelExcluded ? 'True' : 'False',
		],
		['div7', DETAILS_DIVIDER],
		['Notes', `\n${replaceEmpty(transaction.noteContent)}`],
	]);

	return getCopyDetailsFromTransactionDetailsMap(
		'Reported Transaction Details',
		detailsMap,
	);
};

export const getProjectedTransactionCopyContentValue = (
	transaction: ProjectedTransaction,
) => {
	const detailsMap = new Map([
		['div1', DETAILS_DIVIDER],
		['Entity', transaction.primaryParty.object?.name],
		['Account', transaction.primaryParty.account?.name],
		['Counterparty Name', transaction.bankName],
		['Counterparty Code', transaction.bankCode],
		['div2', DETAILS_DIVIDER],
		['Secondary Party Type', transaction.secondaryParty?.type],
		['Secondary Entity', transaction.secondaryParty?.object?.name],
		['Secondary Account', transaction.secondaryParty?.account?.name],
		['div3', DETAILS_DIVIDER],
		['Label', transaction.label],
		[
			'Transaction Amount',
			`${formatCurrency(transaction.amount.accountCurrencyAmount, {
				currency: transaction.amount.accountCurrencyCode,
			})} ${transaction.amount.accountCurrencyCode.toUpperCase()}`,
		],
		[
			'Reporting Amount',
			!isNullOrUndefined(transaction.amount.reportingCurrencyAmount)
				? `${formatCurrency(transaction.amount.reportingCurrencyAmount, {
						currency: transaction.amount.reportingCurrencyCode,
				  })} ${
						transaction.amount.reportingCurrencyCode?.toUpperCase() ||
						defaultCurrencyCode
				  }`
				: '-',
		],
		[
			'Expected Value Date',
			formatReadDate(convertDate(transaction.expectedValueDate)),
		],
		['Check Number', transaction.checkNumber],
		['End-to-End ID Number', transaction.endToEndId],
		['Instruction ID Number', transaction.instructionalId],
		['Source', transaction.source?.sourceType],
		['div4', DETAILS_DIVIDER],
		[
			'Cash Flow Class',
			transaction.categorization?.class
				? `${transaction.categorization?.class?.name} (${transaction.categorization?.class?.code})`
				: undefined,
		],
		[
			'Cash Flow Type',
			transaction.categorization?.type
				? `${transaction.categorization?.type?.name} (${transaction.categorization?.type?.code})`
				: undefined,
		],
		[
			'Cash Flow Subtype',
			transaction.categorization?.subtype
				? `${transaction.categorization?.subtype?.name} (${transaction.categorization?.subtype?.code})`
				: undefined,
		],
		['GL Code', transaction.categorization?.glCode?.code],
		['div5', DETAILS_DIVIDER],
		[
			'Reconciliation',
			transaction.reconciliationSummary
				? normalizeReconciliationStatus(
						transaction.reconciliationSummary?.reconciliationStatus,
				  )
				: 'Unreconciled',
		],
		[
			'Variance',
			transaction.reconciliationSummary
				? convertToPercentage(
						transaction.reconciliationSummary?.variancePercentage,
				  )
				: '-',
		],
		[
			'Reconciled Amount',
			transaction.reconciliationSummary
				? `${formatCurrency(
						transaction.reconciliationSummary.reconciledAmount,
						{ currency: transaction.reconciliationSummary.currencyCode },
				  )} ${transaction.reconciliationSummary.currencyCode.toUpperCase()}`
				: '-',
		],
		[
			'Unreconciled Amount',
			transaction.reconciliationSummary
				? `${formatCurrency(
						transaction.reconciliationSummary.unreconciledAmount,
						{ currency: transaction.reconciliationSummary.currencyCode },
				  )} ${transaction.reconciliationSummary.currencyCode.toUpperCase()}`
				: '-',
		],
		[
			'Reconciled With',
			transaction.reconciliationSummary
				? `${
						transaction.reconciliationSummary?.reportedCount +
						transaction.reconciliationSummary?.projectedCount
				  } transactions`
				: '-',
		],
		['div6', DETAILS_DIVIDER],
		[
			'Excluded from forecast reports',
			transaction.isForecastModelExcluded ? 'True' : 'False',
		],
		['div7', DETAILS_DIVIDER],
		['Notes', `\n${replaceEmpty(transaction.description)}`],
	]);

	return getCopyDetailsFromTransactionDetailsMap(
		'Projected Transaction Details',
		detailsMap,
	);
};

export const getForecastedTransactionCopyContentValue = (
	transaction: ForecastedTransaction,
) => {
	const detailsMap = new Map([
		['div1', DETAILS_DIVIDER],
		['Entity', transaction.primaryParty.object?.name],
		['Account', transaction.primaryParty.account?.name],
		['Counterparty Name', transaction.bankInformation?.name],
		['Counterparty Code', transaction.bankInformation?.code],
		['div2', DETAILS_DIVIDER],
		['Secondary Party Type', transaction.secondaryParty?.type],
		['Secondary Entity', transaction.secondaryParty?.object?.name],
		['Secondary Account', transaction.secondaryParty?.account?.name],
		['div3', DETAILS_DIVIDER],
		['Label', transaction.label],
		[
			'Transaction Amount',
			`${formatCurrency(transaction.amount.accountCurrencyAmount, {
				currency: transaction.amount.accountCurrencyCode,
			})} ${transaction.amount.accountCurrencyCode.toUpperCase()}`,
		],
		[
			'Reporting Amount',
			!isNullOrUndefined(transaction.amount.reportingCurrencyAmount)
				? `${formatCurrency(transaction.amount.reportingCurrencyAmount, {
						currency: transaction.amount.reportingCurrencyCode,
				  })} ${
						transaction.amount.reportingCurrencyCode?.toUpperCase() ||
						defaultCurrencyCode
				  }`
				: '-',
		],
		[
			'Expected Value Date',
			formatReadDate(convertDate(transaction.expectedValueDate)),
		],
		['As of Date', formatReadDate(convertDate(transaction.asOfDate))],
		['Due Date', formatReadDate(convertDate(transaction.dueDate))],
		[
			'Expiration Date',
			formatReadDate(convertDate(transaction.expirationDate)),
		],
		['Source', transaction.source?.sourceType],
		[
			'Reconciliation Status',
			normalizeReconciliationStatus(transaction.reconciliationStatus),
		],
		['div4', DETAILS_DIVIDER],
		[
			'Cash Flow Class',
			transaction.categorization?.class
				? `${transaction.categorization?.class?.name} (${transaction.categorization?.class?.code})`
				: undefined,
		],
		[
			'Cash Flow Type',
			transaction.categorization?.type
				? `${transaction.categorization?.type?.name} (${transaction.categorization?.type?.code})`
				: undefined,
		],
		[
			'Cash Flow Subtype',
			transaction.categorization?.subtype
				? `${transaction.categorization?.subtype?.name} (${transaction.categorization?.subtype?.code})`
				: undefined,
		],
		['GL Code', transaction.categorization?.glCode?.code],
		['div6', DETAILS_DIVIDER],
		[
			'Excluded from forecast reports',
			transaction.isForecastModelExcluded ? 'True' : 'False',
		],
		['div7', DETAILS_DIVIDER],
		['Notes', `\n${replaceEmpty(transaction.noteContent)}`],
	]);

	return getCopyDetailsFromTransactionDetailsMap(
		'Forecasted Transaction Details',
		detailsMap,
	);
};

export const getBalanceCopyContentValue = (
	balance: Balance,
	reportingCurrency: string,
) => {
	if (!balance) {
		return '';
	}

	return (
		`Balance Record\n\n` +
		`Account Code:                 ${replaceEmpty(balance.e4AccountName)}\n` +
		`Counterparty Code:            ${replaceEmpty(balance.bankCode)}\n\n` +
		`Account:                      ${replaceEmpty(balance.e4AccountNumber)}\n` +
		`Cash4 Account:                ${replaceEmpty(balance.c4AccountNumber)}\n` +
		`Counterparty:                 ${replaceEmpty(balance.bankName)}\n` +
		`Balance Date:                 ${replaceEmpty(
			formatDateSimpleUpperCase(balance.statementDate),
		)}\n` +
		'\n' +
		`Opening Ledger Balance:       ${
			balance.openingLedgerBalance.value !== undefined
				? `${formatCurrency(balance.openingLedgerBalance.value, {
						currency: balance.openingLedgerBalance.valueCurrency!,
				  })} ${balance.openingLedgerBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.openingLedgerBalance.reportingValue !== undefined
				? `${formatCurrency(balance.openingLedgerBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Opening Available Balance:    ${
			balance.openingAvailableBalance.value !== undefined
				? `${formatCurrency(balance.openingAvailableBalance.value, {
						currency: balance.openingAvailableBalance.valueCurrency!,
				  })} ${balance.openingAvailableBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.openingAvailableBalance.reportingValue !== undefined
				? `${formatCurrency(balance.openingAvailableBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Closing Ledger Balance:       ${
			balance.closingLedgerBalance.value !== undefined
				? `${formatCurrency(balance.closingLedgerBalance.value, {
						currency: balance.closingLedgerBalance.valueCurrency!,
				  })} ${balance.closingLedgerBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.closingLedgerBalance.reportingValue !== undefined
				? `${formatCurrency(balance.closingLedgerBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Closing Available Balance:    ${
			balance.closingAvailableBalance.value !== undefined
				? `${formatCurrency(balance.closingAvailableBalance.value, {
						currency: balance.closingAvailableBalance.valueCurrency!,
				  })} ${balance.closingAvailableBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.closingAvailableBalance.reportingValue !== undefined
				? `${formatCurrency(balance.closingAvailableBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Foreign Exchange (FX) Rate:   ${
			balance.foreignExchangeRate
				? `${formattedExchangeRate(balance.foreignExchangeRate)}`
				: `-`
		}\n` +
		`Foreign Exchange (FX) Date:   ${
			balance.foreignExchangeDate
				? `${formatDateSimpleUpperCase(balance.foreignExchangeDate)}`
				: `-`
		}\n` +
		'\n' +
		`Notes:\n${replaceEmpty(balance.note)}`
	);
};

export const getTransactionListItem = (
	transaction: Transaction,
): TransactionListItem => ({
	id: transaction.id,
	date: transaction.date,
	c4AccountNumber: transaction.c4AccountNumber,
	e4AccountNumber: transaction.e4AccountNumber,
	e4AccountName: transaction.e4AccountName,
	e4AccountId: transaction.e4AccountId,
	transactionCode: transaction.transactionCode,
	transactionCodeDescription: transaction.transactionCodeDescription,
	bankReference: transaction.bankReference,
	customerReference: transaction.customerReference,
	checkNumber: transaction.checkNumber,
	detail: transaction.detail,
	cfc: transaction.cfc,
	cfcName: transaction.cfcName,
	cft: transaction.cft,
	cftName: transaction.cftName,
	cfst: transaction.cfst,
	cfstName: transaction.cfstName,
	glNumber: transaction.glNumber,
	number: {
		accountCurrencyAmount: transaction.number,
		accountCurrencyCode: transaction.currency,
		reportingCurrencyAmount: transaction.number_ReportingCurrency,
		reportingCurrencyCode: defaultCurrencyCode,
		reportingCurrencyRate: transaction.reportingCurrency_Rate,
		reportingCurrencyEffectiveDate: transaction.reportingCurrency_EffectiveDate,
	},
	noteContent: transaction.noteContent,
	currency: transaction.currency,
	bankName: transaction.bankName,
	bankCode: transaction.bankCode,
	debitCredit: transaction.debitCredit,
	valueDate: transaction.valueDate,
	transactionDateTime: transaction.transactionDateTime,
	lastUpdatedDate: transaction.lastUpdatedDate,
	transactionImportedDate: transaction.transactionImportedDate,
	transactionRuleId: transaction.transactionRuleId,
	transactionRuleName: transaction.transactionRuleName,
	bankTransactionId: transaction.fI_TransactionId,
	reconciliationRecordId: transaction.reconciliationRecordId,
	reconciliationStatus: transaction.reconciliationStatus,
	isForecastModelExcluded: transaction.isForecastModelExcluded,
	reconciliationSummary: transaction.reconciliationSummary,
});

export function normalizeDebitCredit(indicator: string): string {
	return indicator.toLowerCase() === 'debit'
		? 'Debit'
		: indicator.toLowerCase() === 'credit'
		? 'Credit'
		: indicator;
}

export const stonlyIds = {
	categorizationInfoModal: 'categorization-info-modal',

	// Reported Transactions
	transactionsDrawer: 'cash4-transactions-drawer',
	transactionsRowContextMenu: 'cash4-transactions-row-context-menu',
	transactionsRowContextMenuView: 'cash4-transactions-row-context-menu-view',
	transactionsRowContextMenuDelete:
		'cash4-transactions-row-context-menu-delete',
	transactionsDeleteConfirmationModal:
		'cash4-transactions-delete-confirmation-modal',
	transactionsDeleteConfirmationCancelButton:
		'cash4-transactions-delete-confirmation-cancel',
	transactionsDeleteConfirmationDeleteButton:
		'cash4-transactions-delete-confirmation-delete',

	// Projected Transactions
	projectedTransactionsGrid: 'cash4-projected-transactions-grid',
	projectedTransactionsDrawer: 'cash4-projected-transactions-drawer',
	projectedTransactionsRowContextMenu:
		'cash4-projected-transactions-row-context-menu',
	projectedTransactionsRowContextMenuView:
		'cash4-projected-transactions-row-context-menu-view',
	projectedTransactionsRowContextMenuDelete:
		'cash4-projected-transactions-row-context-menu-delete',
	projectedTransactionsDeleteConfirmationModal:
		'cash4-projected-transactions-delete-confirmation-modal',
	projectedTransactionsDeleteConfirmationCancelButton:
		'cash4-projected-transactions-delete-confirmation-cancel',
	projectedTransactionsDeleteConfirmationDeleteButton:
		'cash4-projected-transactions-delete-confirmation-delete',
	projectedTransactionsCreateButton:
		'cash4-transactions-create-projected-item-button',

	// Forecasted Transactions
	forecastedTransactionsGrid: 'cash4-forecasted-transactions-grid',
	forecastedTransactionsRowContextMenu:
		'cash4-forecasted-transactions-row-context-menu',
};

export const projectedTransactionsColumnVisibility = {
	isForecastModelExcluded: false,
	_cfcName: false,
	_cftName: false,
	_cfstName: false,
};

export const reportedTransactionsColumnVisibility = {
	c4AccountNumber: false,
	bankName: false,
	bankCode: false,
	bankReference: false,
	checkNumber: false,
	glNumber: false,
	customerReference: false,
	transactionImportedDate: false,
	transactionRuleName: false,
	bankTransactionId: false,
	noteContent: false,
	cfcName: false,
	cftName: false,
	cfstName: false,
	isForecastModelExcluded: false,
	debitCredit: false,
	valueDate: false,
	transactionDateTime: false,
	lastUpdatedDate: false,
	transactionCodeDescription: false,
	reportingNumber: false,
	reportingCurrency: false,
};

export const forecastedTransactionsColumnVisibility = {
	_cfcName: false,
	_cftName: false,
	_cfstName: false,
	_secondaryParty_type: false,
	_secondaryParty_name: false,
	_secondaryParty__account_name: false,
	_secondaryParty__account_number: false,
	_counterpartyCode: false,
	_counterpartyName: false,
	isForecastModelExcluded: false,
};

export function getProjectedTransactionsColumnDefs(
	amountColumn: GridColDef<ProjectedTransaction>,
	currencyColumn: GridColDef<ProjectedTransaction>,
	actionColumn?: GridColDef<ProjectedTransaction>,
	reconciliationStatusColumn?: GridColDef<ProjectedTransaction>,
): GridColDef<ProjectedTransaction>[] {
	const columns: GridColDef<ProjectedTransaction>[] = [];

	if (actionColumn) {
		columns.push(actionColumn);
	}

	columns.push({
		field: 'label',
		headerName: 'Label',
	});

	if (reconciliationStatusColumn) {
		columns.push(reconciliationStatusColumn);
	}

	columns.push(
		{
			field: 'description',
			headerName: 'Notes',
		},
		{
			field: 'flowDirection',
			headerName: 'Fund Direction',
		},
		amountColumn,
		currencyColumn,
		{
			...getDateColumnDefinition(),
			field: 'expectedValueDate',
			headerName: 'Expected Value Date',
		},
		{
			field: '_cfc',
			headerName: 'CFC',
			description: 'Cash Flow Class',
			width: DataGridColumnWidths.threeChar,
			valueGetter: (params) => params.row?.categorization?.class?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.class?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cfcName',
			headerName: 'CFC Description',
			description: 'Cash Flow Class',
			valueGetter: (params) => params.row?.categorization?.class?.name,
		},
		{
			field: '_cft',
			headerName: 'CFT',
			description: 'Cash Flow Type',
			width: DataGridColumnWidths.threeChar,
			valueGetter: (params) => params.row?.categorization?.type?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.type?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cftName',
			headerName: 'CFT Description',
			description: 'Cash Flow Type',
			valueGetter: (params) => params.row?.categorization?.type?.name,
		},
		{
			field: '_cfst',
			headerName: 'CFST',
			description: 'Cash Flow Subtype',
			width: DataGridColumnWidths.fourChar,
			valueGetter: (params) => params.row?.categorization?.subtype?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.subtype?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cfstName',
			headerName: 'CFST Description',
			description: 'Cash Flow Subtype',
			valueGetter: (params) => params.row?.categorization?.subtype?.name,
		},
		{
			field: '_glCode',
			headerName: 'GL Code',
			valueGetter: (params) => params.row?.categorization?.glCode?.code,
		},
		{
			field: '_primaryParty_name',
			headerName: 'Entity',
			valueGetter: (params) => params.row.primaryParty.object?.name,
		},
		{
			field: '_primaryParty__account_name',
			headerName: 'Account',
			valueGetter: (params) => params.row.primaryParty.account?.name,
		},
		{
			field: '_primaryParty__account_number',
			headerName: 'Account Number',
			valueGetter: (params) => params.row.primaryParty.account?.number,
		},
		{
			field: '_secondaryParty_type',
			headerName: 'Secondary Party Type',
			valueGetter: (params) => params.row.secondaryParty?.type,
		},
		{
			field: '_secondaryParty_name',
			headerName: 'Secondary Entity',
			valueGetter: (params) => params.row.secondaryParty?.object?.name,
		},
		{
			field: '_secondaryParty__account_name',
			headerName: 'Secondary Account',
			valueGetter: (params) => params.row.secondaryParty?.account?.name,
		},
		{
			field: '_secondaryParty__account_number',
			headerName: 'Secondary Account Number',
			valueGetter: (params) => params.row.secondaryParty?.account?.number,
		},
		{
			field: '_counterpartyCode',
			headerName: 'Counterparty Code',
			valueGetter: (params) => params.row.bankCode,
		},
		{
			field: '_counterpartyName',
			headerName: 'Counterparty Name',
			valueGetter: (params) => params.row.bankName,
		},
		{
			field: 'isForecastModelExcluded',
			headerName: 'Forecast Reports',
			valueGetter: (params) => params.row.isForecastModelExcluded,
			valueFormatter: (params) => (params.value ? 'Excluded' : 'Included'),
		},
		{
			field: '_source_source_type',
			headerName: 'Source',
			valueGetter: (params) => params.row.source.sourceType ?? null,
		},
	);

	return columns;
}

export function getReportedTransactionsColumnDefs(
	amountColumn: GridColDef<TransactionListItem>,
	currencyColumn: GridColDef<TransactionListItem>,
	actionColumn?: GridColDef<TransactionListItem>,
	reconciliationStatusColumn?: GridColDef<TransactionListItem>,
): GridColDef<TransactionListItem>[] {
	const columns: GridColDef<TransactionListItem>[] = [];

	if (actionColumn) {
		columns.push(actionColumn);
	}

	columns.push(
		{
			...getDateColumnDefinition(),
			field: 'date',
			headerName: 'Post Date',
		},
		{
			field: 'e4AccountName',
			headerName: 'Account Name',
			headerClassName: 'wrap-column-header',
		},
		{
			field: 'e4AccountNumber',
			headerName: 'Account Number',
		},
		{
			field: 'c4AccountNumber',
			headerName: 'C4 Account Number',
		},
		{
			field: 'transactionCode',
			headerName: 'Bank Transaction Code',
		},
		{
			field: 'transactionCodeDescription',
			headerName: 'Transaction Code Description',
		},
		{
			field: 'bankReference',
			headerName: 'Bank Reference',
		},
		{
			field: 'customerReference',
			headerName: 'Customer Reference',
		},
		{
			field: 'checkNumber',
			headerName: 'Check Number',
		},
		{
			field: 'detail',
			headerName: 'Transaction Details',
		},
		{
			field: 'noteContent',
			headerName: 'Notes',
		},
		{
			field: 'bankTransactionId',
			headerName: 'Bank Transaction Id',
		},
		{
			field: 'transactionRuleName',
			headerName: 'Rule Name',
			valueGetter: (params) => {
				return params.row.id === params.row.transactionRuleName
					? 'Manual Categorization'
					: params.row.transactionRuleName || 'No Rule Matched';
			},
			valueFormatter: (params) => params.value,
		},
		{
			field: 'cfc',
			headerName: 'CFC',
			description: 'Cash Flow Class',
			width: DataGridColumnWidths.threeChar,
			renderCell: (value) => {
				return (
					<Tooltip title={value.row.cfcName}>
						<Typography variant="body2" noWrap>
							{value.row.cfc}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: 'cfcName',
			headerName: 'CFC Description',
			description: 'Cash Flow Class',
		},
		{
			field: 'cft',
			headerName: 'CFT',
			description: 'Cash Flow Type',
			width: DataGridColumnWidths.threeChar,
			renderCell: (value) => {
				return (
					<Tooltip title={value.row.cftName}>
						<Typography variant="body2" noWrap>
							{value.row.cft}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: 'cftName',
			headerName: 'CFT Description',
			description: 'Cash Flow Type',
		},
		{
			field: 'cfst',
			headerName: 'CFST',
			description: 'Cash Flow Subtype',
			width: DataGridColumnWidths.fourChar,
			renderCell: (value) => {
				return (
					<Tooltip title={value.row.cfstName}>
						<Typography variant="body2" noWrap>
							{value.row.cfst}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: 'cfstName',
			headerName: 'CFST Description',
			description: 'Cash Flow Subtype',
		},
		{
			field: 'glNumber',
			headerName: 'GL Code',
		},
		amountColumn,
		currencyColumn,
	);

	if (reconciliationStatusColumn) {
		columns.push(reconciliationStatusColumn);
	}

	columns.push(
		{
			field: 'bankName',
			headerName: 'Counterparty Name',
		},
		{
			field: 'bankCode',
			headerName: 'Counterparty Code',
		},
		{
			...getFormattedDateTimeColumnDefinition(),
			field: 'transactionImportedDate',
			headerName: 'Imported Date',
		},
		{
			...getDateColumnDefinition(),
			field: 'valueDate',
			headerName: 'Value Date',
		},
		{
			...getFormattedDateTimeColumnDefinition(),
			field: 'lastUpdatedDate',
			headerName: 'Last Updated Date',
		},
		{
			...getFormattedDateTimeColumnDefinition(),
			field: 'transactionDateTime',
			headerName: 'Transaction Date',
		},
		{
			field: 'debitCredit',
			headerName: 'Debit/Credit',
			valueGetter: (params) => normalizeDebitCredit(params.value),
		},
		{
			field: 'reportingNumber',
			headerName: 'Reporting Amount',
			resizable: false,
			sortable: false,
			disableColumnMenu: true,
			disableExport: false,
			filterable: false,
			hideable: false,
			disableReorder: true,
			valueGetter: (params) => {
				return params.row.number.reportingCurrencyAmount;
			},
		},
		{
			field: 'reportingCurrency',
			headerName: 'Reporting Currency',
			resizable: false,
			sortable: false,
			disableColumnMenu: true,
			disableExport: false,
			filterable: false,
			hideable: false,
			disableReorder: true,
			valueGetter: (params) => {
				return params.row.number.reportingCurrencyCode;
			},
		},
		{
			field: 'isForecastModelExcluded',
			headerName: 'Forecast Reports',
			valueGetter: (params) => params.row.isForecastModelExcluded,
			valueFormatter: (params) => (params.value ? 'Excluded' : 'Included'),
		},
	);

	return columns;
}

export function getForecastedTransactionsColumnDefs(
	amountColumn: GridColDef<ForecastedTransaction>,
	currencyColumn: GridColDef<ForecastedTransaction>,
	actionColumn?: GridColDef<ForecastedTransaction>,
	reconciliationStatusColumn?: GridColDef<ForecastedTransaction>,
): GridColDef<ForecastedTransaction>[] {
	const columns: GridColDef<ForecastedTransaction>[] = [];

	if (actionColumn) {
		columns.push(actionColumn);
	}

	columns.push({
		field: 'label',
		headerName: 'Label',
	});

	if (reconciliationStatusColumn) {
		columns.push(reconciliationStatusColumn);
	}

	columns.push(
		{
			field: 'noteContent',
			headerName: 'Notes',
		},
		{
			field: 'flowDirection',
			headerName: 'Fund Direction',
		},
		amountColumn,
		currencyColumn,
		{
			...getDateColumnDefinition(),
			field: 'expectedValueDate',
			headerName: 'Expected Value Date',
		},
		{
			...getDateColumnDefinition(),
			field: 'asOfDate',
			headerName: 'As of Date',
		},
		{
			...getDateColumnDefinition(),
			field: 'dueDate',
			headerName: 'Due Date',
		},
		{
			...getDateColumnDefinition(),
			field: 'expirationDate',
			headerName: 'Expiration Date',
		},
		{
			field: '_cfc',
			headerName: 'CFC',
			description: 'Cash Flow Class',
			width: DataGridColumnWidths.threeChar,
			valueGetter: (params) => params.row?.categorization?.class?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.class?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cfcName',
			headerName: 'CFC Description',
			description: 'Cash Flow Class',
			valueGetter: (params) => params.row?.categorization?.class?.name,
		},
		{
			field: '_cft',
			headerName: 'CFT',
			description: 'Cash Flow Type',
			width: DataGridColumnWidths.threeChar,
			valueGetter: (params) => params.row?.categorization?.type?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.type?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cftName',
			headerName: 'CFT Description',
			description: 'Cash Flow Type',
			valueGetter: (params) => params.row?.categorization?.type?.name,
		},
		{
			field: '_cfst',
			headerName: 'CFST',
			description: 'Cash Flow Subtype',
			width: DataGridColumnWidths.fourChar,
			valueGetter: (params) => params.row?.categorization?.subtype?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.subtype?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cfstName',
			headerName: 'CFST Description',
			description: 'Cash Flow Subtype',
			valueGetter: (params) => params.row?.categorization?.subtype?.name,
		},
		{
			field: '_glCode',
			headerName: 'GL Code',
			valueGetter: (params) => params.row?.categorization?.glCode?.code,
		},
		{
			field: '_primaryParty_name',
			headerName: 'Entity',
			valueGetter: (params) => params.row.primaryParty.object?.name,
		},
		{
			field: '_primaryParty__account_name',
			headerName: 'Account',
			valueGetter: (params) => params.row.primaryParty.account?.name,
		},
		{
			field: '_primaryParty__account_number',
			headerName: 'Account Number',
			valueGetter: (params) => params.row.primaryParty.account?.number,
		},
		{
			field: '_secondaryParty_type',
			headerName: 'Secondary Party Type',
			valueGetter: (params) => params.row.secondaryParty?.type,
		},
		{
			field: '_secondaryParty_name',
			headerName: 'Secondary Entity',
			valueGetter: (params) => params.row.secondaryParty?.object?.name,
		},
		{
			field: '_secondaryParty__account_name',
			headerName: 'Secondary Account',
			valueGetter: (params) => params.row.secondaryParty?.account?.name,
		},
		{
			field: '_secondaryParty__account_number',
			headerName: 'Secondary Account Number',
			valueGetter: (params) => params.row.secondaryParty?.account?.number,
		},
		{
			field: '_counterpartyCode',
			headerName: 'Counterparty Code',
			valueGetter: (params) => params.row.bankInformation?.code,
		},
		{
			field: '_counterpartyName',
			headerName: 'Counterparty Name',
			valueGetter: (params) => params.row.bankInformation?.name,
		},
		{
			field: 'isForecastModelExcluded',
			headerName: 'Forecast Reports',
			valueGetter: (params) => params.row.isForecastModelExcluded,
			valueFormatter: (params) => (params.value ? 'Excluded' : 'Included'),
		},
	);

	return columns;
}
