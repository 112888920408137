import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { brandColors } from 'shared/theme/brandColors';
import { useDataContext } from '../providers/DataProvider';

const RuleMatchCount: FC = observer(() => {
	const { rule } = useDataContext();
	return (
		<Alert
			sx={{ bgcolor: brandColors.denim[100] }}
			severity="info"
			icon={<ErrorOutlineIcon sx={{ color: brandColors.denim[500] }} />}
		>
			<span>This rule applies to</span>{' '}
			<strong> {(rule?.menu.transactionCount ?? 0).toLocaleString()} </strong>
			<span>transactions.</span>
		</Alert>
	);
});

export default RuleMatchCount;
