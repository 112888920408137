import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { USER_PREFERENCES_FIELD_OPTIONS } from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { CreateRelationshipDrawer } from '../components/createRelationshipDrawer/createRelationshipDrawer';
import { DeleteRelationshipModal } from '../components/deleteRelationshipModal';
import { EditRelationshipDrawer } from '../components/editRelationshipDrawer/editRelationshipDrawer';
import { RelationshipsLayout } from '../components/relationshipsLayout';
import { IRelationshipDefinitionData } from '../relationshipApiTypes';
import { LegalEntityPowerOfAttorneyRelationship } from './entityRelationshipsListModel';
import { useEntityRelationshipViewModel } from './entityRelationshipsViewModel';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';

export const EntityRelationships: FC = observer(() => {
	const viewModel = useEntityRelationshipViewModel();
	const history = useHistory();

	const navigateToTab = useCallback(
		(definition: IRelationshipDefinitionData | null) => {
			if (definition && definition.displayName === 'Power of Attorney') {
				history.push(
					history.location.pathname + `?tab=${definition.displayName}`,
				);
			} else if (definition && definition.category.displayName) {
				history.push(
					history.location.pathname + `?tab=${definition.category.displayName}`,
				);
			}
		},
		[history],
	);

	return (
		<RelationshipsLayout
			createRelationshipButton={
				<CreateRelationshipDrawer
					viewModel={viewModel.getCreateViewModel()!}
					readOnly={viewModel.loading()}
					navigateToTab={navigateToTab}
				/>
			}
			tabDetails={[
				{
					tab: 'Accounts',
					component: (
						<ActuallyPrettyGoodDataGridWrapper>
							<UserPreferencesDataGrid
								stonlyId="accounts"
								tableKey="entityRelationshipsAccounts"
								columns={viewModel.getAccountColumns()}
								rows={viewModel.getAccountRows()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Account Relationships"
								showToolbar
								showCustomViewButton
								initialState={{
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
							/>
						</ActuallyPrettyGoodDataGridWrapper>
					),
				},
				{
					tab: 'Ownership',
					component: (
						<Box
							sx={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							}}
						>
							<Box sx={{ height: '40vh' }}>
								<ActuallyPrettyGoodDataGridWrapper>
									<UserPreferencesDataGrid
										stonlyId="ownership"
										tableKey="entityRelationshipsOwnership"
										columns={viewModel.getParentColumns()}
										rows={viewModel.getParentRows()}
										loading={viewModel.loading()}
										errorMessage={viewModel.error()}
										emptyMessage="No Parent Relationships"
										showToolbar
										showCustomViewButton
										initialState={{
											pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
										}}
									/>
								</ActuallyPrettyGoodDataGridWrapper>
							</Box>

							<Box sx={{ height: '40vh' }}>
								<ActuallyPrettyGoodDataGridWrapper>
									<UserPreferencesDataGrid
										stonlyId="children"
										tableKey="entityRelationshipsChildren"
										columns={viewModel.getChildrenColumns()}
										rows={viewModel.getChildrenRows()}
										loading={viewModel.loading()}
										errorMessage={viewModel.error()}
										emptyMessage="No Child Relationships"
										showToolbar
										showCustomViewButton
										initialState={{
											pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
										}}
									/>
								</ActuallyPrettyGoodDataGridWrapper>
							</Box>
						</Box>
					),
				},
				{
					tab: 'Staff',
					component: (
						<ActuallyPrettyGoodDataGridWrapper>
							<UserPreferencesDataGrid
								stonlyId="staff"
								tableKey="entityRelationshipsStaff"
								columns={viewModel.getStaffColumns()}
								rows={viewModel.getStaffRows()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Staff Relationships"
								showToolbar
								showCustomViewButton
								initialState={{
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
							/>
						</ActuallyPrettyGoodDataGridWrapper>
					),
				},
				{
					tab: 'Power of Attorney',
					component: (
						<ActuallyPrettyGoodDataGridWrapper>
							<UserPreferencesDataGrid<LegalEntityPowerOfAttorneyRelationship>
								stonlyId="legal-entity-power-of-attorney-relationships"
								tableKey="legal-entity-power-of-attorney-relationships"
								columns={viewModel.getPowerOfAttorneyColumns()}
								rows={viewModel.getPowerOfAttorneyRelationships()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Power of Attorney Relationships"
								showToolbar
								showCustomViewButton
								initialState={{
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
							/>
						</ActuallyPrettyGoodDataGridWrapper>
					),
				},
			]}
		>
			<EditRelationshipDrawer
				viewModel={viewModel.editRelationshipDrawerViewModel}
			/>
			<DeleteRelationshipModal
				viewModel={viewModel.deleteRelationshipViewModel}
			/>
		</RelationshipsLayout>
	);
});
