import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { T4Button } from './t4Button';

export type T4ModalProps = Pick<
	DialogProps,
	'children' | 'id' | 'open' | 'sx'
> & {
	title?: ReactNode | string | undefined;
	onClose?: () => void;
	actions?: ReactNode;
	fullWidth?: boolean;
};

export const T4Modal: FC<T4ModalProps> = observer(
	({
		id,
		title,
		onClose = () => {},
		actions,
		children,
		fullWidth,
		...props
	}) => {
		const partialId = id ? `-${id}` : '';

		return (
			<Dialog
				id={`t4-modal${partialId}`}
				data-testid="t4-modal"
				fullWidth={fullWidth}
				{...props}
			>
				{title && (
					<DialogTitle
						id={`t4-modal-title${partialId}`}
						data-testid="t4-modal-title"
					>
						{title}
					</DialogTitle>
				)}
				{children && (
					<DialogContent
						id={`t4-modal-content${partialId}`}
						data-testid="t4-modal-content"
					>
						{children}
					</DialogContent>
				)}
				{actions ?? (
					<DialogActions
						id={`t4-modal-actions${partialId}`}
						data-testid="t4-modal-actions"
					>
						<T4Button
							id={`modal-action${partialId}`}
							onClick={onClose}
							color="secondary"
						>
							Close
						</T4Button>
					</DialogActions>
				)}
			</Dialog>
		);
	},
);
