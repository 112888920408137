/* eslint-disable mobx/missing-observer */
import { Box, CircularProgress, Divider } from '@mui/material';
import { E4AccountLink } from 'features/cash4/shared/components/T4SideDrawer/E4AccountLink';
import { FC, useMemo } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
} from 'shared/constants/cannotDisplayMessaging';
import { useUser } from 'shared/hooks/useUser';
import { formatCurrency } from 'utilities/currencyUtils';
import { Action, Actions } from '../../shared/components/T4ActionMenu';
import T4BlueHeader from '../../shared/components/T4SideDrawer/T4BlueHeader';
import T4DetailBox from '../../shared/components/T4SideDrawer/T4DetailBox';
import T4TopBar from '../../shared/components/T4SideDrawer/T4TopBar';
import { formatDateSimpleUpperCase } from '../../shared/components/T4SideDrawer/utilites';
import {
	formattedExchangeRate,
	getBalanceCopyContentValue,
} from '../../transactions/utilities';
import { Balance } from '../_hooks/useBalancesQuery';
import { AccountBalanceNote } from '../components/AccountBalanceNote';

export type BalancesDrawerProps = {
	isLoading: boolean;
	balance?: Balance;
	reportingCurrencyCode: string;
	balanceActions?: Action[];
};

export const BalancesDrawer: FC<BalancesDrawerProps> = ({
	isLoading: loading,
	balance,
	reportingCurrencyCode,
	balanceActions,
}) => {
	const { cash4 } = useUser();

	const actions = useMemo(() => {
		return (
			<Actions
				objecttype="Balance"
				stonlyId={'cash4-balance-drawer-context-menu'}
				actions={balanceActions!}
			/>
		);
	}, [balanceActions]);

	if (loading) {
		return (
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					padding: '1rem',
					justifyContent: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (!cash4.isViewer) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}
	if (!balance) {
		return (
			<CannotDisplay
				headingText="Not Found"
				bodyText="Balance Record Doesn't Exist."
				imageSrc={NOT_FOUND_MESSAGING.IMAGE}
			/>
		);
	}

	return (
		<>
			<T4TopBar
				title="Balance Record"
				copyText={getBalanceCopyContentValue(balance, balance.currency!)}
				stonlyPrefix="balance-details"
				customActionDropdown={
					balanceActions && balanceActions!.length > 0 ? actions : undefined
				}
			/>
			<T4BlueHeader
				bigNumber={balance.closingLedgerBalance.value}
				currency={balance.closingLedgerBalance.valueCurrency!}
				subheadlines={[
					balance.e4AccountType === 'Subaccount' ? (
						balance.e4AccountName
					) : (
						<E4AccountLink
							e4AccountId={balance?.e4AccountId}
							e4AccountName={balance?.e4AccountName}
							stonlyPrefix="balance-details"
						/>
					),
					balance.bankCode,
					formatDateSimpleUpperCase(balance?.statementDate),
				]}
			/>
			<T4DetailBox
				details={[
					{
						type: 'bold',
						label: 'Account',
						value: balance.e4AccountNumber,
						shouldDisplay: !!balance.e4AccountNumber,
					},
					{
						type: 'bold',
						label: 'Cash4 Account',
						value: balance.c4AccountNumber,
						shouldDisplay: !!balance.c4AccountNumber,
					},
					{
						type: 'bold',
						label: 'Counterparty',
						value: balance.bankName,
						shouldDisplay: !!balance.bankName,
					},
					{
						type: 'bold',
						label: 'Balance Date',
						value: formatDateSimpleUpperCase(balance.statementDate),
						shouldDisplay: !!balance.statementDate,
					},
					<Divider sx={{ pt: 2, mb: 1 }} />,
					{ type: 'single', label: 'Opening Ledger Balance' },
					{
						type: 'normal',
						label: 'Amount',
						value: `${formatCurrency(balance.openingLedgerBalance.value, {
							currency: balance.openingLedgerBalance.valueCurrency!,
						})} ${balance.openingLedgerBalance.valueCurrency}`,
						shouldDisplay: balance.openingLedgerBalance.value != null,
					},
					{
						type: 'normal',
						label: 'Reporting Ccy Amount',
						value: `${formatCurrency(
							balance.openingLedgerBalance.reportingValue,
							{
								currency: reportingCurrencyCode,
							},
						)} ${reportingCurrencyCode}`,
						shouldDisplay: balance.openingLedgerBalance.reportingValue != null,
					},
					{ type: 'single', label: 'Opening Available Balance' },
					{
						type: 'normal',
						label: 'Amount',
						value: `${formatCurrency(balance.openingAvailableBalance.value, {
							currency: balance.openingAvailableBalance.valueCurrency!,
						})} ${balance.openingAvailableBalance.valueCurrency}`,
						shouldDisplay: balance.openingAvailableBalance.value != null,
					},
					{
						type: 'normal',
						label: 'Reporting Ccy Amount',
						value: `${formatCurrency(
							balance.openingAvailableBalance.reportingValue,
							{
								currency: reportingCurrencyCode,
							},
						)} ${reportingCurrencyCode}`,
						shouldDisplay:
							balance.openingAvailableBalance.reportingValue != null,
					},
					{ type: 'single', label: 'Closing Ledger Balance' },
					{
						type: 'normal',
						label: 'Amount',
						value: `${formatCurrency(balance.closingLedgerBalance.value, {
							currency: balance.closingLedgerBalance.valueCurrency!,
						})} ${balance.closingLedgerBalance.valueCurrency}`,
						shouldDisplay: balance.closingLedgerBalance.value != null,
					},
					{
						type: 'normal',
						label: 'Reporting Ccy Amount',
						value: `${formatCurrency(
							balance.closingLedgerBalance.reportingValue,
							{
								currency: reportingCurrencyCode,
							},
						)} ${reportingCurrencyCode}`,
						shouldDisplay: balance.closingLedgerBalance.reportingValue != null,
					},
					{ type: 'single', label: 'Closing Available Balance' },
					{
						type: 'normal',
						label: 'Amount',
						value: `${formatCurrency(balance.closingAvailableBalance.value, {
							currency: balance.closingAvailableBalance.valueCurrency!,
						})} ${balance.closingAvailableBalance.valueCurrency}`,
						shouldDisplay: balance.closingAvailableBalance.value != null,
					},
					{
						type: 'normal',
						label: 'Reporting Ccy Amount',
						value: `${formatCurrency(
							balance.closingAvailableBalance.reportingValue,
							{
								currency: reportingCurrencyCode,
							},
						)} ${reportingCurrencyCode}`,
						shouldDisplay:
							balance.closingAvailableBalance.reportingValue != null,
					},
					<Divider sx={{ pt: 2, mb: 2 }} />,
					{
						type: 'normal',
						label: 'Foreign Exchange (FX) Rate',
						value: formattedExchangeRate(balance.foreignExchangeRate),
						shouldDisplay: !!formattedExchangeRate(balance.foreignExchangeRate),
					},
					{
						type: 'normal',
						label: 'Foreign Exchange (FX) Date',
						value: formatDateSimpleUpperCase(balance?.foreignExchangeDate),
						shouldDisplay: !!balance.foreignExchangeDate,
					},
				]}
			/>
			<AccountBalanceNote
				balanceId={balance.id}
				noteContent={balance.note ?? undefined}
			/>
		</>
	);
};
