import { Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type FieldDescriptionProps = {
	description: string;
};

export const FieldDescription: FC<FieldDescriptionProps> = observer((props) => (
	<Typography
		data-testid="field-description"
		component="div"
		dangerouslySetInnerHTML={{
			__html: DOMPurify.sanitize(props.description),
		}}
		sx={{
			description: {
				'& p:first-of-type': {
					marginTop: 0,
				},
			},
		}}
	/>
));
