import { useCash4ConfigurationsQuery } from 'features/_shared/_hooks/useCash4ConfigurationsQuery';
import {
	Action,
	ActionsEnum,
} from 'features/cash4/shared/components/T4ActionMenu';
import T4Drawer from 'features/cash4/shared/components/T4SideDrawer/T4DrawerShell';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from 'shared/hooks/useUser';
import { defaultCurrencyCode } from 'utilities/currencyUtils';
import { useBalanceQuery } from '../_hooks/useBalanceQuery';
import { BalancesDrawer } from '../components/BalancesDrawer';
import { DeleteBalanceConfirmationDialog } from './deleteBalanceConfirmationDialog';

export type BalanceDrawerProps = {
	onClose?: () => void;
};

export const BalanceDrawer: FC<BalanceDrawerProps> = observer(({ onClose }) => {
	const { balanceId: id } = useParams<{ balanceId: string }>();
	const {
		cash4: { isAuthor: isCash4Author },
	} = useUser();
	const { data: cash4Configurations } = useCash4ConfigurationsQuery();
	const { isLoading: isLoadingBalance, data: balance } = useBalanceQuery(id);

	const [balanceIdToDelete, setBalanceIdToDelete] = useState<
		string | undefined
	>();

	const getBalanceActions = useMemo((): Action[] | undefined => {
		if (isCash4Author) {
			return [
				{
					action: ActionsEnum.delete,
					callback: () => {
						setBalanceIdToDelete(balance?.id);
					},
				},
			];
		}
	}, [balance?.id, isCash4Author]);

	const handleClose = useCallback(() => {
		setBalanceIdToDelete(undefined);
		onClose?.();
	}, [onClose]);

	return (
		<T4Drawer
			stonlyPrefix="balance-details"
			open={id !== undefined}
			onClose={handleClose}
		>
			<BalancesDrawer
				isLoading={isLoadingBalance}
				balance={balance}
				reportingCurrencyCode={
					cash4Configurations?.reportingCurrencyCode || defaultCurrencyCode
				}
				balanceActions={getBalanceActions}
			/>
			<DeleteBalanceConfirmationDialog
				id={balanceIdToDelete}
				onClose={onClose}
			/>
		</T4Drawer>
	);
});
