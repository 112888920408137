import { useQuery } from '@tanstack/react-query';
import {
	CashFlowClass,
	GLCode,
} from 'modules/clients/customer-api/src/api/cash4';
import { useClients } from 'shared/hooks/useClients';
import { Payments4QueryKeyBase } from './constants';

export const CategorizationQueryKeys = {
	base: [...Payments4QueryKeyBase, 'categorization'] as const,
	getAllCashFlowClasses: () =>
		[CategorizationQueryKeys.base, 'cash-flow-class', 'get-all'] as const,
	getAllGlCodes: () =>
		[CategorizationQueryKeys.base, 'gl-code', 'get-all'] as const,
};

export const useGetCategorization = (enabled: boolean = true) => {
	const { customerApiClient } = useClients();

	const {
		isFetching: areCashFlowClassesFetching,
		data: cashFlowClasses,
		error: loadingCashFlowClassesError,
	} = useQuery<CashFlowClass[], Error>(
		CategorizationQueryKeys.getAllCashFlowClasses(),
		async () => {
			const response =
				await customerApiClient.api.cash4.getAllCashFlowClasses();
			if (response.status === 200 && response.data) return response.data.value;
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled,
			staleTime: 60000,
		},
	);
	const {
		isFetching: areGlCodesFetching,
		data: glCodes,
		error: loadingGlCodesError,
	} = useQuery<GLCode[], Error>(
		CategorizationQueryKeys.getAllGlCodes(),
		async () => {
			const response = await customerApiClient.api.cash4.getAllGlCodes();
			if (response.status === 200 && response.data) return response.data.data;
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled,
			staleTime: 60000,
		},
	);

	return {
		isCategorizationLoading: areCashFlowClassesFetching || areGlCodesFetching,
		cashFlowClasses: cashFlowClasses ?? [],
		glCodes: glCodes ?? [],
		loadingCashFlowClassesError,
		loadingGlCodesError,
	};
};
