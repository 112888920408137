/* eslint-disable mobx/missing-observer */
import { DateRange } from '@mui/x-date-pickers-pro';
import { UseCash4ConfigurationsProps } from 'features/_shared/_providers/cash4ConfigurationsProvider';
import { Cash4Configurations } from 'modules/clients/customer-api/src/api/administration';
import {
	ForecastedTransaction,
	ProjectedTransaction,
} from 'modules/clients/customer-api/src/api/cash4';
import { DeleteTransactionResponse } from 'modules/clients/customer-api/src/transactions';
import moment, { Moment } from 'moment';
import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { defaultCurrencyCode } from 'utilities/currencyUtils';
import { CurrencyOption, TransactionListItem } from '../models';

export type Currency = {
	code: string;
	name: string;
};

export type CurrencyConfiguration = {
	reportingCurrencyCode: Currency;
	activeCurrencies: Currency[];
};

//#region Context

type TransactionsContextProps = {
	dateRange: DateRange<Moment>;
	handleDateRangeChange: (dateRange: DateRange<Moment>) => void;
	defaultDateRange: DateRange<Moment>;
	fetchTransactions: (
		dateRange: DateRange<Moment>,
		legalEntityGroupIds: string[],
		accountGroupIds: string[],
	) => Promise<TransactionListItem[]>;
	fetchProjectedTransactions: (
		dateRange: DateRange<Moment>,
		legalEntityGroupIds: string[],
	) => Promise<ProjectedTransaction[]>;
	fetchForecastedTransactions: (
		dateRange: DateRange<Moment>,
		legalEntityGroupIds: string[],
	) => Promise<ForecastedTransaction[]>;
	currencyOption: CurrencyOption;
	configurations: UseCash4ConfigurationsProps['data'];
	handleCurrencyOptionChange: (value: CurrencyOption['id']) => void;
	deleteReportedTransactionApiCall: (
		transactionId: string,
	) => Promise<DeleteTransactionResponse>;
};

const TransactionsContext = createContext<TransactionsContextProps>({
	dateRange: [moment(), moment()],
	handleDateRangeChange: () => {},
	defaultDateRange: [moment(), moment()],
	fetchTransactions: async () => [],
	fetchProjectedTransactions: async () => [],
	fetchForecastedTransactions: async () => [],
	currencyOption: { id: 'original', value: 'Account Currency' },
	configurations: undefined,
	handleCurrencyOptionChange: () => {},
	deleteReportedTransactionApiCall: async () => ({
		error: null,
		errors: null,
		failure: false,
		success: true,
		value: '',
	}),
});

//#endregion

//#region Provider

export type TransactionProviderProps = {
	children: ReactNode;
};

export const TransactionsProvider: FC<TransactionProviderProps> = ({
	children,
}) => {
	const { customerApiClient } = useClients();
	const { transactionStartDate, transactionEndDate } = useSessionStorage();
	const configurations = {
		reportingCurrencyCode: defaultCurrencyCode,
	} as Cash4Configurations;

	const defaultDateRange: DateRange<Moment> = useMemo(
		() => [moment().subtract(8, 'days').startOf('day'), moment().endOf('day')],
		[],
	);

	const [dateRange, setDateRange] = useState<DateRange<Moment>>([
		moment(transactionStartDate),
		moment(transactionEndDate),
	]);

	const [currencyConfiguration, setCurrencyConfiguration] =
		useState<CurrencyConfiguration>({} as CurrencyConfiguration);

	const currencyOptions: CurrencyOption[] = useMemo(
		() => [
			{ id: 'original', value: 'Account Currency' },
			{
				id: 'reporting',
				value: `Reporting Currency (${currencyConfiguration?.reportingCurrencyCode?.code})`,
			},
		],
		[currencyConfiguration?.reportingCurrencyCode?.code],
	);

	const [currencyOption, setCurrencyOption] = useState<CurrencyOption>(
		currencyOptions[0],
	);

	const handleCurrencyOptionChange = useCallback(
		(value: CurrencyOption['id']) => {
			const selectedOption = currencyOptions.find(
				(option) => option.id === value,
			);

			if (selectedOption) {
				setCurrencyOption(selectedOption);
			}
		},
		[currencyOptions],
	);

	const handleDateRangeChange = useCallback((dateRange: DateRange<Moment>) => {
		setDateRange(dateRange);
	}, []);

	const fetchTransactions = useCallback(
		async (
			dateRange: DateRange<Moment>,
			legalEntityGroupIds: string[] = [],
			accountGroupIds: string[] = [],
		): Promise<TransactionListItem[]> => {
			const response = await customerApiClient.transactions.all({
				startDate: dateRange?.[0]?.format('YYYY-MM-DD') ?? '',
				endDate: dateRange?.[1]?.format('YYYY-MM-DD') ?? '',
				legalEntityGroupIds: legalEntityGroupIds,
				accountGroupIds: accountGroupIds,
			});

			return response.data.data || [];
		},
		[customerApiClient.transactions],
	);

	const fetchProjectedTransactions = useCallback(
		async (
			dateRange: DateRange<Moment>,
			legalEntityGroupIds: string[] = [],
		): Promise<ProjectedTransaction[]> => {
			const response = await customerApiClient.api.cash4.projected({
				startDate: dateRange?.[0]?.format('YYYY-MM-DD') ?? '',
				endDate: dateRange?.[1]?.format('YYYY-MM-DD') ?? '',
				legalEntityGroupIds: legalEntityGroupIds,
			});

			return response.data.data || [];
		},
		[customerApiClient.api.cash4],
	);

	const fetchForecastedTransactions = useCallback(
		async (
			dateRange: DateRange<Moment>,
			legalEntityGroupIds: string[] = [],
		): Promise<ForecastedTransaction[]> => {
			const response =
				await customerApiClient.api.cash4.getForecastedTransactions({
					startDate: dateRange?.[0]?.format('YYYY-MM-DD') ?? '',
					endDate: dateRange?.[1]?.format('YYYY-MM-DD') ?? '',
					legalEntityGroupIds: legalEntityGroupIds,
				});

			return response.data.data || [];
		},
		[customerApiClient.api.cash4],
	);

	const fetchCurrencyConfiguration = useCallback(async () => {
		const response =
			await customerApiClient.cash4.configurations.getCurrencyConfiguration();
		const currencyConfiguration = response.data.value;

		const safeCurrencyConfiguration: CurrencyConfiguration = {
			reportingCurrencyCode: {
				name: currencyConfiguration.reportingCurrencyCode.name || '',
				code: currencyConfiguration.reportingCurrencyCode.code || '',
			},
			activeCurrencies: currencyConfiguration.activeCurrencies.map(
				(currency) => ({
					name: currency.name || '',
					code: currency.code || '',
				}),
			),
		};

		setCurrencyConfiguration(safeCurrencyConfiguration);
	}, [customerApiClient.cash4.configurations]);

	useEffect(() => {
		fetchCurrencyConfiguration();
	}, [fetchCurrencyConfiguration]);

	const deleteReportedTransactionApiCall = useCallback(
		async (transactionId: string) => {
			try {
				const response = await customerApiClient.transactions.deleteTransaction(
					transactionId,
				);
				if (response.data.error) {
					throw new Error(response.data.error);
				}
				return response.data;
			} catch (error) {
				throw error;
			}
		},
		[customerApiClient.transactions],
	);

	return (
		<TransactionsContext.Provider
			value={{
				dateRange,
				handleDateRangeChange,
				defaultDateRange,
				configurations: configurations,
				fetchTransactions,
				fetchProjectedTransactions,
				fetchForecastedTransactions,
				currencyOption,
				handleCurrencyOptionChange,
				deleteReportedTransactionApiCall: deleteReportedTransactionApiCall,
			}}
		>
			{children}
		</TransactionsContext.Provider>
	);
};

//#endregion

export type UseTransactionsProps = TransactionsContextProps;

export function useTransactions(): UseTransactionsProps {
	const context = useContext(TransactionsContext);
	if (context === undefined) {
		throw new Error(
			'useTransactions must be used within a TransactionsProvider',
		);
	}
	return context;
}
