import { ChasConnectionSettings } from './constants';
import * as yup from 'yup';

export const ChasConnectionSettingsValidator: yup.ObjectSchema<ChasConnectionSettings> =
	yup.object({
		transportCertificateName: yup
			.string()
			.trim()
			.label('Transport Certificate')
			.required(),
		signingCertificateName: yup
			.string()
			.trim()
			.label('Signing Certificate')
			.required(),
	});
