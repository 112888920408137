import { useQuery } from '@tanstack/react-query';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import { T4DataResponse2 } from 'modules/clients/types';
import { useClients } from 'shared/hooks/useClients';
import { BankConnectionQueryKeyBase } from './constants';

export const FinancialInstitutionQueryKeys = {
	getAll: () =>
		[
			...BankConnectionQueryKeyBase,
			'financial-institutions',
			'get-all',
		] as const,
};

export const useGetFinancialInstitutions = (isEnabled: boolean = true) => {
	const { applicationApiClient } = useClients();

	return useQuery<FinancialInstitution[], Error>(
		FinancialInstitutionQueryKeys.getAll(),
		async () => {
			const response =
				await applicationApiClient.financialInstitutions.getAll();
			if (response.status === 200 && response.data)
				return (
					response.data as T4DataResponse2<FinancialInstitution[]>
				).data.sort((a, b) =>
					(
						a.displayName ??
						a.bankCode ??
						a.financialInstitutionId
					).localeCompare(
						b.displayName ?? b.bankCode ?? b.financialInstitutionId,
					),
				);
			else throw new Error('Unable to load financial institutions.');
		},
		{
			refetchOnWindowFocus: false,
			staleTime: 120000,
			enabled: isEnabled,
		},
	);
};
