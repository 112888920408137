import { observer } from 'mobx-react-lite';
import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useState,
} from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

//#region Context

type AccountGroupsProviderContextProps = {
	accountGroupIds: string[];
	setAccountGroupIds: (accountGroupIds: string[]) => void;
};

const AccountGroupsContext = createContext<AccountGroupsProviderContextProps>({
	accountGroupIds: [],
	setAccountGroupIds: () => {},
});

//#endregion

//#region Provider

export type AccountGroupsProviderProps = {
	children: ReactNode;
};

export const AccountGroupsProvider: FC = observer(({ children }) => {
	const { getItem, updateItem } = useLocalStorage();

	const [accountGroupIds, _setAccountGroupIds] = useState(
		getItem('accountGroupIds')?.split(',') ?? [],
	);

	const setAccountGroupIds = useCallback(
		(accountGroupIds: string[]) => {
			_setAccountGroupIds(accountGroupIds);
			updateItem('accountGroupIds', accountGroupIds.join(','));
		},
		[updateItem],
	);

	return (
		<AccountGroupsContext.Provider
			value={{
				accountGroupIds: accountGroupIds,
				setAccountGroupIds: setAccountGroupIds,
			}}
		>
			{children}
		</AccountGroupsContext.Provider>
	);
});

//#endregion

//#region Hooks

export type UseAccountGroups = AccountGroupsProviderContextProps;

export function useAccountGroups(): UseAccountGroups {
	return useContext(AccountGroupsContext);
}

//#endregion
