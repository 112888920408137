import { BankConnectionSettingsFieldDefinition } from '../constants';

export type UsbkConnectionSettings = {
	consumerKey: string | null;
	consumerSecret: string | null;
	authenticationCertificateName: string | null;
	onBehalfOf: string | null;
	tid: string | null;
	cifid: string | null;
};

export const usbkConnectionSettingsForm: UsbkConnectionSettings = {
	consumerKey: null,
	consumerSecret: null,
	authenticationCertificateName: null,
	onBehalfOf: null,
	tid: null,
	cifid: null,
};

export const usbkConnectionSettingsFieldDefinitions: BankConnectionSettingsFieldDefinition[] =
	[
		{
			key: 'consumerKey',
			name: 'Consumer Key',
			type: 'string',
		},
		{
			key: 'consumerSecret',
			name: 'Consumer Secret',
			type: 'string',
			isSecret: true,
		},
		{
			key: 'authenticationCertificateName',
			name: 'Authentication Certificate',
			type: 'certificate',
		},
		{
			key: 'onBehalfOf',
			name: 'On Behalf Of',
			type: 'string',
		},
		{
			key: 'tid',
			name: 'TID',
			type: 'string',
		},
		{
			key: 'cifid',
			name: 'CIFID',
			type: 'string',
		},
	];
