import { AxiosInstance, AxiosResponse } from 'axios';
import { T4DataResponse, T4DataResponse2 } from 'modules/clients/types';
import { TransactionListItem } from '../../../../../../features/cash4/transactions/models';

//#region Read Models

export type CurrencyAmount = {
	accountCurrencyAmount: number;
	accountCurrencyCode: string;
	reportingCurrencyAmount?: number;
	reportingCurrencyCode?: string;
	reportingCurrencyRate?: number;
	reportingCurrencyEffectiveDate?: string;
	AccountBookingFxRate?: number;
};

export type Party = {
	id: string;
	type: string;
	name: string;
	accounts?: PartyAccount[];
};

export type PartyAccount = {
	id: string;
	name: string;
	number?: string;
	currencyCode?: string;
};

export type ReconciliationSummary = {
	reconciliationRecordId: string;
	reconciliationStatus: string;
	currencyCode: string;
	variancePercentage: number;
	unreconciledAmount: number;
	reconciledAmount: number;
	projectedAmount: number;
	projectedCount: number;
	reportedAmount: number;
	reportedCount: number;
};

export type Reconciliation = {
	id: string;
	status: string;
	notes?: string;
	createdOn: string;
	createdBy: string;
	updatedOn: string;
	updatedBy: string;
	projectedTransactions: ProjectedTransaction[];
	reportedTransactions: TransactionListItem[];
	summary: ReconciliationSummary;
};

export type ProjectedTransactionSourceDefinition = {
	sourceName: string;
	hasRestrictedFields: boolean;
	editableFields: string[];
};

export type ProjectedTransactionSourceDefinitions = {
	cash4Manual: ProjectedTransactionSourceDefinition;
	payments4: ProjectedTransactionSourceDefinition;
};

export type ProjectedTransaction = {
	id: string;
	primaryParty: PartyInformation;
	secondaryParty?: PartyInformation;
	expectedValueDate: string;
	amount: CurrencyAmount;
	currencyCode: string;
	checkNumber?: string;
	label?: string;
	description?: string;
	reconciliationId?: string;
	reconciliationStatus: string;
	categorization?: CategorizationDetail;
	createdBy: string;
	createdOn: string;
	updatedBy?: string;
	updatedOn?: string;
	deletedBy?: string;
	deletedOn?: string;
	flowDirection: string;
	isForecastModelExcluded: boolean;
	reconciliationSummary?: ReconciliationSummary;
	bankCode?: string;
	bankName?: string;

	actualValueDate?: string;

	endToEndId?: string;
	instructionalId?: string;
	source: TransactionSource;
};

export type ForecastedTransaction = {
	id: string;
	primaryParty: PartyInformation;
	secondaryParty?: PartyInformation;
	expectedValueDate?: string;
	asOfDate?: string;
	dueDate?: string;
	expirationDate?: string;
	flowDirection: string;
	amount: CurrencyAmount;
	isForecastModelExcluded: boolean;
	label?: string;
	categorization?: CategorizationDetail;
	reconciliationStatus: string;
	reconciliationSummary?: ReconciliationSummary;
	source?: TransactionSource;
	bankInformation?: BankInformation;
	noteContent?: string;
	auditableInformation: AuditableInformation;
};

export type TransactionSource = {
	sourceId?: string;
	sourceType?: string;
};

export type PartyInformation = {
	type: string;
	object?: ObjectInformation;
	account?: AccountInformation;
};

export type BankInformation = {
	code: string;
	name?: string;
};

export type AccountInformation = {
	id: string;
	name: string;
	number: string;
	bank?: BankInformation;
};

export type ObjectInformation = {
	id: string;
	name: string;
};

export type CategorizationDetail = {
	id: string;
	name: string;
	isManual: boolean;
	class: CashFlowDetail;
	type: CashFlowDetail;
	subtype?: CashFlowDetail;
	glCode?: GeneralLedgerDetail;
};

export type CashFlowDetail = {
	id: string;
	code: string;
	name: string;
};

export type GeneralLedgerDetail = {
	id: string;
	code: string;
};

export type QueuedAccountIntegrations = {
	count: number;
};

export type AccountSyncEvent = {
	lastUpdatedDate: string;
};

export type AuditableInformation = {
	createdBy: string;
	createdOn: string;
	updatedBy?: string;
	updatedOn?: string;
	deletedBy?: string;
	deletedOn?: string;
};

//#endregion

//#region Write Models

export type ReconciliationReq = {
	note?: string;
	projectedTransactions: string[];
	reportedTransactions: string[];
};

export type ProjectedTransactionReq = {
	amount: number;
	currencyCode: string;
	expectedValueDate: string;
	label?: string;
	description?: string;
	checkNumber?: string;
	primaryParty: PartyReq;
	secondaryParty?: PartyReq;
	categorization?: CategorizationReq;
	flowDirection: string;
	isForecastModelExcluded: boolean;

	endToEndId?: string;
	instructionalId?: string;
	source: TransactionSource;
	actualValueDate?: string;
};

export type PartyReq = {
	partyType?: string;
	entityId?: string;
	accountId?: string;
};

export type CategorizationReq = {
	classId: string;
	typeId: string;
	subtypeId?: string;
	glCode?: GlCodeReq;
};

export type GlCodeReq = {
	id?: string;
	code?: string;
};

export type CashFlowClass = {
	id: string;
	code: string;
	name: string;
	transactionCount: number;
	transactionRuleCount: number;
	types: CashFlowType[];
};

export type CashFlowType = {
	classId: string;
	id: string;
	code: string;
	name: string;
	transactionCount: number;
	transactionRuleCount: number;
	subtypes: CashFlowSubtype[];
};

export type CashFlowSubtype = {
	classId: string;
	typeId: string;
	id: string;
	code: string;
	name: string;
	transactionCount: number;
	transactionRuleCount: number;
};

export type GLCode = {
	id: string;
	code: string;
	addCodeTitle?: string;
};

export type ForecastedTransactionReq = {
	primaryParty: PartyReq;
	secondaryParty?: PartyReq;

	expectedValueDate?: string;
	asOfDate?: string;
	dueDate?: string;
	expirationDate?: string;

	categorization?: CategorizationReq;

	flowDirection: string;
	isForecastModelExcluded: boolean;
	label?: string;
	noteContent?: string;
	currencyCode: string;
	amount: number;
};

//#endregion

//#region Request Models

export type GetProjectedTransactionsRequest = {
	startDate?: string;
	endDate?: string;
	reconciliationStatuses?: string[];
	legalEntityGroupIds?: string[];
};

export type GetReportedTransactionsRequest = {
	startDate: string;
	endDate: string;
	reconciliationStatuses?: string[];
	legalEntityGroupIds?: string[];
	accountGroupIds?: string[];
};

export type GetForecastedTransactionsRequest = {
	startDate: string;
	endDate: string;
	reconciliationStatuses?: string[];
	legalEntityGroupIds?: string[];
};

//#endregion

export type Cash4Endpoints = {
	parties: () => Promise<AxiosResponse<T4DataResponse2<Party[]>>>;
	projected: (
		params?: GetProjectedTransactionsRequest,
	) => Promise<AxiosResponse<T4DataResponse2<ProjectedTransaction[]>>>;
	singleProjected: (
		id: string,
	) => Promise<AxiosResponse<T4DataResponse2<ProjectedTransaction>>>;
	reconciliations: () => Promise<
		AxiosResponse<T4DataResponse2<Reconciliation[]>>
	>;
	reconciliation: (
		id: string,
	) => Promise<AxiosResponse<T4DataResponse<Reconciliation>>>;
	updateReconciliation: (
		id: string,
		req: ReconciliationReq,
	) => Promise<AxiosResponse<T4DataResponse2<string>>>;
	deleteReconciliation: (
		id: string,
	) => Promise<AxiosResponse<T4DataResponse2<string>>>;
	createProjected: (
		data: ProjectedTransactionReq,
	) => Promise<AxiosResponse<T4DataResponse<string>, ProjectedTransactionReq>>;
	updateProjected: (
		id: string,
		data: ProjectedTransactionReq,
	) => Promise<AxiosResponse<T4DataResponse<string>, ProjectedTransactionReq>>;
	updateCurrentDay: () => Promise<
		AxiosResponse<T4DataResponse2<QueuedAccountIntegrations>>
	>;
	recentSyncEvent: () => Promise<
		AxiosResponse<T4DataResponse2<AccountSyncEvent>>
	>;

	getAllCashFlowClasses: (
		includeTransactionCount?: boolean,
	) => Promise<
		AxiosResponse<{ error: string; success: boolean; value: CashFlowClass[] }>
	>;
	getAllGlCodes: () => Promise<AxiosResponse<T4DataResponse<GLCode[]>>>;

	// Forecasted Transactions Endpoints
	getForecastedTransactions: (
		params?: GetForecastedTransactionsRequest,
	) => Promise<AxiosResponse<T4DataResponse2<ForecastedTransaction[]>>>;
	createForecasted: (
		data: ForecastedTransactionReq,
	) => Promise<AxiosResponse<T4DataResponse<string>, ForecastedTransactionReq>>;
	updateForecasted: (
		id: string,
		data: ForecastedTransactionReq,
	) => Promise<AxiosResponse<T4DataResponse<string>, ForecastedTransactionReq>>;
	getForecastedTransaction: (
		id: string,
	) => Promise<AxiosResponse<T4DataResponse2<ForecastedTransaction>>>;
	getProjectedTransactionSourceDefinitions: () => Promise<ProjectedTransactionSourceDefinitions>;
};

const apiCash4Path = 'api/cash4';
const apiV1Cash4Path = 'api/v1.0/cash4';
const reconciliationRecordsPath = apiV1Cash4Path + '/reconciliationRecords';
const projectedTransactionsPath = apiV1Cash4Path + '/projectedTransactions';
const forecastedTransactionsPath = apiV1Cash4Path + '/forecastedTransactions';
const partiesPath = apiCash4Path + '/parties';
const accountIntegrationsPath = apiV1Cash4Path + '/accountIntegrations';

export function cash4(axiosInstance: AxiosInstance): Cash4Endpoints {
	return {
		parties: async () =>
			await axiosInstance.get<T4DataResponse2<Party[]>>(partiesPath),
		projected: async (params) =>
			await axiosInstance.get<T4DataResponse2<ProjectedTransaction[]>>(
				projectedTransactionsPath,
				{
					params: params,
					paramsSerializer: {
						indexes: null,
					},
				},
			),
		singleProjected: async (id) =>
			await axiosInstance.get<T4DataResponse2<ProjectedTransaction>>(
				`${projectedTransactionsPath}/${id}`,
			),
		reconciliations: async () =>
			await axiosInstance.get<T4DataResponse2<Reconciliation[]>>(
				reconciliationRecordsPath,
			),
		reconciliation: async (id) =>
			await axiosInstance.get<T4DataResponse<Reconciliation>>(
				`${reconciliationRecordsPath}/${id}`,
			),
		updateReconciliation: async (id, req) =>
			await axiosInstance.put<T4DataResponse2<string>>(
				`${reconciliationRecordsPath}/${id}`,
				req,
			),
		deleteReconciliation: async (reconciliationRecordId: string) =>
			await axiosInstance.delete<T4DataResponse2<string>>(
				`${reconciliationRecordsPath}/${reconciliationRecordId}`,
			),
		createProjected: async (data) =>
			await axiosInstance.post<
				T4DataResponse<string>,
				AxiosResponse<T4DataResponse<string>, ProjectedTransactionReq>
			>(projectedTransactionsPath, data),
		updateProjected: async (id, data) =>
			await axiosInstance.put<
				string,
				AxiosResponse<T4DataResponse<string>, ProjectedTransactionReq>
			>(`${projectedTransactionsPath}/${id}`, data),
		updateCurrentDay: async () =>
			await axiosInstance.post<T4DataResponse2<QueuedAccountIntegrations>>(
				`${accountIntegrationsPath}/currentDay/refresh`,
			),
		recentSyncEvent: async () =>
			await axiosInstance.get<T4DataResponse2<AccountSyncEvent>>(
				`${accountIntegrationsPath}/accountSyncEvents/recent?reportType=current`,
			),

		getAllCashFlowClasses: async (includeTransactionCount = false) =>
			await axiosInstance.get('categories', {
				params: {
					includeTransactionCount,
				},
			}),
		getAllGlCodes: async () =>
			await axiosInstance.get(`${apiV1Cash4Path}/transactionRules/glCodes`),

		// Forecasted Transactions
		getForecastedTransactions: async (params) =>
			await axiosInstance.get<T4DataResponse2<ForecastedTransaction[]>>(
				forecastedTransactionsPath,
				{
					params: params,
					paramsSerializer: {
						indexes: null,
					},
				},
			),
		createForecasted: async (data) =>
			await axiosInstance.post<
				T4DataResponse<string>,
				AxiosResponse<T4DataResponse<string>, ForecastedTransactionReq>
			>(forecastedTransactionsPath, data),
		updateForecasted: async (id, data) =>
			await axiosInstance.put<
				string,
				AxiosResponse<T4DataResponse<string>, ForecastedTransactionReq>
			>(`${forecastedTransactionsPath}/${id}`, data),
		getForecastedTransaction: async (id) =>
			await axiosInstance.get<T4DataResponse2<ForecastedTransaction>>(
				`${forecastedTransactionsPath}/${id}`,
			),
		getProjectedTransactionSourceDefinitions: async () =>
			await axiosInstance
				.get(`${projectedTransactionsPath}/sourceDefinitions`)
				.then((response) => response.data),
	};
}
