import * as yup from 'yup';
import { UsbkConnectionSettings } from './constants';

export const UsbkConnectionSettingsValidator: yup.ObjectSchema<UsbkConnectionSettings> =
	yup.object({
		consumerKey: yup.string().trim().label('Consumer Key').required(),
		consumerSecret: yup.string().trim().label('Consumer Secret').required(),
		authenticationCertificateName: yup
			.string()
			.trim()
			.label('Authentation Certificate')
			.required(),
		onBehalfOf: yup.string().trim().label('On Behalf Of').required(),
		tid: yup.string().trim().label('TID').required(),
		cifid: yup.string().trim().label('CIFID').required(),
	});
