import { RestrictedAccessError } from 'utilities/errors/restrictedAccessError';
import { UnexpectedError } from './unexpectedError';
import { T4ProblemDetails } from 'modules/clients/types';

export type ErrorsObject = {
	[key: string]: string[];
};

export type Errors<T> = {
	[key in keyof T]: string[];
};

export const unwrapError = (error: any): string => {
	if (error.response?.data) {
		return error.response.data.error;
	} else if (error.message) {
		return error.message;
	} else {
		return error as string;
	}
};

export const parseError = (error: any): string => {
	if (error === RestrictedAccessError || error === UnexpectedError) {
		throw error;
	} else {
		return unwrapError(error);
	}
};

export const parseErrors = (error: any): ErrorsObject => {
	if (error === RestrictedAccessError || error === UnexpectedError) {
		throw error;
	} else if (error.response?.data?.errors) {
		return error.response.data.errors;
	}
	return {};
};

export function parseErrorsV2(error: any) {
	if (typeof error === 'string' || error instanceof String) {
		if (error === '') {
			return 'Something unexpected happened.';
		}

		return error as string;
	} else if (error instanceof Map) {
		return Array.from(error.values())
			.flatMap((x) => x)
			.join(',');
	} else {
		return Object.values(error)
			.flatMap((x) => x)
			.join(',');
	}
}

export const flattenErrors = (errors: ErrorsObject | Map<string, string[]>) => {
	if (errors instanceof Map)
		return Array.from(errors.values()).flatMap((x) => x);
	else {
		return Object.values(errors).flatMap((x) => x);
	}
};

export const flattenProblemDetails = (problemDetails: T4ProblemDetails) => {
	let errors = flattenErrors(problemDetails.errors ?? []);
	if (!errors.length && problemDetails.detail)
		errors.push(problemDetails.detail);

	return errors;
};

export class MultiError extends Error {
	messages: string[] = [];

	constructor(messages: string[]) {
		super(messages.join('\n'));
		this.messages = messages;
	}
}
