import { Drawer, Grid, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import {
	Payment,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { T4DataResponse2 } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useMemo, useState } from 'react';
import { DrawerWidth } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { PaymentQueryKeys } from './hooks/usePayments';
import { getPaymentStatusText } from './utilities';

export const CompletePaymentDrawer: FC<{
	isOpen: boolean;
	onClose: () => void;
	payment: Payment | null | undefined;
}> = ({ isOpen, onClose, payment }) => {
	const { applicationApiClient } = useClients();
	const { payments4 } = useUser();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const canCompletePayment = useMemo(
		() =>
			payments4.isPaymentInitiator &&
			(payment?.currentStatus ===
				PaymentStatusTypes[PaymentStatusTypes.Transmitted] ||
				payment?.currentStatus ===
					PaymentStatusTypes[PaymentStatusTypes.Pending]),
		[payments4, payment],
	);

	const [completeStatus, setCompleteStatus] =
		useState<PaymentStatusTypes | null>(null);
	const [completeReason, setCompleteReason] = useState<string | null>(null);
	const completePayment = useCallback(async () => {
		const response = await applicationApiClient.payments4.payments.complete({
			id: payment!.id,
			data: {
				status: PaymentStatusTypes[completeStatus!],
				reason: completeReason,
			},
		});
		if (response.status === 200 && response.data)
			return (response.data as T4DataResponse2<Payment>).data;
		else throw new Error('');
	}, [payment, completeStatus, completeReason, applicationApiClient]);

	const { isLoading, mutate } = useMutation<Payment, Error>({
		mutationFn: completePayment,
		onSuccess: () => {
			enqueueSnackbar('Completed payment successfully.', {
				variant: 'success',
			});
			queryClient.invalidateQueries(PaymentQueryKeys.base);
		},
		onError: () => {
			enqueueSnackbar(
				'Unable to complete the payment at this time. Please try again later.',
				{
					variant: 'error',
				},
			);
		},
		onSettled: () => {
			onClose();
		},
	});

	return (
		<Drawer
			variant="temporary"
			anchor="right"
			open={isOpen}
			onClose={onClose}
			sx={{
				'& .MuiPaper-root': {
					top: 'auto',
					bottom: 0,
					width: DrawerWidth,
					height: 'fit-content',
				},
			}}
		>
			<Grid container sx={{ gap: 2, paddingX: '1.5rem', paddingY: '0.75rem' }}>
				<Grid item xs={12}>
					<Typography variant="h4" fontWeight={500}>
						Complete Payment
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<T4Autocomplete<PaymentStatusTypes>
						id="completion-status"
						label="Status"
						value={completeStatus}
						options={[
							PaymentStatusTypes.Canceled,
							PaymentStatusTypes.Completed,
							PaymentStatusTypes.Failed,
							PaymentStatusTypes.Rejected,
						]}
						onChange={(_, value: PaymentStatusTypes | null) =>
							setCompleteStatus(value)
						}
						getOptionLabel={(option) =>
							getPaymentStatusText(option) ?? PaymentStatusTypes[option]
						}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<T4TextFieldV2
						id="completion-reason"
						label="What is the reason for completion?"
						value={completeReason ?? ''}
						onChange={(value: string) => {
							if (isStringUndefinedOrNullOrWhitespace(value))
								setCompleteReason(null);
							else setCompleteReason(value);
						}}
						multiline
						minRows={4}
						maxRows={4}
						helperText={`${completeReason?.length ?? 0}/200`}
						inputProps={{ maxLength: 200 }}
					/>
				</Grid>
				<Grid
					container
					item
					xs={12}
					sx={{ justifyContent: 'flex-end' }}
					columnSpacing={1}
				>
					<Grid item xs="auto">
						<DrawerCancelButton
							onCancel={onClose}
							stonlyId="complete-payment-cancel-button"
						/>
					</Grid>
					<Grid item xs="auto">
						<T4Button
							color="success"
							variant="contained"
							onClick={() => mutate()}
							disabled={
								!canCompletePayment || isLoading || completeStatus === null
							}
						>
							Complete
						</T4Button>
					</Grid>
				</Grid>
			</Grid>
		</Drawer>
	);
};
