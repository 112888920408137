/* eslint-disable mobx/missing-observer */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, InputAdornment } from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateBalanceNoteMutation } from '../_hooks/useUpdateBalanceNoteMutation';

export type AccountBalanceNoteProps = {
	balanceId: string;
	noteContent: string | undefined;
};

export const AccountBalanceNote: FC<AccountBalanceNoteProps> = ({
	balanceId,
	noteContent,
}) => {
	const {
		isLoading,
		isError,
		isSuccess,
		mutate: updateBalanceNote,
	} = useUpdateBalanceNoteMutation();

	const [note, setNote] = useState<string | undefined>(noteContent);
	useEffect(() => {
		setNote(noteContent);
	}, [noteContent]);

	const characterLimit = useMemo(() => 2048, []);

	const handleChange = useCallback(
		(value: string | undefined) => {
			if ((value?.length ?? 0) <= characterLimit) {
				setNote(value ?? undefined);
			}
		},
		[characterLimit],
	);

	const handleSave = useCallback(async () => {
		if (note !== noteContent) {
			updateBalanceNote({
				id: balanceId,
				note: note,
			});
		}
	}, [balanceId, note, noteContent, updateBalanceNote]);

	return (
		<T4TextFieldV2
			sx={{ mt: 2, mb: 2 }}
			multiline
			minRows={5}
			maxRows={5}
			label="Notes"
			value={note}
			onChange={handleChange}
			onBlur={handleSave}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						{isLoading ? (
							<CircularProgress size={20} />
						) : isSuccess ? (
							<CheckCircleOutlineIcon color="success" />
						) : isError ? (
							<ErrorOutlineIcon color="error" />
						) : null}
					</InputAdornment>
				),
			}}
			helperText={`${note?.length ?? 0}/${characterLimit}`}
		/>
	);
};
