import { useAccountGroupCollectionsQuery } from 'features/administration/accountGroups/hooks/useAccountGroupCollectionsQuery';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { useAccountGroups } from 'shared/providers/accountGroupsProvider';
import { T4MultiSelectAutocomplete } from './autocompletes/t4MultiselectAutocomplete';

type AccountGroupOption = {
	id: string;
	collectionName: string;
	name: string;
};

export type AccountGroupsFilterProps = {
	callback?: (accountGroupIds: string[]) => void;
};

export const AccountGroupsFilter: FC<AccountGroupsFilterProps> = observer(
	({ callback }) => {
		const { loading, data } = useAccountGroupCollectionsQuery();
		const { accountGroupIds, setAccountGroupIds } = useAccountGroups();

		const options = useMemo<AccountGroupOption[]>(() => {
			return (
				data?.flatMap(
					(collection) =>
						collection.groups?.map((group) => ({
							id: group.id,
							collectionName: collection.name,
							name: group.name,
						})) ?? [],
				) ?? []
			);
		}, [data]);

		const value = useMemo<AccountGroupOption[]>(() => {
			return options.filter((option) => accountGroupIds.includes(option.id));
		}, [accountGroupIds, options]);

		return (
			<T4MultiSelectAutocomplete<AccountGroupOption>
				disablePortal
				loading={loading}
				disabled={loading}
				label="Filter by Account Group"
				options={options}
				value={value}
				groupBy={(x) => x.collectionName}
				getOptionKey={(option) => option.id}
				getOptionLabel={(x) => x.name}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(_, values) => {
					const accountGroupIds = values.map((x) => x.id);

					setAccountGroupIds(accountGroupIds);
					callback?.(accountGroupIds);
				}}
				listHelperText="Account groups are managed by administrators through Global Settings within Administration."
				placeholder={
					accountGroupIds.length === 0 ? 'Select account groups' : ''
				}
				startAdornment={
					<T4FieldAdornment
						title="Filter by account group"
						description="Selecting an account group filters records based on the account"
						t4AdornmentType="info"
					/>
				}
				sx={{
					minWidth: '20rem',
				}}
				disableListWrap
			/>
		);
	},
);
