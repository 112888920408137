import { UseT4QueryProps } from 'features/cash4/_shared/_utilities/useT4Query';
import { observer } from 'mobx-react-lite';
import {
	Cash4Configurations,
	UpdateCash4ConfigurationsReqeust,
} from 'modules/clients/customer-api/src/api/administration';
import { useSnackbar } from 'notistack';
import { createContext, FC, ReactNode, useCallback, useContext } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useCash4ConfigurationsQuery } from '../_hooks/useCash4ConfigurationsQuery';

//#region Context

type Cash4ConfigurationsContextProps = UseT4QueryProps<Cash4Configurations> & {
	refetch: () => Promise<void>;
	update: (input: UpdateCash4ConfigurationsReqeust) => Promise<void>;
	updateEnabledMoneyMarkets: (moneyMarketIds: string[]) => Promise<void>;
};

const Cash4ConfigurationsContext =
	createContext<Cash4ConfigurationsContextProps>(
		{} as Cash4ConfigurationsContextProps,
	);

//#endregion

//#region Provider

export type Cash4ConfigurationsProviderProps = {
	children: ReactNode;
};

export const Cash4ConfigurationsProvider: FC = observer(({ children }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();
	const { isInitialLoading, isLoading, data, refetch } =
		useCash4ConfigurationsQuery();

	const update = useCallback(
		async (input: UpdateCash4ConfigurationsReqeust) => {
			try {
				await customerApiClient.api.administration.updateCash4Configurations(
					input,
				);

				refetch();
			} catch {
				enqueueSnackbar('Failed to update Cash4 configurations.', {
					variant: 'error',
				});
			}
		},
		[customerApiClient.api.administration, enqueueSnackbar, refetch],
	);

	const updateEnabledMoneyMarkets = useCallback(
		async (moneyMarketIds: string[]) => {
			if (data) {
				try {
					await customerApiClient.api.administration.updateEnabledMoneyMarkets({
						id: data?.id,
						enabledMoneyMarkets: moneyMarketIds,
					});

					refetch();
				} catch {
					enqueueSnackbar('Failed to update enabled money markets.', {
						variant: 'error',
					});
				}
			}
		},
		[customerApiClient.api.administration, data, enqueueSnackbar, refetch],
	);

	return (
		<Cash4ConfigurationsContext.Provider
			value={{
				initializing: isInitialLoading,
				loading: isLoading,
				data: data,
				refetch: refetch as any,
				update: update,
				updateEnabledMoneyMarkets: updateEnabledMoneyMarkets,
			}}
		>
			{children}
		</Cash4ConfigurationsContext.Provider>
	);
});

//#endregion

//#region Hook

export type UseCash4ConfigurationsProps = Cash4ConfigurationsContextProps;

export function useCash4Configurations(): UseCash4ConfigurationsProps {
	return useContext(Cash4ConfigurationsContext);
}

//#endregion
