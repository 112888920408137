import { Grid } from '@mui/material';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { CannotDisplay } from '../../../shared/components/cannotDisplay';
import { ACCESS_DENIED_MESSAGING } from '../../../shared/constants/cannotDisplayMessaging';
import AuditViewModel from './auditViewModel';

export type AuditPageProps = {
	viewModel: AuditViewModel;
};

const InnerAuditPage: React.FC<AuditPageProps> = observer(({ viewModel }) => {
	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.auditPage}
					title="Audit Log"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
		>
			<Grid
				container
				sx={{
					flexDirection: 'column',
					flexWrap: 'nowrap',
					height: '100%',
					gap: 2,
				}}
			>
				<Grid item xs="auto">
					<T4DateRangePicker
						value={viewModel.getDateRange()}
						onChange={viewModel.updateDateRange}
						onClose={viewModel.onDateSelect}
					/>
				</Grid>
				<Grid item xs>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid
							tableKey="auditPage"
							stonlyId="audit"
							rows={viewModel.getAudits()}
							calculateColumnWidths={true}
							hideFooter={false}
							loading={viewModel.getLoading()}
							errorMessage={viewModel.getError()}
							pagination
							showToolbar
							showCustomViewButton
							initialState={{
								sorting: {
									sortModel: [
										{
											field: 'date',
											sort: 'desc',
										},
									],
								},
							}}
							columns={[
								{
									...getDateColumnDefinition('DD-MMM-YYYY h:mma'),
									field: 'date',
									headerName: 'Date',
								},
								{
									field: 'user',
									headerName: 'User',
								},
								{
									field: 'action',
									headerName: 'Action',
								},
								{
									field: 'area',
									headerName: 'Area',
								},
								{
									field: 'areaName',
									headerName: 'Area Name',
								},
								{
									field: 'fieldChanged',
									headerName: 'Field Changed',
								},
								{
									field: 'oldValue',
									headerName: 'Old Value',
								},
								{
									field: 'newValue',
									headerName: 'New Value',
								},
							]}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>
		</T4View>
	);
});

export const AuditPage: FC = observer(() => {
	const [viewModel] = useState(new AuditViewModel());
	const { isAdmin } = useUser();
	if (!isAdmin) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}

	return <InnerAuditPage viewModel={viewModel} />;
});
