import { Grid } from '@mui/material';
import { DesktopTimePicker } from '@mui/x-date-pickers-pro';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4Checkbox } from 'features/entity4/shared/components/atoms/t4Checkbox';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { ConfirmFormModal } from 'shared/components/confirmFormModal';
import { UseSetAccountIntegrationScheduleProps } from '../hooks/useSetAccountIntegrationSchedule';

export type SetAccountIntegrationScheduleModalProps = {
	setAccountIntegrationSchedule: UseSetAccountIntegrationScheduleProps;
};

export const SetAccountIntegrationScheduleModal: FC<SetAccountIntegrationScheduleModalProps> =
	observer(({ setAccountIntegrationSchedule }) => {
		return (
			<ConfirmFormModal
				loading={setAccountIntegrationSchedule.isLoading}
				isOpen={setAccountIntegrationSchedule.isOpen}
				isDirty={setAccountIntegrationSchedule.isDirty}
				disableSubmit={setAccountIntegrationSchedule.isSubmitDisabled}
				error={setAccountIntegrationSchedule.error}
				modalTitle={'Account Integration Schedule'}
				cancelModalTitle={'Discard Schedule?'}
				cancelModalDescription={
					'Are you sure you want to discard this Account Integration Schedule? Your changes will not be saved.'
				}
				cancelButtonColor="warning"
				onSubmit={setAccountIntegrationSchedule.setAccountIntegrationSchedule}
				onClose={setAccountIntegrationSchedule.closeModal}
				submitButtonLabel="Save"
			>
				<Grid container sx={{ gap: 1 }}>
					<Grid item xs={12}>
						<T4Checkbox
							label="Enable scheduled integration"
							checked={setAccountIntegrationSchedule.enabled}
							value={setAccountIntegrationSchedule.enabled}
							onChange={(_, value) =>
								setAccountIntegrationSchedule.setEnabled(value)
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<DesktopTimePicker
							key="triggerTime"
							label="Trigger Time"
							value={setAccountIntegrationSchedule.triggerTime}
							onChange={setAccountIntegrationSchedule.setTriggerTime}
							onAccept={setAccountIntegrationSchedule.setTriggerTime}
							slotProps={{
								textField: { fullWidth: true, size: 'small', required: true },
							}}
							ampm={false}
						/>
					</Grid>
					<Grid item xs={12}>
						<T4Autocomplete<string, false, true, false>
							key="triggerTimezone"
							label="Trigger Timezone"
							options={setAccountIntegrationSchedule.timezones}
							value={setAccountIntegrationSchedule.triggerTimezone ?? ''}
							onSelect={setAccountIntegrationSchedule.setTriggerTimezone}
							disableClearable={true}
							required
						/>
					</Grid>
				</Grid>
			</ConfirmFormModal>
		);
	});
