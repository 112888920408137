import { BankConnectionSettingsFieldDefinition } from '../constants';

export type WfbiConnectionSettings = {
	consumerKey: string;
	consumerSecret: string;
	authenticationCertificateName: string | null;
	gatewayCompanyId: string;
	gatewayEntityId: string;
};

export const wfbiConnectionSettingsForm: WfbiConnectionSettings = {
	consumerKey: '',
	consumerSecret: '',
	authenticationCertificateName: null,
	gatewayCompanyId: '',
	gatewayEntityId: '',
};

export const wfbiConnectionSettingsFieldDefinitions: BankConnectionSettingsFieldDefinition[] =
	[
		{
			key: 'consumerKey',
			name: 'Consumer Key',
			type: 'string',
		},
		{
			key: 'consumerSecret',
			name: 'Consumer Secret',
			type: 'string',
			isSecret: true,
		},
		{
			key: 'authenticationCertificateName',
			name: 'Authentication Certificate',
			type: 'certificate',
		},
		{
			key: 'gatewayCompanyId',
			name: 'Gateway Company Id',
			type: 'string',
		},
		{
			key: 'gatewayEntityId',
			name: 'Gateway Entity Id',
			type: 'string',
		},
	];
