import { ApprovalOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Payment } from 'modules/clients/apiGateway/payments4/payments';
import { Moment } from 'moment';
import { FC, useMemo } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { USER_PREFERENCES_FIELD_OPTIONS } from 'shared/utilities/dataGrid/dataGridUtils';
import { dateRangeDefault } from '../hooks/usePayments';

export const AllPaymentsTab: FC<{
	tabIndex: number;
	dateRange: DateRange<Moment>;
	setDateRange: (dateRange: DateRange<Moment>) => void;

	payments: Payment[];
	isLoading: boolean;
	columns: GridColDef[];
}> = ({ tabIndex, dateRange, setDateRange, payments, isLoading, columns }) => {
	const GridNoRowsOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={ApprovalOutlined}
				heading={
					<Typography variant="h3">
						No payments pending approval or rejection.
					</Typography>
				}
			/>
		),
		[],
	);

	return tabIndex === 2 ? (
		<div role="tabpanel" style={{ height: '100%' }}>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', gap: 2 }}
			>
				<Grid item xs="auto">
					<T4DateRangePicker
						value={dateRange}
						onAccept={(dateRange) => setDateRange(dateRange)}
						showShortcuts
						shortcutResetDefaults={dateRangeDefault}
						disabled={isLoading}
					/>
				</Grid>
				<Grid item xs>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid<Payment>
							stonlyId={'all-payments'}
							tableKey={'all-payments'}
							loading={isLoading}
							columns={columns}
							rows={payments}
							showToolbar
							showCustomViewButton
							initialState={{
								sorting: {
									sortModel: [
										{
											field: 'valueDate',
											sort: 'asc',
										},
										{
											field: 'createdOn',
											sort: 'desc',
										},
									],
								},
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							slots={{ noRowsOverlay: GridNoRowsOverlay }}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>
		</div>
	) : null;
};
