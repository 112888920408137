import * as yup from 'yup';
import { WfbiConnectionSettings } from './constants';

export const WfbiConnectionSettingsValidator: yup.ObjectSchema<WfbiConnectionSettings> =
	yup.object({
		consumerKey: yup.string().trim().label('Consumer Key').required(),
		consumerSecret: yup.string().trim().label('Consumer Secret').required(),
		authenticationCertificateName: yup
			.string()
			.trim()
			.label('Authentication Certificate')
			.required(),
		gatewayCompanyId: yup
			.string()
			.trim()
			.label('Gateway Company Id')
			.required(),
		gatewayEntityId: yup.string().trim().label('Gateway Entity Id').required(),
	});
