import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type TransactionNotesProps = {
	transactionId: string;
	transactionNote: string;
};

const TransactionNotes: FC<TransactionNotesProps> = observer(
	({ transactionId, transactionNote }) => {
		const [note, setNote] = useState<string>(transactionNote);
		const characterLimit = 2048;

		const { enqueueSnackbar } = useSnackbar();
		const queryClient = useQueryClient();
		const { customerApiClient } = useClients();

		const handleChange = (value: string) => {
			if (value.length <= characterLimit) {
				setNote(value);
			}
		};

		async function updateNote(note: string, transactionId: string) {
			const newNotePayload = {
				note: { content: note },
				transactionId: transactionId,
			};
			return await customerApiClient.transactions.updateNote(newNotePayload);
		}

		const {
			mutate: updateNoteMutation,
			isLoading,
			isError,
			isSuccess,
		} = useMutation((newNote: string) => updateNote(newNote, transactionId), {
			onSuccess: () => {
				queryClient.invalidateQueries([cash4QueryKeys.reportedTransactions]);
				enqueueSnackbar('Note saved', { variant: 'success' });
			},
			onError: () => {
				enqueueSnackbar('Failed to save note', { variant: 'error' });
			},
			onSettled: () => {
				queryClient.invalidateQueries(['transaction', transactionId]);
			},
		});

		const handleSave = async () => {
			updateNoteMutation(note);
		};

		useEffect(() => {
			setNote(transactionNote);
		}, [transactionNote]);

		return (
			<Box>
				<T4TextFieldV2
					sx={{ mt: 2, mb: 2 }}
					multiline
					minRows={5}
					maxRows={5}
					label="Notes"
					value={note}
					onChange={handleChange}
					onBlur={handleSave}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								{isLoading ? (
									<CircularProgress size={20} />
								) : isSuccess ? (
									<CheckCircleOutlineIcon color="success" />
								) : isError ? (
									<ErrorOutlineIcon color="error" />
								) : null}
							</InputAdornment>
						),
					}}
					helperText={`${note?.length ?? 0}/${characterLimit}`}
				/>
			</Box>
		);
	},
);

export default TransactionNotes;
