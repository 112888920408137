import { Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'shared/hooks/useUser';
import { getQueryParam } from 'shared/utilities/navigationUtilities';
import { stonlyData } from 'stonly/functions';

export type Dimensions = {
	maxHeight: number | undefined;
	tableMaxHeight: number | undefined;
};

export type TabDetails = {
	tab: string;
	component: ReactNode;
};

export type RelationshipsLayoutProps = {
	createRelationshipButton: ReactNode;
	tabDetails: TabDetails[];
	children: ReactNode;
};

export const RelationshipsLayout: FC<RelationshipsLayoutProps> = observer(
	({ createRelationshipButton, tabDetails: tabs = [], children }) => {
		const { isAuthor } = useUser();
		const theme = useTheme();
		const history = useHistory();

		const headerRef = useRef<HTMLDivElement>(null);

		const currentTab = useMemo(() => {
			let tab = tabs.length > 0 ? tabs[0].tab : '';

			const queryTab = getQueryParam(history.location.search, 'tab');
			if (queryTab) {
				tab = queryTab;
			}

			return tab;
		}, [history.location.search, tabs]);

		const setTab = useCallback(
			(newTab: string) => {
				if (newTab) {
					history.push(history.location.pathname + `?tab=${newTab}`);
				}
			},
			[history],
		);

		const tabComponent = useMemo(() => {
			return tabs.find((x) => x.tab === currentTab)?.component;
		}, [tabs, currentTab]);

		return (
			<Grid
				container
				sx={{
					height: '100%',
					flexWrap: 'nowrap',
				}}
			>
				<Grid item xs={1.5} sx={{ paddingTop: '1.25rem' }}>
					<Tabs
						orientation="vertical"
						value={currentTab}
						onChange={(_, tab) => setTab(tab)}
						sx={{
							position: 'sticky',
							top: '1.25rem',
							justifySelf: 'flex-start',
						}}
					>
						{tabs.map((x) => (
							<Tab
								data-testid={`${x.tab.toLowerCase()}-tab`}
								{...stonlyData({
									id: `${x.tab.toLowerCase()}-tab`,
								})}
								key={x.tab}
								value={x.tab}
								label={x.tab}
								color="secondary"
								sx={(theme) => ({
									alignItems: 'flex-start',
									...(x.tab === currentTab
										? {
												backgroundColor: theme.palette.denim[50],
												color: theme.palette.denim[500],
										  }
										: {}),
								})}
							/>
						))}
					</Tabs>
				</Grid>
				<Grid
					container
					item
					xs={10.5}
					sx={{
						flexDirection: 'column',
						flexWrap: 'nowrap',
						overflowY: 'auto',
						paddingY: '1.25rem',
						paddingX: '2rem',
						borderLeft: '1px solid',
						borderColor: theme.palette.charcoal[50],
						gap: '2rem',
					}}
				>
					<Grid
						ref={headerRef}
						container
						item
						xs="auto"
						sx={{
							width: '100%',
							justifyContent: 'space-between',
							alignItems: 'flex-end',
						}}
					>
						<Typography variant="h3">{currentTab}</Typography>
						{isAuthor && createRelationshipButton}
					</Grid>
					<Grid item xs>
						{tabComponent}
					</Grid>
				</Grid>
				{children}
			</Grid>
		);
	},
);
