/* eslint-disable mobx/missing-observer */
import * as yup from 'yup';
import {
	BankConnectionForm,
	BankConnectionSettingsType,
	BankConnectionType,
	BankConnectionTypes,
} from './constants';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import {
	Account,
	BankConnection,
	LegalEntity,
} from 'modules/clients/apiGateway/payments4/bankConnections';
import { getBankConnectionSpecification } from './utilities';

export const GetBankConnectionFormValidator = (
	bankConnections: BankConnection[],
): yup.ObjectSchema<BankConnectionForm> => {
	return yup.object({
		id: yup.string().trim().nullable().defined(),
		name: yup
			.string()
			.trim()
			.label('Connection Name')
			.required()
			.test(
				'non-unique-name',
				'Bank connection name must be unique',
				function (value) {
					return !bankConnections.some(
						(x) =>
							x.name.toLocaleLowerCase() === value.trim().toLocaleLowerCase() &&
							(this.parent.id === null || x.id !== this.parent.id),
					);
				},
			),
		bank: yup.mixed<FinancialInstitution>().label('Bank').required(),
		connectionType: yup
			.mixed<BankConnectionType>()
			.label('Connection Type')
			.oneOf(BankConnectionTypes)
			.required()
			.test(
				'global-connection-already-exists',
				'A global connection for this bank already exists',
				function (value) {
					if (value === 'Global')
						return !bankConnections.some(
							(x) =>
								x.isGlobalConnection &&
								x.bankCode === this.parent.bank?.bankCode &&
								(this.parent.id === null || x.id !== this.parent.id),
						);
					return true;
				},
			),
		connectionSettings: yup
			.mixed<BankConnectionSettingsType>()
			.when('bank', ([bank], schema) => {
				if (bank !== null)
					return schema
						.concat<any, yup.AnyObject, any, ''>(
							getBankConnectionSpecification(bank.bankCode)!.validator,
						)
						.required();
				else return schema.nullable();
			})
			.defined(),
		e4Entities: yup
			.array<LegalEntity>()
			.label('Entities')
			.required()
			.when('connectionType', ([connectionType], schema) => {
				if (connectionType === 'Entity') return schema.min(1);
				else return schema.length(0);
			}),
		e4Accounts: yup
			.array<Account>()
			.label('Accounts')
			.required()
			.when('connectionType', ([connectionType], schema) => {
				if (connectionType === 'Account') return schema.min(1);
				else return schema.length(0);
			}),
		notes: yup.string().trim().label('Notes').max(140).nullable().defined(),
	});
};
