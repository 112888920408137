import { AxiosInstance, AxiosResponse } from 'axios';
import { T4DataResponse2 } from 'modules/clients/types';

export type AnalyaticStudioConfigurationEndpoints = {
	getInterval: () => Promise<AxiosResponse<T4DataResponse2<number>>>;
	updateInterval: (
		interval: number,
	) => Promise<AxiosResponse<T4DataResponse2<number>, number>>;
};

export function analyticSuticoConfiguraitons(
	axiosInstance: AxiosInstance,
): AnalyaticStudioConfigurationEndpoints {
	return {
		getInterval: async () =>
			await axiosInstance.get(
				'/api/administration/analytic-studio-configurations/interval',
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			),
		updateInterval: async (interval: number) =>
			await axiosInstance.post(
				'/api/administration/analytic-studio-configurations/interval',
				interval,
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			),
	};
}
