import { Button, Checkbox, Grid, Typography } from '@mui/material';
import { C4Drawer } from 'features/cash4/reconciliations/_components/c4Drawer';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import {
	AccountGroup,
	AccountGroupCollection,
} from 'modules/clients/customer-api/src/api/administration/accountGroupCollections';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type AccountGroupCollectionDrawerProps = {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	collections?: AccountGroupCollection[];
	collection?: AccountGroupCollection;
};

export const AccountGroupCollectionDrawer: FC<AccountGroupCollectionDrawerProps> =
	observer(({ open, onClose, onSuccess, collection, collections }) => {
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();

		const [view, setView] = useState<'create' | 'edit'>('create');
		const groups = useMemo(() => {
			return (
				collections?.flatMap((collection) => collection.groups ?? []) ?? []
			);
		}, [collections]);

		const [name, setName] = useState<string>();
		const [nameError, setNameError] = useState(false);
		const [nameErrorReason, setNameErrorReason] = useState<string>();
		useEffect(() => {
			let isValid = true;

			if ((name?.trim().length ?? 0) === 0) {
				isValid = false;
				setNameErrorReason('Name is required.');
			}

			if ((name?.trim().length ?? 0) > 256) {
				isValid = false;
				setNameErrorReason('Name is too long.');
			}

			if (
				collections
					?.filter((x) => x.id !== collection?.id)
					?.some((x) => x.name === name?.trim()) ??
				false
			) {
				isValid = false;
				setNameErrorReason('Name is not unique.');
			}

			if (isValid) {
				setNameError(false);
				setNameErrorReason(undefined);
			} else {
				setNameError(true);
			}

			return () => {
				setNameError(false);
				setNameErrorReason(undefined);
			};
		}, [collection?.id, collections, name]);

		const [description, setDescription] = useState<string>();
		const [descriptionError, setDescriptionError] = useState(false);
		const [descriptionErrorReason, setDescriptionErrorReason] =
			useState<string>();
		useEffect(() => {
			let isValid = true;

			if ((description?.trim().length ?? 0) > 256) {
				isValid = false;
				setDescriptionErrorReason('Description is too long.');
			}

			if (isValid) {
				setDescriptionError(false);
				setDescriptionErrorReason(undefined);
			} else {
				setDescriptionError(true);
			}

			return () => {
				setDescriptionError(false);
				setDescriptionErrorReason(undefined);
			};
		}, [description]);

		const [selectedGroups, setselectedGroups] = useState<AccountGroup[]>([]);

		const reset = useCallback(() => {
			setName(undefined);
			setDescription(undefined);
			setselectedGroups([]);
		}, []);

		useEffect(() => {
			return () => {
				setName(undefined);
				setDescription(undefined);
				setselectedGroups([]);
			};
		}, []);

		useEffect(() => {
			if (collection) {
				setView('edit');

				setName(collection.name);
				setDescription(collection.description);
				setselectedGroups(collection.groups ?? []);
			} else {
				setView('create');

				setName(undefined);
				setDescription(undefined);
				setselectedGroups([]);
			}
		}, [collection]);

		const title = useMemo(
			() => (view === 'create' ? 'Create collection' : 'Edit collection'),
			[view],
		);
		const submitText = useMemo(
			() => (view === 'create' ? 'Create' : 'Save'),
			[view],
		);
		const canSubmit = useMemo(() => {
			let isValid = true;

			if ((name?.trim().length ?? 0) === 0) {
				isValid = false;
			}
			if (
				collections
					?.filter((x) => x.id !== collection?.id)
					?.some((x) => x.name === name?.trim()) ??
				false
			) {
				isValid = false;
			}

			return isValid;
		}, [collection?.id, collections, name]);

		const onCloseHandler = useCallback(() => {
			onClose();
			reset();
		}, [onClose, reset]);

		const onSubmitHandler = useCallback(async () => {
			if (canSubmit) {
				if (collection) {
					const response =
						await customerApiClient.api.administration.accountGroupCollections.update(
							collection.id,
							{
								name: name?.trim() ?? '',
								description: description?.trim(),
								groupIds: selectedGroups.map((x) => x.id),
							},
						);

					if (response.status === 200) {
						onClose();
						onSuccess();
						reset();

						enqueueSnackbar('Collection updated successfully.', {
							variant: 'success',
						});
					} else {
						let message = 'An error occurred while updating collection.';
						if (typeof response?.data?.data === 'string') {
							message = response?.data?.data;
						}

						enqueueSnackbar(message, {
							variant: 'error',
						});
					}
				} else {
					const response =
						await customerApiClient.api.administration.accountGroupCollections.create(
							{
								name: name?.trim() ?? '',
								description: description?.trim(),
								groupIds: selectedGroups.map((x) => x.id),
							},
						);

					if (response.status === 201) {
						onClose();
						onSuccess();
						reset();

						enqueueSnackbar('Collection created successfully.', {
							variant: 'success',
						});
					} else {
						let message = 'An error occurred while creating the collection.';
						if (typeof response?.data?.data === 'string') {
							message = response?.data?.data;
						}

						enqueueSnackbar(message, {
							variant: 'error',
						});
					}
				}
			}
		}, [
			canSubmit,
			collection,
			customerApiClient.api.administration.accountGroupCollections,
			description,
			enqueueSnackbar,
			name,
			onClose,
			onSuccess,
			reset,
			selectedGroups,
		]);

		return (
			<C4Drawer
				open={open}
				onClose={onCloseHandler}
				title={<Typography variant="h3">{title}</Typography>}
				actions={{
					end: [
						<Button color="secondary" onClick={onCloseHandler}>
							Cancel
						</Button>,
						<Button
							variant="contained"
							disabled={!canSubmit}
							onClick={() => onSubmitHandler()}
						>
							{submitText}
						</Button>,
					],
				}}
				sx={{
					'& .MuiDrawer-paper': {
						width: '30rem',
					},
				}}
			>
				<Grid
					container
					direction="column"
					sx={{ height: '100%', gap: 1, maxWidth: '30rem' }}
				>
					<Grid item xs="auto">
						<T4TextFieldV2
							label="Name"
							value={name ?? ''}
							onChangeActual={(event) => setName(event.target.value)}
							error={nameError}
							helperText={nameErrorReason}
							autoFocus
							required
						/>
					</Grid>
					<Grid item xs="auto">
						<T4TextFieldV2
							label="Description"
							value={description ?? ''}
							onChangeActual={(event) => setDescription(event.target.value)}
							error={descriptionError}
							helperText={descriptionErrorReason}
							minRows={3}
							maxRows={3}
							multiline
						/>
					</Grid>
					<Grid item xs="auto">
						<T4Autocomplete
							label="Assign Account Groups to Collection"
							multiple
							options={groups}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(a, b) => a?.id === b?.id}
							renderOption={({ key, ...optionProps }, option, state) => (
								<li key={key} {...optionProps}>
									<Checkbox
										size="small"
										style={{ marginRight: 8 }}
										checked={state.selected}
									/>
									{option.name}
								</li>
							)}
							value={selectedGroups}
							onChange={(_, value) => setselectedGroups(value)}
							filterSelectedOptions
							disableCloseOnSelect
							disableListWrap
							sx={{
								'& .MuiAutocomplete-tag': {
									maxWidth: '15rem',
								},
							}}
						/>
					</Grid>
				</Grid>
			</C4Drawer>
		);
	});
