import { useQuery } from '@tanstack/react-query';
import { EntityTypeId } from 'features/entity4/entity4Constants';
import { Account } from 'modules/clients/apiGateway/entity4/accounts';
import { Counterparty } from 'modules/clients/apiGateway/entity4/counterparties';
import { LegalEntity } from 'modules/clients/apiGateway/entity4/legalentities';
import { Partner } from 'modules/clients/apiGateway/entity4/partners';
import { PaymentTemplate } from 'modules/clients/apiGateway/payments4/paymentTemplates';
import {
	CashFlowClass,
	CashFlowSubtype,
	CashFlowType,
	GLCode,
} from 'modules/clients/customer-api/src/api/cash4';
import { T4DataResponse2 } from 'modules/clients/types';
import { useMemo } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useGetCategorization } from '../hooks/useCategorization';
import {
	PaymentTemplateQueryKeys,
	useGetPaymentTemplate,
} from '../hooks/usePaymentTemplates';
import { usePaymentTemplateFormDataFetchers } from './usePaymentTemplateFormDataFetchers';
import {
	CounterpartyWithAccounts,
	groupAccountsByCounterparties,
	PayeeTypes,
} from './utilities';

export const EditPaymentTemplateFormQueryKeys = {
	getInitiator: (id: string | null | undefined) =>
		[...PaymentTemplateQueryKeys.base, 'initiator', 'get', id] as const,
	getInitiatorCounterparty: (id: string | null | undefined) =>
		[
			...EditPaymentTemplateFormQueryKeys.getInitiator(id),
			'counterparty',
		] as const,
	getPayee: (id: string | null | undefined) =>
		[...PaymentTemplateQueryKeys.base, 'payee', 'get', id] as const,
	getPayeeCounterparty: (id: string | null | undefined) =>
		[...EditPaymentTemplateFormQueryKeys.getPayee(id), 'counterparty'] as const,
};

const convertToPayeeType = (value: EntityTypeId | null): PayeeTypes => {
	switch (value) {
		case EntityTypeId.InternalEntity:
			return 'Entity';
		case EntityTypeId.Partner:
			return 'Partner';
		case EntityTypeId.Counterparty:
			return 'Counterparty';
		default:
			return 'Entity';
	}
};

export type EditPaymentTemplateForm = {
	name: string;
	initiator: LegalEntity | null;
	initiatorBank: CounterpartyWithAccounts | null;
	initiatorAccount: Account | null;
	initiatorNeedsProjectedTransaction: boolean;
	initiatorCashFlowClass: CashFlowClass | null;
	initiatorCashFlowType: CashFlowType | null;
	initiatorCashFlowSubtype: CashFlowSubtype | null;
	initiatorGlCode: GLCode | null;
	payeeType: PayeeTypes;
	payee: LegalEntity | Partner | Counterparty | null;
	payeeBank: CounterpartyWithAccounts | null;
	payeeAccount: Account | null;
	referenceData: string | null;
	payeeNeedsProjectedTransaction: boolean;
	payeeCashFlowClass: CashFlowClass | null;
	payeeCashFlowType: CashFlowType | null;
	payeeCashFlowSubtype: CashFlowSubtype | null;
	payeeGlCode: GLCode | null;
};

export const defaultEditPaymentTemplateForm: EditPaymentTemplateForm = {
	name: '',
	initiator: null,
	initiatorBank: null,
	initiatorAccount: null,
	initiatorNeedsProjectedTransaction: false,
	initiatorCashFlowClass: null,
	initiatorCashFlowType: null,
	initiatorCashFlowSubtype: null,
	initiatorGlCode: null,
	payeeType: 'Entity',
	payee: null,
	payeeBank: null,
	payeeAccount: null,
	referenceData: null,
	payeeNeedsProjectedTransaction: false,
	payeeCashFlowClass: null,
	payeeCashFlowType: null,
	payeeCashFlowSubtype: null,
	payeeGlCode: null,
};

type UseGetEditPaymentTemplateFormProps = {
	error: Error | null;
	isLoading: boolean;
	template: EditPaymentTemplateForm | undefined;
	originalTemplate: PaymentTemplate | undefined;
};

export const useGetEditPaymentTemplateForm = (
	templateId: string | null,
): UseGetEditPaymentTemplateFormProps => {
	const { applicationApiClient } = useClients();
	const { getValidAccountsOwnedByObjectAsync } =
		usePaymentTemplateFormDataFetchers();

	const {
		isFetching: isTemplateFetching,
		data: template,
		error: templateLoadingError,
	} = useGetPaymentTemplate(templateId);

	const {
		isFetching: isInitiatorFetching,
		data: initiator,
		error: loadingInitiatorError,
	} = useQuery<LegalEntity | undefined, Error>(
		EditPaymentTemplateFormQueryKeys.getInitiator(template?.initiator.entityId),
		async () => {
			if (template?.initiator.entityId === null) return;

			const response = await applicationApiClient.entity4.legalEntities.get(
				template!.initiator.entityId,
			);
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<LegalEntity>).data;
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled: !!template,
		},
	);

	const {
		isFetching: isPayeeFetching,
		data: payee,
		error: loadingPayeeError,
	} = useQuery<LegalEntity | Partner | Counterparty | undefined, Error>(
		EditPaymentTemplateFormQueryKeys.getPayee(template?.payee.entityId),
		async () => {
			if (
				template?.payee.entityId === null ||
				template?.payee.partyType === null
			)
				return;
			const getPayee = async () => {
				if (template!.payee.partyType! === EntityTypeId.InternalEntity) {
					const response = await applicationApiClient.entity4.legalEntities.get(
						template!.payee.entityId!,
					);
					if (response.status === 200 && response.data)
						return (response.data as T4DataResponse2<LegalEntity>).data;
					else throw new Error();
				} else if (template!.payee.partyType! === EntityTypeId.Partner) {
					const response = await applicationApiClient.entity4.partners.get(
						template!.payee.entityId!,
					);
					if (response.status === 200 && response.data)
						return (response.data as T4DataResponse2<Partner>).data;
					else throw new Error();
				} else if (template!.payee.partyType! === EntityTypeId.Counterparty) {
					const response =
						await applicationApiClient.entity4.counterparties.get(
							template!.payee.entityId!,
						);
					if (response.status === 200 && response.data)
						return (response.data as T4DataResponse2<Counterparty>).data;
					else throw new Error();
				}
			};

			return await getPayee();
		},
		{
			refetchOnWindowFocus: false,
			enabled: !!template,
		},
	);

	const {
		isFetching: isInitiatorCounterpartyFetching,
		data: initiatorCounterparty,
		error: loadingInitiatorCounterpartyError,
	} = useQuery<CounterpartyWithAccounts | undefined, Error>(
		EditPaymentTemplateFormQueryKeys.getInitiatorCounterparty(
			template?.initiator.bank.entityId,
		),
		async () => {
			const response = await getValidAccountsOwnedByObjectAsync(initiator!);
			return groupAccountsByCounterparties(response).find(
				(x) => x.id === template?.initiator.bank.entityId,
			);
		},
		{
			enabled: !!template && !!initiator,
			refetchOnWindowFocus: false,
		},
	);

	const {
		isFetching: isPayeeCounterpartyFetching,
		data: payeeCounterparty,
		error: loadingPayeeCounterpartyError,
	} = useQuery<CounterpartyWithAccounts | undefined, Error>(
		EditPaymentTemplateFormQueryKeys.getPayeeCounterparty(
			template?.payee.bank.entityId,
		),
		async () => {
			const response = await getValidAccountsOwnedByObjectAsync(payee!);
			return groupAccountsByCounterparties(response).find(
				(x) => x.id === template?.payee.bank.entityId,
			);
		},
		{
			enabled: !!template && !!payee,
			refetchOnWindowFocus: false,
		},
	);

	const { isCategorizationLoading, cashFlowClasses, glCodes } =
		useGetCategorization();

	const initiatorAccount = useMemo(
		() =>
			initiatorCounterparty?.accounts?.find(
				(x) => x.id === template?.initiator.accountId,
			),
		[initiatorCounterparty, template],
	);

	const payeeAccount = useMemo(
		() =>
			payeeCounterparty?.accounts?.find(
				(x) => x.id === template?.payee.accountId,
			),
		[payeeCounterparty, template],
	);

	const initiatorCategorization = useMemo(() => {
		const cashFlowClass = cashFlowClasses.find(
			(x) => x.id === template?.initiator.cashFlowClassId,
		);
		const cashFlowType = cashFlowClass?.types.find(
			(x) => x.id === template?.initiator.cashFlowTypeId,
		);
		const cashFlowSubtype = cashFlowType?.subtypes.find(
			(x) => x.id === template?.initiator.cashFlowSubtypeId,
		);
		return {
			cashFlowClass,
			cashFlowType,
			cashFlowSubtype,
			glCode: glCodes.find((x) => x.id === template?.initiator.glCodeId),
		};
	}, [cashFlowClasses, glCodes, template]);

	const payeeCategorization = useMemo(() => {
		const cashFlowClass = cashFlowClasses.find(
			(x) => x.id === template?.payee.cashFlowClassId,
		);
		const cashFlowType = cashFlowClass?.types.find(
			(x) => x.id === template?.payee.cashFlowTypeId,
		);
		const cashFlowSubtype = cashFlowType?.subtypes.find(
			(x) => x.id === template?.payee.cashFlowSubtypeId,
		);
		return {
			cashFlowClass,
			cashFlowType,
			cashFlowSubtype,
			glCode: glCodes.find((x) => x.id === template?.payee.glCodeId),
		};
	}, [cashFlowClasses, glCodes, template]);

	const isLoading = useMemo(
		() =>
			isTemplateFetching ||
			isInitiatorFetching ||
			isPayeeFetching ||
			isInitiatorCounterpartyFetching ||
			isPayeeCounterpartyFetching ||
			isCategorizationLoading,
		[
			isTemplateFetching,
			isInitiatorFetching,
			isPayeeFetching,
			isInitiatorCounterpartyFetching,
			isPayeeCounterpartyFetching,
			isCategorizationLoading,
		],
	);

	const error = useMemo(() => {
		if (
			!!templateLoadingError ||
			!!loadingInitiatorError ||
			!!loadingInitiatorCounterpartyError ||
			!!loadingPayeeError ||
			!!loadingPayeeCounterpartyError
		) {
			if (templateLoadingError?.message === 'Payment template not found.')
				return templateLoadingError;

			return new Error(
				'Unable to load payment template. Please try again later.',
			);
		}
		return null;
	}, [
		templateLoadingError,
		loadingInitiatorError,
		loadingInitiatorCounterpartyError,
		loadingPayeeError,
		loadingPayeeCounterpartyError,
	]);

	const templateForm: EditPaymentTemplateForm | undefined = useMemo(() => {
		if (
			!!template &&
			!!initiator &&
			!!initiatorCounterparty &&
			!!initiatorAccount &&
			!!payee &&
			!!payeeCounterparty &&
			!!payeeAccount
		) {
			return {
				name: template.name,
				paymentType: template.paymentType,
				initiator: initiator,
				initiatorBank: initiatorCounterparty,
				initiatorAccount: initiatorAccount,
				initiatorNeedsProjectedTransaction:
					template.initiator.needsProjectedTransaction,
				initiatorCashFlowClass: initiatorCategorization.cashFlowClass ?? null,
				initiatorCashFlowType: initiatorCategorization.cashFlowType ?? null,
				initiatorCashFlowSubtype:
					initiatorCategorization.cashFlowSubtype ?? null,
				initiatorGlCode: initiatorCategorization.glCode ?? null,
				payeeType: convertToPayeeType(template.payee.partyType),
				payee: payee,
				payeeBank: payeeCounterparty,
				payeeAccount: payeeAccount,
				currencyCode: template.currencyCode,
				referenceData: template.referenceData,
				payeeNeedsProjectedTransaction:
					template.payee.needsProjectedTransaction,
				payeeCashFlowClass: payeeCategorization.cashFlowClass ?? null,
				payeeCashFlowType: payeeCategorization.cashFlowType ?? null,
				payeeCashFlowSubtype: payeeCategorization.cashFlowSubtype ?? null,
				payeeGlCode: payeeCategorization.glCode ?? null,
			};
		}
	}, [
		template,
		initiator,
		initiatorCounterparty,
		initiatorAccount,
		initiatorCategorization,
		payee,
		payeeCounterparty,
		payeeAccount,
		payeeCategorization,
	]);

	return {
		error,
		isLoading,
		template: templateForm,
		originalTemplate: template,
	};
};
