import { useQuery } from '@tanstack/react-query';
import { T4DataResponse2 } from 'modules/clients/types';
import { useClients } from 'shared/hooks/useClients';
import { BankConnectionQueryKeyBase } from './constants';

export const CertificateQueryKeys = {
	getAll: () =>
		[
			...BankConnectionQueryKeyBase,
			'payment-certificate-name',
			'get-all',
		] as const,
};

export const useGetCertificateNames = (isEnabled: boolean = true) => {
	const { applicationApiClient } = useClients();

	return useQuery<string[], Error>(
		CertificateQueryKeys.getAll(),
		async () => {
			const response =
				await applicationApiClient.payments4.bankConnections.getAllCertificateNames();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<string[]>).data.sort((a, b) =>
					a.localeCompare(b),
				);
			else throw new Error('Unable to load certificate names.');
		},
		{
			enabled: isEnabled,
			refetchOnWindowFocus: false,
		},
	);
};
