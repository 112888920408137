import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import {
	BalanceListItem,
	BalancesQueryParams,
} from 'modules/clients/customer-api/src/api/v1/cash4/balances';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useAccountGroups } from 'shared/providers/accountGroupsProvider';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { formatWriteDate } from 'shared/utilities/dateUtilities';
import { mapBalance } from '../_utilities/balanceUtils';

export type BalanceValue = {
	value: number | null;
	valueCurrency: string | null;
	reportingValue: number | null;
};

export type Balance = Pick<
	BalanceListItem,
	| 'statementDate'
	| 'balanceImportedDate'
	| 'lastUpdatedDate'
	| 'e4AccountName'
	| 'e4AccountId'
	| 'e4AccountNumber'
	| 'c4AccountNumber'
	| 'bankCode'
	| 'bankName'
	| 'transactionCount'
	| 'debitTransactionsCount'
	| 'creditTransactionsCount'
	| 'currency'
	| 'foreignExchangeRate'
	| 'foreignExchangeDate'
	| 'note'
	| 'e4AccountType'
> & {
	id: string;
	openingLedgerBalance: BalanceValue;
	openingAvailableBalance: BalanceValue;
	currentLedgerBalance: BalanceValue;
	currentAvailableBalance: BalanceValue;
	totalCreditTransactions: BalanceValue;
	totalDebitTransactions: BalanceValue;
	closingLedgerBalance: BalanceValue;
	closingAvailableBalance: BalanceValue;
	forwardAvailableBalance0Day: BalanceValue;
	forwardAvailableBalance1Day: BalanceValue;
	forwardAvailableBalance2Day: BalanceValue;
	forwardAvailableBalance3Day: BalanceValue;
};

export type UseBalancesQueryProps = UseQueryResult<Balance[], Error>;

export function useBalancesQuery(dateRange: [Moment, Moment]) {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const { legalEntityGroupIds } = useLegalEntityGroups();
	const { accountGroupIds } = useAccountGroups();

	const queryParams = useMemo<BalancesQueryParams>(
		() => ({
			startDate: formatWriteDate(dateRange[0]),
			endDate: formatWriteDate(dateRange[1]),
			legalEntityGroupIds: legalEntityGroupIds,
			accountGroupIds: accountGroupIds,
		}),
		[accountGroupIds, dateRange, legalEntityGroupIds],
	);

	const getBalances = useCallback(async () => {
		const response = await customerApiClient.api.v1.balances.all(queryParams);

		if (response?.data?.value) {
			return response.data.value.map((balance) => mapBalance(balance));
		}

		throw new Error('Unable to get balances.');
	}, [customerApiClient.api.v1.balances, queryParams]);

	const result = useQuery<Balance[], Error>({
		queryKey: [cash4QueryKeys.balances],
		queryFn: getBalances,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	const { isSuccess, isError, refetch } = result;
	useEffect(() => {
		if (isError) {
			enqueueSnackbar('Unable to get balances.', {
				key: 'cash4-balances-failure',
				preventDuplicate: true,
				variant: 'error',
			});
		}
	}, [enqueueSnackbar, isError]);

	useEffect(() => {
		if (isSuccess) {
			refetch();
		}
	}, [getBalances, isSuccess, refetch]);

	return result;
}
