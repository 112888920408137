import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { TransactionRuleListItem } from '../models';
import { useDeleteRule } from '../services';

interface IDeleteRuleModalProps {
	open: boolean;
	onClose: () => void;
	rule: TransactionRuleListItem | null | undefined;
}

export const DeleteRuleModal: FC<IDeleteRuleModalProps> = observer(
	({ rule, open, onClose }) => {
		const { mutate: deleteRule, isLoading } = useDeleteRule(rule?.id ?? '');

		const handleDelete = async () => {
			await deleteRule();
			onClose();
		};

		return (
			<FormModal
				open={open}
				onClose={onClose}
				onSubmit={handleDelete}
				submitDisabled={isLoading}
				submitButtonLabel="Delete"
				submitButtonColor="error"
				title="Delete rule?"
				loading={isLoading}
				fullWidth
				maxWidth="sm"
			>
				<Typography>
					There are existing transactions associated with this rule, and
					deleting this rule removes the categories from those associated
					transactions. Transactions will be categorized by other rules as
					applicable the next time the rules are run.
				</Typography>
			</FormModal>
		);
	},
);
