/* eslint-disable mobx/missing-observer */
import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { FC } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

const T4NumberTextField: FC<TextFieldProps> = ({
	sx,
	variant = 'outlined',
	margin = 'dense',
	size = 'small',
	fullWidth = true,
	...rest
}) => {
	const theme = useTheme();

	return (
		<TextField
			{...rest}
			variant={variant}
			margin={margin}
			size={size}
			fullWidth={fullWidth}
			InputLabelProps={{ ...rest.InputLabelProps, shrink: true }}
			sx={{
				...sx,
				'& .MuiFormLabel-root.Mui-disabled': {
					color: theme.palette.text.primary,
				},
				'& .MuiInputBase-input': {
					color: theme.palette.text.primary,
				},
				'& .MuiFormControlLabel-root.Mui-disabled': {
					color: theme.palette.text.primary,
				},
				'& .MuiFormControlLabel-label': {
					color: theme.palette.text.primary,
				},
				...(rest.InputProps?.readOnly && {
					'& label.Mui-focused': {
						color: '#414042',
					},
					'& .MuiOutlinedInput-root': {
						'&.Mui-focused fieldset': {
							borderColor: '#C0C0C0',
							borderWidth: '1px',
						},
						'&:hover fieldset': {
							borderColor: '#C0C0C0',
							borderWidth: '1px',
						},
					},
				}),
			}}
		/>
	);
};

export interface T4NumberInputProps
	extends Omit<
		NumericFormatProps<TextFieldProps>,
		'customInput' | 'isAllowed'
	> {
	min?: number;
	max?: number;
}

export const T4NumberInput: FC<T4NumberInputProps> = ({
	min,
	max,
	thousandSeparator = true,
	allowLeadingZeros = false,
	...rest
}) => {
	return (
		<NumericFormat
			{...rest}
			thousandSeparator={thousandSeparator}
			allowLeadingZeros={allowLeadingZeros}
			decimalScale={2}
			fixedDecimalScale
			isAllowed={(values) => {
				const { floatValue, formattedValue } = values;
				if (floatValue === null) return formattedValue === '';

				let valid = true;
				if (floatValue !== undefined && min !== undefined)
					valid = floatValue >= min;
				if (floatValue !== undefined && max !== undefined)
					valid = valid && floatValue <= max;
				return valid;
			}}
			customInput={T4NumberTextField}
		/>
	);
};
