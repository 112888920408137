/* eslint-disable mobx/missing-observer */
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers-pro';

export interface T4DatePickerProps<TDateType>
	extends DatePickerProps<TDateType> {}

export function T4DatePicker<TDateType>({
	...rest
}: T4DatePickerProps<TDateType>) {
	return (
		<DesktopDatePicker<TDateType>
			format="YYYY/MM/DD"
			{...rest}
			slotProps={{
				...rest.slotProps,
				textField: {
					size: 'small',
					...rest.slotProps?.textField,
				},
			}}
			sx={{
				marginTop: '8px',
				marginBottom: '4px',
				...rest.sx,
			}}
		/>
	);
}
