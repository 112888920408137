import { Check, Close, Edit, Error, Report } from '@mui/icons-material';
import {
	Button,
	Divider,
	Grid,
	IconButton,
	ListItemText,
	MenuItem,
	Tooltip,
	useTheme,
} from '@mui/material';
import {
	GridCellModes,
	GridCellModesModel,
	GridCellParams,
	GridColDef,
	GridRenderCellParams,
	GridRenderEditCellParams,
	GridValidRowModel,
	GridValueGetterParams,
	GridValueSetterParams,
} from '@mui/x-data-grid-pro';
import { CreateDataImportButton } from 'features/entity4/dataImports/components/createDataImportButton';
import { SensitiveField } from 'features/entity4/entities/components/sensitiveField';
import { staffPiiFields } from 'features/entity4/entities/object/contants';
import {
	EntityConfig,
	EntityType,
	accountFieldIdentifiers,
	aspectUrlIds,
	columnDefinitionNames,
	counterpartyFieldIdentifiers,
	entityFieldIdentifiers,
	partnerFieldIdentifiers,
	staffFieldIdentifiers,
} from 'features/entity4/entity4Constants';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import T4Chip from 'features/entity4/shared/chips/t4Chip';
import { DeleteObjectModal } from 'features/entity4/shared/components/deleteObjectModal';
import { LegalEntityGroupsFilter } from 'features/entity4/shared/components/legalEntityGroupsFilter';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { FieldDataType } from 'features/entity4/shared/fieldSets/fieldTypes';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import {
	IListColumnDto,
	IListTagsDto,
	IListTaxIdsDto,
} from 'features/entity4/shared/repositories/frontendRepository';
import { observer } from 'mobx-react-lite';
import {
	AddLegalEntityGroupsResponse,
	RemoveLegalEntityGroupsResponse,
} from 'modules/clients/customer-api/src/entity4/entities';
import { LegalEntityGroup } from 'modules/clients/customer-api/src/entity4/legalEntityGroups';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { AccountGroupsFilter } from 'shared/components/accountGroupsFilter';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, PageHeaderProps } from 'shared/components/pageHeader';
import { T4Link } from 'shared/components/t4Link';
import { T4View } from 'shared/components/t4View';
import { ACCESS_DENIED_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { useApplicationConfiguration } from 'shared/hooks/useApplicationConfigurations';
import { useClients } from 'shared/hooks/useClients';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import {
	getDateColumnDefinition,
	getMultiSelectGridColDef,
	getSingleSelectGridColDef,
} from 'shared/utilities/dataGrid/columnDefinitions';
import {
	USER_PREFERENCES_FIELD_OPTIONS,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { convertDate } from 'shared/utilities/dateUtilities';
import { referenceDataToJson } from 'shared/utilities/referenceDataUtilities';
import { stonlyData } from 'stonly/functions';
import { areArraysEqual } from 'utilities/objectUtils';
import {
	AddTagResponse,
	RemoveTagResponse,
	Tag,
} from '../../../../modules/clients/customer-api/src/entity4/tags';
import { useTags } from '../../../../shared/hooks/useTags';
import { CreateEntityButton } from '../../entities/components/createEntityButton';
import { useListPage } from '../useListPage';
import {
	EntityObjectTagsEditCellDropdown,
	LegalEntityGroupsEditCellDropdown,
	OptionEditCellDropdown,
	ReferenceEditCellDropdown,
} from './inlineEditInputs';

export const stonlyIds = {
	optionsMenu: 'options-menu',
	listEdit: 'list-edit',
	listReview: 'list-review',
	listView: 'list-view',
};

export const ObjectsPage: FC = observer(() => {
	const { entity4StaffPii } = useT4FeatureFlags();
	const { customerApiClient } = useClients();
	const { approvalConfiguration } = useApplicationConfiguration();
	const { viewType, setViewType } = useProfileView();
	const { enqueueSnackbar } = useSnackbar();
	const { legalEntityGroupCollections, refetch: refetchLegalEntityGroups } =
		useLegalEntityGroups();
	const { tags } = useTags();
	const user = useUser();
	const theme = useTheme();
	const history = useHistory();

	const {
		objectType,
		parentObjectId,
		parentObjectType,
		refetch,
		loading,
		error,
		updateField,
		getReferenceList,
		getOptionList,
		initialColumns,
		initialRows,
		creationFields,
	} = useListPage();

	const navigate = useCallback(
		(id: string, viewType: FieldViews) => {
			let path = generatePath(paths.entity4.objects.object.information.href, {
				objectType: objectType,
				objectId: id,
			});

			if (parentObjectType) {
				path = generatePath(
					paths.entity4.objects.object.subaccounts.subaccount.href,
					{
						objectType: parentObjectType,
						objectId: parentObjectId,
						subaccountId: id,
					},
				);
			}

			switch (viewType) {
				case FieldViews.edit:
					path += '?view=edit';
					break;

				case FieldViews.review:
					path += '?view=review';
					break;

				default:
					break;
			}

			history.push(path);
		},
		[objectType, parentObjectId, parentObjectType, history],
	);

	// #region State
	const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
	const [objectToDelete, setObjectToDelete] = useState<
		{ id: string; code: string } | undefined
	>(undefined);

	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
	const [successfulEdits, setSuccessfulEdits] = useState<{
		[rowId: string]: string[];
	}>({});
	const [showDoneButton, setShowDoneButton] = useState<boolean>(false);
	const [showOverflowMenu, setShowOverflowMenu] = useState<boolean>(false);

	useEffect(() => {
		if (parentObjectId) setViewType(FieldViews.default);

		return () => {
			setIsEditMode(false);
			setShowDoneButton(false);
			if (parentObjectId) setViewType(FieldViews.default);
		};
	}, [objectType, parentObjectId, setViewType]);

	/**
	 * Controlls isEditMode state if on subaccount list page
	 */
	useEffect(() => {
		if (viewType === FieldViews.default) {
			setIsEditMode(false);
			setSuccessfulEdits({});
			setShowDoneButton(false);
		}
	}, [viewType]);

	const pageTitle = useMemo(
		() => EntityConfig.get(objectType)?.sideMenuConfig?.listPageTitle,
		[objectType],
	);

	const showImportButton = useMemo(
		() =>
			(objectType === EntityType.Entity || objectType === EntityType.Account) &&
			user.isDataImporter,
		[objectType, user.isDataImporter],
	);

	useEffect(() => {
		if (
			![
				EntityType.Entity,
				EntityType.Account,
				EntityType.Partner,
				EntityType.Staff,
				EntityType.Counterparty,
			].includes(objectType)
		) {
			setShowOverflowMenu(true);
		}
	}, [objectType]);

	const columns = useMemo(() => {
		const isSuccessfullyChangedValue = (
			params: GridRenderCellParams<GridValidRowModel>,
		) => {
			return successfulEdits[params.row.id]?.includes(params.field) ?? false;
		};

		const gridColumns: GridColDef[] = [
			...initialColumns
				.filter((x) => {
					if (!entity4StaffPii) {
						return !staffPiiFields.includes(x.identifier);
					}

					return true;
				})
				.map((col) => {
					const isSensitive = [
						't4_field_staff_tax_id_number',
						't4_field_passport_number',
						't4_field_drivers_license_number',
						't4_field_permanent_residence_card_number',
					].includes(col.identifier);
					const isEditable =
						user.isAuthor &&
						!col.isAspectField &&
						(!approvalConfiguration.isActive || !col.requiresApproval) &&
						!isSensitive;

					const colDef = {
						field: col.identifier,
						headerName: col.columnName,
						sortable: true,
						filterable: true,
						editable: isEditable,
						cellClassName: () =>
							isSensitive
								? 'sensitive'
								: isEditMode && !isEditable
								? 'disabled'
								: undefined,
						renderCell: (params: GridRenderCellParams<GridValidRowModel>) => {
							if (
								[
									entityFieldIdentifiers.code,
									counterpartyFieldIdentifiers.code,
									partnerFieldIdentifiers.code,
									accountFieldIdentifiers.code,
									staffFieldIdentifiers.code,
								].includes(col.identifier) &&
								!isEditMode
							) {
								let path = generatePath(
									paths.entity4.objects.object.information.href,
									{
										objectType: objectType,
										objectId: params.id as string,
									},
								);

								if (parentObjectType) {
									path = generatePath(
										paths.entity4.objects.object.subaccounts.subaccount.href,
										{
											objectType: parentObjectType,
											objectId: parentObjectId,
											subaccountId: params.id as string,
										},
									);
								}

								return (
									<T4Link to={path} color="secondary">
										{params.formattedValue}
									</T4Link>
								);
							} else if (col.isAspectField) {
								if (params.value) {
									const href =
										col.aspectUrlId === aspectUrlIds.subAccounts
											? paths.entity4.objects.object.subaccounts.href
											: col.aspectUrlId === aspectUrlIds.registeredAgentDetails
											? paths.entity4.objects.object.registeredAgentDetails.href
											: paths.entity4.objects.object.information.href;

									const path = generatePath(href, {
										objectType: objectType,
										objectId: params.id as string,
									});

									return (
										<T4Link to={path} color="secondary">
											{params.value}
										</T4Link>
									);
								}
								return undefined;
							} else if (isSensitive) {
								return (
									<Tooltip
										title={
											!user.isStaffUser
												? 'You do not have the correct permissions to view or edit this field. Contact your administrator if you require access.'
												: 'Sensitive information is not displayed on list pages. Open the record to view details.'
										}
									>
										<SensitiveField
											id={`sensitive-field-${col.identifier}-${
												params.row?.id ?? ''
											}`}
											hideAdornments
											disableMargin
										/>
									</Tooltip>
								);
							} else {
								if (isSuccessfullyChangedValue(params)) {
									return (
										<>
											<T4FieldAdornment t4AdornmentType="success" />
											{params.formattedValue}
										</>
									);
								}
								return undefined;
							}
						},
					} as GridColDef;

					if (col.fieldType === FieldDataType.options) {
						const optionList = getOptionList(col.optionListId ?? '');
						return {
							...getSingleSelectGridColDef(
								optionList.map((x) => x.displayName),
							),
							renderEditCell: (
								params: GridRenderEditCellParams<GridValidRowModel>,
							) => (
								<OptionEditCellDropdown
									params={params}
									definition={col}
									options={optionList}
								/>
							),
							...colDef,
							valueSetter: (
								params: GridValueSetterParams<GridValidRowModel>,
							): GridValidRowModel => {
								if (typeof params.value === 'string') return params.row;

								const optionValue = params.value as Option;
								return {
									...params.row,
									[col.identifier]: optionValue?.displayName,
									[`${col.identifier}_data`]: optionValue,
								};
							},
						};
					} else if (col.referenceCollectionName) {
						const referenceList = getReferenceList(col.referenceCollectionName);
						return {
							...getSingleSelectGridColDef(
								referenceList.map((x) => x.displayName),
							),
							renderEditCell: (
								params: GridRenderEditCellParams<GridValidRowModel>,
							) => (
								<ReferenceEditCellDropdown
									params={params}
									definition={col}
									options={referenceList}
								/>
							),
							...colDef,
							valueSetter: (
								params: GridValueSetterParams<GridValidRowModel>,
							): GridValidRowModel => {
								if (typeof params.value === 'string') return params.row;

								const referenceValue = params.value as ReferenceDataValue;
								return {
									...params.row,
									[col.identifier]: referenceValue?.displayName,
									[`${col.identifier}_data`]: referenceValue,
								};
							},
						};
					} else if (col.fieldType === FieldDataType.text) {
						return {
							type: 'string',
							...colDef,
						};
					} else if (col.fieldType === FieldDataType.longText) {
						return {
							...colDef,
							type: 'string',
							editable: false,
							cellClassName: () => (isEditMode ? 'disabled' : ''),
						};
					} else if (col.fieldType === FieldDataType.date) {
						return {
							...getDateColumnDefinition(),
							...colDef,
							valueSetter: (
								params: GridValueSetterParams<GridValidRowModel>,
							) => {
								const value = convertDate(params.value);
								return {
									...params.row,
									[col.identifier]: value,
								};
							},
						};
					} else if (col.fieldType === FieldDataType.boolean) {
						return {
							type: 'boolean',
							...colDef,
							renderCell: (params: GridRenderCellParams<GridValidRowModel>) => {
								const value = params.value ?? false;
								return (
									<>
										{isSuccessfullyChangedValue(params) ? (
											<T4FieldAdornment t4AdornmentType="success" />
										) : null}
										{value ? (
											<Check sx={{ color: theme.palette.text.primary }} />
										) : (
											<Close sx={{ color: theme.palette.text.disabled }} />
										)}
									</>
								);
							},
						};
					} else return colDef;
				}),
		];

		if (objectType === EntityType.Entity) {
			gridColumns.push({
				...getMultiSelectGridColDef(
					legalEntityGroupCollections
						.flatMap((x) => x.legalEntityGroups)
						.map((x) => x.name),
					true,
				),
				field: 'legalEntityGroupNames',
				headerName: 'Legal Entity Groups',
				width: 240,
				editable: user.isAuthor,
				valueGetter: (params: GridValueGetterParams<GridValidRowModel>) =>
					params.row.legalEntityGroups
						?.map((x: LegalEntityGroup) => x?.name)
						.join(', '),
				cellClassName: () =>
					isEditMode && !user.isAuthor ? 'disabled' : undefined,
				renderCell: (params: GridRenderCellParams<GridValidRowModel>) => {
					if (isSuccessfullyChangedValue(params)) {
						return (
							<>
								<T4FieldAdornment t4AdornmentType="success" />
								{params.formattedValue}
							</>
						);
					}
					return undefined;
				},
				renderEditCell: (
					params: GridRenderEditCellParams<GridValidRowModel>,
				) => {
					return (
						<LegalEntityGroupsEditCellDropdown
							params={params}
							options={legalEntityGroupCollections}
						/>
					);
				},
				valueSetter: (
					params: GridValueSetterParams<GridValidRowModel>,
				): GridValidRowModel => {
					if (typeof params.value === 'string') return params.row;

					const legalEntityGroups = params.value as LegalEntityGroup[];
					return {
						...params.row,
						legalEntityGroups: legalEntityGroups,
					};
				},
			} as GridColDef);
		}

		if (objectType === EntityType.Entity || objectType === EntityType.Partner) {
			gridColumns.unshift({
				field: 'taxIds',
				headerName: 'Tax Id',
				width: 240,
				sortable: true,
				hideSortIcons: true,
				filterable: true,
				editable: false,
				valueGetter: (params: GridValueGetterParams<GridValidRowModel>) => {
					return (params.row.taxIds as IListTaxIdsDto[])?.find(
						(x) => x.isPrimaryFederalIncomeTaxId,
					)?.taxIdNumber;
				},
				renderCell: (params: GridRenderCellParams<GridValidRowModel>) => {
					const taxId: IListTaxIdsDto = params.row?.taxIds?.find(
						(taxId: IListTaxIdsDto) => taxId.isPrimaryFederalIncomeTaxId,
					);
					if (
						taxId?.isPrimaryFederalIncomeTaxId &&
						!isEditMode &&
						taxId.taxIdNumber !== undefined
					) {
						let path = generatePath(
							paths.entity4.objects.object.taxIds.taxId.href,
							{
								objectType: objectType,
								objectId: params.id as string,
								taxIdId: taxId.taxIdId,
							},
						);
						return (
							<T4Link to={path} color="secondary">
								{taxId.taxIdNumber}
							</T4Link>
						);
					} else {
						return null;
					}
				},
			});
		}

		gridColumns.unshift({
			...getMultiSelectGridColDef(tags.map((x) => x.name)),
			field: 'tags',
			headerName: 'Tags',
			width: 240,
			sortable: false,
			hideSortIcons: true,
			filterable: true,
			editable: true,
			align: 'left',
			renderCell: (params: GridRenderCellParams<GridValidRowModel>) => {
				return (
					<>
						{isSuccessfullyChangedValue(params) && (
							<T4FieldAdornment t4AdornmentType="success" />
						)}
						{params.row.tags?.length > 0 &&
							params.row.tags.map((tag: IListTagsDto) => (
								<Tooltip key={tag.name} title={tag.name}>
									<span>
										<T4Chip
											label={tag.name}
											chipColor="denim"
											sx={{ marginRight: '.5em', width: '88px' }}
										/>
									</span>
								</Tooltip>
							))}
					</>
				);
			},
			renderEditCell: (params: GridRenderEditCellParams<GridValidRowModel>) => {
				return (
					<EntityObjectTagsEditCellDropdown params={params} options={tags} />
				);
			},
			valueSetter: (
				params: GridValueSetterParams<GridValidRowModel>,
			): GridValidRowModel => {
				if (typeof params.value === 'string') return params.row;

				const entityObjectTags = params.value as Tag[];

				return {
					...params.row,
					tags: entityObjectTags,
				};
			},
			valueGetter: (params: GridValueGetterParams<GridValidRowModel>) =>
				params.row.tags?.map((x: Tag) => x.name).join(', '),
		});

		if (approvalConfiguration.isActive && !isEditMode) {
			if (user.isAuthor) {
				gridColumns.unshift({
					field: columnDefinitionNames.hasRejections,
					headerName: '',
					width: 50,
					sortable: true,
					hideSortIcons: false,
					filterable: false,
					disableExport: true,
					align: 'center',

					renderCell: (params: GridRenderCellParams<GridValidRowModel>) => {
						if (params.row.hasRejections) {
							return (
								<Tooltip title={columnDefinitionNames.hasRejections}>
									<IconButton
										onClick={() => {
											navigate(params.id as string, FieldViews.edit);
										}}
									>
										<Report
											color="error"
											aria-label={columnDefinitionNames.hasRejections}
										/>
									</IconButton>
								</Tooltip>
							);
						}

						return '';
					},
					valueGetter: (params: GridValueGetterParams<GridValidRowModel>) =>
						params.row.hasRejections,
				});
			}
			if (user.isApprover) {
				gridColumns.unshift({
					field: columnDefinitionNames.hasPendingChanges,
					headerName: '',
					width: 50,
					sortable: true,
					hideSortIcons: false,
					filterable: false,
					disableExport: true,
					align: 'center',
					renderCell: (params: GridRenderCellParams<GridValidRowModel>) => {
						if (params.row.hasChanges) {
							return (
								<Tooltip title={columnDefinitionNames.hasPendingChanges}>
									<IconButton
										onClick={() => {
											navigate(params.id as string, FieldViews.review);
										}}
									>
										<Error
											color="primary"
											aria-label={columnDefinitionNames.hasPendingChanges}
										/>
									</IconButton>
								</Tooltip>
							);
						}

						return '';
					},
					valueGetter: (params: GridValueGetterParams<GridValidRowModel>) =>
						params.row.hasChanges,
				});
			}
		}

		gridColumns.unshift({
			...getOptionsMenuColDef((params: GridCellParams) => (
				<OverflowMenu
					id={`${objectType}-more-menu`}
					iconButtonProps={{
						...stonlyData({ id: stonlyIds.optionsMenu }),
					}}
				>
					{user.isAuthor ? (
						<MenuItem
							onClick={() => {
								navigate(params.id as string, FieldViews.edit);
							}}
							{...stonlyData({ id: stonlyIds.listEdit })}
						>
							<ListItemText>Edit</ListItemText>
						</MenuItem>
					) : null}
					{user.isApprover && approvalConfiguration.isActive ? (
						<MenuItem
							onClick={() => {
								navigate(params.id as string, FieldViews.review);
							}}
							{...stonlyData({ id: stonlyIds.listReview })}
						>
							<ListItemText>Review</ListItemText>
						</MenuItem>
					) : null}
					<MenuItem
						onClick={() => {
							navigate(params.id as string, FieldViews.default);
						}}
						{...stonlyData({ id: stonlyIds.listView })}
					>
						<ListItemText>View</ListItemText>
					</MenuItem>
					{user.isAuthor && <Divider />}
					{user.isAuthor && (
						<DeleteMenuItem
							onClick={() => {
								const getObjectCode = (): string => {
									switch (objectType) {
										case EntityType.Entity:
											return params.row[entityFieldIdentifiers.code];
										case EntityType.Partner:
											return params.row[partnerFieldIdentifiers.code];
										case EntityType.Counterparty:
											return params.row[counterpartyFieldIdentifiers.code];
										case EntityType.Account:
											return params.row[accountFieldIdentifiers.code];
										case EntityType.Staff:
											return params.row[staffFieldIdentifiers.code];
										default:
											return '';
									}
								};
								setObjectToDelete({
									id: params.row.id as string,
									code: getObjectCode(),
								});
								setDeleteModalOpen(true);
							}}
						/>
					)}
				</OverflowMenu>
			)),
		});

		return gridColumns;
	}, [
		initialColumns,
		objectType,
		tags,
		approvalConfiguration.isActive,
		isEditMode,
		successfulEdits,
		entity4StaffPii,
		user.isAuthor,
		user.isStaffUser,
		user.isApprover,
		parentObjectType,
		parentObjectId,
		getOptionList,
		getReferenceList,
		theme.palette.text.primary,
		theme.palette.text.disabled,
		legalEntityGroupCollections,
		navigate,
	]);

	const columnVisibilityModel = useMemo(() => {
		let columnVisiblityModel: any = {};
		initialColumns.forEach((col) => {
			columnVisiblityModel[col.identifier] = col.isDefaultListColumn;
		});

		return columnVisiblityModel;
	}, [initialColumns]);

	const actions = useMemo(() => {
		let actions: PageHeaderProps['actions'] = [
			{
				stonlyId: 'header-view-button',
				testId: 'header-view-button',
				label: 'View',
				action: () => {
					if (isEditMode) {
						if (
							Object.values(successfulEdits)
								.flatMap((x) => x)
								.includes('legalEntityGroupNames')
						) {
							refetchLegalEntityGroups();
						}
						setSuccessfulEdits({});
						setShowDoneButton(true);
					}

					setIsEditMode(false);
				},
			},
		];

		if (user.isAuthor) {
			actions.push({
				stonlyId: 'header-edit-button',
				testId: 'header-edit-button',
				label: 'Edit',
				action: () => {
					setIsEditMode(true);
					setShowDoneButton(true);
				},
			});
		}

		return actions;
	}, [isEditMode, refetchLegalEntityGroups, successfulEdits, user.isAuthor]);

	const onCellClick = useCallback(
		function (params: GridCellParams) {
			let model = {} as GridCellModesModel;
			const definition = initialColumns.find(
				(x) => x.identifier === params.field,
			);
			if (
				params.field !== columnDefinitionNames.hasPendingChanges &&
				params.field !== columnDefinitionNames.hasRejections &&
				params.field !== 'Options' &&
				!definition?.isAspectField
			) {
				if (isEditMode) {
					if (params.isEditable) {
						model[params.id] = {};
						model[params.id][params.field] = {
							mode: GridCellModes.Edit,
						};
					}
				}
			}
			return model;
		},
		[initialColumns, isEditMode],
	);

	const updateLegalEntityGroups = useCallback(
		async (
			entityId: string,
			newRow: GridValidRowModel,
			oldRow: GridValidRowModel,
		): Promise<GridValidRowModel> => {
			try {
				const newIds: string[] =
					newRow.legalEntityGroups?.map((x: LegalEntityGroup) => x.id) ?? [];
				const oldIds: string[] =
					oldRow.legalEntityGroups?.map((x: LegalEntityGroup) => x.id) ?? [];
				if (areArraysEqual(newIds, oldIds)) return newRow;

				const addedLegalEntityGroupIds = newIds.filter(
					(x) => !oldIds.includes(x),
				);
				const removedLegalEntityGroupIds = oldIds.filter(
					(x) => !newIds.includes(x),
				);

				let requests: [
					Promise<AddLegalEntityGroupsResponse>?,
					Promise<RemoveLegalEntityGroupsResponse>?,
				] = [];
				if (addedLegalEntityGroupIds.length > 0) {
					requests.push(
						customerApiClient.entity4.entities.addLegalEntityGroups({
							legalEntityId: entityId,
							legalEntityGroupIds: addedLegalEntityGroupIds,
						}),
					);
				}

				if (removedLegalEntityGroupIds.length > 0) {
					requests.push(
						customerApiClient.entity4.entities.removeLegalEntityGroups({
							legalEntityId: entityId,
							legalEntityGroupIds: removedLegalEntityGroupIds,
						}),
					);
				}
				const [addedResponse, removedResponse] = await Promise.all(requests);
				let newState: LegalEntityGroup[] = [
					...(newRow.legalEntityGroups ?? []),
				];
				if (!(addedResponse === undefined || addedResponse?.data?.success)) {
					newState = newState.filter(
						(x) => !addedLegalEntityGroupIds.includes(x.id),
					);
				}

				if (
					!(removedResponse === undefined || removedResponse?.data?.success)
				) {
					newState = newState.concat(
						(oldRow.legalEntityGroups ?? []).filter((x: LegalEntityGroup) =>
							removedLegalEntityGroupIds.includes(x.id),
						),
					);
				}

				setSuccessfulEdits((previous) => ({
					...previous,
					[newRow.id]: [
						...new Set([
							...(previous[newRow.id] ?? []),
							'legalEntityGroupNames',
						]),
					],
				}));

				return {
					...newRow,
					legalEntityGroups: newState,
				};
			} catch {
				return oldRow;
			}
		},
		[customerApiClient],
	);

	const updateEntityObjectsTags = useCallback(
		async (
			entityId: string,
			newRow: GridValidRowModel,
			oldRow: GridValidRowModel,
		): Promise<GridValidRowModel> => {
			try {
				const newIds: string[] = newRow.tags?.map((x: Tag) => x.tagId) ?? [];
				const oldIds: string[] = oldRow.tags?.map((x: Tag) => x.tagId) ?? [];

				if (areArraysEqual(newIds, oldIds)) return newRow;

				const tagsToAdd = newRow.tags.filter(
					(x: Tag) => !oldIds.includes(x.tagId),
				);
				const tagsToRemove = oldRow.tags.filter(
					(x: Tag) => !newIds.includes(x.tagId),
				);

				let requests: [Promise<AddTagResponse>?, Promise<RemoveTagResponse>?] =
					[];

				if (tagsToAdd.length > 0) {
					tagsToAdd.forEach((tagToAdd: Tag) => {
						requests.push(
							// Subaccount note: We do not allow tagging subaccounts atm, so no need to pass in parentObjectId or parentObjectType.
							customerApiClient.entity4.tags.add({
								data: { name: tagToAdd.name },
								entityType: objectType,
								entityId: entityId,
							}),
						);
					});
				}

				if (tagsToRemove.length > 0) {
					tagsToRemove.forEach((tagToRemove: Tag) => {
						requests.push(
							// Subaccount note: We do not allow tagging subaccounts atm, so no need to pass in parentObjectId or parentObjectType.
							customerApiClient.entity4.tags.remove({
								entityType: objectType,
								entityId: entityId,
								tagId: tagToRemove.tagId,
							}),
						);
					});
				}

				const [addedResponse, removedResponse] = await Promise.all(requests);
				let newTagState: Tag[] = [...(newRow.tags ?? [])];

				if (
					addedResponse === undefined ||
					addedResponse.status !== 200 ||
					!addedResponse.data.success
				) {
					newTagState = newTagState.filter(
						(tag) => !tagsToAdd.includes(tag.tagId),
					);
				}

				if (
					removedResponse === undefined ||
					removedResponse.status !== 200 ||
					!removedResponse.data.success
				) {
					newTagState = newTagState.concat(
						(oldRow.tags ?? []).filter((tag: Tag) =>
							tagsToRemove.includes(tag.tagId),
						),
					);
				}

				setSuccessfulEdits((previous) => ({
					...previous,
					[newRow.id]: [...new Set([...(previous[newRow.id] ?? []), 'tags'])],
				}));

				return {
					...newRow,
					tags: newTagState,
				};
			} catch {
				return oldRow;
			}
		},
		[customerApiClient, objectType],
	);

	const processRowUpdate = useCallback(
		async (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
			let rowToUpdate = newRow;
			const isValueEqual = (
				definition: IListColumnDto,
				oldValue: any,
				newValue: any,
			): boolean => {
				if (definition.fieldType === FieldDataType.date) {
					const oldDate = convertDate(oldValue);
					const newDate = convertDate(newValue);
					if (oldDate !== null && newDate !== null)
						return oldDate.isSame(newDate);
				}

				return oldValue === newValue;
			};

			Object.keys(newRow).forEach(async (identifier) => {
				// get field defintion for current update
				const definition = initialColumns.find(
					(x) => x.identifier === identifier,
				);

				/**
				 * NOTE: for the identifiers postfixed with "_data" the definition will be undefined (this is on purpose for reference and option fields)
				 * only send updates when definition exists
				 */
				if (definition !== undefined) {
					if (
						!isValueEqual(definition, oldRow[identifier], newRow[identifier])
					) {
						// set correct value to send to api based on what type of field it is
						let valueToSave = newRow[identifier];
						if (definition.fieldType === FieldDataType.options) {
							valueToSave = newRow[`${identifier}_data`]?.id;
						} else if (definition.referenceCollectionName) {
							valueToSave = referenceDataToJson(newRow[`${identifier}_data`]);
						}

						await updateField(newRow.id, identifier, valueToSave);
						setSuccessfulEdits((previous) => ({
							...previous,
							[newRow.id]: [
								...new Set([...(previous[newRow.id] ?? []), identifier]),
							],
						}));
						rowToUpdate = newRow;
					}
				} else if (identifier === 'legalEntityGroups') {
					rowToUpdate = await updateLegalEntityGroups(
						newRow.id,
						newRow,
						oldRow,
					);
				} else if (identifier === 'tags') {
					rowToUpdate = await updateEntityObjectsTags(
						newRow.id,
						newRow,
						oldRow,
					);
				}
			});

			return rowToUpdate;
		},
		[
			initialColumns,
			updateField,
			updateLegalEntityGroups,
			updateEntityObjectsTags,
		],
	);

	//#endregion
	const tableKey = useMemo(
		() => `${objectType.toString()}ListPage`,
		[objectType],
	);
	const stonlyId = useMemo(() => `${objectType.toString()}-list`, [objectType]);
	const emptyMessage = useMemo(
		() => `No ${objectType.toString()} found`,
		[objectType],
	);

	const listGrid = useMemo(
		() => (
			<Grid
				container
				sx={{
					flexDirection: 'column',
					flexWrap: 'nowrap',
					height: '100%',
					gap: 2,
				}}
			>
				<Grid
					container
					item
					xs="auto"
					sx={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 1,
						flexWrap: 'nowrap',
					}}
				>
					<Grid container item xs="auto" sx={{ gap: 2 }}>
						<Grid item xs="auto">
							<LegalEntityGroupsFilter loading={loading} />
						</Grid>
						{(objectType === 'accounts' || objectType === 'subaccounts') && (
							<Grid item xs="auto">
								<AccountGroupsFilter />
							</Grid>
						)}
					</Grid>
					{user.isAuthor && (
						<Grid
							container
							item
							xs={4}
							sx={{
								gap: 2,
								marginBottom: '4px',
								justifyContent: 'flex-end',
							}}
						>
							{showDoneButton && !showOverflowMenu && (
								<Grid item xs="auto">
									<Button
										variant="outlined"
										startIcon={<Check />}
										onClick={() => {
											setIsEditMode(false);
											setShowDoneButton(false);
											setSuccessfulEdits({});
										}}
										sx={{
											background: 'white',
											verticalAlign: 'center',
											'&:hover': {
												background: 'white',
											},
										}}
									>
										Done
									</Button>
								</Grid>
							)}
							{!showDoneButton && !showOverflowMenu && (
								<Grid item>
									<Button
										startIcon={<Edit />}
										type="button"
										variant="outlined"
										color="primary"
										onClick={() => {
											setIsEditMode(true);
											setShowDoneButton(true);
										}}
										disabled={isEditMode}
									>
										Manage
									</Button>
								</Grid>
							)}
							<Grid item>
								<CreateEntityButton
									objectType={objectType}
									parentObjectId={parentObjectId}
									parentObjectType={parentObjectType}
									creationFields={creationFields}
									disabled={isEditMode || loading}
									getReferenceList={getReferenceList}
									getOptionList={getOptionList}
								/>
							</Grid>

							{showImportButton && (
								<Grid item>
									<CreateDataImportButton
										navigateToDataImports={true}
										initialDataType={objectType}
										disabled={isEditMode || loading}
									/>
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						'& .sensitive': {
							backgroundColor: 'rgba(0, 0, 0, 0.06)',
						},
						width: '100%',
					}}
				>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							tableKey={tableKey}
							stonlyId={stonlyId}
							calculateColumnWidths
							columns={columns}
							rows={initialRows}
							loading={loading}
							columnVisibilityModel={columnVisibilityModel}
							pagination
							hideFooter={false}
							errorMessage={error}
							emptyMessage={emptyMessage}
							density="compact"
							showToolbar
							showCustomViewButton
							isCellEditable={() => isEditMode}
							cellModesModel={cellModesModel}
							onCellModesModelChange={(newModel: GridCellModesModel) => {
								setCellModesModel(newModel);
							}}
							onCellClick={(params: GridCellParams) => {
								setCellModesModel(onCellClick(params));
							}}
							processRowUpdate={(
								newRow: GridValidRowModel,
								oldRow: GridValidRowModel,
							) => {
								return processRowUpdate(newRow, oldRow);
							}}
							onProcessRowUpdateError={(error: any) => {
								enqueueSnackbar(`An unexpected error occurred: ${error}`, {
									variant: 'error',
								});
							}}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>

				<DeleteObjectModal
					isOpen={deleteModalOpen}
					objectType={objectType}
					objectCode={objectToDelete?.code}
					objectId={objectToDelete?.id}
					closeModal={() => {
						setDeleteModalOpen(false);
						setObjectToDelete(undefined);
					}}
					afterDelete={() => refetch()}
					parentObjectId={parentObjectId}
				/>
			</Grid>
		),
		[
			objectType,
			parentObjectType,
			parentObjectId,
			user,
			loading,
			isEditMode,
			tableKey,
			stonlyId,
			columns,
			initialRows,
			columnVisibilityModel,
			error,
			emptyMessage,
			cellModesModel,
			creationFields,
			getOptionList,
			getReferenceList,
			deleteModalOpen,
			objectToDelete,
			showDoneButton,
			showImportButton,
			showOverflowMenu,
			refetch,
			onCellClick,
			processRowUpdate,
			enqueueSnackbar,
		],
	);

	if (objectType === EntityType.Account && !user.isAccountUser) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}

	return (
		<>
			{parentObjectId ? (
				listGrid
			) : (
				<T4View
					header={
						<PageHeader
							id={pageTitle ?? 'listpage'}
							title={pageTitle}
							isEditMode={isEditMode}
							showDoneButton={isEditMode}
							actions={actions}
							showOverflowMenu={showOverflowMenu}
						/>
					}
				>
					{listGrid}
				</T4View>
			)}
		</>
	);
});
