import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { stonlyData } from 'stonly/functions';

/* eslint-disable mobx/missing-observer */
interface E4EntityLinkProps {
	e4EntityId?: string | null;
	e4EntityName?: string | null;
	stonlyPrefix?: string;
}

export const E4EntityLink = ({
	e4EntityId,
	e4EntityName,
	stonlyPrefix,
}: E4EntityLinkProps) => {
	const stonlyId = stonlyPrefix
		? stonlyData({
				id: `${stonlyPrefix}-link-to-e4-entity`,
		  })
		: {};

	return (
		<Link
			component={RouterLink}
			to={`/entity4/entities/${e4EntityId}/information`}
			target="_blank"
			rel="noreferrer"
			sx={{
				color: 'white',
				textDecoration: 'underline',
			}}
			{...stonlyId}
		>
			{e4EntityName}
		</Link>
	);
};
