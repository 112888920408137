import { BankConnectionSettingsFieldDefinition } from '../constants';

export type ChasConnectionSettings = {
	transportCertificateName: string | null;
	signingCertificateName: string | null;
};

export const chasConnectionSettingsForm: ChasConnectionSettings = {
	transportCertificateName: null,
	signingCertificateName: null,
};

export const chasConnectionSettingsFieldDefinitions: BankConnectionSettingsFieldDefinition[] =
	[
		{
			key: 'transportCertificateName',
			name: 'Transport Certificate',
			type: 'certificate',
		},
		{
			key: 'signingCertificateName',
			name: 'Signing Certificate',
			type: 'certificate',
		},
	];
